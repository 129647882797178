import { useState } from 'react';
import { callRequest as baseCallRequest } from 'utils/request';

const useCallRequest = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	const callRequest = async ({
		endpoint,
		data,
		endpointParameters = null,
		headers = null,
		encode = false,
		customExtendConfig = null,
		handleSuccess = () => null,
		handleError = () => null
	}) => {
		setError(false);
		setLoading(true);
		try {
			const response = await baseCallRequest(
				endpoint,
				data,
				endpointParameters,
				headers,
				encode,
				customExtendConfig
			);
			setLoading(false);

			handleSuccess(response);

			return response;
		} catch (e) {
			setLoading(false);
			handleError(e);
			setError(true);
		}
	};
	return { callRequest, error, loading };
};

export default useCallRequest;
