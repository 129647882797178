/* istanbul ignore next */
/* istanbul ignore file */
import PropTypes from 'prop-types';
import isURL from 'validator/lib/isURL';
import { momentObj } from 'react-moment-proptypes';
import icons from 'components/Icon/icons.json';

export const optionShape = PropTypes.shape({
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
});

export const fieldValue = PropTypes.oneOfType([
	PropTypes.string,
	PropTypes.bool, // checkbox, radio, switch
	momentObj, // date
	PropTypes.oneOfType([
		// option
		optionShape,
		PropTypes.arrayOf(optionShape)
	])
]);

export const reduxInputPropTypes = PropTypes.shape({
	name: PropTypes.string,
	value: fieldValue,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func
});

export const reduxMetaPropTypes = PropTypes.shape({
	active: PropTypes.bool,
	autofilled: PropTypes.bool,
	asyncValidating: PropTypes.bool,
	dirty: PropTypes.bool,
	dispatch: PropTypes.func,
	error: PropTypes.string,
	form: PropTypes.string,
	initial: fieldValue,
	invalid: PropTypes.bool,
	pristine: PropTypes.bool,
	submitting: PropTypes.bool,
	submitFailed: PropTypes.bool,
	touched: PropTypes.bool,
	valid: PropTypes.bool,
	visited: PropTypes.bool,
	warning: PropTypes.bool
});

export const rowDataPropTypes = PropTypes.shape({
	appliesToLogistics: PropTypes.bool,
	areaInCharge: PropTypes.string,
	flags: PropTypes.string,
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	motiveName: PropTypes.string,
	name: PropTypes.string,
	parentName: PropTypes.string,
	sla: PropTypes.number,
	status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	statusColor: PropTypes.string
});

export const iconName = PropTypes.oneOfType([PropTypes.oneOf(Object.keys(icons)), PropTypes.bool]);

export const url = (props, propName, componentName) => {
	if (typeof props[propName] === 'undefined') return null;

	if (!isURL(props[propName], { require_host: false }))
		return new Error(`Invalid prop ${propName} supplied to ${componentName}. Validation failed`);
};

export const styles = PropTypes.oneOfType([
	PropTypes.string,
	PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func]))
]);

export const itemsCheckListPropTypes = PropTypes.arrayOf(
	PropTypes.shape({
		name: PropTypes.string.isRequired,
		label: PropTypes.string,
		translateLabel: PropTypes.bool,
		value: PropTypes.number
	})
);
export const groupsCheckListPropTypes = PropTypes.arrayOf(
	PropTypes.shape({
		name: PropTypes.string.isRequired,
		label: PropTypes.string,
		translateLabel: PropTypes.bool,
		items: itemsCheckListPropTypes
	})
);
export const sectionsCheckListPropTypes = PropTypes.arrayOf(
	PropTypes.shape({
		name: PropTypes.string.isRequired,
		label: PropTypes.string,
		translateLabel: PropTypes.bool,
		groups: groupsCheckListPropTypes
	})
);

export const fieldsReduxFormPropTypes = PropTypes.shape({
	forEach: PropTypes.func.isRequired,
	get: PropTypes.func.isRequired,
	getAll: PropTypes.func.isRequired,
	insert: PropTypes.func.isRequired,
	length: PropTypes.number.isRequired,
	map: PropTypes.func.isRequired,
	move: PropTypes.func.isRequired,
	pop: PropTypes.func.isRequired,
	push: PropTypes.func.isRequired,
	remove: PropTypes.func.isRequired,
	removeAll: PropTypes.func.isRequired,
	shift: PropTypes.func.isRequired,
	swap: PropTypes.func.isRequired,
	unshift: PropTypes.func.isRequired
});

export const ErrorDataResponse = PropTypes.shape({
	status: PropTypes.number
});

export const mapperPropTypes = PropTypes.oneOfType([
	PropTypes.array,
	PropTypes.string,
	PropTypes.shape({})
]);

export const BrowseSchemaPropTypes = PropTypes.shape({
	source: PropTypes.shape({
		service: PropTypes.string.isRequired,
		namespace: PropTypes.string.isRequired,
		method: PropTypes.string.isRequired
	}),
	canEdit: PropTypes.bool,
	canView: PropTypes.bool,
	name: PropTypes.string.isRequired,
	fields: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			component: PropTypes.string.isRequired,
			mapper: mapperPropTypes,
			componetAttributes: PropTypes.shape({}),
			filter: PropTypes.shape({})
		})
	),
	pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	canPreview: PropTypes.bool
});
