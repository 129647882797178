import { getAuthCookieDecoded } from 'utils/auth';
import * as types from './types';

export const fetchUserId = id => ({
	type: types.SET_USER_ID,
	id
});

export const fetchUserIdFailure = () => ({
	type: types.SET_USER_ID_FAILURE
});

const fetchUserDataRequest = () => ({
	type: types.FETCH_USER_DATA_REQUEST
});

export const fetchUserDataSuccess = ({
	family_name: lastname,
	given_name: firstname,
	...clientData
}) => ({
	type: types.FETCH_USER_DATA_SUCCESS,
	clientData: { ...clientData, firstname, lastname }
});

export const fetchUserDataFailure = () => ({
	type: types.FETCH_USER_DATA_FAILURE
});

export const fetchUserData = () => dispatch => {
	try {
		dispatch(fetchUserDataRequest());

		// Get current account data
		const userAccountData = getAuthCookieDecoded();

		dispatch(fetchUserDataSuccess(userAccountData));
	} catch (e) {
		dispatch(fetchUserDataFailure());
	}
};
