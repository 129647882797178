export const CREATE_PAGE = 'editRoute/CREATE_PAGE';
export const FETCH_ROUTE_DATA_SUCCESS = 'editRoute/FETCH_ROUTE_DATA_SUCCESS';
export const CHANGE_SELECTED_ROWS = 'editRoute/CHANGE_SELECTED_ROWS';
export const SET_STOPS = 'editRoute/SET_STOPS';
export const SET_LOADING = 'editRoute/SET_LOADING';
export const SAVE_DRIVER_DATA = 'editRoute/SAVE_DRIVER_DATA';
export const SET_ERROR = 'editRoute/SET_ERROR';
export const SAVE_STOPS_COLOR = 'editRoute/SAVE_STOPS_COLOR';
export const SET_ROUTE_DATA = 'editRoute/SET_ROUTE_DATA';
export const CHANGE_SHIPPINGS = 'editRoute/CHANGE_SHIPPINGS';
export const SET_SCHEDULE = 'editRoute/SET_SCHEDULE';
export const UPDATE_SEVERAL_FIELDS = 'editRoute/UPDATE_SEVERAL_FIELDS';
