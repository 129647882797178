import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CustomTooltip from 'components/CustomTooltip';
import Icon from 'components/Icon';
import UseDevicesWrapper from 'components/UseDevicesWrapper';
import { getUserData } from 'utils/api';
import translationHOC from 'hocs/translationHOC';
import styled from './styles';
import Chip from './Chip';

class UserChip extends PureComponent {
	state = {
		data: {
			email: this.props.email,
			firstname: this.props.firstName,
			image: this.props.image || this.defaultImageUrl,
			lastname: this.props.lastName,
			status: this.props.status
		},
		errorMessage: '',
		hasError: false,
		noUser: false,
		isLoading: true,
		tooltipFocused: false
	};

	async componentDidMount() {
		const {
			firstname: preloadedFirstName,
			lastname: preloadedLastName,
			email: preloadedEmail
		} = this.state.data;

		if (preloadedFirstName || preloadedLastName || preloadedEmail) {
			return this.setState({
				isLoading: false
			});
		}

		if (!this.props.id && this.props.id !== 0) {
			return this.setState({
				noUser: true
			});
		}

		try {
			if (
				preloadedFirstName !== undefined ||
				preloadedLastName !== undefined ||
				preloadedEmail !== undefined
			)
				return this.setError('userDataNotFound');

			const { source } = this.props;

			const data = await getUserData(this.props.id, source);

			if (!Object.keys(data).length) this.setError('userDataNotFound');

			this.setState({ data, isLoading: false });
		} catch {
			this.setError('cannotFetchUserData');
		}
	}

	setError(errorKey) {
		this.setState({
			errorMessage: `views.error.${errorKey}`,
			hasError: true
		});
	}

	toggleTooltip() {
		this.setState(prevState => ({
			tooltipFocused: !prevState.tooltipFocused
		}));
	}

	render() {
		const { data, errorMessage, hasError, noUser, isLoading } = this.state;

		const { showFull, variant, firstRow } = this.props;

		const userChipProps = {
			isLoading,
			...data,
			...this.props
		};

		if (noUser) return <div />;

		if (hasError)
			return (
				<styled.UserChip variant={variant}>
					<styled.InnerWrapper>
						<styled.UserImageWrapper>
							<Icon name="exclamation_circle" color="statusRed" />
						</styled.UserImageWrapper>
						<styled.ErrorText>{this.props.t(errorMessage)}</styled.ErrorText>
					</styled.InnerWrapper>
				</styled.UserChip>
			);

		return showFull ? (
			<Chip {...userChipProps} />
		) : (
			<UseDevicesWrapper>
				{({ onlyMobile }) => (
					<CustomTooltip
						content={
							<styled.Tooltip firstRow={firstRow}>
								<Chip {...userChipProps} showFull variant="none" />
							</styled.Tooltip>
						}
						handlers={onlyMobile ? { onClick: () => this.toggleTooltip() } : undefined}
						outsideFocus={onlyMobile ? this.state.tooltipFocused : undefined}
					>
						<Chip {...userChipProps} />
					</CustomTooltip>
				)}
			</UseDevicesWrapper>
		);
	}
}

UserChip.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	email: PropTypes.string,
	firstName: PropTypes.string,
	image: PropTypes.string,
	lastName: PropTypes.string,
	/** If the data should be shown without cropping */
	showFull: PropTypes.bool,
	status: PropTypes.string,
	variant: PropTypes.string
};

export default translationHOC(UserChip);
