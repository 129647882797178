import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions as actionsMenu } from 'modules/menu';
import { removeHistoryMenu, goBackHistoryMenu } from 'utils/mobileMenuHistory';
import { getCurrentHistory } from 'utils/location';

class HandleHistory extends React.PureComponent {
	componentDidMount() {
		const history = getCurrentHistory();
		const {
			location: { hash }
		} = history;

		if (hash === '#menu') {
			removeHistoryMenu();
		}

		window.onpopstate = event => {
			event.preventDefault();
			const {
				isNavbarOpen,
				selectedModule,
				selectedCategory,
				isMenuOpen,
				closeMenu,
				closeCategory
			} = this.props;

			const currentHistory = getCurrentHistory();

			const {
				location: { hash: currentHash }
			} = currentHistory;

			if (isNavbarOpen || isMenuOpen) {
				// if redux state have navbar or menu open
				if (currentHash === '#menu') {
					// if url path contain #menu
					if (selectedCategory) {
						//	if have selected category in redux
						closeCategory(false);
					} else if (!selectedCategory && selectedModule) {
						//	if havent selected category and has selected module in redux
						closeMenu(false);
					}
				} else {
					// if path doesn't contain #menu
					goBackHistoryMenu();
					closeMenu(true);
				}
			}
			if (!isNavbarOpen && !isMenuOpen && currentHash === '#menu') {
				goBackHistoryMenu();
			}
		};
	}

	componentWillUnmount() {
		window.onpopstate = null;
	}

	render() {
		const { children } = this.props;

		return <>{children}</>;
	}
}

HandleHistory.propTypes = {
	children: PropTypes.node,
	closeMenu: PropTypes.func,
	closeCategory: PropTypes.func,
	isNavbarOpen: PropTypes.bool,
	isMenuOpen: PropTypes.bool,
	selectedModule: PropTypes.string,
	selectedCategory: PropTypes.string
};

const mapStateToProps = state => ({
	isMenuOpen: state.menu.isOpen,
	isNavbarOpen: state.navbar.isOpen,
	selectedCategory: state.menu.selectedCategory,
	selectedModule: state.navbar.selectedModule
});

const mapDispatchToProps = {
	closeMenu: actionsMenu.close,
	closeCategory: actionsMenu.closeCategory
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(HandleHistory);
