import styled from 'styled-components';
import { colors, font, mixins } from 'theme';

export default {
	Action: styled.span`
		text-transform: uppercase;
		color: ${colors.darkGrey};
		font-size: ${font.small};
		font-weight: 900;
		letter-spacing: 0.5px;
		line-height: 14px;
		display: flex;
		align-items: center;
		white-space: nowrap;
		margin-right: 8px;
	`,
	ItemWrapper: styled.div`
		display: flex;
		align-items: center;
		margin-bottom: 8px;
	`,
	SectionTitle: styled.h3`
		margin-top: 12px;
		text-transform: uppercase;
		color: ${colors.black};
		font-size: ${font.base};
		font-weight: 900;
		letter-spacing: 0.5px;
		line-height: 14px;
		display: flex;
		align-items: center;
		white-space: nowrap;
		width: 100%;
		height: 24px;
		&:after {
			content: '';
			background-color: ${colors.black};
			height: 1px;
			width: 100%;
			margin-left: 16px;
		}
	`,
	SectionItemsWrapper: styled.div`
		display: flex;
		flex-direction: column;
		margin: 12px 8px 0px;
	`,
	Wrapper: styled.div`
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		${mixins.scrollbar(colors.lightGreyHover)}
	`
};
