import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions } from 'modules/menu';
import { useDevices } from 'utils/hooks';
import Button from 'components/Button';
import { back as styled } from './styles';

const Back = ({ closeCategory, isVisible, noTransition }) => {
	const { tabletLg: onlyMobile } = useDevices();
	return (
		<styled.Container isVisible={isVisible} noTransition={noTransition}>
			<Button icon="arrow_left" rounded onClick={() => closeCategory(onlyMobile)} />
		</styled.Container>
	);
};

Back.propTypes = {
	closeCategory: PropTypes.func.isRequired,
	isVisible: PropTypes.bool.isRequired,
	noTransition: PropTypes.bool.isRequired
};

const mapStateToProps = ({ menu, navbar }) => ({
	isVisible: !!menu.selectedCategory,
	noTransition: navbar.isChangingModule
});

const mapDispatchToprops = {
	closeCategory: actions.closeSelectedCategory
};

export default connect(
	mapStateToProps,
	mapDispatchToprops
)(Back);
