/* eslint-disable no-restricted-syntax */
import { createSelectorCreator, createSelector } from 'reselect';
import { memoize } from 'lodash';
import { translateHelperString as translateHelper } from 'utils/translate';
import { normalize } from 'utils/string';

// hashFn for selector parameters on memoized function
const hashFn = (...args) => args.reduce((acc, val) => `${acc}-${JSON.stringify(val)}`, '');

// Custom createSelector using lodash memoize for an unbounded cache size
const customCreateSelector = createSelectorCreator(memoize, hashFn);

export const getSelectedModule = state => state.navbar.selectedModule;
export const getSelectedCategory = state => state.menu.selectedCategory;
export const getMenuSource = state => state.menu.source;

export const getCurrentCategories = customCreateSelector(
	[getSelectedModule, getMenuSource],
	(selectedModule, menuSource) => {
		const module = menuSource.find(({ name }) => name === selectedModule);
		return module.children || [];
	}
);

export const getSearchTerm = state => state.menu.searchTerm;

export const hasSearchTerm = createSelector(
	getSearchTerm,
	searchTerm => !!searchTerm
);

const categoryIncludesSearchTerm = searchTerm => category => {
	const name = translateHelper(`views.menu.${category.name}`);

	return normalize(name.toLowerCase()).includes(normalize(searchTerm.toLowerCase()));
};

export const getCurrentFilteredCategories = customCreateSelector(
	getCurrentCategories,
	getSearchTerm,
	getMenuSource,
	(currentCategories, searchTerm) =>
		currentCategories.filter(categoryIncludesSearchTerm(searchTerm))
);

export const searchCategory = (tree, categoryName) => {
	const stack = tree.map(node => ({ node, path: [] }));

	for (const { node, path } of stack) {
		if (node.name === categoryName) return { node, path };

		if (node.children && node.children.length) {
			const childrenWithParentPath = node.children.map(child => ({
				node: child,
				path: [...path, node.name]
			}));

			stack.push(...childrenWithParentPath);
		}
	}

	return null;
};

export const getCurrentSubcategories = customCreateSelector(
	getSelectedModule,
	getSelectedCategory,
	getMenuSource,
	(selectedModule, selectedCategory, menuSource) => {
		if (!selectedModule || !selectedCategory) return [];

		const { node: module } = searchCategory(menuSource, selectedModule);

		if (!module || !module.children) return [];

		const { node: category } = searchCategory(module.children, selectedCategory);

		return category?.children || [];
	}
);

export const getCurrentFilteredSubcategories = customCreateSelector(
	getCurrentSubcategories,
	getSearchTerm,
	(currentCategories, searchTerm) =>
		currentCategories.filter(categoryIncludesSearchTerm(searchTerm))
);

export const isEmptySearch = customCreateSelector(
	getSelectedCategory,
	getCurrentFilteredCategories,
	getCurrentSubcategories,
	hasSearchTerm,
	(selectedCategory, categories, subcategories, hasSearchText) => {
		if (!hasSearchText) return false;

		return selectedCategory ? subcategories.length === 0 : categories.length === 0;
	}
);
