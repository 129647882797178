import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { stringify, parse } from 'qs';
import { useUserIsLogged } from 'hooks';
import Loading from 'components/PageLoader/Loading';

const getRedirectPath = withLoginHintRedirect => {
	const { pathname, search, hash } = window.location;
	const redirURL = encodeURIComponent(`${pathname}${search}${hash}`);
	const { login_hint: loginHint } = parse(search, { ignoreQueryPrefix: true });
	const searchParams =
		withLoginHintRedirect && loginHint
			? { redir: redirURL, login_hint: loginHint }
			: { redir: redirURL };
	return {
		pathname: '/login',
		search: stringify(searchParams, { addQueryPrefix: true })
	};
};

const PrivateRoute = ({ render, withLoginHintRedirect, ...props }) => {
	const { loading, userIsLogged } = useUserIsLogged();

	if (loading) return <Loading />;

	return (
		<Route
			{...props}
			render={renderProps =>
				userIsLogged ? (
					render(renderProps)
				) : (
					<Redirect
						to={{
							...getRedirectPath(withLoginHintRedirect),
							state: { from: renderProps.location }
						}}
					/>
				)
			}
		/>
	);
};

PrivateRoute.propTypes = {
	render: PropTypes.func,
	withLoginHintRedirect: PropTypes.bool
};

PrivateRoute.defaultProps = {
	withLoginHintRedirect: false
};

export default PrivateRoute;
