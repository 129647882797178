import styled from 'styled-components';
import { colors, mixins } from 'theme';
import Button from 'components/Button';
import Icon from 'components/Icon';

export default {
	Wrapper: styled.div`
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		${mixins.scrollbar(colors.lightGreyHover)}
	`,
	TitleWrapper: styled.div`
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 14px 0 0;
	`,
	Title: styled.h3`
		font-size: 12px;
		text-transform: uppercase;
		color: ${colors.black};
		font-weight: 900;
		letter-spacing: 0.5px;
		line-height: 14px;
	`,
	Icon: styled(Icon)`
		flex-shrink: 0;
	`,
	ContentWrapper: styled.div`
		margin-top: 25px;
		margin-bottom: auto;
	`,
	ActionsWrapper: styled.div`
		display: flex;
		justify-content: flex-end;
		margin-top: 30px;
		margin-bottom: 12px;
	`,
	Button: styled(Button)`
		margin-right: 8px;
	`,
	SpinnerWrapper: styled.div`
		background: ${colors.transparentWhite};
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1;
	`
};
