import React from 'react';
import { components } from 'react-select';
import UserImage from 'components/UserImage';
import { ControlContext } from './Control';
import styles from '../styles';

const limitChildren = props => {
	const {
		selectProps: { containerWidth, icon, maxChipWidth, value },
		children
	} = props;
	const maxVisibleChips = Math.floor((containerWidth - (icon ? 110 : 80)) / maxChipWidth);
	const [childrenOptions] = children;
	if (!value || !childrenOptions || !childrenOptions.length) return children;

	const filteredChildren = [...children];
	filteredChildren[0] = childrenOptions.slice(0, maxVisibleChips);

	const extraOptions = childrenOptions.length - filteredChildren[0].length;

	if (extraOptions > 0) {
		const extraOptionsText = extraOptions > 1 ? `(+${extraOptions} others)` : '(+1)';
		filteredChildren.push(extraOptionsText);
	}

	return filteredChildren;
};

const ValueContainer = props => {
	const { isIconSelector, hasFloatingLabel, isMulti, label, showValueImage } = props.selectProps;
	const [option = {}] = props.getValue();
	const { userData, value } = option;

	return (
		<ControlContext.Consumer>
			{control => (
				<components.ValueContainer {...props}>
					<styles.RowContainer>
						{showValueImage && !isMulti && userData && (
							<styles.ImageWrapper>
								<UserImage {...userData} />
							</styles.ImageWrapper>
						)}
						{isIconSelector && <styles.SelectIcon name={value} />}
						<div className="select-value-wrapper">
							{isMulti ? limitChildren(props) : props.children}
						</div>
						{hasFloatingLabel && (
							<div
								className={`select-floating-label ${
									control.isFocused ? 'select-floating-label--is-focused' : ''
								}`}
							>
								{label}
							</div>
						)}
					</styles.RowContainer>
				</components.ValueContainer>
			)}
		</ControlContext.Consumer>
	);
};
export default ValueContainer;
