import React from 'react';
import PropTypes from 'prop-types';
import { times } from 'lodash';
import { newId } from 'utils';
import GroupSkeleton from './GroupSkeleton';
import { styles as styled } from './styles';

const Loader = ({ schemaData, isEditSection, hasTopComponents }) => {
	const { fieldsGroup = [], topComponents = [], columnsType } = schemaData;

	if (!fieldsGroup.length) {
		return (
			<div>
				<styled.StyledSpinner />
			</div>
		);
	}

	const hasSideColumn =
		fieldsGroup.length > 1 && fieldsGroup.some(group => group.position === 'right');

	return (
		<styled.Wrapper
			isEditSection={isEditSection}
			hasTopComponents={hasTopComponents !== undefined ? hasTopComponents : !!topComponents.length}
		>
			<styled.MainColumn singleColumn={!hasSideColumn} columnsType={columnsType}>
				{times(fieldsGroup.length >= 2 ? 2 : 1, () => (
					<GroupSkeleton key={newId('loading')} hasIcon />
				))}
			</styled.MainColumn>
			{hasSideColumn && (
				<styled.SideColumn columnsType={columnsType}>
					{times(3, idx => (
						<GroupSkeleton key={newId('loading')} hasIcon={idx < 2} />
					))}
				</styled.SideColumn>
			)}
		</styled.Wrapper>
	);
};

Loader.propTypes = {
	schemaData: PropTypes.shape({}),
	isEditSection: PropTypes.bool,
	hasTopComponents: PropTypes.bool
};

Loader.defaultProps = {
	isEditSection: true
};

export default Loader;
