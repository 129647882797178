import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import translationHOC from 'hocs/translationHOC';
import { useDevices } from 'utils/hooks';
import { url as urlPropType, iconName as iconNamePropType } from 'utils/prop-types';
import { actions } from 'modules/navbar';
import { actions as menuActions } from 'modules/menu';
import Icon from 'components/Icon';
import { prefixName } from './utils';
import { modules as styled } from './styles';
import TagNew from './TagNew';

const makeOnClick = (name, onClick, url, closeMenu, onlyMobile) => () => {
	onClick(name, onlyMobile);
	if (url) {
		closeMenu();
	}
};

const Component = ({ children, url, ...props }) =>
	url ? (
		<styled.ModuleContainerLink href={url} {...props}>
			{children}
		</styled.ModuleContainerLink>
	) : (
		<styled.ModuleContainerButton {...props}>{children}</styled.ModuleContainerButton>
	);

const Module = ({ closeMenu, icon, isSelected, name, onClick, url, t, isNew = false }) => {
	const formatedName = prefixName(name);

	const { tabletLg: onlyMobile } = useDevices();
	return (
		<Component
			url={url}
			isSelected={isSelected}
			onClick={!isSelected ? makeOnClick(name, onClick, url, closeMenu, onlyMobile) : undefined}
		>
			<styled.IconContainer>
				<Icon name={icon} color={styled.iconColor} />
			</styled.IconContainer>
			{onlyMobile ? (
				<>
					<styled.Text color="white" fontSize="medium">
						{t(formatedName)}
						{isNew && <TagNew />}
					</styled.Text>
					<Icon name="chevron_right" color={styled.iconColor} />
				</>
			) : (
				<>
					<styled.Text>{t(formatedName)}</styled.Text>
					{isNew && <TagNew />}
				</>
			)}
		</Component>
	);
};

Module.propTypes = {
	closeMenu: PropTypes.func,
	icon: iconNamePropType.isRequired,
	isSelected: PropTypes.bool.isRequired,
	name: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	url: PropTypes.oneOfType([urlPropType, PropTypes.string]),
	isNew: PropTypes.bool
};

const ModuleTranslated = translationHOC(Module);

class Modules extends PureComponent {
	componentDidMount() {
		this.props.fetchMenu();
	}

	render() {
		const { closeMenu, menu, selectedModule, selectModule, isDev } = this.props;
		const filteredMenu = !isDev ? menu.filter(({ onlyDev }) => !onlyDev) : menu;

		return (
			<styled.Container>
				{filteredMenu.map(({ icon, url, ...module }) => (
					<ModuleTranslated
						key={module.name}
						isSelected={module.name === selectedModule}
						onClick={selectModule}
						closeMenu={closeMenu}
						icon={icon || 'circle_flat'}
						url={url || ''}
						{...module}
					/>
				))}
			</styled.Container>
		);
	}
}

Modules.propTypes = {
	fetchMenu: PropTypes.func,
	menu: PropTypes.arrayOf(PropTypes.shape({})),
	selectedModule: PropTypes.string.isRequired,
	selectModule: PropTypes.func.isRequired,
	isDev: PropTypes.bool
};

Modules.defaultProps = {
	isDev: false
};

const mapStateToProps = state => ({
	selectedModule: state.navbar.selectedModule,
	menu: state.menu.source,
	isDev: state.user.isDev
});

const mapDispatchToProps = {
	closeMenu: menuActions.close,
	fetchMenu: menuActions.fetchMenu,
	selectModule: actions.selectModule
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Modules);
