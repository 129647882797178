import React from 'react';
import { useDevices } from 'utils/hooks';
import SearchInput from './SearchInput';
import { searchbar as styled } from './styles';
import Back from './Back';

const Searchbar = props => {
	const { onlyDesktop } = useDevices();
	return (
		<styled.Searchbar>
			{onlyDesktop && <Back />}
			<SearchInput {...props} />
		</styled.Searchbar>
	);
};

export default Searchbar;
