import styled, { css, keyframes } from 'styled-components';
import { findColorInTheme } from 'theme';

const rotateAnimation = keyframes`
	100% {
		transform: rotate(360deg);
	}
`;

const rotateAnimationRule = css`
	animation: ${rotateAnimation} 2s linear infinite;
`;

const dashAnimation = keyframes`
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px;
	}
	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124px;
	}
`;

const dashAnimationRule = css`
	animation: ${dashAnimation} 1.5s ease-in-out infinite;
`;

export default {
	Loader: styled.div`
		position: relative;
		width: ${props => props.size}px;
		&:before {
			content: '';
			display: block;
			padding-top: 100%;
		}
	`,
	Circular: styled.svg`
		height: 100%;
		transform-origin: center center;
		width: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		${rotateAnimationRule};
	`,
	Path: styled.circle`
		stroke: ${props => findColorInTheme(props.color)};
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
		stroke-linecap: round;
		${dashAnimationRule};
	`
};
