import React from 'react';
import PropTypes from 'prop-types';
import { menu as styled } from './styles';

const MenuSubtitle = ({ children }) => <styled.MenuSubtitle>{children}</styled.MenuSubtitle>;

MenuSubtitle.propTypes = {
	children: PropTypes.node
};

export default MenuSubtitle;
