import React from 'react';
import PropTypes from 'prop-types';
import { useGetPlatform } from 'utils/hooks';
import translationHOC from 'hocs/translationHOC';
import Modal from 'components/Modal';
import Chip from 'components/Chip';
import styled from './styles';

const shortcutKey = 'common.shortcut';

const ShortcutModal = ({ showModal, handleCloseModal, t }) => {
	const { mac } = useGetPlatform();

	const ctrlKey = mac ? 'Command' : 'Control';
	const altKey = mac ? 'Option' : 'Alt';

	const sections = [
		{
			title: `${shortcutKey}.editCreate`,
			keys: [
				{
					key: `${ctrlKey} + S`,
					action: `${shortcutKey}.applyChanges`
				},
				{
					key: `${altKey} + S`,
					action: `${shortcutKey}.saveChanges`
				},
				{
					key: 'Esc',
					action: `${shortcutKey}.discardChanges`
				},
				{
					key: 'Shift + F',
					action: `${shortcutKey}.search`
				},
				{
					key: `${ctrlKey} + ${altKey} + C`,
					action: `${shortcutKey}.openClient`
				}
			]
		}
	];
	return (
		<Modal
			defaultTitle={t('common.title.availableShortcuts')}
			isOpen={showModal}
			handleCloseModal={handleCloseModal}
		>
			<styled.Wrapper>
				{sections.map(section => (
					<div key={section.title}>
						<styled.SectionTitle>{t(section.title)} </styled.SectionTitle>
						<styled.SectionItemsWrapper>
							{section.keys.map(item => (
								<styled.ItemWrapper key={item.action}>
									<styled.Action> {t(item.action)}</styled.Action>
									<Chip variant="contained">{item.key}</Chip>
								</styled.ItemWrapper>
							))}
						</styled.SectionItemsWrapper>
					</div>
				))}
			</styled.Wrapper>
		</Modal>
	);
};

ShortcutModal.propTypes = {
	showModal: PropTypes.bool,
	handleCloseModal: PropTypes.func
};

export default translationHOC(ShortcutModal);
