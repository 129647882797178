import styled from 'styled-components';
import { colors, font } from 'theme';
import { mediaBreaks } from 'devices';
import Icon from 'components/Icon';

const { onlyDesktop, tabletLg: onlyMobile } = mediaBreaks;

const backgroundColor = alertType => {
	switch (alertType) {
		case 'error':
			return colors.statusRed;
		case 'warning':
			return colors.yellow;
		case 'notice':
			return colors.lightBlue;
		case 'success':
			return colors.green;
		default:
			return colors.lightGrey;
	}
};

export default {
	ToastContainer: styled.div`
		position: fixed;
		display: flex;
		flex-direction: column;
		z-index: 15;
		top: 0;
		left: 50%;
		right: 50%;
	`,
	ToastBody: styled.div`
		width: ${props => (props.type ? '570px' : '323px')};
		color: ${colors.white};
		background: ${props => backgroundColor(props.type)};
		display: flex;
		flex-direction: ${props => (props.type ? 'row' : 'column')};
		padding: ${props => (props.type ? '0px 22px 0px 16px' : '40px 28px')};
		align-items: ${props => (props.type ? 'center' : 'flex-start')};
		align-self: center;
		z-index: ${props => -Math.abs(props.index)};
		max-width: 90vw;
		${onlyDesktop`
			height: ${props => (props.type ? '64px' : 'auto')};
		`}
		${onlyMobile`
			margin-top: 10px;
			box-shadow: 0 2px 5px 0 rgba(39,57,71,0.15);
			flex-direction: column;
		`}
	`,
	TitleWrapper: styled.div`
		display: flex;
		${onlyMobile`
			align-self: flex-start;
			margin-top: 16px;
		`}
	`,
	Title: styled.h3`
		font-size: 18px;
		min-width: 118px;
		line-height: 24px;
		margin: ${props => (props.hasIcon ? '0 16px' : '0 0 22px')};
	`,
	Message: styled.p`
		color: ${colors.white};
		font-size: ${font.medium};
		line-height: 16px;
		flex-grow: 1;
		${onlyMobile`
			align-self: flex-start;
			margin: ${props => (props.titleHasIcon ? '16px 16px 16px 40px' : '16px')};
		`}
	`,
	CrossIcon: styled(Icon)`
		cursor: pointer;
		${onlyMobile`
			position: absolute;
			flex-shrink: 0;
			top: 22px;
			right: 22px;
		`}
	`
};
