import React, { useEffect, useState, useContext } from 'react';
import translationHOC from 'hocs/translationHOC';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loginUser } from 'utils/api/user';
import { parse, stringify } from 'qs';
import { Link } from 'react-router-dom';
import styled from 'components/AuthResources/styles';
import { map } from 'utils/mappers';
import { callRequest, getRouterEndpoint } from 'utils/request';
import { setAuthCookie } from 'utils/auth';
import { validateURL } from 'utils/url/validateURL';
import { goToURL } from 'utils/location';
import Form from './Form';
import ProvidersSelector from '../ProvidersSelector';
import { ContentContext } from '../../Content';

const providerAuthorizeSource = {
	service: 'id',
	namespace: 'identity-provider',
	method: 'authorize'
};

const providerListSource = {
	service: 'id',
	namespace: 'identity-provider',
	method: 'public-list'
};

const LoginForm = ({ setForm, loginForm, translateHelperString }) => {
	const [providerUrl, setProviderUrl] = useState('');
	const [providers, setProviders] = useState([]);
	const [loadingProviders, setLoadingProviders] = useState(true);
	const [redirectPath, setRedirectPath] = useState('');
	const [loading, setLoading] = useState(false);
	const [errorData, setErrorData] = useState({ error: false, errorMessage: '' });

	const { clientCode, currentForm } = useContext(ContentContext);

	const toggleLoader = () => setLoading(status => !status);

	const submitForm = async formData => {
		toggleLoader();

		try {
			const { data } = await loginUser(formData);

			if (data?.nextStep && data?.nextStep === 'setNewPassword' && data?.data?.passwordToken)
				goToURL(`/id/user/password-recovery?token=${data?.data?.passwordToken}`);

			setAuthCookie(data);

			if (!redirectPath) window.location.href = '/';
			else window.location.href = redirectPath;
		} catch ({ data = {} }) {
			const message = data.message
				? translateHelperString(data.message, data.messageVariables)
				: map('statusError', data.status);

			setErrorData({ errorMessage: message, error: true });
		}

		toggleLoader();
	};

	// If exist error in params show it
	const getErrorByUrl = () => {
		const params = parse(window.location.search, { ignoreQueryPrefix: true });

		const { error: errorParam, error_description: errorDescriptionParam } = params;

		if (errorParam) {
			const message = `${errorParam}${errorDescriptionParam ? ` - ${errorDescriptionParam}` : ''}`;

			setErrorData({ error: true, errorMessage: message });
		}
	};

	// Get redir path from params
	const getRedirectPath = () => {
		const { search } = window.location;

		if (search.includes('redir')) {
			const { redir } = parse(window.location.search, { ignoreQueryPrefix: true });

			if (redir) {
				const validRedir = validateURL(redir);

				const pathname = decodeURIComponent(validRedir);
				setRedirectPath(pathname);
			}
		}
	};

	// Get ProviderUrl base
	const getProviderPath = async () => {
		const url = await getRouterEndpoint(providerAuthorizeSource);
		setProviderUrl(url);
	};

	// Get Providers from api
	const getProviders = async () => {
		const providersResponse = await callRequest(providerListSource, { clientCode });
		setProviders(providersResponse);
		setLoadingProviders(false);
	};

	useEffect(() => {
		getRedirectPath();
		getProviderPath();
		getErrorByUrl();
	}, []);

	useEffect(
		() => {
			getProviders();
		},
		[clientCode]
	);

	if (currentForm !== 'login') return null;

	const redirParam = redirectPath && stringify({ redir: redirectPath }, { addQueryPrefix: true });

	return (
		<>
			{errorData.error && <styled.ErrorMessage>{errorData.errorMessage}</styled.ErrorMessage>}

			<Form onSubmit={submitForm} formData={loginForm} isLoading={loading} />

			<ProvidersSelector
				isLoading={loadingProviders}
				providers={providers}
				providerUrl={providerUrl}
				redirectPath={redirectPath}
			/>

			<styled.BottomContent>
				<styled.LinkContainer center={!!clientCode}>
					{!clientCode && (
						<button type="button" onClick={() => setForm('client')}>
							{translateHelperString('login.action.areYouCorporate')}
						</button>
					)}
					<Link to={`/forgot-password${redirParam}`}>
						{translateHelperString('login.action.cannotLogin')}
					</Link>
				</styled.LinkContainer>
			</styled.BottomContent>
		</>
	);
};

LoginForm.propTypes = {
	setForm: PropTypes.func,
	loginForm: PropTypes.shape({})
};

LoginForm.defaultProps = {
	loginForm: {}
};

const mapStateToProps = state => ({
	loginForm: state.form.login
});

export default translationHOC(connect(mapStateToProps)(LoginForm));
