import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import { addAlert } from 'modules/alerts/actions';
import { recoveryPassword } from 'utils/api/user';
import { translateHelperString } from 'utils/translate';
import { goToURL } from 'utils/location';
import { encodeRedirectState } from 'utils/redirect';
import BackToLogin from 'components/AuthResources/BackToLogin';
import Title from 'components/AuthResources/Title';
import styled from 'components/AuthResources/styles';
import Form from './components/Form';
import SuccessMessage from './components/SuccessMessage';

const Content = () => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [userEmail, setUserEmail] = useState('');

	const selectFormValues = state => getFormValues('forgotPassword')(state) || {};
	const { email } = useSelector(selectFormValues);

	const title = `${translateHelperString('common.page.title.forgotPassword')} | JANIS`;

	useEffect(() => {
		document.title = title;
	}, []);

	const submitForm = async () => {
		setIsLoading(true);

		try {
			await recoveryPassword({ email, ...encodeRedirectState() });
			setSuccess(true);
			setUserEmail(email);
		} catch (error) {
			const message = error?.data?.errorMessage;

			dispatch(
				addAlert({
					type: 'error',
					message
				})
			);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<styled.Container>
			<Title
				subtitle={
					!success ? `${translateHelperString('login.titles.aRecoveryLinkWillBeSentTo')}:` : ''
				}
			>
				{translateHelperString('login.titles.cannotLogin')}
			</Title>

			{!success ? (
				<Form onSubmit={submitForm} isLoading={isLoading} />
			) : (
				<SuccessMessage email={userEmail} />
			)}

			<BackToLogin action={() => goToURL(`/login${window.location.search}`)} />
		</styled.Container>
	);
};

export default Content;
