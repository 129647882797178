import React from 'react';
import PropTypes from 'prop-types';
import translationHOC from 'hocs/translationHOC';
import { stringify } from 'qs';
import Text from 'components/Text';
import { goToURL } from 'utils/location';
import styled from '../styles';

const PreFooter = ({ callAuthorize, qsParams = {}, t }) => {
	/* eslint-disable camelcase */
	const { redirect_uri, scope, state } = qsParams;

	const getRedirectUrl = () => {
		try {
			const url = new URL(redirect_uri);
			return url.origin;
		} catch {
			return redirect_uri;
		}
	};

	const linkHasQuery = redirect_uri.includes('?');
	const cancelUrl = `${redirect_uri}${linkHasQuery ? '&' : '?'}${stringify({
		error: 'access_denied',
		error_description: 'User denied authorization',
		state
	})}`;

	return (
		<>
			<styled.ActionsWrapper showBorder={!!scope}>
				<styled.ActionButton variant="outlined" onClick={() => goToURL(cancelUrl)}>
					{t('common.action.cancel')}
				</styled.ActionButton>
				<styled.ActionButton
					variant="contained"
					onClick={() => {
						callAuthorize('authorize');
					}}
				>
					{t('common.action.authorize')}
				</styled.ActionButton>
			</styled.ActionsWrapper>

			<styled.RedirectInfo>
				{t('common.message.authorizationWillRedirectTo')}:{' '}
				<Text color="blue" fontSize="baseSmall">
					{getRedirectUrl()}
				</Text>
			</styled.RedirectInfo>
		</>
	);
};

PreFooter.propTypes = {
	callAuthorize: PropTypes.func,
	qsParams: PropTypes.shape({})
};

export default translationHOC(PreFooter);
