import styled from 'styled-components';
import { colors, font } from 'theme';
import { mediaBreaks } from 'devices';

const { onlyDesktop, tabletLg: onlyMobile } = mediaBreaks;

export default {
	SuccessMessage: styled.div`
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: ${font.medium};
		color: ${colors.black};
		line-height: 21px;
		max-width: 288px;
		text-align: center;
		margin: 0px auto 36px;
	`,
	ImageWrapper: styled.div`
		${onlyDesktop`
			margin: 28px auto 22px;
		`}
		${onlyMobile`
			margin: 56px auto 22px;
		`}
	`
};
