import store from 'store';
import dates from 'utils/date';

/**
 * Function for set data in localStorage with expiration time opcional
 * @param {string} key - indentifier string key
 * @param {any} value - data for save in storage
 * @param {number} expiration - expiration time in minutes
 */
export const setToStorage = (key, value, expiration) => {
	const expireDate = expiration && dates.changeDate(new Date(), 'add', expiration, 'minutes');

	const data = { value };

	if (expireDate) data.expireDate = expireDate;

	store.set(key, JSON.stringify(data));
};

/**
 * Function for get data from localStorage
 * @param {string} key - indentifier string key
 * @returns {any} - data saved in storage
 */
export const getFromStorage = key => {
	const data = store.get(key);

	if (!data) return;

	try {
		const dataParsed = JSON.parse(data);

		const { expireDate, value } = dataParsed;

		const isStillValid = expireDate ? dates.beforeOrAfter(new Date(), expireDate, 'before') : true;

		if (!isStillValid) return;

		return value;
	} catch (error) {
		console.error(error);
	}
};
