import styled, { css, keyframes } from 'styled-components';
import Button from 'components/Button';
import { colors, timingFunctions, mixins, font } from 'theme';
import { mediaBreaks } from 'devices';
import { openedNavbarWidth, closedNavbarWidth } from '../styles';

const menuWidth = 360;
const initialLeftWithOpenedNavbar = -menuWidth + openedNavbarWidth;
const initialLeftWithClosedNavbar = -menuWidth + closedNavbarWidth;

const { onlyDesktop, tabletLg: onlyMobile } = mediaBreaks;

const menuAnimation = keyframes`
	from {
		transform: translateY(10px) translateX(0);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
`;
const menuAnimationRule = css`
	animation: ${menuAnimation} 0.35s ${timingFunctions.decelerate} forwards;
`;

export const menu = {
	Container: styled.div`
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		background-color: ${colors.white};
		box-shadow: 0 2px 5px 0 rgba(39, 57, 71, 0.15);
		z-index: 9;
		opacity: 0;
		${mixins.transition('transform, opacity')};
		transition-duration: ${props => (props.isNavbarOpen ? '.2s' : '.15s')};
		${onlyDesktop`
			width: ${menuWidth}px;
			padding-left: 28px;
			padding-right: 28px;
			padding-top: 20px;
			overflow: hidden;
			transform: translateX(
				${props => (props.isNavbarOpen ? initialLeftWithOpenedNavbar : initialLeftWithClosedNavbar)}px
			);
		`}
		${onlyMobile`
			width: 100%;
			transform: translateX(100%);
			overflow-y: auto;
		`}

		${props =>
			props.isOpen &&
			css`
				${onlyDesktop`
					transform: translateX(
						${({ isNavbarOpen }) => (isNavbarOpen ? openedNavbarWidth : closedNavbarWidth)}px
					);
				`}
				${onlyMobile`
					transform: translateX(0%);
				`}
				opacity: 1;
				transition-property: transform;
				transition-duration: 0.2s;
			`}
	`,
	AnimatedContainer: styled.div`
		${props =>
			props.enterToUp &&
			css`
				${menuAnimationRule};
			`}
	`,
	MobileMenuHeader: styled.div`
		background: ${colors.white};
		display: flex;
		height: 60px;
		padding: ${props => (props.isSearchBarOpen ? '15' : '10')}px 15px;
		border-bottom: 1px solid ${colors.lightGrey};
		justify-content: space-between;
		align-items: center;
		width: 100%;
		z-index: 10;
		flex-shrink: 0;
	`,
	MobileMenuHeaderButton: styled(Button)`
		background-color: transparent;
		&:hover:after,
		&:active:after {
			background-color: transparent;
		}
	`,
	MenuSubtitle: styled.h2`
		color: ${colors.black};
		font-weight: 500;
		font-size: ${font.medium};
		letter-spacing: 0.93px;
		line-height: 16px;
		margin: 0 15px 25px;
		text-transform: uppercase;
	`
};

export const cross = {
	buttonStyles: css`
		position: absolute;
		top: 8px;
		right: 22px;
		z-index: 2;
	`
};

const titleStyles = css`
	color: ${colors.black};
	font-size: 24px;
	${onlyDesktop`
		font-weight: 500;
		-webkit-line-clamp: 2;
	`}
	${onlyMobile`
		font-size: ${font.medium};
	`}
`;

export const titles = {
	Container: styled.div`
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		position: relative;
		${onlyDesktop`
			min-height: 54px;
		`}
	`,
	ModuleTitle: styled.div`
		${titleStyles};
		top: 0;
		left: 0;
		transition: all 0.25s ${timingFunctions.standard};
		transform: translateY(20px);
		position: absolute;
		${onlyMobile`
			position: initial;
			transform: none;
		`}

		${props =>
			props.isSmall &&
			css`
				text-transform: uppercase;
				font-size: 10px;
				letter-spacing: 0.77px;
				color: ${colors.blue};
				transform: translateY(0);
			`}
		${props =>
			props.noTransition &&
			css`
				transition-duration: 0s;
			`}
	`,
	CategoryTitle: styled.div`
		font-size: 20px;
		transition: font-size 0.25s ${timingFunctions.standard};
		transition-property: font-size, opacity;
		opacity: 0;

		${props =>
			props.isVisible &&
			css`
				${titleStyles};
				opacity: 1;
				overflow: hidden;
				text-overflow: ellipsis;
				padding-top: 22px;
			`}
	`
};
