import React from 'react';
import PropTypes from 'prop-types';
import translationHOC from 'hocs/translationHOC';
import store from 'store';
import { removeAuthCookie } from 'utils/auth';
import Icon from 'components/Icon';
import { iconName as iconNamePropType } from 'utils/prop-types';
import { logoutModule as styled } from './styles';

const Module = ({ icon, text, onClick }) => (
	<styled.Container onClick={onClick}>
		<Icon name={icon} color="blue" size={20} />
		<styled.Text>{text}</styled.Text>
	</styled.Container>
);

Module.propTypes = {
	icon: iconNamePropType,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	onClick: PropTypes.func
};

const LogoutModule = ({ t }) => {
	const closeSession = () => {
		removeAuthCookie();

		store.each((value, key) => {
			if (key !== 'currentLanguage') store.remove(key);
		});

		window.location.href = '/';
	};

	return (
		<Module
			icon="arrow_alt_from_left"
			text={t('views.navigation.closeSession')}
			onClick={closeSession}
		/>
	);
};

export default translationHOC(LogoutModule);
