import { isEmpty } from 'lodash';
import { getServiceNamepaceValues, generateCustomRowLink, makeFetchDataParams } from 'utils';
import { getFromStorage, setToStorage } from 'utils/storage';
import { getPageSchema } from 'utils/api/page';
import { callRequest, getPathFiltersParameters, getQueryFiltersParameters } from 'utils/request';
import { goToURL, updateUrlFilters } from 'utils/location';
import { stringify } from 'qs';

const makeSchemaKey = ({ service, namespace, method }) =>
	`cacheSchema-${[service, namespace, method].join('/')}`;

const createError = (e = {}) => {
	const { response = {} } = e;
	const { status = 500 } = response;
	const customError = new Error(e.message);

	customError.status = status;

	return customError;
};

export const fetchStaticSchema = async ({ endpoint }) => {
	try {
		const schemaKey = makeSchemaKey(endpoint);

		const gettedSchema = await getPageSchema(endpoint);

		if (!gettedSchema || isEmpty(gettedSchema)) {
			throw new Error('Empty schema');
		}

		setToStorage(schemaKey, gettedSchema, 30);

		return gettedSchema;
	} catch (e) {
		const error = createError(e);
		throw error;
	}
};

export const useGetCachedSchema = ({ endpoint }) => {
	const schemaKey = makeSchemaKey(endpoint);
	const cacheSchema = getFromStorage(schemaKey);

	return cacheSchema;
};

export const auxCallRequest = async ({
	endpoint,
	endpointParameters,
	filterParams,
	sortingParams,
	headers
}) => {
	// to endpointParameters type static
	const sourceEndpointParameters =
		endpointParameters && getPathFiltersParameters(endpointParameters, {});

	const queryParameters = endpointParameters
		? getQueryFiltersParameters(endpointParameters, {})
		: {};

	const { filters } = filterParams;

	const { filters: queryFilters = {} } = queryParameters;

	try {
		const response = await callRequest(
			endpoint,
			{
				...queryParameters,
				...sortingParams,
				filters: {
					...filters,
					...queryFilters
				}
			},
			sourceEndpointParameters,
			headers
		);

		return response;
	} catch (e) {
		const error = createError(e);
		throw error;
	}
};

export const validateSchema = schema => {
	let validation = true;
	const { fields, filters } = schema || {};

	const validateObj = obj => !!(obj && obj.constructor === Object && Object.keys(obj).length);

	const isArrayOfObjects = data => Array.isArray(data) && data.every(validateObj);

	switch (true) {
		case isEmpty(schema):
			validation = false;
			break;
		case isEmpty(fields) || !isArrayOfObjects(fields):
			validation = false;
			break;
		case !!filters && !isArrayOfObjects(filters):
			validation = false;
			break;
		default:
			return validation;
	}
	return validation;
};

/**
 * Get field from fieldsGroup by name or component
 * @param {Array} fieldsGroup
 * @param {Array} conditions
 * @returns {Object}
 */
export const getField = (fieldsGroup, conditions) => {
	if (
		!Array.isArray(fieldsGroup) ||
		isEmpty(fieldsGroup) ||
		!Array.isArray(conditions) ||
		isEmpty(conditions) ||
		conditions.some(condition => typeof condition !== 'function')
	) {
		return {};
	}

	const filterGroups = condition =>
		fieldsGroup.reduce((prev, group) => {
			const { fields = [] } = group || {};
			return prev || fields.find(condition);
		}, undefined);

	let filteredField = {};

	for (let i = 0; i < conditions.length; i++) {
		const field = filterGroups(conditions[i]);
		if (!isEmpty(field)) {
			filteredField = field;
			break;
		}
	}

	return filteredField;
};

export const makeLinkUrl = (linkProps = {}, cardData, source) => {
	const { service, namespace } = getServiceNamepaceValues({ source });

	const { id } = cardData;

	if ((isEmpty(linkProps) && !id) || !linkProps) return false;

	const formattedProps = { path: `/${service}/${namespace}/edit/{id}`, ...linkProps };

	return generateCustomRowLink(formattedProps, cardData);
};

export const checkLoadingDependencies = (dependencies = {}) =>
	Object.values(dependencies).some(dependency => !!dependency && dependency.isLoading);

export const compareFilters = (filters, dynamicFilters) => {
	if (!filters.length) return [];

	return filters
		.map(
			filter => !dynamicFilters.some(dynamicFilter => filter.name === dynamicFilter.name) && filter
		)
		.filter(Boolean);
};

export const fetchDataMonitor = async ({
	appliedFilters,
	sorting,
	dynamicFilters,
	fetchMonitorData,
	page,
	pages,
	schema,
	dependencies,
	showLoadMoreButtonPressed,
	nameButtonPressed,
	isAutoRefresh
}) => {
	// Update url with applied filters, sorting and page params
	const filterParams = makeFetchDataParams(appliedFilters);

	updateUrlFilters({ ...filterParams, ...sorting }, dynamicFilters);

	const response =
		(await fetchMonitorData(
			page,
			pages,
			{ ...schema, dependencies },
			filterParams,
			sorting,
			showLoadMoreButtonPressed,
			nameButtonPressed,
			isAutoRefresh
		)) || [];
	return response;
};

export const validateError = ({
	monitorHasError,
	handleError,
	monitorErrorData,
	fetchData,
	viewSchema
}) => {
	let hasErrors = false;

	if (monitorHasError) {
		handleError({
			status: monitorErrorData.status,
			callback: fetchData
		});
		hasErrors = true;
	}

	if (!validateSchema(viewSchema)) {
		handleError({
			status: 500
		});
		hasErrors = true;
	}

	if (!hasErrors) handleError(false);
};

export const fetchSchemaMonitor = async ({
	schemaSource,
	endpointParameters,
	appliedFilters,
	handleError
}) => {
	try {
		if (schemaSource.type === 'static') {
			const currentSchema =
				useGetCachedSchema(schemaSource) || (await fetchStaticSchema(schemaSource));
			return currentSchema;
		}
		// case dynamic
		const gettedSchema = await auxCallRequest({
			...schemaSource,
			endpointParameters,
			filterParams: makeFetchDataParams(appliedFilters)
		});
		return gettedSchema;
	} catch (e) {
		handleError({
			status: e.status,
			callback: fetchSchemaMonitor
		});
	}
};

const hasMatchIdByArray = (arr = [], id = '') => Array.from(arr).includes(id);

export const userHasWarehouseAccess = (shippingData = {}, warehouseUserAccess = {}) => {
	if (!shippingData || !Object.keys(shippingData).length) return false;

	const { warehouseId = '' } = shippingData;
	const { warehousesIds: warehousesIdsToMatch, hasAccessToAllLocations } = warehouseUserAccess;

	if (hasAccessToAllLocations) return true;
	if (!warehousesIdsToMatch || !warehousesIdsToMatch.length) return false;
	return hasMatchIdByArray(warehousesIdsToMatch, warehouseId);
};

const events = {
	PICK_UP: 'delivery:shipping:pickup',
	EXPRESS_DELIVERY: 'delivery:shipping:express'
};

export const monitorMapper = {
	'/delivery/shipping/pick-up': socketData => {
		const message = 'announcedOrder';
		const conditions = {
			event: socketData?.event === events.PICK_UP
		};

		return {
			conditions,
			message
		};
	},
	'/delivery/shipping/express': socketData => {
		const conditions = {
			event: socketData?.event === events.EXPRESS_DELIVERY
		};
		const message = 'announcedOrder';

		return {
			conditions,
			message
		};
	}
};

export const redirectToPage = (url, appliedFilters) => {
	const filterParams = makeFetchDataParams(appliedFilters);
	const urlParams = stringify(
		filterParams,
		{ encodeValuesOnly: true },
		{ addQueryPrefix: true },
		{ arrayFormat: 'index' }
	);
	goToURL(`${url}?${urlParams}`);
};
