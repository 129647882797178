import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import translationHOC from 'hocs/translationHOC';
import { url as urlPropType } from 'utils/prop-types';
import { useDevices } from 'utils/hooks';
import { selectors, actions } from 'modules/menu';
import Icon from 'components/Icon';
import TagNew from 'components/Navbar/TagNew';
import { prefixName } from '../../utils';
import { categories as styled } from './styles';

const CategoryLink = ({ name, url, closeMenu, t, isNew }) => {
	const { tabletLg: onlyMobile } = useDevices();

	const handleClick = event => {
		const { ctrlKey, shiftKey } = event;
		if (!ctrlKey && !shiftKey) closeMenu(true, onlyMobile);
	};
	return (
		<styled.CategoryLink href={url} onClick={event => handleClick(event)}>
			{t(name)}
			{isNew && <TagNew isCategory />}
		</styled.CategoryLink>
	);
};

CategoryLink.propTypes = {
	closeMenu: PropTypes.func,
	name: PropTypes.string.isRequired,
	url: urlPropType,
	isNew: PropTypes.bool
};

const CategoryLinkTranslated = translationHOC(CategoryLink);

const makeOnClick = (selectCategory, name, onlyMobile) => () => selectCategory(name, onlyMobile);

const Category = ({ name, selectCategory, title, t, isNew }) => {
	const { tabletLg: onlyMobile } = useDevices();
	return (
		<styled.Category onClick={makeOnClick(selectCategory, name, onlyMobile)}>
			{t(title)}
			{isNew && <TagNew isCategory />}
			<Icon name="chevron_right" size={16} styles={styled.chevronStyles} />
		</styled.Category>
	);
};

Category.propTypes = {
	name: PropTypes.string.isRequired,
	selectCategory: PropTypes.func.isRequired,
	title: PropTypes.string,
	isNew: PropTypes.bool
};

const CategoryTranslated = translationHOC(Category);

export const renderCategories = (categories, selectCategory, closeMenu, isDev) => {
	const filteredCategories = !isDev ? categories.filter(({ onlyDev }) => !onlyDev) : categories;

	return filteredCategories.map(({ children, name, url, isNew = false }) => {
		const formatedName = prefixName(name);

		return children && children.length > 0 ? (
			<CategoryTranslated
				selectCategory={selectCategory}
				key={name}
				name={name}
				title={formatedName}
				isNew={isNew}
			/>
		) : (
			<CategoryLinkTranslated
				key={name}
				name={formatedName}
				url={url}
				closeMenu={closeMenu}
				isNew={isNew}
			/>
		);
	});
};

const Categories = ({ categories, closeMenu, exitToLeft, noTransition, selectCategory, isDev }) => (
	<styled.Container exitToLeft={exitToLeft} noTransition={noTransition}>
		{renderCategories(categories, selectCategory, closeMenu, isDev)}
	</styled.Container>
);

Categories.propTypes = {
	categories: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			children: PropTypes.arrayOf(
				PropTypes.shape({
					name: PropTypes.string
				})
			)
		})
	),
	closeMenu: PropTypes.func,
	exitToLeft: PropTypes.bool.isRequired,
	noTransition: PropTypes.bool.isRequired,
	selectCategory: PropTypes.func,
	isDev: PropTypes.bool
};

Categories.defaultProps = {
	selectCategory: () => null,
	isDev: false
};

const mapStateToProps = state => ({
	categories: selectors.getCurrentFilteredCategories(state),
	exitToLeft: !!selectors.getSelectedCategory(state),
	noTransition: state.navbar.isChangingModule,
	isDev: state.user.isDev
});

const mapDispatchToProps = {
	closeMenu: actions.closeMenu,
	selectCategory: actions.addSelectedCategory
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Categories);
