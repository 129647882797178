import React from 'react';
import { components } from 'react-select';
import { ControlContext } from './Control';

const Placeholder = props => (
	<ControlContext.Consumer>
		{control => <components.Placeholder {...props} controlIsFocused={control.isFocused} />}
	</ControlContext.Consumer>
);

export default Placeholder;
