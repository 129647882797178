import styled from 'styled-components';
import Button from 'components/Button';
import { colors, font } from 'theme';
import { mediaBreaks } from 'devices';

const { onlyDesktop, tabletLg: onlyMobile } = mediaBreaks;

export default {
	Button: styled(Button)`
		width: 100%;
		height: 42px;
		margin-bottom: 10px;
	`,
	Container: styled.div`
		display: flex;
		flex-direction: column;
		align-items: center;
		${onlyDesktop`
			padding-top: 16px;
		`}
		${onlyMobile`
			padding-top: 8px;
		`}
	`,
	Text: styled.p`
		margin-bottom: 20px;
		text-align: center;
		color: ${colors.darkGrey};
		font-size: ${font.baseSmall};
		letter-spacing: 1.5px;
		line-height: 14px;
		text-transform: uppercase;
	`,
	Provider: styled.div`
		display: flex;
		align-items: center;
		> img {
			transform: translate(-8px, 0px);
		}
	`,
	SkeletonWrapper: styled.div`
		width: 100%;
		span {
			border-radius: 50px;
		}
	`
};
