import styled from 'styled-components';

export default {
	LanguageSelector: styled.div`
		${({ onlyDesktop }) => (onlyDesktop ? 'width: 190px;' : 'width: 170px;')}
		> div {
			${props => props.isAuthWrapper && 'border: none;'}
		}
	`
};
