import * as types from './types';

const initialState = {
	isLocked: false,
	isOpen: false,
	selectedModule: '',
	enterTimerId: null,
	isChangingModule: false,
	isProfileDetailOpen: false,
	isOpenSearch: false,
	isOpenClient: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.OPEN:
			return {
				...state,
				isOpen: true,
				enterTimerId: null,
				isProfileDetailOpen: false
			};
		case types.CLOSE:
			return {
				...state,
				isLocked: false,
				isOpen: !action.closeAll,
				selectedModule: '',
				isProfileDetailOpen: false
			};
		case types.SELECT_MODULE:
			return {
				...state,
				selectedModule: action.module
			};
		case types.CLOSE_MODULE:
			return {
				...state,
				selectedModule: ''
			};
		case types.LOCK:
			return {
				...state,
				isLocked: true
			};
		case types.UNLOCK:
			return {
				...state,
				isLocked: false
			};
		case types.SET_ENTER_TIMER_ID:
			return {
				...state,
				enterTimerId: action.timerID
			};
		case types.CLEAR_ENTER_TIMER_ID:
			return {
				...state,
				enterTimerId: null
			};
		case types.START_CHANGING_MODULE:
			return {
				...state,
				isChangingModule: true
			};
		case types.STOP_CHANGING_MODULE:
			return {
				...state,
				isChangingModule: false
			};
		case types.OPEN_PROFILE_DETAIL:
			return {
				...state,
				isProfileDetailOpen: true,
				selectedModule: ''
			};
		case types.OPEN_CLIENT:
			return {
				...state,
				isOpenClient: true,
				selectedModule: ''
			};
		case types.CLOSE_CLIENT:
			return {
				...state,
				isOpenClient: false
			};
		case types.CLOSE_PROFILE_DETAIL:
			return {
				...state,
				isProfileDetailOpen: false
			};
		case types.OPEN_SEARCH:
			return {
				...state,
				isOpen: false,
				isLocked: true,
				isOpenSearch: true
			};
		case types.CLOSE_SEARCH:
			return {
				...state,
				isLocked: false,
				isOpenSearch: false
			};
		default:
			return state;
	}
}
