import { createDate, createMonth, createWeek } from 'utils/date/calendar';
import { startOfToday } from 'date-fns';
import * as types from './types';

export const initialError = { hasError: false, errorData: {} };

const initialState = {
	schema: {},
	isReady: false,
	isFetching: true,
	errorStatus: {},
	data: [],
	hasFilter: false,
	calendarMode: 'weekly',
	selectedDay: createDate(startOfToday()),
	selectedWeek: createWeek(createDate(startOfToday()).date),
	selectedMonth: createMonth(
		new Date(createDate(startOfToday()).year, createDate(startOfToday()).monthIndex)
	)
};

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case types.CREATE_PAGE:
			return { ...state, schema: action.schema };
		case types.FETCHING_DATA:
			return { ...state, isReady: false };
		case types.FETCH_DATA_SUCCESS:
			return {
				...state,
				...action.data,
				isFetching: false,
				isReady: true,
				errorStatus: initialError
			};
		case types.SET_REQUIRED_FILTERS:
			return {
				...state,
				isFetching: false,
				isReady: true,
				errorStatus: action.errorStatus,
				data: []
			};
		case types.SET_CALENDAR_MODE:
			return {
				...state,
				calendarMode: action.mode
			};
		case types.SET_ERROR:
			return {
				...state,
				isFetching: false,
				isReady: true,
				errorStatus: action.errorStatus,
				data: []
			};
		case types.SET_SELECTED_DAY:
			return {
				...state,
				selectedDay: action.currentDay
			};
		case types.SET_SELECTED_WEEK:
			return {
				...state,
				selectedWeek: action.currentWeek
			};
		case types.SET_SELECTED_MONTH:
			return {
				...state,
				selectedMonth: action.currentMonth
			};
		default:
			return state;
	}
}
