import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions } from 'modules/navbar';
import { actions as userActions } from 'modules/user';
import { useDevices } from 'utils/hooks';
import { Transition } from 'react-spring/renderprops';
import translationHOC from 'hocs/translationHOC';
import { goToURL } from 'utils/location';
import ClientLogo from 'components/ClientLogo';
import ClientsModal from 'components/ClientsModal';
import { navbar as styled, mobileMenu } from './styles';
import Modules from './Modules';
import HandleHistoryMobile from './handleHistoryMobile';
import ConfigModules from './ConfigModules';
import JanisLogo from './JanisLogo';
import Menu from './Menu/index';
import ProfileDetail from './ProfileDetail';
import Background from './Background';

const Navbar = ({
	closeMenu,
	isOpen,
	enterNavbar,
	leaveNavbar,
	fetchUserData,
	id,
	t,
	openClient
}) => {
	const { onlyDesktop, tabletLg: onlyMobile } = useDevices();

	useEffect(
		() => {
			if (id) fetchUserData();
		},
		[id]
	);

	const goToHome = () => {
		const {
			location: { pathname }
		} = window;

		closeMenu();

		if (pathname !== '/') goToURL('/');
	};

	return (
		<Fragment>
			{onlyMobile ? (
				<HandleHistoryMobile>
					<styled.Nav>
						<ConfigModules goToHome={goToHome} />
					</styled.Nav>
					<Transition
						items={isOpen}
						from={{ opacity: 0 }}
						enter={{ opacity: 1 }}
						leave={{ opacity: 0 }}
						config={{ duration: 100 }}
					>
						{show =>
							show &&
							(props => (
								<mobileMenu.Wrapper name="MobileMenu" isOpen={isOpen} style={props}>
									<mobileMenu.Header>{t('views.navigation.menu')}</mobileMenu.Header>
									<Modules />
								</mobileMenu.Wrapper>
							))
						}
					</Transition>
					<Menu onlyMobile={onlyMobile} />
					<ClientsModal />
					<ProfileDetail />
				</HandleHistoryMobile>
			) : (
				<>
					<Background />
					<styled.Nav onMouseEnter={() => enterNavbar()} onMouseLeave={leaveNavbar} isOpen={isOpen}>
						<ClientLogo toggleModalMethod={() => openClient()} />
						<Modules />
						<styled.Menu>
							<ConfigModules />
						</styled.Menu>
						<JanisLogo isOpen={isOpen} />
					</styled.Nav>
					<Menu onlyDesktop={onlyDesktop} />
					<ClientsModal />
					<ProfileDetail />
				</>
			)}
		</Fragment>
	);
};

Navbar.propTypes = {
	closeMenu: PropTypes.func,
	enterNavbar: PropTypes.func.isRequired,
	leaveNavbar: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.oneOf([null])]),
	fetchUserData: PropTypes.func.isRequired,
	openClient: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	isOpen: state.navbar.isOpen,
	id: state.user.id,
	searchTerm: state.menu.searchTerm
});

const mapDispatchToProps = {
	enterNavbar: actions.enter,
	leaveNavbar: actions.leave,
	fetchUserData: userActions.fetchUserData,
	closeMenu: actions.closeAll,
	openClient: actions.openClient
};

export default translationHOC(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(React.memo(Navbar))
);
