import styled from 'styled-components';
import { colors } from 'theme';
import Spinner from 'components/Spinner';

export default {
	Spinner: styled(Spinner)`
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 3;
	`,
	SpinnerWrapper: styled.div`
		position: absolute;
		background-color: ${colors.transparentWhite};
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: 2;
	`
};
