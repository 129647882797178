import styled from 'styled-components';
import Text from 'components/Text';
import { font } from 'theme';

const { xlarge } = font;

export default {
	PopupTitleContainer: styled.div`
		margin-top: 10px;
		display: flex;
		justify-content: center;
	`,
	PopupTitle: styled(Text)`
		font-size: ${xlarge};
	`
};
