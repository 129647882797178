import React, { useEffect } from 'react';
import store from 'store';
import { parse } from 'qs';
import { removeAuthCookie } from 'utils/auth';
import { goToURL } from 'utils/location';

const Logout = () => {
	useEffect(() => {
		removeAuthCookie();

		store.each((value, key) => {
			if (key !== 'currentLanguage') store.remove(key);
		});

		const query = parse(window.location.search, { ignoreQueryPrefix: true });

		goToURL(query.redir || '/login');
	}, []);

	return <div />;
};

export default Logout;
