import * as types from './types';

// const initialState = {};

const mergeState = (state, action, properties) => ({
	...state,
	[action.name]: {
		...state[action.name],
		...properties
	}
});

export default function reducer(state = {}, action) {
	switch (action.type) {
		case types.FETCH_PREVIEW_SCHEMA_SUCCESS:
			return mergeState(state, action, { schema: action.schema });

		case types.FETCH_DATA_REQUEST:
			return mergeState(state, action, { isFetching: true, isReady: false, hasError: false });

		case types.FETCH_DATA_SUCCESS:
			return mergeState(state, action, {
				isFetching: false,
				isReady: true,
				currentPreviewData: action.data,
				hasError: false,
				errorData: {}
			});

		case types.FETCH_DATA_FAILURE:
			return mergeState(state, action, {
				isFetching: false,
				isReady: true,
				hasError: true,
				errorData: action.status
			});

		case types.SET_ACTIVE_ROW:
			return mergeState(state, action, {
				activeRow: action.activeRow
			});

		default:
			return state;
	}
}
