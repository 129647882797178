import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getFontColorByContrast } from 'utils';
import { useDevices } from 'utils/hooks';
import { colors } from 'theme';
import UserImage from 'components/UserImage';
import { NavLink } from 'react-router-dom';
import { getAuthMultipleClients } from 'utils/auth';
import { actions } from 'modules/navbar';
import { clientLogo as styled } from './styles';

const ClientLogo = ({ openClient, userData = {}, isOpen }) => {
	const { onlyDesktop, mobile } = useDevices();
	const { tname, timage, mainColor = colors.darkGrey } = userData;
	const fontColor = getFontColorByContrast(mainColor);
	const hasMultipleClients = getAuthMultipleClients();

	if (mobile) {
		return (
			<styled.Container onClick={openClient} bgColor={mainColor} isOpen={isOpen}>
				<styled.LogoContainer>
					<UserImage
						url={timage}
						size={onlyDesktop ? 48 : 42}
						firstname={tname}
						mainColor={mainColor}
						alt={tname}
						rounded={!onlyDesktop}
					/>
				</styled.LogoContainer>
			</styled.Container>
		);
	}

	return (
		<styled.Container bgColor={mainColor} isOpen={isOpen}>
			<NavLink to="/">
				<styled.LogoContainer>
					<UserImage
						url={timage}
						size={onlyDesktop ? 48 : 42}
						firstname={tname}
						mainColor={mainColor}
						alt={tname}
						rounded={!onlyDesktop}
					/>
				</styled.LogoContainer>
			</NavLink>
			{onlyDesktop && <styled.Text fontColor={fontColor}>{tname}</styled.Text>}
			{onlyDesktop && hasMultipleClients && (
				<styled.Icon onClick={openClient} name="edit" color="white" size={24} />
			)}
		</styled.Container>
	);
};

ClientLogo.propTypes = {
	openClient: PropTypes.func,
	userData: PropTypes.shape({}),
	isOpen: PropTypes.bool
};

const mapStateToProps = state => ({
	userData: state.user,
	isOpen: state.navbar.isOpenClient
});

const mapDispatchToProps = {
	openClient: actions.openClient
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ClientLogo);
