import { getObjectKeyByIndex } from 'utils';

export const dataFormatter = (array, formatValues, filters) => {
	if (!array || !Array.isArray(array)) return [{}];

	return array.map(id => ({
		id,
		...formatValues,
		...filters
	}));
};

export const isFieldComponentVisible = object => object[getObjectKeyByIndex(object)].visible;
