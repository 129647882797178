import React from 'react';
import { connect } from 'react-redux';
import { actions } from 'modules/navbar';
import { bool, func, shape } from 'prop-types';
import { useDevices } from 'utils/hooks';
import UserImage from 'components/UserImage';
import { ProfileModule as styled } from './styles';

const ProfileModule = ({ openProfileDetail, closeProfileDetail, isActive, userData = {} }) => {
	const { onlyDesktop } = useDevices();
	const { name, lastName, images = {} } = userData;

	const handleClick = () => {
		if (onlyDesktop) return openProfileDetail();

		return isActive ? closeProfileDetail() : openProfileDetail(true);
	};

	return (
		<styled.ProfileContainer onClick={handleClick} isActive={isActive}>
			<styled.IconContainer>
				<UserImage
					firstname={name}
					lastname={lastName}
					url={images && images.thumbnail}
					size={onlyDesktop ? 'large' : 'small'}
				/>
			</styled.IconContainer>

			{onlyDesktop && (
				<styled.ProfileText>
					{name} {lastName}
				</styled.ProfileText>
			)}
		</styled.ProfileContainer>
	);
};

ProfileModule.propTypes = {
	openProfileDetail: func.isRequired,
	closeProfileDetail: func.isRequired,
	isActive: bool,
	userData: shape({})
};

const mapStateToProps = state => ({
	isActive: state.navbar.isProfileDetailOpen,
	userData: state.user
});

const mapDispatchToProps = {
	openProfileDetail: actions.openProfileDetail,
	closeProfileDetail: actions.closeProfileDetail
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProfileModule);
