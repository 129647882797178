import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { getLocationParts } from 'utils/location';
import { getFromStorage } from 'utils/storage';
import Button from 'components/Button';
import Modal from 'components/ActionsModal';
import Code from 'components/Code';

const ShowSchemaModal = ({ page, t }) => {
	const [service, namespace, method] = getLocationParts();
	const name = `cacheSchema-${service}/${namespace}/${method}`;

	const [modalOpen, setOpen] = useState(false);
	const [schema, setSchema] = useState(getFromStorage(name));

	const availableSchema = schema && !isEmpty(schema);

	const pageSchema = page.schema;

	useEffect(
		() => {
			const newSchema = getFromStorage(name) || pageSchema;
			setSchema(newSchema);
		},
		[pageSchema]
	);

	const targetUrl = `/schemas/${service}/${namespace}/${method}.json`;

	return (
		<>
			<Button rounded icon="eye" disabled={!availableSchema} onClick={() => setOpen(!modalOpen)} />
			<Modal
				isOpen={modalOpen}
				width="xl"
				title={t('common.title.pageSchema')}
				confirmButtonText="common.action.openInNewTab"
				handleCloseModal={() => setOpen(false)}
				handleConfirm={() => {
					window.open(targetUrl, '_blank').focus();
				}}
			>
				<Code value={schema} canEdit={false} />
			</Modal>
		</>
	);
};

export default ShowSchemaModal;
