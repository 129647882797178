import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import translationHOC from 'hocs/translationHOC';
import styled from 'components/AuthResources/Title/styles';
import Icon from 'components/Icon';
import { ContentContext } from '../../Content';

const TitleContent = ({ setForm, translateHelperString }) => {
	const { clientCode, currentForm } = useContext(ContentContext);

	const isLoginForm = currentForm === 'login';

	const title = currentForm === 'client' ? 'areYouCorporate' : 'loginToYourAccount';

	return (
		<>
			{clientCode && isLoginForm ? (
				<>
					{translateHelperString('common.title.loginIn')}{' '}
					<styled.ClientButton type="button" onClick={() => setForm('client')}>
						{clientCode} <Icon color="blue" name="edit" />
					</styled.ClientButton>
				</>
			) : (
				translateHelperString(`common.title.${title}`)
			)}
		</>
	);
};

TitleContent.propTypes = {
	setForm: PropTypes.func
};

export default translationHOC(TitleContent);
