import React, { useState } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import Button from 'components/Button';
import Modal from 'components/ActionsModal';
import Code from 'components/Code';

const ShowEditDataModal = ({ editCreateData, t }) => {
	const [modalOpen, setOpen] = useState(false);

	const availableData = editCreateData && !isEmpty(editCreateData);

	return (
		<>
			<Button rounded icon="eye" disabled={!availableData} onClick={() => setOpen(!modalOpen)} />
			<Modal
				confirmDisabled
				isOpen={modalOpen}
				width="xl"
				title={t('common.title.pageEditData')}
				handleCloseModal={() => setOpen(false)}
			>
				<Code value={editCreateData} canEdit={false} />
			</Modal>
		</>
	);
};

const mapStateToProps = state => ({
	editCreateData: state.editCreate.data
});

export default connect(mapStateToProps)(ShowEditDataModal);
