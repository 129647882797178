import axios from 'axios';
import { ROUTER_ENDPOINT } from 'config/endpoints';
import MicroServiceCallInvalidService from './microservice-call-invalid-service';

/**
 * @class RouterFetcher
 * @classdesc Use this to make request to the router.
 */
export default class RouterFetcher {
	/**
	 * @param  {String} janisClient
	 */
	constructor(janisClient) {
		this.currentEndpoints = {};
		this.janisClient = janisClient;
	}

	/**
	 * Get the endpoint data doing one request to the router.
	 * @param  {String} service The name of the microservice.
	 * @param  {String} namespace The namespace of the microservice.
	 * @param  {String} method The method of microservice.
	 * @return {Promise<RouterResponse>}
	 */

	getEndpoint(service, namespace, method) {
		const params = { namespace, method, service };

		const requestHeaders = {
			'Content-Type': 'application/json'
		};

		if (this.janisClient) requestHeaders['Janis-Client'] = this.janisClient;

		const endPointId = `${service}_${namespace}_${method}`;

		if (this.currentEndpoints[endPointId]) return this.currentEndpoints[endPointId];

		this.currentEndpoints[endPointId] = new Promise((resolve, reject) => {
			axios({
				url: ROUTER_ENDPOINT,
				headers: requestHeaders,
				params,
				method: 'GET'
			})
				.then(response => {
					if (response.statusCode >= 400) {
						const { headers, status, statusText, data } = response;
						return reject(new MicroServiceCallInvalidService(status, statusText, headers, data));
					}

					resolve(response.data);
				})
				.catch(err => {
					reject(err);
				});
		});

		return this.currentEndpoints[endPointId];
	}
}
