import styled, { createGlobalStyle } from 'styled-components';
import { colors, mixins } from 'theme';

export default {
	ModalTransitions: createGlobalStyle`
		.ReactModal__Modal {
			opacity: 0;
			transition: all 0.2s ease-in-out;
			transform: scale(0.8);
		}

		.ReactModal__Modal--after-open {
			opacity: 1;
			transform: scale(1);

		}

		.ReactModal__Modal--before-close {
			transform: scale(.2);
			opacity: 0;
		}

		.ReactModal__Overlay {
			opacity: 0;
			transition: all 0.3s;
		}

		.ReactModal__Overlay--after-open {
			opacity: 1;
			z-index: 10;
			overflow-y: auto;
			${mixins.scrollbar(colors.grey)}
		}

		.ReactModal__Overlay--before-close {
			opacity: 0;
		}
	`,
	Modal: props => ({
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		transform: 'translate(-50%, -50%)',
		width: props.width,
		height: props.height || 'auto',
		padding: '12px 24px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		border: `1px solid ${colors.lightGrey}`,
		boxShadow: `0 2px 5px 0 rgba(39, 57, 71, 0.15)`,
		borderRadius: `none`,
		maxWidth: '90vw',
		maxHeight: '80vh',
		overflow: 'initial',
		transition: 'all 0.4s ease-in-out',
		...(props.styles ? props.styles : null)
	}),
	OverlayBackground: {
		backgroundColor: 'rgba(39,57,71,0.1)'
	},
	TitleWrapper: styled.div`
		display: flex;
		justify-content: space-between;
	`,
	Title: styled.p`
		font-size: 12px;
		text-transform: uppercase;
		color: ${colors.black};
		font-weight: 900;
		letter-spacing: 0.5px;
		line-height: 24px;
		white-space: nowrap;
		margin-right: 24px;
	`
};
