import styled from 'styled-components';
import { colors, mixins } from 'theme';
import { mediaBreaks } from 'devices';
import { selectModule } from 'components/Navbar/styles';
import Icon from 'components/Icon';

const { onlyDesktop, tabletLg: onlyMobile } = mediaBreaks;

export const openedNavbarWidth = 220;
export const closedNavbarWidth = 64;

export const clientLogo = {
	Container: styled.button`
		flex-shrink: 0;
		position: relative;
		background-color: ${({ bgColor }) => bgColor};
		${onlyDesktop`
			width: ${openedNavbarWidth}px;
			height: 64px;
			${mixins.flexCenter}
		`}
		${onlyMobile`
			height: 60px;
			width: 60px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
		`}
		${({ isOpen }) => isOpen && selectModule}
	`,
	LogoContainer: styled.div`
		${mixins.flexCenter}
		flex-shrink: 0;
		${onlyDesktop`
			width: ${closedNavbarWidth}px;
	`}
		${onlyMobile`
			width: 60px;
			height: 60px;
			margin-right: 0;`}
	`,
	Text: styled.span`
		flex-grow: 1;
		color: ${({ fontColor }) => fontColor};
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		margin-right: 5px;
		${onlyDesktop`
			font-size: 17px;
			font-weight: 500;
			text-align: left;
		`}
		${onlyMobile`
			color: ${colors.fizzGreen};
			font-size: 17px;
			font-weight: 500;
			line-height: 20px;
			margin-left: 8px;
			flex-grow: 0;
		`}
	`,
	Icon: styled(Icon)`
		margin-right: 10px;
	`
};
