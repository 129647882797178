import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from './styles';

const CustomTooltip = ({ children, content, handlers, label, mouseLeaveTimeout, outsideFocus }) => {
	const [isFocused, setFocus] = useState(false);
	const [delayHandler, setDelayHandler] = useState(null);

	const handleMouseLeave = () => {
		setDelayHandler(
			setTimeout(() => {
				setFocus(false);
			}, mouseLeaveTimeout)
		);
	};

	const handleMouseEnter = () => {
		clearTimeout(delayHandler);
		setFocus(true);
	};

	useEffect(() => () => clearTimeout(delayHandler), []);

	const currentHandlers = handlers || {
		onMouseEnter: handleMouseEnter,
		onMouseLeave: handleMouseLeave
	};

	const hoverHandlers = {
		onMouseEnter: currentHandlers.onMouseEnter,
		onMouseLeave: currentHandlers.onMouseLeave
	};

	const focus = handlers ? outsideFocus : isFocused;
	const defaultOutsideClick = () => null;

	const handleClick = e => {
		e.stopPropagation(); /** For tooltips nested within other clickable elements such as a Collapser toggler */

		return handlers ? handlers.onClick() : defaultOutsideClick();
	};

	if (!children || (!content && !label)) return null;

	return (
		<styled.TooltipWrapper {...hoverHandlers} hasClick={!!handlers}>
			<OutsideClickHandler onOutsideClick={handleClick || defaultOutsideClick} disabled={!focus}>
				{focus && (
					<styled.Tooltip
						isFocused={focus}
						onClick={e => {
							e.stopPropagation();
						}}
					>
						{content || <styled.TooltipLabel>{label}</styled.TooltipLabel>}
					</styled.Tooltip>
				)}
				<button type="button" onClick={handleClick}>
					{children}
				</button>
			</OutsideClickHandler>
		</styled.TooltipWrapper>
	);
};

CustomTooltip.propTypes = {
	children: PropTypes.node,
	content: PropTypes.node,
	/** Indica si el tooltip tiene controladores externos */
	handlers: PropTypes.oneOfType([PropTypes.shape({})]),
	label: PropTypes.string,
	/** Tiempo que tarda el tooltip en cerrarse al quitar el puntero */
	mouseLeaveTimeout: PropTypes.number,
	/** Si el componente recibe controladores externos, debe recibir el estado del focus tambien */
	outsideFocus: PropTypes.bool
};

CustomTooltip.defaultProps = {
	mouseLeaveTimeout: 0
};

export default CustomTooltip;
