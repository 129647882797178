import { map } from './mappers';
import i18n from '../i18n';

const t = i18n.t.bind(i18n);

/** Merge translation keys from level 3 and above into one single camelCased key, so all translation keys have three levels.
 * @param {array} args - All params passed into translation functions
 * @returns {array} - Formatted three-level translation key and the rest of the original params
 */
export const formatTranslationParams = args => {
	const [key = '', ...remainingParams] = args;

	/* Get each level of the translation key */
	const [firstLevel, secondLevel, ...remainingLevels] = key.split('.');

	if (remainingLevels.length) {
		/* Update key with the merged third level */
		const formattedKey = `${firstLevel}.${secondLevel}.${map('camelCase', remainingLevels)}`;

		return [formattedKey, ...remainingParams];
	}

	return args;
};

/** Merge translation keys from level 5 and above into one single camelCased key, so all translation keys have four levels.
 * @param {array} args - All params passed into translation functions
 * @returns {array} - Formatted four-level translation key and the rest of the original params
 */
export const formatTranslationParamsToValue = args => {
	const [key = '', ...remainingParams] = args;

	/* Get each level of the translation key */
	const [firstLevel, secondLevel, ...remainingLevels] = key.split('.');

	if (remainingLevels.length) {
		let lastLevel = remainingLevels.pop();

		const thirdLevel = remainingLevels && map('camelCase', remainingLevels);

		lastLevel = map('camelCase', lastLevel);

		const formattedKey = [firstLevel, secondLevel, thirdLevel, lastLevel].filter(Boolean).join('.');

		return [formattedKey, ...remainingParams];
	}

	return args;
};

//	format the key in trhee levels and returns the translation as string without underline (return string)
export const translateHelperString = (...args) => t(...formatTranslationParams(args));

//	format the key in four levels and returns the translation as string without underline (return string)
export const translateHelperValue = (...args) => t(...formatTranslationParamsToValue(args));
