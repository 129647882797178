import React from 'react';
import PropTypes from 'prop-types';
import translationHOC from 'hocs/translationHOC';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { useDevices } from 'utils/hooks';
import styled from '../styles';

const ScopeDetails = ({ appName, scope = [], t }) => {
	const { onlyDesktop } = useDevices();

	return (
		<>
			<styled.Message showData>
				<Text color="black" fontSize={onlyDesktop ? 'large' : 'medium'} fontWeight="medium">
					{t('common.message.thisWillAllow')}
					<styled.BlueText> {appName}:</styled.BlueText>
				</Text>
			</styled.Message>
			<styled.MessageList>
				{scope.map(param => (
					<li key={param}>
						<Icon name="check_circle_bold" color="blue" /> {param}
					</li>
				))}
			</styled.MessageList>
		</>
	);
};

ScopeDetails.propTypes = {
	appName: PropTypes.string,
	/** The translated scope strings */
	scope: PropTypes.arrayOf(PropTypes.string)
};

export default translationHOC(ScopeDetails);
