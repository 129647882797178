export const OPEN = 'navbar/OPEN';
export const CLOSE = 'navbar/CLOSE';
export const LOCK = 'navbar/LOCK';
export const UNLOCK = 'navbar/UNLOCK';
export const CLOSE_MODULE = 'navbar/CLOSE_MODULE';
export const SET_ENTER_TIMER_ID = 'navbar/SET_ENTER_TIMER_ID';
export const CLEAR_ENTER_TIMER_ID = 'navbar/CLEAR_ENTER_TIMER_ID';
export const SELECT_MODULE = 'navbar/SELECT_MODULE';
export const START_CHANGING_MODULE = 'menu/START_CHANGING_MODULE';
export const STOP_CHANGING_MODULE = 'menu/STOP_CHANGING_MODULE';
export const OPEN_PROFILE_DETAIL = 'navbar/OPEN_PROFILE_DETAIL';
export const CLOSE_PROFILE_DETAIL = 'navbar/CLOSE_PROFILE_DETAIL';
export const OPEN_SEARCH = 'navbar/OPEN_SEARCH';
export const CLOSE_SEARCH = 'navbar/CLOSE_SEARCH';
export const OPEN_CLIENT = 'navbar/OPEN_CLIENT';
export const CLOSE_CLIENT = 'navbar/CLOSE_CLIENT';
