import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Toast from './Toast';
import styled from './styles';

class ToastWrapper extends PureComponent {
	render() {
		const { alerts, defaultConfig, removeAlert, timeout } = this.props;
		return (
			<styled.ToastContainer>
				{alerts.map((alert, idx) => (
					<Toast
						{...alert}
						defaultConfig={defaultConfig}
						index={idx}
						key={`alert_${idx.toString()}`}
						removeAlert={removeAlert}
						timeout={timeout}
					/>
				))}
			</styled.ToastContainer>
		);
	}
}

ToastWrapper.propTypes = {
	/** Array de alertas a mostrar.
	 * Deben tener un id generado para identificarlas.
	 * Y además, un tipo (para definir color, ícono, y título) y un mensaje adicional.
	 * (Si no se pasa un tipo de alerta, será de color gris.)
	 * Pueden tener además un título específico. */
	alerts: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			message: PropTypes.string,
			title: PropTypes.string,
			type: PropTypes.oneOf(['error', 'warning', 'notice', 'success'])
		})
	),
	/** Valores por default para títulos según el tipo de alerta.
	 * (En caso de necesitar traducciones y no tener que definir un title repetido en cada instancia) */
	defaultConfig: PropTypes.shape({
		error: PropTypes.shape({ title: PropTypes.string }),
		warning: PropTypes.shape({ title: PropTypes.string }),
		notice: PropTypes.shape({ title: PropTypes.string }),
		success: PropTypes.shape({ title: PropTypes.string })
	}),
	/** Función que recibe el id, y que debe eliminar la alerta a cerrarse del array de alertas. */
	removeAlert: PropTypes.func,
	/** Tiempo (en milisegundos) que tarda en desaparecer automáticamente la notificación. */
	timeout: PropTypes.number
};

ToastWrapper.defaultProps = {
	defaultConfig: {
		error: {},
		warning: {},
		notice: {},
		success: {}
	},
	removeAlert: () => null,
	timeout: 5000
};

export default ToastWrapper;
