import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { parse } from 'qs';
import { getAuthCookieDecoded } from 'utils/auth';
import { useTransition, animated } from 'react-spring';
import { email, minLength, required } from 'utils/redux-form-validation';
import InputField from 'components/AuthResources/InputField';
import styled from 'components/AuthResources/styles';
import Actions from 'components/AuthResources/Actions';

const passwordLengthValidator = minLength(6);

/**	Get email from login_hint param or cookie.email property */
const getLoginHint = () => {
	const { search } = window.location;

	const params = parse(search, { ignoreQueryPrefix: true });

	if (params.login_hint) return params.login_hint;

	const cookie = getAuthCookieDecoded();

	return cookie && cookie.email;
};

const Form = ({ formData, handleSubmit, isLoading, change, dispatch }) => {
	const [showPassword, togglePassword] = useState(false);

	const { fields = {}, syncErrors = {} } = formData;

	const showPasswordField = !syncErrors.email;

	const transitions = useTransition(showPasswordField, null, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 }
	});

	useEffect(() => {
		try {
			const mail = getLoginHint();
			if (mail) dispatch(change('email', mail));
			// eslint-disable-next-line no-empty
		} catch (error) {}
	}, []);

	return (
		<form onSubmit={handleSubmit}>
			<styled.FieldsContainer singleField={!showPasswordField}>
				<Field name="email" type="email" component={InputField} validate={[email, required]} />

				{transitions.map(
					({ item, key, props }) =>
						item && (
							<animated.div style={{ ...props, position: 'relative' }} key={key}>
								<Field
									name="password"
									type={showPassword ? 'text' : 'password'}
									component={InputField}
									validate={[passwordLengthValidator, required]}
								/>

								<styled.TogglePasswordButton
									type="button"
									hasError={!!syncErrors.password && (fields.password && fields.password.touched)}
									rounded
									icon={showPassword ? 'eye_slash' : 'eye'}
									iconColor="blue"
									onClick={() => togglePassword(!showPassword)}
								/>
							</animated.div>
						)
				)}
			</styled.FieldsContainer>

			<Actions isLoading={isLoading} label="enter" />
		</form>
	);
};

Form.propTypes = {
	formData: PropTypes.shape({}),
	handleSubmit: PropTypes.func,
	isLoading: PropTypes.bool,
	change: PropTypes.func,
	dispatch: PropTypes.func
};

export default reduxForm({ form: 'login' })(Form);
