/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { actions, selectors } from 'modules/menu';
import { InputStyled } from './styles';

const Input = props => <InputStyled {...props} />;

const mapStateToProps = state => ({
	value: selectors.getSearchTerm(state)
});

const mapDispatchToProps = ({
	onChange: event => actions.changeSearchTerm(event.target.value)
});

export default connect(mapStateToProps, mapDispatchToProps)(Input);
