import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { logger } from 'redux-logger';
import { parse } from 'qs';
import browserStore from 'store';
import reducer from './modules';

const stateOnGlobal = store => next => action => {
	const result = next(action);
	window.reduxState = store.getState();
	return result;
};

const useLogs = () => {
	const paramObj = parse(window.location.search, { ignoreQueryPrefix: true });

	const { noLogs: noLogsParam } = paramObj;

	const disableLogs = browserStore.get('noLogs');

	return noLogsParam === '1' || disableLogs ? [] : [logger];
};

const middlewares =
	process.env.NODE_ENV === 'development' ? [thunk, ...useLogs(), stateOnGlobal] : [thunk];

/* eslint-disable-next-line no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, undefined, composeEnhancers(applyMiddleware(...middlewares)));

export default store;
