import React from 'react';
import PropTypes from 'prop-types';
import { parse } from 'qs';
import { isLoggedIn } from 'utils/auth';
import { Route, Redirect } from 'react-router-dom';

const getRedirectPath = () => {
	let pathname = '/';

	const { search } = window.location;

	if (search.includes('redir')) {
		const params = parse(search, { ignoreQueryPrefix: true });

		if (params.redir) pathname = decodeURIComponent(params.redir);
	}

	return { pathname };
};

const AuthenticationRoute = ({ render, ...props }) => {
	const isLogged = isLoggedIn();

	return (
		<Route
			{...props}
			render={renderProps =>
				!isLogged ? (
					render(renderProps)
				) : (
					<Redirect
						to={{
							...getRedirectPath(),
							state: { from: renderProps.location }
						}}
					/>
				)
			}
		/>
	);
};

AuthenticationRoute.propTypes = {
	render: PropTypes.func
};

export default AuthenticationRoute;
