import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import styled from './styles';

class Switch extends Component {
	state = {
		checked: !!this.props.value || this.props.defaultChecked
	};

	handleChange = event => {
		const { checked } = event.target;

		this.setState({ checked });

		this.props.onChange(checked);
	};

	render() {
		const { checked = this.state.checked, id, name, disabled, autoComplete } = this.props;

		return (
			<styled.OuterContainer disabled={disabled} checked={checked}>
				<styled.Input
					checked={checked}
					id={id}
					name={name}
					type="checkbox"
					disabled={disabled}
					autoComplete={autoComplete ? 'on' : 'off'}
					onChange={this.handleChange}
				/>
				<styled.Container checked={checked} disabled={disabled}>
					<styled.Ball checked={checked} disabled={disabled}>
						<Icon
							checked={checked}
							disabled={disabled}
							name="check_bold"
							size={16}
							styles={styled.iconCheckStyles}
						/>
					</styled.Ball>
				</styled.Container>
			</styled.OuterContainer>
		);
	}
}

Switch.propTypes = {
	/** Valor del componente. Requerido en caso de usarlo como controlled component */
	checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
	/** Si está checkeado por defecto, en caso de usarlo como uncontrolled component */
	defaultChecked: PropTypes.bool,
	/** Id del campo */
	id: PropTypes.string,
	/** Atributo name del input */
	name: PropTypes.string,
	/** Callback disparado al cambiar el valor de checked */
	onChange: PropTypes.func,
	/** Si es true, deshabilita el componente */
	disabled: PropTypes.bool,
	/** Si es true habilita el autoComplete */
	autoComplete: PropTypes.bool,
	/** Valor del componente en caso de mostrar contenido ya existente */
	value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
};

Switch.defaultProps = {
	defaultChecked: false,
	disabled: false,
	autoComplete: false,
	onChange: () => null
};

export default Switch;
