import { INIT_BROWSE, CLEAR_BROWSE, SET_BROWSE_INITIAL_STATES } from '../types';

export const createBrowse = (key, schema, useUrlUpdates = false, withinPreview = false) => ({
	type: INIT_BROWSE,
	browse: key,
	schema,
	useUrlUpdates,
	withinPreview
});

export const clearBrowse = key => ({
	type: CLEAR_BROWSE,
	browse: key
});

export const setInitialStates = schema => currentBrowse => ({
	type: SET_BROWSE_INITIAL_STATES,
	schema,
	meta: { name: currentBrowse }
});
