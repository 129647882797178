import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions } from 'modules/navbar';
import { background as styled } from './styles';

const Background = ({ isVisible, close }) => (
	<styled.Background isVisible={isVisible} onClick={close} />
);

Background.propTypes = {
	isVisible: PropTypes.bool.isRequired,
	close: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	isVisible: state.menu.isOpen || state.navbar.isProfileDetailOpen || state.navbar.isOpenClient
});

const mapDispatchToProps = {
	close: actions.closeAll
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Background);
