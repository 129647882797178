import styled, { css } from 'styled-components';
import { colors } from 'theme';
import { Grid } from 'components/Grid';
import { mediaBreaks } from 'devices';

const { onlyDesktop, tabletLg: onlyMobile, onlyPrint } = mediaBreaks;

const getGridStyles = ({ isEditSection, contained }) => {
	const baseStyles = css`
		padding: 0;
		margin: 0;
	`;

	if (contained) return baseStyles;

	if (isEditSection)
		return css`
			${onlyDesktop`
				padding: 24px 20px;
				margin: 16px 26px;
			`}
			${onlyMobile`
				border-radius: 3px;
				margin: 16px 15px;
				padding-top: 15px;
			`}
		`;

	return baseStyles;
};

export const content = {
	ContainerForm: styled.form`
		display: flex;
		flex-grow: 1;
		width: 100%;
	`,
	StyledGrid: styled(Grid)`
		width: 100%;
		background-color: ${colors.white};
		${props => getGridStyles(props)}
		${onlyPrint`
			flex-flow: column;
			> div {
				flex: initial;
				max-width: initial;
				margin-left: 0;
			}
		`}
	`,
	GroupWrapper: styled.div`
		&:not(:last-child) {
			${onlyDesktop`
			margin-bottom: 40px;
			`}
		}
		${({ showGroup }) => css`
			display: ${showGroup ? 'block' : 'none'};
		`}
	`,
	Group: styled.div`
		${onlyDesktop`
			${({ splitColumns }) =>
				splitColumns &&
				`
				display: grid;
				grid-template-columns: 55.9% 1fr;
				grid-column-gap: 5.4%;
			`}
		`}
	`,
	Column: styled.div`
		${onlyDesktop`
			display: flex;
			flex-wrap: wrap;
	 	`}
	`,
	Container: styled.div`
		padding: 16px 26px 48px;
		background-color: ${colors.lightGrey};
		flex-grow: 1;
		margin-top: 8px;
		border-top: 1px solid ${colors.grey};
		position: relative;
		padding-bottom: 16px;
		display: flex;
	`,
	SpinnerWrapper: styled.div`
		display: flex;
		align-items: center;
		justify-content: center;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 3;
		width: 100%;
		height: 100%;
		position: absolute;
		background-color: ${colors.transparentWhite};
	`
};
