import { addHistoryMenu, openMenuSection } from 'utils/mobileMenuHistory';
import * as types from './types';
import { openMenu, closeMenuOnly, close as closeMenu } from '../menu/actions';

export const open = () => ({
	type: types.OPEN
});

export const close = (closeAll = true) => ({
	type: types.CLOSE,
	closeAll
});

export const closeModule = () => ({
	type: types.CLOSE_MODULE
});

export const lock = () => ({
	type: types.LOCK
});

export const unlock = () => ({
	type: types.UNLOCK
});

const setEnterTimerId = timerID => ({
	type: types.SET_ENTER_TIMER_ID,
	timerID
});

const clearEnterTimerIdAction = () => ({
	type: types.CLEAR_ENTER_TIMER_ID
});

const clearEnterTimerId = () => (dispatch, getState) => {
	const { navbar } = getState();

	if (navbar.enterTimerId) {
		clearTimeout(navbar.enterTimerId);
		dispatch(clearEnterTimerIdAction());
	}
};

const startChangingModule = () => ({
	type: types.START_CHANGING_MODULE
});

const stopChangingModule = () => ({
	type: types.STOP_CHANGING_MODULE
});

export const selectModuleAction = module => ({
	type: types.SELECT_MODULE,
	module
});

export const selectModule = (module, isMobile = false) => (dispatch, getState) => {
	const { menu, navbar } = getState();
	if (isMobile) openMenuSection('module', module);

	if (navbar.selectedModule !== '') {
		dispatch(startChangingModule());
		setTimeout(dispatch, 200, stopChangingModule());
	}

	dispatch(clearEnterTimerId());
	dispatch(selectModuleAction(module));

	if (!navbar.isOpen && !menu.isOpen) dispatch(lock());

	if (!menu.isOpen) dispatch(openMenu());
};

export const enter = (isMobile = false) => (dispatch, getState) => {
	const { navbar } = getState();
	if (navbar.isOpen || navbar.isLocked) return false;

	const timerID = setTimeout(dispatch, 300, open());
	if (isMobile) {
		addHistoryMenu();
	}

	dispatch(isMobile ? open() : setEnterTimerId(timerID));
};

export const leave = () => (dispatch, getState) => {
	const { navbar } = getState();

	dispatch(clearEnterTimerId());

	if (
		navbar.isOpen &&
		!navbar.selectedModule &&
		!navbar.isProfileDetailOpen &&
		!navbar.isOpenClient
	)
		dispatch(close());
};

export const openProfile = () => ({
	type: types.OPEN_PROFILE_DETAIL
});

export const clientClose = () => ({
	type: types.CLOSE_CLIENT
});

export const closeClient = () => dispatch => {
	dispatch(clientClose());
};

export const openProfileDetail = (isMobile = false) => (dispatch, getState) => {
	const { menu, navbar } = getState();

	if (navbar.isOpenClient) dispatch(closeClient());
	if (menu.isOpen) dispatch(closeMenuOnly());
	if (isMobile && navbar.isOpen) dispatch(closeMenu());

	dispatch(openProfile());
};

export const closeProfileDetail = () => ({
	type: types.CLOSE_PROFILE_DETAIL
});

export const openSearch = () => ({
	type: types.OPEN_SEARCH
});

export const closeSearch = () => ({
	type: types.CLOSE_SEARCH
});

const clientOpen = () => ({
	type: types.OPEN_CLIENT
});

export const openClient = () => (dispatch, getState) => {
	const { menu, navbar } = getState();
	if (navbar.isProfileDetailOpen) dispatch(closeProfileDetail());
	if (!navbar.isOpen) dispatch(open());
	if (menu.isOpen) dispatch(closeMenuOnly());
	dispatch(clientOpen());
};

export const closeAll = () => (dispatch, getState) => {
	const { menu, navbar } = getState();

	if (menu.isOpen) dispatch(closeMenuOnly());
	if (navbar.isOpenClient) dispatch(closeClient());
	dispatch(close());
};
