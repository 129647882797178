import React from 'react';
import PropTypes from 'prop-types';
import translationHOC from 'hocs/translationHOC';
import styled from './styles';

const Title = ({ t, titleColor, iconColor }) => (
	<styled.PopupTitleContainer>
		<styled.PopupTitle
			fontWeight="bold"
			icon="exclamation_triangle"
			color={titleColor}
			iconColor={iconColor}
		>
			¡{t('common.title.atention')}!
		</styled.PopupTitle>
	</styled.PopupTitleContainer>
);

Title.defaultProps = {
	titleColor: 'statusRed',
	iconColor: 'statusRed'
};

Title.propTypes = {
	titleColor: PropTypes.string,
	iconColor: PropTypes.string
};

export default translationHOC(Title);
