import { actions as asyncDependenciesActions } from 'modules/asyncDependencies';
import { auxCallRequest } from 'pages/Monitor/utils';
import * as types from './types';

const fetchMonitorDataRequest = monitorName => ({
	type: types.FETCH_DATA_REQUEST,
	name: monitorName
});

export const fetchMonitorDataSuccess = (data, monitorName) => ({
	type: types.FETCH_DATA_SUCCESS,
	name: monitorName,
	data
});

const fetchMonitorDataFailure = (status, monitorName) => ({
	type: types.FETCH_DATA_FAILURE,
	status,
	name: monitorName
});

export const getMonitorCurrentData = (monitorName, getState) => {
	const state = getState();

	const { currentData = [] } = state.monitor[monitorName] || {};

	return currentData;
};

export const executeGetMonitorDependencies = (
	dependencies,
	monitorName,
	newRegister,
	formattedData
) => async (dispatch, getState) => {
	dispatch(
		asyncDependenciesActions.executeGetAsyncDependencies(
			dependencies,
			() => getMonitorCurrentData(monitorName, getState),
			monitorName,
			'monitor',
			newRegister,
			formattedData
		)
	);
};

export const reloadData = reloadMonitor => (dispatch, getState) => {
	const {
		page: { schema }
	} = getState();

	dispatch({
		type: types.RELOAD_DATA,
		name: schema.name,
		reloadMonitor
	});
};

export const fetchMonitorData = (
	pageNumber,
	pages,
	schema,
	filterParams,
	sortingParams,
	showLoadMoreButtonPressed,
	nameButtonPressed,
	isAutoRefresh
) => async (dispatch, getState) => {
	const { name, dependencies, fields } = schema;

	const currentData = getMonitorCurrentData(name, getState);

	const headers = { 'x-janis-page': pageNumber };

	if (!fields || !fields.length) return;
	dispatch(fetchMonitorDataRequest(name));

	const filterColumn = fieldsColumns => {
		const columns =
			(fieldsColumns?.length && fieldsColumns.filter(field => field.name === nameButtonPressed)) ||
			fields;
		return columns?.length ? columns : fields;
	};

	const columnsData = filterColumn(fields);

	const standarRequest = async () => {
		const response = await Promise.all(
			columnsData?.map(async field => {
				const dataResponse = await auxCallRequest({
					endpoint: field.source,
					endpointParameters: field.endpointParameters,
					filterParams,
					sortingParams,
					headers
				});
				return {
					name: field.name,
					dataResponse
				};
			})
		);
		return response;
	};

	const filterPromiseResponse = response =>
		response.filter(res => res.status === 'fulfilled').map(res => res.value);

	const autoRefreshRequests = async () => {
		const response = await Promise.allSettled(
			columnsData.map(async column => {
				const currentColumnPages = pages[column.name];
				const dataResponse = await Promise.allSettled(
					currentColumnPages.map(async currentPage => {
						const res = await auxCallRequest({
							endpoint: column.source,
							endpointParameters: column.endpointParameters,
							filterParams,
							sortingParams,
							headers: { 'x-janis-page': currentPage }
						});

						return res;
					})
				);
				const flatData = filterPromiseResponse(dataResponse).flat();
				return { name: column.name, dataResponse: flatData };
			})
		);
		const parseResponse = filterPromiseResponse(response);
		return parseResponse || [];
	};

	try {
		const responseData = isAutoRefresh ? await autoRefreshRequests() : await standarRequest();

		const formattedData = responseData?.map(field =>
			field?.dataResponse?.map(item => ({
				...item,
				columnName: field.name
			}))
		);

		const response = formattedData?.length && formattedData.flat();

		const data = showLoadMoreButtonPressed ? [...currentData, ...response] : response;

		dispatch(fetchMonitorDataSuccess(data, name));

		dispatch(
			executeGetMonitorDependencies(
				dependencies,
				name,
				showLoadMoreButtonPressed && response,
				formattedData
			)
		);
		dispatch(reloadData(false));
		return response;
	} catch (e) {
		const { response = {} } = e;
		const { status = 500 } = response;
		dispatch(fetchMonitorDataFailure(status, name));
	}
};
