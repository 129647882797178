import React from 'react';
import PropTypes from 'prop-types';
import styled from './styles';

/* eslint-disable react/no-unknown-property */
const Spinner = ({ size, color, ...props }) => (
	<styled.Loader size={size} {...props}>
		<styled.Circular viewBox="25 25 50 50">
			<styled.Path
				color={color}
				cx="50"
				cy="50"
				r="20"
				fill="none"
				stroke-width="2"
				stroke-miterlimit="10"
			/>
		</styled.Circular>
	</styled.Loader>
);

Spinner.propTypes = {
	size: PropTypes.number,
	color: PropTypes.string
};

Spinner.defaultProps = {
	size: 50,
	color: 'blue'
};

export default Spinner;
