import * as types from './types';
import * as pageTypes from '../page/types';

const initialState = {
	schema: {},
	fieldsComponents: {},
	data: {},
	error: false,
	isFetching: false,
	isReady: false,
	isModalOpen: false,
	navigationFlag: '',
	errorData: {},
	fieldsChangedByTriggers: {}
};

const mergeState = (state, action, properties) => ({
	...state,
	[action.meta.name]: {
		...state[action.meta.name],
		...properties
	}
});

export default function reducer(state = {}, action) {
	switch (action.type) {
		case pageTypes.CHANGE_PAGE:
			return {};

		case types.FETCH_DATA_REQUEST:
			return mergeState(state, action, {
				error: false,
				isFetching: true,
				isReady: false
			});

		case types.FETCH_DATA_SUCCESS:
			return mergeState(state, action, {
				data: action.data,
				error: false,
				isFetching: false,
				isReady: true
			});

		case types.FETCH_DATA_FAILURE:
			return mergeState(state, action, {
				error: true,
				isFetching: false,
				errorData: action.status
			});

		case types.SAVE_DATA_REQUEST:
			return mergeState(state, action, {
				isFetching: true
			});

		case types.SAVE_DATA_SUCCESS:
			return mergeState(state, action, {
				isFetching: false
			});

		case types.SAVE_DATA_FAILURE:
			return mergeState(state, action, {
				isFetching: false
			});

		case types.SET_NAVIGATION_FLAG:
			return mergeState(state, action, { navigationFlag: action.flag });

		case types.SET_REMOTE_FIELDS: {
			const { schema } = state[action.meta.name];

			return mergeState(state, action, {
				schema: {
					...schema,
					fieldsGroup: action.fieldsGroup
				}
			});
		}

		case types.SET_FIELD_COMPONENT: {
			if (!state[action.meta.name]) return state;

			const currentFieldsComponents = state[action.meta.name].fieldsComponents;

			return mergeState(state, action, {
				fieldsComponents: {
					...currentFieldsComponents,
					[action.field]: {
						...currentFieldsComponents[action.field],
						[action.component]: {
							visible: action.visible,
							id: action.id
						}
					}
				}
			});
		}

		case types.SET_AFFECTED_FIELD:
			return mergeState(state, action, {
				fieldsChangedByTriggers: {
					...state[action.meta.name].fieldsChangedByTriggers,
					[action.field]: action.value
				}
			});

		case types.INIT_FORM_SECTION:
			return {
				...state,
				[action.formSection]: {
					...initialState,
					schema: action.schema
				}
			};

		case types.UPDATE_FORM_SECTION:
			return {
				...state,
				[action.formSection]: {
					...state[action.formSection],
					schema: {
						...state[action.formSection].schema,
						fieldsGroup: [
							{ ...state[action.formSection].schema.fieldsGroup[0], fields: action.fields }
						]
					}
				}
			};

		case types.DESTROY_FORM_SECTION:
			return Object.keys(state).reduce((accum, key) => {
				const accumulator = { ...accum };
				if (action.formSection !== key) accumulator[key] = state[key];
				return accumulator;
			}, {});

		default:
			return state;
	}
}
