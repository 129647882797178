import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { hot, setConfig } from 'react-hot-loader';
import { I18nextProvider } from 'react-i18next';
import Router from 'components/Router';
import { currentEnvironment } from 'config/environment';
import ReactGA from 'react-ga4';
import storage from 'store';
import i18n from './i18n';
import store from './store';
import Reset from './global-styles/reset';
import Fonts from './global-styles/fonts';
import Tooltip from './global-styles/tooltip';
import { TranslateContext } from './contexts/translateContext';

setConfig({ logLevel: 'no-errors-please' });

window.i18n = i18n;

const envToLogInAnalytics = ['prod'];

if (envToLogInAnalytics.includes(currentEnvironment)) {
	ReactGA.initialize('G-F8MMX1GKPP');
}

const App = () => {
	const [isTranslateActive, setIsTranslateActive] = useState(storage.get('translationStatus'));

	return (
		<Provider store={store}>
			<I18nextProvider i18n={i18n}>
				<TranslateContext.Provider value={{ isTranslateActive, setIsTranslateActive }}>
					<Tooltip />
					<Reset />
					<Fonts />
					<Router />
				</TranslateContext.Provider>
			</I18nextProvider>
		</Provider>
	);
};

export default hot(module)(App);
