import { useState, useEffect } from 'react';

/** Checks the current OS.
 *
 * @returns {object} an object with the current OS names and boolean values for each device.
 */

const useGetPlatform = () => {
	const [platforms, setPlatforms] = useState({ windows: false, linux: false, mac: false });

	const {
		navigator: { platform, userAgentData = {} }
	} = window;

	const { platform: userAgentDataPlatform } = userAgentData;

	useEffect(() => {
		const currentPlatform = userAgentDataPlatform || platform || 'windows';
		const platformString = currentPlatform.toLowerCase();

		const windows = platformString.includes('windows');
		const linux = platformString.includes('linux');
		const mac = platformString.includes('mac');
		const android = /android/i.test(platformString);

		setPlatforms({ windows, linux, mac, android });
	}, []);

	return platforms;
};

export default useGetPlatform;
