import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import translationHOC from 'hocs/translationHOC';
import Image from 'components/Image';
import Text from 'components/Text';
import UserChip from 'components/UserChip';
import legacyImg from 'images/legacy.svg';
import { getAuthCookieDecoded } from 'utils/auth';
import { useDevices } from 'utils/hooks';
import styled from '../styles';

const Header = ({ appName, appLogo, error, t }) => {
	const [logo, setLogo] = useState(legacyImg);

	useEffect(
		() => {
			async function verifyUrl() {
				try {
					await fetch(appLogo);

					setLogo(appLogo);
				} catch (e) {
					setLogo(legacyImg);
				}
			}

			if (appLogo) verifyUrl();
		},
		[appLogo]
	);

	const { onlyDesktop } = useDevices();

	const {
		given_name: firstName,
		family_name: lastName,
		email,
		images = {}
	} = getAuthCookieDecoded();

	return (
		<styled.Header>
			<styled.Title color="black" fontSize={onlyDesktop ? 'xxlarge' : 'large'} fontWeight="medium">
				<styled.BlueText>{appName} </styled.BlueText>
				{t('common.message.wantsToAccessYourJanisAccount')}:
			</styled.Title>

			{error && (
				<Text color="statusRed">
					{t('common.title.error')}! {error}
				</Text>
			)}

			<styled.UserDataWrapper>
				<Image url={logo} alt={appName} width={48} />
				<styled.ArrowIcon color="blue" name="arrow_down" />
				<UserChip
					showFull
					variant="contained"
					firstName={firstName}
					lastName={lastName}
					email={email}
					image={images.thumbnail}
				/>
			</styled.UserDataWrapper>
		</styled.Header>
	);
};

Header.propTypes = {
	appName: PropTypes.string,
	appLogo: PropTypes.string,
	error: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool])
};

export default translationHOC(Header);
