import React from 'react';
import { colors } from 'theme';
import PropTypes from 'prop-types';
import { StyledText, StyledIcon, StyledWrapper } from './styles';

const Text = ({
	children,
	color,
	disabled,
	fontWeight,
	icon,
	iconColor,
	withWhiteSpace,
	...props
}) => (
	<StyledWrapper className="text-wrapper">
		{icon && <StyledIcon name={icon} color={iconColor} />}
		<StyledText
			disabled={disabled}
			fontWeight={fontWeight}
			withWhiteSpace={withWhiteSpace}
			color={color}
			{...props}
		>
			{children || children === 0 ? children : '-'}
		</StyledText>
	</StyledWrapper>
);

Text.propTypes = {
	children: PropTypes.node,
	color: PropTypes.string,
	disabled: PropTypes.bool,
	fontWeight: PropTypes.oneOf(['light', 'normal', 'medium', 'bold']),
	icon: PropTypes.string,
	iconColor: PropTypes.string
};

Text.defaultProps = {
	color: colors.blackHover,
	disabled: false,
	fontWeight: 'normal',
	icon: '',
	iconColor: colors.black
};

export default Text;
