import React from 'react';
import PropTypes from 'prop-types';
import translationHOC from 'hocs/translationHOC';
import Icon from 'components/Icon';
import styled from '../styles';

const BackToLogin = ({ action, translateHelperString }) => (
	<styled.BottomContent>
		<styled.LinkContainer>
			<styled.BackToLoginButton type="button" onClick={action}>
				<Icon name="chevron_left" color="blue" />
				{translateHelperString('login.action.backToLogin')}
			</styled.BackToLoginButton>
		</styled.LinkContainer>
	</styled.BottomContent>
);

BackToLogin.propTypes = {
	action: PropTypes.func,
	translateHelperString: PropTypes.func
};

export default translationHOC(BackToLogin);
