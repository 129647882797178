import React, { useCallback, useEffect, useState } from 'react';
import { arrayOf, oneOfType, node } from 'prop-types';
import { getAuthCookieDecoded } from 'utils/auth';

const OnlyDev = ({ children }) => {
	const [isDev, setIsDev] = useState(false);

	const validateIsDev = useCallback(() => {
		const { isDev: isDevFromCookie } = getAuthCookieDecoded() || {};
		if (isDevFromCookie === isDev) return null;

		setIsDev(isDevFromCookie);
	}, []);

	if (!children) return null;

	useEffect(() => {
		validateIsDev();
	}, []);

	if (isDev) return <React.Fragment>{children}</React.Fragment>;
	return null;
};

OnlyDev.propTypes = {
	children: oneOfType([node, arrayOf(node)]).isRequired
};

export default OnlyDev;
