import * as types from './types';

export const addAlert = data => ({
	type: types.ALERTS_ADD,
	data
});

export const removeAlert = id => ({
	type: types.ALERTS_REMOVE,
	id
});
