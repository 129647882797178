import { buildUrlFromLocationPath, getLocationParts } from 'utils/location';
import { HOST_ENDPOINT, SCHEMA_ENDPOINT } from 'config/endpoints';
import { extendConfigForArrays as extendConfig } from 'utils/request';
import { get as lodashGet } from 'lodash';
import { isSchemaCacheDisabled } from 'modules/page/actions';
import { get } from 'axios';
import { getFromStorage, setToStorage } from 'utils/storage';
import ms from './ms';

// fetch schema type dynamic
export const getPageSchema = async (source = {}, isCacheDisabled = isSchemaCacheDisabled()) => {
	const [urlService, urlNameSpace, urlMethod] = getLocationParts();
	const { service = urlService, namespace = urlNameSpace, method = urlMethod } = source;

	const url = `${SCHEMA_ENDPOINT}/schemas/${service}/${namespace}/${method}.json`;

	if (isCacheDisabled) {
		const response = await get(url);
		return response.data;
	}

	const schemaKey = `cacheSchema-${[service, namespace, method].join('/')}`;
	const cached = getFromStorage(schemaKey);
	if (cached) return cached;

	const response = await get(url);
	setToStorage(schemaKey, response.data, 30);
	return response.data;
};

export const getUrlPath = (prefix, useEndpointParam = false, source) => {
	if (source && typeof source === 'object') {
		const { service, namespace, method } = source;

		return `${HOST_ENDPOINT}/${prefix}/${service}/${namespace}/${method}/`;
	}

	return buildUrlFromLocationPath(prefix, useEndpointParam);
};

export const getPageData = async ({
	page,
	pageSize,
	source,
	endpointParameters,
	customHeaders,
	sourceField,
	currentViewData,
	isFetchTotal,
	...params
}) => {
	if (sourceField) {
		const items = lodashGet(currentViewData, sourceField) || [];

		return {
			rows: items,
			total: pageSize ? Math.ceil(items.length / pageSize) : items.length
		};
	}

	const url = source && source.url;

	const headers =
		page && pageSize
			? {
					'x-janis-page': page,
					'x-janis-page-size': pageSize
			  }
			: undefined;

	const method = source.httpMethod || 'get';

	const sourceData = url ? { url } : source;

	const response = await ms[method.toLowerCase()]({
		...sourceData,
		data: params,
		headers: {
			...headers,
			...customHeaders
		},
		extendConfig: extendConfig(true),
		endpointParameters,
		cancelOverlappingRequests: true
	});
	if (Array.isArray(response.data)) {
		return {
			rows: response.data,
			total: response.headers['x-janis-total']
				? parseInt(response.headers['x-janis-total'], 10)
				: null
		};
	}
	if (isFetchTotal && !response.data)
		return {
			total: response.headers['x-janis-total']
				? parseInt(response.headers['x-janis-total'], 10)
				: null
		};

	return response.data;
};

/**
 * GET from location pathname: /prefix/service/nameSpace/method
 * @param {string} prefix
 * @returns {Promise}
 * @example
 * const data = await getFromLocation('data');
 */
export const getFromLocation = async (prefix, source) => {
	const url = getUrlPath(prefix, false, source);

	const response = await ms.get({ url });

	return response.data;
};
