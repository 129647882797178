import styled from 'styled-components';
import { colors, font, mixins, timingFunctions } from 'theme';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Link from 'components/Link';
import TextComponent from 'components/Text';
import { mediaBreaks } from 'devices';

const { onlyDesktop, tabletLg: onlyMobile } = mediaBreaks;

export default {
	Wrapper: styled.div`
		background: ${colors.white};
		display: flex;
		flex-direction: column;
		align-items: center;
		color: ${colors.black};
		${onlyMobile`
			width: 100%;
			max-width: 400px;
			margin: auto;
		`}
	`,
	Content: styled.div`
		min-height: 210px;
		margin: auto;
		display: flex;
		flex-direction: column;
		position: relative;
		${onlyDesktop`
			width: 480px;
			padding: 26px 34px 30px;
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
		`}
		${onlyMobile`
			width: 100%;
			padding: 0px 15px;
		`}
	`,
	SpinnerWrapper: styled.div`
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(255, 255, 255, 0.9);
		z-index: 1;
	`,
	LogoWrapper: styled.div`
		${onlyDesktop`
			margin: 45px auto 30px;
		`}
		${onlyMobile`
			margin: 28px auto 24px;
			img {
				width: 115px;
			}
		`}
	`,
	Header: styled.div`
		text-align: center;
		margin: 0px auto;
		${onlyDesktop`
			width: 325px;
		`}
		${onlyMobile`
			width: 100%;
		`}
	`,
	Title: styled(TextComponent)`
		letter-spacing: 0;
		text-align: center;
		margin-bottom: 10px;
		${onlyDesktop`
			width: 100%;
			line-height: 29px;
		`}
		${onlyMobile`
			width: 90%;
			margin: auto;
			line-height: 22px;
		`}
	`,
	BlueText: styled.span`
		color: ${colors.blue};
	`,
	UserDataWrapper: styled.div`
		display: flex;
		align-items: center;
		margin: 14px auto 0px;
		justify-content: center;
	`,
	ArrowIcon: styled(Icon)`
		transform: rotate(270deg);
		flex-shrink: 0;
		${onlyDesktop`
			margin: 0px 18px;
		`}
		${onlyMobile`
			margin: 0px 8px;
		`}
	`,
	Message: styled.div`
		${({ showData }) => !showData && 'height: 100%;'}
		margin: 14px auto 0px;
		border-top: 1px solid ${colors.greyHover};
		font-weight: 500;
		${onlyDesktop`
			width: 413px;
			padding: ${props => (props.showData ? '18px 0px 0px' : '32px 0px 24px')} ;
		`}
		${onlyMobile`
			width: 100%;
			padding: ${props => (props.showData ? '18px 0px 0px' : '32px 0px 21px')};
			font-size: ${font.medium};
		`}
	`,
	PreAuthErrorMessage: styled.p`
		text-overflow: ellipsis;
		overflow: hidden;
	`,
	MessageList: styled.ul`
		list-style-type: none;
		overflow: auto;
		font-weight: 400;
		${mixins.scrollbar(colors.grey)}
		${onlyDesktop`
			font-size: ${font.medium};
			margin-top: 22px;
			max-height: 30vh;
		`}
		${onlyMobile`
			font-size: ${font.base};
			margin-top: 18px;
		`}
		li {
			display: flex;
			align-items: center;
			margin-bottom: 10px;
			line-height: 17px;
			svg {
				margin-right: 8px;
			}
		}
	`,
	ActionsWrapper: styled.div`
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin: auto;
		justify-content: space-between;
		${props => props.showBorder && `border-top: 1px solid ${colors.greyHover};`}
		${onlyDesktop`
			padding: 21px 54px 0;
		`}
		${onlyMobile`
			padding: 16px 0 0;
		`}
	`,
	ActionButton: styled(Button)`
		flex-shrink: 0;
		${onlyDesktop`
			width: 146px;
			&:first-child {
				margin-right: 12px;
			}
		`}
		${onlyMobile`
			width: 48%;
		`}
	`,
	RedirectInfo: styled.div`
		color: ${colors.darkGrey};
		font-size: ${font.baseSmall};
		width: 100%;
		margin-top: 18px;
		text-align: center;
	`,
	Footer: styled.div`
		color: ${colors.darkGrey};
		font-size: ${font.baseSmall};
		display: flex;
		align-items: center;
		${onlyDesktop`
			margin: 29px auto;
		`}
		${onlyMobile`
			margin: 22px auto 17px;
		`}
	`,
	LanguageSelector: styled.div`
		> div {
			border: none;
		}
	`,
	Link: styled(Link)`
		color: ${colors.blackHover};
		font-size: ${font.baseSmall};
		white-space: nowrap;
		transition: color 0.2s ${timingFunctions.standard};
		line-height: 33px;
		${onlyDesktop`
			margin-left: 38px;
		`}
		&:after {
			content: '';
			display: block;
			width: 1px;
			height: 14px;
			background: ${colors.grey};
			${onlyDesktop`
				margin: 0px 24px 0px 38px;
			`}
			${onlyMobile`
				margin: 0px 4px 0px 18px;
			`}
		}
		&:hover,
		&:active {
			color: ${colors.black};
		}
	`
};
