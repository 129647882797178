import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import translationHOC from 'hocs/translationHOC';
import { useDevices } from 'utils/hooks';
import { prefixName } from '../utils';
import { titles as styled } from './styles';

const TranslatedTitle = ({ title, t }) => t(title);

const TitleTranslated = translationHOC(TranslatedTitle);

const Titles = ({ categoryName, moduleName, hasSubcategories, noTransition }) => {
	const { onlyDesktop } = useDevices();

	const formatedModuleName = prefixName(moduleName);
	const formatedCategoryName = categoryName && prefixName(categoryName);

	return (
		<styled.Container>
			{(onlyDesktop || !categoryName) && (
				<styled.ModuleTitle noTransition={noTransition} isSmall={hasSubcategories}>
					<TitleTranslated title={formatedModuleName} />
				</styled.ModuleTitle>
			)}
			<styled.CategoryTitle isVisible={hasSubcategories}>
				<TitleTranslated title={formatedCategoryName} />
			</styled.CategoryTitle>
		</styled.Container>
	);
};

Titles.propTypes = {
	categoryName: PropTypes.string.isRequired,
	hasSubcategories: PropTypes.bool.isRequired,
	moduleName: PropTypes.string.isRequired,
	noTransition: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	categoryName: state.menu.selectedCategory,
	moduleName: state.navbar.selectedModule,
	hasSubcategories: !!state.menu.selectedCategory,
	noTransition: state.navbar.isChangingModule
});

export default connect(mapStateToProps)(Titles);
