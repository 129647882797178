import React from 'react';
import { AsyncPaginate, withAsyncPaginate } from 'react-select-async-paginate';
import Creatable from 'react-select/creatable';

const CreatableAsyncPaginate = withAsyncPaginate(Creatable);

const Select = ({ baseProps, commonProps, ...props }) => {
	const { canCreate, isSelectForm } = baseProps;
	const { handleCreateOption } = commonProps;
	const Component = canCreate && !isSelectForm ? CreatableAsyncPaginate : AsyncPaginate;

	const onCreateOption = value => handleCreateOption(props.value, value);

	return (
		<Component
			{...baseProps}
			{...commonProps}
			{...props}
			{...(canCreate ? { onCreateOption } : {})}
			classNamePrefix="select"
		/>
	);
};

export default Select;
