import { getAsyncDependencies } from 'utils/dependencies';
import { get } from 'lodash';
import { setSaveEditDependencies } from 'modules/editCreate/actions';
import { getCurrentPreview } from './sections/preview';
import * as types from './types';
import sectionsSet from './sections';

const setAsyncDependenciesLoading = dependencyName => section => ({
	meta: { name: section },
	type: types.SET_ASYNC_DEPENDENCIES_LOADING,
	dependencyName
});

const setAsyncDependenciesSuccess = (dependencyName, data) => section => ({
	meta: { name: section },
	type: types.SET_ASYNC_DEPENDENCIES_SUCCESS,
	dependencyName,
	data
});

const setAsyncDependenciesError = dependencyName => section => ({
	meta: { name: section },
	type: types.SET_ASYNC_DEPENDENCIES_ERROR,
	dependencyName
});

const sectionsTypes = {
	edit: 'edit',
	preview: 'preview',
	rowPreview: 'preview',
	formSection: 'form',
	browse: 'browse',
	monitor: 'monitor'
};

const executeSectionsTypes = (type, args) => {
	const sectionType = sectionsTypes[type];

	const sectionFunction = get(sectionsSet, sectionType);
	if (sectionFunction) sectionFunction({ type, ...args });
};

export const executeGetAsyncDependencies = (
	dependencies,
	data,
	section,
	type,
	browseData = [],
	dataPerColumn
) => (dispatch, getState) => {
	if (!dependencies || !dependencies.length) return null;

	const sectionIdentifier =
		type === 'preview' || type === 'rowPreview'
			? getCurrentPreview(type, getState, section).name
			: section;

	const sectionName = type ? `${type}-${sectionIdentifier}` : `${sectionIdentifier}`;

	const setLoading = ({ name, dependencies: innerDependencies }) => {
		dispatch(setAsyncDependenciesLoading(name)(sectionName));
		if (innerDependencies && innerDependencies.length) innerDependencies.forEach(setLoading);
	};

	dependencies.forEach(setLoading);

	const setDependency = (error, dependencyName, value, field = '') => {
		if (error) {
			return error === 'skip'
				? dispatch(setAsyncDependenciesSuccess(dependencyName, value)(sectionName))
				: dispatch(setAsyncDependenciesError(dependencyName)(sectionName));
		}

		if (type)
			executeSectionsTypes(type, {
				dispatch,
				getState,
				section,
				field,
				value,
				browseData,
				auxData: browseData && typeof data === 'function' && data()
			});
		dispatch(setAsyncDependenciesSuccess(dependencyName, value)(sectionName));
	};

	const editDependenciesChangeData = dependency => {
		dispatch(setSaveEditDependencies(dependency));
	};

	const getDependenciesTypes = {
		monitor: [dependencies, data, setDependency, type, false, browseData, dataPerColumn],
		formSection: [dependencies, data, setDependency, type, false, editDependenciesChangeData]
	};

	const getDependenciesArgs = getDependenciesTypes[type] || [
		dependencies,
		data,
		setDependency,
		type
	];

	getAsyncDependencies(...getDependenciesArgs);
};
