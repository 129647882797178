import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchSchema } from 'modules/page/actions';
import translationHOC from 'hocs/translationHOC';
import { parse } from 'qs';
import Title from 'components/AuthResources/Title';
import Form from './components/Form';
import Spinner from './components/Spinner';
import styled from './styles';

class Content extends PureComponent {
	state = {
		isLoading: true
	};

	async componentDidMount() {
		const params = parse(window.location.search, { ignoreQueryPrefix: true });

		if (params.token) {
			this.setState({ token: params.token });
			await this.props.fetchSchema();
		}

		this.toggleLoader();
	}

	toggleLoader = () => {
		this.setState(prevState => ({ isLoading: !prevState.isLoading }));
	};

	render() {
		const { translateHelperString, recovery = false } = this.props;
		const { isLoading, token } = this.state;
		const title = recovery ? 'recoveryPassword' : 'connectToJanis';

		return (
			<styled.Container>
				{isLoading && <Spinner />}

				<Title>{translateHelperString(`login.title.${title}`)}</Title>

				{token ? (
					<Form token={token} toggleLoader={this.toggleLoader} recovery={recovery} />
				) : (
					<styled.NoTokenError>
						{translateHelperString('common.message.noToken')}
					</styled.NoTokenError>
				)}
			</styled.Container>
		);
	}
}

const mapDispatchToProps = { fetchSchema };

Content.propTypes = {
	fetchSchema: PropTypes.func,
	recovery: PropTypes.bool,
	translateHelperString: PropTypes.func
};

export default connect(
	null,
	mapDispatchToProps
)(translationHOC(Content));
