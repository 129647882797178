import { connect } from 'react-redux';
import { actions, selectors } from 'modules/formSection';

const FormWrapper = ({
	formSection,
	schema,
	fieldsGroup,
	initialValues,
	globalDependencies,
	save,
	children
}) =>
	children({
		formSection,
		initialValues,
		globalDependencies,
		onSubmit: save,
		fieldsGroup,
		schema
	});
const makeMapStateToProps = () => {
	const getFieldsGroup = selectors.fieldsGroup();
	const getInitialValues = selectors.formatData();
	const getDefaultValues = selectors.getFormSectionDefaultValues();

	return (state, props) => {
		const formData = selectors.getData(state, props) || {};
		const emptyForm = !Object.keys(formData).length;

		return {
			fieldsGroup: getFieldsGroup(state, props),
			initialValues: emptyForm ? getDefaultValues(state, props) : getInitialValues(state, props),
			globalDependencies: selectors.getGlobalDependencies(state)
		};
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	save: () =>
		dispatch(actions.saveChanges(props.onSuccessCallback, props.dataFormatter)(props.formSection))
});

export default connect(
	makeMapStateToProps,
	mapDispatchToProps
)(FormWrapper);
