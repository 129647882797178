import { getCurrentHistory } from 'utils/location';

const history = getCurrentHistory();

const currentLocationState = () => {
	const {
		location: { state = {} }
	} = history;
	return { ...state };
};

/**
 *  add tag #Menu in url, and history state
 */
export const addHistoryMenu = () => {
	const currentState = currentLocationState();
	const state = { ...currentState, menu: true };

	history.push('#menu', { ...state });
};

/**
 * remove tag #Menu in url, and history state
 */
export const removeHistoryMenu = () => {
	const currentState = currentLocationState();
	const {
		location: { pathname }
	} = history;

	const state = Object.keys(currentState).reduce((accum, key) => {
		const accumulator = { ...accum };
		if (key !== 'menu') accumulator[key] = currentState[key];
		return accumulator;
	}, {});

	history.push(pathname, { ...state });
};

/**
 * go back to history before menu
 */
export const goBackHistoryMenu = () => {
	const { menu } = currentLocationState();
	if (menu) {
		const { length } = Object.keys(menu) + 1;
		history.goBack(-length);
	}
};

/**
 *  open modules and categories in history state
 * @param {string} type
 * @param {string} name
 */
export const openMenuSection = (type, name) => {
	const currentState = currentLocationState();
	const { menu: currentMenu } = currentState;
	// modules contains category
	const state = { ...currentState, menu: { ...currentMenu, [type]: name } };

	history.push('#menu', { ...state });
};

/**
 * close module and categories
 */
export const closeMenuSection = () => {
	history.goBack();
};
