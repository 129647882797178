import { browseDependencies as browse } from './browse';
import { editDependencies as edit } from './edit';
import { formDependencies as form } from './form';
import { monitorDependencies as monitor } from './monitor';
import { previewDependencies as preview } from './preview';

export default {
	browse,
	form,
	edit,
	monitor,
	preview
};
