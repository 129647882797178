import { CHANGE_SORT } from '../types';
import { getCurrentBrowse } from './actions-utils';
import { fetchData } from './fetch';

export const changeSort = sortDefault => currentBrowse => ({
	type: CHANGE_SORT,
	sortBy: sortDefault,
	meta: { name: currentBrowse }
});

export const sort = sortDefault => currentBrowse => (dispatch, getState) => {
	const state = getCurrentBrowse(getState(), currentBrowse);
	if (state.isFetching) return false;
	dispatch(changeSort(sortDefault)(currentBrowse));

	dispatch(fetchData()(currentBrowse));
};
