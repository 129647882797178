import React from 'react';
import { components } from 'react-select';
import Icon from 'components/Icon';

const DropdownIndicator = props =>
	components.DropdownIndicator && (
		<components.DropdownIndicator {...props}>
			<Icon name="chevron_down" />
		</components.DropdownIndicator>
	);

export default DropdownIndicator;
