import Icon from 'components/Icon';
import styled from 'styled-components';
import { colors } from 'theme';

export default {
	Container: styled.div`
		position: fixed;
		top: 0px;
		background-color: ${colors.red};
		height: 64px;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		z-index: 2064;
	`,
	Icon: styled(Icon)`
		width: 60px;
		margin-left: 20px;
		height: ${props => props.size}px;
	`,
	BoldText: styled.span`
		font-weight: bold;
		font-size: 14px;
	`,
	NormalText: styled.span`
		margin-left: 6px;
		font-size: 14px;
	`
};
