import { createSelector } from 'reselect';
import { getSchema } from 'utils/selectors';

export const getRootComponent = state => getSchema(state).root;

export const getSchemaFields = state => getSchema(state).fields;

export const getService = state => getSchema(state).service;

export const hasErrors = ({ page }) => page.hasErrorSchema || page.hasErrorJanisClient;

export const errorDetails = ({ page }) => page.errorData;

/**
 * Search the status field in the list of fields, if there is no status field return null
 * @param {Array} fields - Array of schema fields
 * @returns {string} fieldname
 */
export const getStatusFieldName = createSelector(
	getSchemaFields,
	fields => {
		const field = fields.find(fd => fd.attributes && fd.attributes.isStatus);
		return field ? field.name : null;
	}
);
