import React from 'react';
import { Router as ReactRouter, Route, Switch } from 'react-router';
import { getCurrentHistory } from 'utils/location';
import { isLoggedIn } from 'utils/auth';
import ToastGlobal from 'components/ToastGlobal';
import Navbar from 'components/Navbar';
import Authorize from 'pages/Authorize';
import WithScripts from 'components/WithScripts';
import WithWebSocket from 'components/WithWebSocket';
import Login from 'pages/Login';
import Logout from 'pages/Logout';
import ForgotPassword from 'pages/ForgotPassword';
import ProviderCallback from 'pages/ProviderCallback';
import PasswordConfirmation from 'pages/PasswordConfirmation';
import AuthenticationRoute from './AuthenticationRoute';
import PrivateRoute from './PrivateRoute';

import PageLoader, { PageCustomLoader } from '../PageLoader';

const history = getCurrentHistory();

const renderNavRgx = RegExp(
	/^(?!\/login|\/logout|\/forgot-password|\/id\/user\/confirm|\/id\/user\/password-recovery|\/oauth|\/provider\/callback).*$/
);

const scriptsToLoad = [
	{
		id: 'script-hotjar',
		script: `(function(h,o,t,j,a,r){
			h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
			h._hjSettings={hjid:3499710,hjsv:6};
			a=o.getElementsByTagName('head')[0];
			r=o.createElement('script');r.async=1;r.setAttribute('script-id','script-hotjar');
			r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
			a.appendChild(r);
			})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')`,
		paths: {
			exclude: [
				'/login',
				'/id/user/confirm',
				'/forgot-password',
				'/id/user/password-recovery',
				'/provider/callback',
				'/oauth/authorize'
			]
		},
		environments: ['prod']
	}
];

/**
 * BrowserRouter receive many props
 * PageLoader recive Key for remount component in change url
 */
const Router = () => (
	<ReactRouter history={history}>
		<WithWebSocket>
			<WithScripts scripts={scriptsToLoad}>
				<>
					<ToastGlobal />
					<Switch>
						<Route
							path="*"
							render={({ match }) => {
								const renderNav = renderNavRgx.test(match.url);
								return renderNav && isLoggedIn() ? <Navbar /> : null;
							}}
						/>
					</Switch>
					<Switch>
						{/* Login page */}
						<AuthenticationRoute path="/login" render={props => <Login {...props} />} />
						<Route path="/logout" component={Logout} />
						{/* Forgot password page */}
						<AuthenticationRoute
							path="/forgot-password"
							render={props => <ForgotPassword {...props} />}
						/>
						{/* User confirmation page */}
						<AuthenticationRoute
							path="/id/user/confirm"
							render={props => <PasswordConfirmation {...props} />}
						/>
						{/* User recovery password page */}
						<AuthenticationRoute
							path="/id/user/password-recovery"
							render={() => <PasswordConfirmation recovery />}
						/>

						{/* Callback page for external provider login */}
						<Route path="/provider/callback" component={ProviderCallback} />

						{/* Authorization page for external apps */}
						<PrivateRoute
							withLoginHintRedirect
							path="/oauth/authorize"
							render={props => <Authorize {...props} />}
						/>

						{/* Internal pages */}
						<PrivateRoute
							path={['/:service/:namespace/:method/:id', '/:service/:namespace/:method']}
							render={({ match }) => <PageLoader key={`${match.url}`} />}
						/>
						{/* Custom pages */}
						<PrivateRoute
							path="*"
							render={({ match }) => <PageCustomLoader url={match.url} key={`${match.url}`} />}
						/>
					</Switch>
				</>
			</WithScripts>
		</WithWebSocket>
	</ReactRouter>
);

export default Router;
