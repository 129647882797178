import { merge } from 'lodash';
import { isObject } from 'utils';
import { getCurrentHistory } from 'utils/location';
import { getQueryFiltersParameters } from 'utils/request';
import { handleFetchData } from './fetch';

const history = getCurrentHistory();

export const isEmptyValue = value => value === '' || value === null;

export const getCurrentValue = option =>
	isObject(option) && 'value' in option ? option.value : option;

export const getCurrentBrowse = (state, currentBrowse) => state.browses[currentBrowse];

export const mapFilters = filters => {
	const getValue = value => {
		if (Array.isArray(value)) return value.map(val => getValue(val));

		return value instanceof Object && 'value' in value ? value.value : value;
	};

	return Object.entries(filters).reduce(
		(acc, [filterName, value]) => ({
			...acc,
			[filterName]: getValue(value)
		}),
		{}
	);
};

export const makeFetchDataParams = (state, page) => {
	const { sortBy } = state;

	const sortingUrl = () => {
		if (sortBy && sortBy.length === 1)
			return {
				sortBy: sortBy[0].name || sortBy[0].value,
				sortDirection: sortBy[0].initialSortDirection
			};

		const sortingSelected = {
			sortBy: [],
			sortDirection: []
		};

		if (sortBy && sortBy.length) {
			sortBy.forEach(item => {
				sortingSelected.sortBy.push(item.value || item.name);
				sortingSelected.sortDirection.push(item.initialSortDirection);
			});
		}

		return sortingSelected;
	};

	const sortAttrs = sortingUrl() || {};

	const { endpointParameters, currentViewData } = state.schema;

	const currentFilters = {
		filters: mapFilters(state.appliedFilters),
		page,
		...sortAttrs
	};

	const filters = getQueryFiltersParameters(endpointParameters, currentViewData, true);

	return merge(currentFilters, filters);
};

/**
 * History listener for refresh filters and data
 */
export const historyListener = currentBrowse => dispatch => {
	const currentPath = window.location.pathname;
	let currentLocationSearch = null;

	const unlisten = history.listen((location, action) => {
		const { pathname, search } = location;

		//	If page is changed call unlisten() for remove listener created.
		if (action === 'PUSH' && pathname !== currentPath) {
			currentLocationSearch = null;
			return unlisten();
		}

		// If change history by arrows in browser call handleFetchData() for update filters applyed and data
		if (action === 'POP' && pathname === currentPath && currentLocationSearch !== search) {
			dispatch(handleFetchData(false)(currentBrowse));
		}

		currentLocationSearch = search;
	});
};
