import { arrayElementMove } from 'utils/array';
import { actions as toastActions } from 'modules/alerts';
import { callRequest } from 'utils/request';
import { translateHelperString as translateHelper } from 'utils/translate';
import { getCurrentBrowse } from './actions-utils';
import { fetchDataSuccess, setEmptyBrowse } from './fetch';
import { processAsyncFields } from './async-fields';

/**
 * Action for remove item in rows and update total
 * @param {string|number} id
 */
export const removeRow = id => currentBrowse => (dispatch, getState) => {
	const { rows, page, total } = getCurrentBrowse(getState(), currentBrowse);

	const rowsModified = rows.filter(row => row.id !== id);
	const totalModified = rows.length === rowsModified.length ? total : total - 1;

	dispatch(fetchDataSuccess({ rows: rowsModified, page, total: totalModified })(currentBrowse));
	if (!totalModified) dispatch(setEmptyBrowse()(currentBrowse));
};

/**
 * Action for adding a row without fetching again the data
 * @param {object} rowData
 */
export const addRow = data => currentBrowse => (dispatch, getState) => {
	const { rows, page, total } = getCurrentBrowse(getState(), currentBrowse);

	const updatedRows = [...rows, data];
	const updatedTotal = total + 1;

	dispatch(fetchDataSuccess({ rows: updatedRows, page, total: updatedTotal })(currentBrowse));
	dispatch(processAsyncFields()(currentBrowse));
	if (!total) dispatch(setEmptyBrowse(false)(currentBrowse));
};

export const sortItemRow = (from, to, id) => currentBrowse => async (dispatch, getState) => {
	const {
		rows,
		page,
		total,
		schema: { sortEndpoint, fieldSortEndpoint, currentViewData, customHeaders }
	} = getCurrentBrowse(getState(), currentBrowse);

	const sortRows = arrayElementMove(rows, from, to);

	const ids = sortRows && sortRows.map(element => element.id);

	const sortId =
		fieldSortEndpoint && currentViewData && currentViewData[fieldSortEndpoint]
			? currentViewData[fieldSortEndpoint]
			: id;

	if (sortRows) {
		try {
			dispatch(fetchDataSuccess({ page, total, rows: sortRows })(currentBrowse));

			await callRequest(sortEndpoint, [...ids], { id: sortId }, customHeaders);

			dispatch(
				toastActions.addAlert({
					message: translateHelper('common.message.changesSaved'),
					type: 'success',
					id: 'sort_success'
				})
			);
		} catch ({ message }) {
			dispatch(toastActions.addAlert({ message, type: 'error', id: 'error' }));
			dispatch(fetchDataSuccess({ page, total, rows })(currentBrowse));
		}
	}
};
