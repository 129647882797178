/* eslint-disable no-useless-escape */
import { DOMAIN } from 'config/endpoints';

const containsJS = URL => {
	const invalidProtocolRegex = /^([^\w]*)(javascript|java|script|data|vbscript)/im;
	return invalidProtocolRegex.test(URL);
};
const containExternalURL = redir => {
	const externalRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
	return externalRegex.test(redir);
};

// We need to add originalUrl because same whe need some space that was removed
const validateRelativeURL = (URL, originalURL) => {
	if (containsJS(URL) || URL.startsWith('//')) return '/';
	const redir = URL.split('redir=')[1];
	if (!redir) return originalURL;
	if (containExternalURL(redir) && !URL.includes(DOMAIN)) return '/';
	if (containsJS(redir)) return '/';
	return originalURL;
};

export const validateURL = URL => {
	if (!URL) return;
	const parseURL = decodeURIComponent(URL).replace(/\s+/g, '');

	if (parseURL.startsWith('/')) return validateRelativeURL(parseURL, URL);
	if (containsJS(parseURL)) return '/';
	if (!parseURL.startsWith('/') && !parseURL.includes(`${DOMAIN}/`)) return '/';

	return parseURL;
};
