import { createSelector } from 'reselect';
import { getMappedFieldValue } from 'utils/mappers';
import { componentWithTranslateWrapper } from 'utils/forms/forms';
import { translateValueComponent, makeTemplateValue } from 'utils/translateComponent';
import { get } from 'lodash';

export const getEditDependencies = state => state.saveEditDependencies;

export const getFieldValue = createSelector(
	({ fieldData, rowData }) => {
		const { mapper, component, name } = fieldData;

		const hasTranslateWrapper = componentWithTranslateWrapper[component];
		const value = get(rowData, name) || null;

		return mapper
			? getMappedFieldValue({
					fieldName: name,
					mapper,
					value,
					currentData: rowData,
					translateValueComponent,
					hasTranslateWrapper,
					makeTemplateValue
			  })
			: value;
	},
	currentValue => currentValue
);
