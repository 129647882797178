import styled from 'styled-components';
import { colors, font, timingFunctions } from 'theme';
import Link from 'components/Link';
import { mediaBreaks } from 'devices';

const { onlyDesktop, tabletLg: onlyMobile } = mediaBreaks;

export default {
	Wrapper: styled.div`
		display: flex;
		flex-direction: column;
		align-items: center;
		color: ${colors.black};
		height: 100vh;
		min-height: 650px;
		> div {
			z-index: 1;
		}
		${onlyMobile`
			width: 100%;
			max-width: 400px;
			margin: auto;
			${props => !props.isAuthScreen && 'height: 100vh;'}
		`}
	`,
	Content: styled.div`
		background: ${colors.white};
		min-height: 210px;
		display: flex;
		flex-direction: column;
		position: relative;
		${props => props.isAuthScreen && 'margin: 0px auto;'}
		${onlyDesktop`
			width: ${props => (props.isAuthScreen ? '480px' : '380px')};
			padding: ${props => (props.isAuthScreen ? '26px 34px' : '36px 42px')} 30px;
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
		`}
		${onlyMobile`
			width: 100%;
			padding: 0px 15px;
			${props => !props.isAuthScreen && 'height: 100%;'}
		`}
	`,
	SpinnerWrapper: styled.div`
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(255, 255, 255, 0.9);
		z-index: 1;
	`,
	LogoWrapper: styled.div`
		flex-shrink: 0;
		${onlyDesktop`
			margin: 45px auto 30px;
			img {
				height: 46px;
			}
		`}
		${onlyMobile`
			margin: 28px auto ${props => (props.isAuthScreen ? '24px' : '17px')};
			img {
				height: 33px;
			}
		`}
	`,
	BackgroundWrapper: styled.div`
		display: flex;
		justify-content: space-between;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 0px 32px 70px;
		background: ${colors.white};
		height: 100vh;
		img {
			margin-top: auto;
			width: 32%;
			min-width: 375px;
			max-width: 563px;
		}
	`,
	Footer: styled.div`
		color: ${colors.darkGrey};
		font-size: ${font.baseSmall};
		display: flex;
		align-items: center;
		${onlyDesktop`
			margin: 29px auto;
		`}
		${onlyMobile`
			margin: 20px auto 17px;
		`}
	`,
	LanguageSelector: styled.div`
		> div {
			border: none;
		}
	`,
	Link: styled(Link)`
		color: ${colors.blackHover};
		font-size: ${font.baseSmall};
		white-space: nowrap;
		transition: color 0.2s ${timingFunctions.standard};
		line-height: 33px;
		${onlyDesktop`
			margin-left: 38px;
		`}
		&:after {
			content: '';
			display: block;
			width: 1px;
			height: 14px;
			background: ${colors.grey};
			${onlyDesktop`
				margin: 0px 24px 0px 38px;
			`}
			${onlyMobile`
				margin: 0px 4px 0px 18px;
			`}
		}
		&:hover,
		&:active {
			color: ${colors.black};
		}
	`
};
