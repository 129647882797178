import { isObject, formatMapperTemplate } from 'utils';
import { makeTemplateValue } from 'utils/translateComponent';
import { formatDatagroup } from 'utils/forms/forms';

export const parseOptions = options => {
	if (options.length > 0 && (typeof options[0] === 'string' || typeof options[0] === 'number'))
		return options.map(option => ({ label: option, value: option, rawLabel: option }));
	return formatDatagroup(options);
};

export const getSelectLabel = (valueData, labelMapper) => {
	const isDataArray = Array.isArray(valueData);
	const currentData = isDataArray ? valueData[0] : valueData;

	if (isObject(labelMapper) && isObject(currentData))
		return formatMapperTemplate(labelMapper, currentData, makeTemplateValue);

	return currentData[labelMapper];
};

export const getMultiSelectLabel = (valueItem, option, labelMapper, valueMapper) => {
	if (isObject(labelMapper) && isObject(option))
		return formatMapperTemplate(labelMapper, option, makeTemplateValue);

	return option ? option[labelMapper] || option[valueMapper].toString() : valueItem.toString();
};

/**
 * make default initial values endpoint
 * @param {object} endpoint
 * @param {string} type
 * @returns {Object}
 */
export const makeDefaultEndpoint = (endpoint, type) => {
	const { service, namespace } = endpoint;

	return {
		service,
		namespace,
		method: type === 'Multiselect' ? 'list' : 'get'
	};
};

/**
 * make default valuesMapper properties
 * @param {object} options
 * @returns {object{label, value}}
 */
export const getValuesMapper = (options = {}) => {
	const { valuesMapper = { label: 'name', value: 'id' } } = options;

	return valuesMapper;
};

/** Returns the componentsAttributes object inside an object with their attributes or adding default attributes
 * @param {object} componentAttributes - The componentAttributes object
 */
export const mappedDataForStatusSelector = componentAttributes => {
	const { values: rawValues = ['active', 'inactive'] } = componentAttributes;

	const values = rawValues.map(value => ({ value, label: value }));

	const selectProperties = {
		componentAttributes: {
			translateLabels: true,
			labelPrefix: 'common.status.',
			options: {
				scope: 'local',
				values
			},
			...componentAttributes
		}
	};

	return selectProperties;
};

export default {};
