import React from 'react';
import PropTypes from 'prop-types';
import styled from './styles';

const Title = ({ children, subtitle }) => (
	<styled.TitleContainer>
		<styled.Title>{children}</styled.Title>
		<styled.SubTitle>{subtitle}</styled.SubTitle>
	</styled.TitleContainer>
);

Title.propTypes = {
	/** The Title content */
	children: PropTypes.node,
	/** The Subtitle to show */
	subtitle: PropTypes.string
};

export default Title;
