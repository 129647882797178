import React from 'react';
import PropTypes from 'prop-types';
import UserImage from 'components/UserImage';
import Skeleton from 'react-loading-skeleton';
import { translate } from 'utils/mappers';
import styled from './styles';

const Chip = ({
	afterUserNameRender,
	email,
	firstname = '',
	image,
	lastname = '',
	isLoading,
	showFull,
	status,
	variant
}) => {
	const showAsInactive = !showFull && status === 'inactive';
	const inactiveFlag =
		showFull && status === 'inactive' && `(${translate('common.status.inactive')})`;
	return (
		<styled.UserChip variant={variant} showFull={showFull} inactive={showAsInactive}>
			<styled.InnerWrapper>
				<styled.UserImageWrapper showFull={showFull} inactive={showAsInactive}>
					{isLoading ? (
						<Skeleton circle width={showFull ? 35 : 23} height={showFull ? 35 : 23} />
					) : (
						<UserImage
							firstname={firstname}
							lastname={lastname}
							url={image}
							size={showFull ? 'large' : 'small'}
						/>
					)}
				</styled.UserImageWrapper>

				{isLoading ? (
					<styled.UserDataWrapper isLoading>
						<Skeleton width={50} height={13} />
						<Skeleton width={90} height={10} />
					</styled.UserDataWrapper>
				) : (
					<styled.UserDataWrapper showFull={showFull}>
						<styled.UserName inactive={showAsInactive}>
							{firstname} {lastname} {inactiveFlag}
						</styled.UserName>
						<styled.UserNameAfterRender inactive={showAsInactive}>
							{afterUserNameRender || email}
						</styled.UserNameAfterRender>
					</styled.UserDataWrapper>
				)}
			</styled.InnerWrapper>
		</styled.UserChip>
	);
};

Chip.propTypes = {
	afterUserNameRender: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	email: PropTypes.string,
	firstname: PropTypes.string,
	image: PropTypes.string,
	lastname: PropTypes.string,
	isLoading: PropTypes.bool,
	showFull: PropTypes.bool,
	status: PropTypes.oneOf(['active', 'inactive']),
	variant: PropTypes.string
};

Chip.defaultProps = {
	afterUserNameRender: ''
};

export default Chip;
