import cleanDeep from 'clean-deep';
import { clearAsyncError } from 'redux-form';
import { get, setWith, unset, isEmpty } from 'lodash';
import { normalizeAsyncFields } from 'utils/forms/forms';
import * as functions from 'utils/redux-form-validation';
import { getFormSectionMinMaxValues } from 'modules/formSection/selectors';
import { getObjectKeyByIndex } from 'utils';
import { isFieldComponentVisible } from './utils';

/**
 * Make custom fields for validate
 * @param {array} fields
 * @param {object} values
 * @returns {array}
 */
export const getValidationFields = (fields, values, lastName = '') =>
	fields.reduce(
		(accum, field) => {
			const { name, component, componentAttributes } = field;

			if (component === 'FieldsArray') {
				const rawValue = get(values, `${lastName && `${lastName}.`}${name}`) || [];

				const value = Array.isArray(rawValue) ? rawValue : [rawValue];

				const { uniqueField, fields: innerFields, additionalFields = {} } = componentAttributes;
				const generatedField = value.reduce((accumulator, val, idx) => {
					const namePrefix = `${name}.${idx}`;

					if (uniqueField) return [...accumulator, { ...innerFields[0], name: namePrefix }];

					const additionalFieldsPrefix = additionalFields[`${name}_${idx}`] || [];

					const innerFieldInternal = getValidationFields(innerFields, values, namePrefix);
					const currentFields = [...innerFields, ...additionalFieldsPrefix, ...innerFieldInternal];

					const innerFieldsModified = currentFields.map(innerField => ({
						...innerField,
						name: `${lastName ? `${lastName}.` : ''}${
							innerField.name.includes(namePrefix) ? '' : `${namePrefix}.`
						}${innerField.name}`
					}));
					return [...accumulator, ...innerFieldsModified];
				}, []);

				return [...accum, ...generatedField];
			}

			return accum;
		},
		lastName ? [] : fields
	);

/**
 * Get current validation function
 * @param {object} validation
 * @returns {function}
 */
const getValidationFunction = validation => {
	const { name, options } = validation;

	if (!functions[name]) return;

	const params = options ? Object.keys(options).map(key => options[key]) : [];

	return params.length ? functions[name](...params) : functions[name];
};

const processValidations = (
	validations,
	allValues,
	fieldName,
	previousErrors,
	isFieldsArray,
	t
) => {
	const value = get(allValues, fieldName);

	return new Promise(resolve => {
		if (!validations.length) {
			if (isEmpty(previousErrors)) resolve({});

			throw previousErrors;
		}

		const validationResults = validations.map(dataValidation => {
			// Mandatory conditions (and)
			if (dataValidation.length === 1) {
				const validate = getValidationFunction(dataValidation[0]);
				const validationResult = validate(value, allValues);

				return validationResult ? t(validationResult) : validationResult;
			}

			// Optional conditions (or) within a mandatory one
			const orValidation = () => {
				const optionalValidations = dataValidation.map(validationValue =>
					getValidationFunction(validationValue)(value, allValues)
				);

				if (optionalValidations.some(result => result === undefined)) return undefined;

				return optionalValidations
					.map(e => (typeof e === 'string' ? t(e) : e))
					.join(` ${t('views.validation.or').toLowerCase()} `);
			};

			return orValidation();
		});

		// If the current field passed all its validations
		if (validationResults.every(result => result === undefined)) {
			// If other fields have errors carry them on and update the current field status.
			if (!isEmpty(previousErrors || {})) {
				const currentErrors = { ...previousErrors };

				unset(currentErrors, fieldName);

				const errors = cleanDeep(currentErrors);

				if (isEmpty(errors)) return resolve({});

				throw errors;
			} else {
				resolve({});
			}
		} else {
			// And if this field has errors, append them to the rest of the form's errors.
			const error = validationResults.find(result => !!result);
			if (error) {
				const newErrors = { ...previousErrors };

				const fieldError = isFieldsArray ? { _error: error } : error;
				setWith(newErrors, fieldName, fieldError, Object);

				throw newErrors;
			}
		}
	});
};

const getValidationRuleInput = (field, state, props) => {
	if (field?.component !== 'Input') return null;

	const { componentAttributes } = field || {};
	const { minValue, maxValue, minValueField, maxValueField, type } = componentAttributes || {};

	if (type && type !== 'number') return null;

	let dynamicMinMaxValue = null;

	if (minValueField || maxValueField)
		dynamicMinMaxValue = getFormSectionMinMaxValues(field)(state, props);

	const { min: minDynamic, max: maxDynamic } = dynamicMinMaxValue?.[field.name] || {};
	const min = minDynamic || !Number.isNaN(Number(minDynamic)) ? minDynamic : minValue;
	const max = maxDynamic || !Number.isNaN(Number(maxDynamic)) ? maxDynamic : maxValue;
	const validationRules = [];

	if (min || !Number.isNaN(Number(min)))
		validationRules.push([{ name: 'minValue', options: { min } }]);
	if (max || !Number.isNaN(Number(max)))
		validationRules.push([{ name: 'maxValue', options: { max } }]);

	return validationRules;
};

export const asyncValidate = (
	values,
	dispatch,
	form,
	field,
	clearErrors,
	fieldsComputed = null,
	mainFieldsComputed = null
) => {
	const { asyncErrors, fieldsGroup, registeredFields, formSection: formName, t } = form;

	let errors = { ...asyncErrors };

	let fieldsComponents;
	let state;

	const customAction = () => (d, getState) => {
		state = getState();
		const { formSections } = state;
		({ fieldsComponents } = formSections[formName] || {});
	};

	dispatch(customAction());

	// Finds each blurred field's validation rules matching the field name
	// with the field validation data held within the schema's fieldsGroup.
	const mainFields =
		mainFieldsComputed ||
		fieldsGroup.reduce((accum, group) => {
			const normalizedFields = normalizeAsyncFields(group.fields);
			return [...accum, ...normalizedFields];
		}, []);

	const fields = fieldsComputed || getValidationFields(mainFields, values);

	const getComponentInfo = (componentInfo, component, id) => {
		const componentData = get(componentInfo, component);
		return componentData && componentData.visible && componentData.id === id;
	};

	const findArrayItem = itemForFind => {
		const inputData = fields
			.filter(item => item.component === 'FieldsArray' && item.componentAttributes?.fields?.length)
			.flatMap(item => item.componentAttributes.fields)
			.find(
				data =>
					itemForFind.includes(data.name) &&
					data.component === getObjectKeyByIndex(fieldsComponents[itemForFind]) &&
					isFieldComponentVisible(fieldsComponents[itemForFind]) &&
					fieldsComponents[itemForFind][data.component].id === data.id
			);

		return inputData || null;
	};

	const findItem = itemForFind =>
		fields.find(
			({ name, component, id }) =>
				name === itemForFind &&
				getComponentInfo(fieldsComponents[itemForFind], component, id, itemForFind) &&
				registeredFields[itemForFind] &&
				isFieldComponentVisible(fieldsComponents[itemForFind]) &&
				fieldsComponents[itemForFind][component].id === id
		);

	const arrayFields = findArrayItem(field) || undefined;
	const onlyInput = registeredFields[field] && findItem(field);

	const itemField = arrayFields || onlyInput;

	const validationRules = itemField && itemField.validations ? itemField.validations : [];

	const componentAttributesRule = getValidationRuleInput(itemField, state, {
		formSection: formName
	});
	const validationRulesWithComponentAttributesRule = componentAttributesRule
		? [...validationRules, ...componentAttributesRule]
		: validationRules;

	const isFieldsArray = itemField && itemField.component === 'FieldsArray';

	const hasInternalValidations = ({ component, componentAttributes = {} }) => {
		const { fields: currentFields, additionalFields = {} } = componentAttributes;

		if (component !== 'FieldsArray') return false;

		const hasAdditionalValidations = Object.keys(additionalFields).some(key =>
			additionalFields[key].some(innerField => !!innerField.validations)
		);

		const hasFieldsValidations = currentFields.some(innerField => !!innerField.validations);

		return hasFieldsValidations || hasAdditionalValidations;
	};

	if (errors) {
		let existErrorsValid = false;

		Object.keys(registeredFields).forEach(keyField => {
			if (!existErrorsValid && get(errors, keyField)) {
				const levels = keyField.split('.');
				const hasDotNotation = levels.length > 1;
				let fieldRegistered = hasDotNotation ? findItem(levels[0]) : findItem(keyField);
				const parentIsFieldsArray = fieldRegistered?.component === 'FieldsArray';
				fieldRegistered = parentIsFieldsArray
					? findArrayItem(keyField)
					: (fieldRegistered = findItem(keyField));

				const { componentAttributes } = fieldRegistered || {};
				const { minValue, maxValue, minValueField, maxValueField } = componentAttributes || {};

				if (fieldRegistered && hasInternalValidations(fieldRegistered)) return;

				if (fieldRegistered && fieldRegistered.validations && fieldRegistered.validations.length) {
					existErrorsValid = true;
				} else if (fieldRegistered && (minValue || maxValue || minValueField || maxValueField)) {
					existErrorsValid = true;
				} else {
					dispatch(clearAsyncError(formName, keyField));
					unset(errors, keyField);
				}
			}
		});

		if (!existErrorsValid) errors = {};
	}
	if (clearErrors && errors) {
		Object.keys(errors).map(key => dispatch(clearAsyncError(formName, key)));
		errors = {};
	}

	return processValidations(
		validationRulesWithComponentAttributesRule,
		values,
		field,
		errors,
		isFieldsArray,
		t
	);
};
