import { get } from 'lodash';
import { normalizeAsyncFields } from 'utils/forms/forms';
import { processConditionals } from 'utils/conditions';
import { getAdditionalFormSectionsValues } from 'modules/formSection/selectors';

/**
 * Add formatted internal fields from FieldsArray to fields array
 * @param {array} fields
 * @param {object} data
 * @returns {array}
 */
const getInternalFields = (fields, data) =>
	fields.reduce((accum, field) => {
		const { component } = field;

		const getFields = itemField => {
			const { componentAttributes, name } = itemField;

			const rawWalue = get(data, name) || [];
			const value = Array.isArray(rawWalue) ? rawWalue : [rawWalue];

			const { uniqueField, fields: innerFields, additionalFields = {} } = componentAttributes;

			return value.reduce((accumulator, val, idx) => {
				const namePrefix = `${name}.${idx}`;

				if (uniqueField) return [...accumulator, { ...innerFields[0], name: namePrefix }];

				const additionalFieldsPrefix = additionalFields[`${name}_${idx}`] || [];

				const internalFields = [...innerFields, ...additionalFieldsPrefix];

				const internalFieldsModified = internalFields.map(innerField => ({
					...innerField,
					prefix: namePrefix,
					name: `${namePrefix}.${innerField.name}`
				}));

				const deepInternalFields = getInternalFields(internalFieldsModified, data);

				return [...accumulator, ...deepInternalFields];
			}, []);
		};

		if (component === 'FieldsArray') {
			const internalFields = getFields(field);

			return [...accum, field, ...internalFields];
		}

		return [...accum, field];
	}, []);

/**
 * Get and process conditions from fields
 * @param {object} state
 * @param {object} props
 * @returns {object}
 */
const getConditions = (state, props) => {
	const { fieldsGroup, form: formName, schema = {} } = props;

	const data = getAdditionalFormSectionsValues(state, formName);

	if (!data.parentData)
		data.parentData = schema.currentViewData?.relatedData || schema.currentViewData;

	const fields = fieldsGroup.reduce((accum, fieldGroup) => {
		const initialFields = normalizeAsyncFields(fieldGroup.fields, true);
		return [...accum, ...getInternalFields(initialFields, data)];
	}, []);

	const findConditions = item => item.conditions;

	const groupsWithConditions = fieldsGroup.filter(findConditions);
	const fieldsWithConditions = fields.filter(findConditions);

	if (!fieldsWithConditions.length && !groupsWithConditions.length) return {};

	const makeShowAndWhenConditionals = (accum, item) => {
		const { conditions, name, prefix, id } = item;
		let show = true;
		let enable = true;

		const { showWhen, enableWhen } = conditions;

		if (showWhen)
			show = processConditionals({ andConditionals: showWhen, data, prefix, matchFieldName: name });
		if (enableWhen)
			enable = processConditionals({
				andConditionals: enableWhen,
				data,
				prefix,
				matchFieldName: name
			});

		const key = `${name}${id ? `_${id}` : ''}`;

		return { ...accum, [key]: { show, enable } };
	};

	const fieldsConditionals = fieldsWithConditions.reduce(makeShowAndWhenConditionals, {});

	const groupConditionals = groupsWithConditions.reduce(makeShowAndWhenConditionals, {});

	return { conditionals: fieldsConditionals, groupConditionals };
};

export default getConditions;
