import * as types from './types';

export const initialError = { hasError: false, errorData: {} };

const initialState = {
	schema: {},
	mainInfo: {
		isLoading: false,
		errorStatus: initialError,
		data: [],
		selectedWarehouse: {},
		selectedRows: [],
		allRowsSelected: false,
		isCreateRouteStep: false,
		dataCarrier: {},
		page: 1,
		userGoBack: false,
		hasMoreForPage: false
	},
	warehouseInfo: {
		isLoading: false,
		errorStatus: initialError,
		data: []
	}
};

/**
 *
 * @param {object} currentProperty fields to change into state with the value
 * @param {string} section section state name (warehouseInfo || mainInfo || schema)
 * @param {object} state current state
 * @returns {object} state
 */
const mergeState = (state, action, properties) => ({
	...state,
	[action.section]: {
		...state[action.section],
		...properties
	}
});

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case types.CREATE_PAGE:
			return { ...state, schema: action.schema };

		case types.FETCH_MAIN_DATA_SUCCESS:
			return mergeState(
				state,
				{ section: 'mainInfo' },
				{
					data:
						action.data.hasFilter || !action.data.applyFiltersToggle
							? action.data.responseData
							: [...state.mainInfo.data, ...action.data.responseData],
					hasMoreForPage: !(action.data.responseData.length < 60),
					isLoading: false,
					errorStatus: initialError
				}
			);

		case types.CLEAN_LIST_DATA:
			return mergeState(
				state,
				{ section: 'mainInfo' },
				{
					data: initialState.data,
					hasMoreForPage: initialState.hasMoreForPage,
					isLoading: false
				}
			);

		case types.FETCH_WAREHOUSE_DATA_SUCCESS:
			return mergeState(
				state,
				{ section: 'warehouseInfo' },
				{ data: action.data, isLoading: false, errorStatus: initialError }
			);

		case types.SET_LOADING:
			return mergeState(state, action, { errorStatus: initialError, isLoading: action.isLoading });

		case types.SET_ERROR:
			return mergeState(state, action, {
				isLoading: false,
				errorStatus: { hasError: true, errorData: action.status }
			});

		case types.SELECT_WAREHOUSE:
			return mergeState(
				state,
				{ section: 'mainInfo' },
				{
					selectedWarehouse: action.warehouse
				}
			);
		case types.CHANGE_SELECTED_ROWS:
			return mergeState(
				state,
				{ section: 'mainInfo' },
				{
					selectedRows: action.selectedRows,
					allRowsSelected: action.allRowsSelected
				}
			);
		case types.CHANGE_STEP:
			return mergeState(
				state,
				{ section: 'mainInfo' },
				{ isCreateRouteStep: action.isCreateRouteStep }
			);

		case types.SEND_DATA_CARRIER:
			return mergeState(state, { section: 'mainInfo' }, { dataCarrier: action.dataCarrier });

		case types.NEXT_PAGE:
			return mergeState(state, { section: 'mainInfo' }, { page: state.mainInfo.page + 1 });

		case types.GO_BACK:
			return mergeState(
				state,
				{ section: 'mainInfo' },
				{
					userGoBack: action.payload
				}
			);

		case types.CLEAN_STATE:
			return mergeState(
				state,
				{ section: 'mainInfo' },
				{
					isLoading: false,
					errorStatus: initialError,
					data: [],
					selectedWarehouse: {},
					selectedRows: [],
					allRowsSelected: false,
					isCreateRouteStep: false,
					dataCarrier: {},
					page: 1,
					userGoBack: false,
					hasMoreForPage: false
				}
			);
		default:
			return state;
	}
}
