export const CREATE_PAGE = 'createRoute/CREATE_PAGE';
export const FETCH_MAIN_DATA_SUCCESS = 'createRoute/FETCH_MAIN_DATA_SUCCESS';
export const CLEAN_LIST_DATA = 'createRoute/CLEAN_LIST_DATA';
export const FETCH_WAREHOUSE_DATA_SUCCESS = 'createRoute/FETCH_WAREHOUSE_DATA_SUCCESS';
export const SET_LOADING = 'createRoute/SET_LOADING';
export const SET_ERROR = 'createRoute/SET_ERROR';
export const SELECT_WAREHOUSE = 'createRoute/SELECT_WAREHOUSE';
export const CHANGE_SELECTED_ROWS = 'createRoute/CHANGE_SELECTED_ROWS';
export const CHANGE_STEP = 'createRoute/CHANGE_STEP';
export const SEND_DATA_CARRIER = 'createRoute/SEND_DATA_CARRIER ';
export const NEXT_PAGE = 'createRoute/NEXT_PAGE';
export const GO_BACK = 'createRoute/GO_BACK';
export const CLEAN_STATE = 'createRoute/CLEAN_STATE';
