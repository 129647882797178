import styled from 'styled-components';
import { mediaBreaks } from 'devices';
import { colors, mixins } from 'theme';
import { ModuleContainerStyles, Text, closedNavbarWidth } from '../styles';

const { onlyDesktop, tabletLg: onlyMobile } = mediaBreaks;

export const ProfileModule = {
	IconContainer: styled.div`
		${onlyDesktop`
			width: ${closedNavbarWidth}px;
			${mixins.flexCenter}
		`}
	`,
	ProfileText: styled(Text)`
		font-weight: 700;
		font-size: 13px;
	`,
	ProfileContainer: styled.div`
		${ModuleContainerStyles}
		height: 44px;
		${onlyDesktop`
			height: 44px;
			cursor: pointer;
		`}
		${onlyMobile`
			${({ isActive }) => isActive && `border-bottom: 2px solid ${colors.blue}`}`}
	`,
	ProfileLoader: styled.div`
		display: flex;
		align-items: center;
		height: 36px;
		${onlyDesktop`
			width: 220px;
		`}
	`
};
