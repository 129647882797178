import { currentEnvironment } from 'config/environment';
import { isObject } from 'utils';

const ATTRIBUTE_SCRIPT = 'script-id';

/**
 * The function `getElementByAttribute` returns an array of elements that have a specific attribute
 * value.
 * @param {string} id
 * @returns array of elements
 */
const getElementByAttribute = id =>
	Array.from(document.querySelectorAll(`[${ATTRIBUTE_SCRIPT}="${id}"]`));

export const deleteElement = id => {
	if (!id) return null;
	const elements = getElementByAttribute(id);

	if (!elements.length) return null;
	return elements.forEach(element => element.parentNode.removeChild(element));
};
/**
 * The function "createElement" creates a script element with the given source and sets it to load
 * asynchronously.
 * @param {string} script - script to add in the element
 * @param {string} source - source to add in the element
 * @param {string} id - id to add in the element
 * @returns an HTML script element.
 */
export const createElement = (script, source, id) => {
	const element = document.createElement('script');

	const scriptElements = getElementByAttribute(id);
	if (scriptElements.length) scriptElements.forEach(() => deleteElement(id));

	element.async = 1;

	if (script) element.text = script;
	if (source) element.src = source;
	if (id) element.setAttribute(ATTRIBUTE_SCRIPT, id);

	return element;
};

/**
 * The function appends an element to the head of the document.
 * @returns If the `element` parameter is falsy (e.g. `null`, `undefined`, `false`, empty string), then
 * `null` is being returned. Otherwise, nothing is being returned explicitly, so `undefined` is being
 * returned by default.
 */
export const appendElement = element => {
	if (!element) return null;
	return document.head.appendChild(element);
};

/**
 * The function checks if a script with a specific id exists in the document.
 */
export const checkIfScriptExistById = id => {
	const nodes = getElementByAttribute(id);
	return !!nodes.length;
};

/**
 * The function checks if a specific JavaScript source file exists in the current HTML document.
 * @returns The function `checkIfScriptExist` returns a boolean value. It returns `true` if a script
 * with the specified `source` exists in the `scriptsArray`, and `false` otherwise.
 */
export const checkIfScriptExist = (id, src, script) => {
	try {
		const scriptElement = document.getElementById(id);
		if (!scriptElement) return false;

		const scriptLoaded = JSON.stringify({ id, src, innerHTML: script });
		const scriptToLoad = JSON.stringify(scriptElement, ['id', 'src', 'innerHTML']);

		if (scriptElement && scriptLoaded !== scriptToLoad) return false;

		return scriptLoaded === scriptToLoad;
	} catch (reason) {
		return false;
	}
};

export const deleteScript = id => {
	if (!id) return null;
	return deleteElement(id);
};

/**
 * The function `getUrlToMatch` returns a URL based on the current location parts.
 * @returns The function `getUrlToMatch` returns a string. If the `service` is equal to 'home', it
 * returns '/'. Otherwise, it returns a string in the format `//`.
 */

export const hasToLoadScript = (currentLocation, environments, paths) => {
	// possibility to not define paths and apply to the entire site
	if (!paths) return true;
	if (!isObject(paths) || !Object.keys(paths).length) return false;

	if (!environments || !Array.isArray(environments)) return false;

	const { include, exclude } = paths;

	const includePathsToString = Array.isArray(include)
		? include?.filter(path => typeof path === 'string')
		: [];

	const excludePathsToString = Array.isArray(exclude)
		? exclude?.filter(path => typeof path === 'string')
		: [];

	const environmentsToStrings = environments.filter(env => typeof env === 'string');

	if (!environmentsToStrings.includes(currentEnvironment)) return false;

	if (excludePathsToString.includes(currentLocation)) return false;

	if (includePathsToString.includes(currentLocation) || !includePathsToString.length) return true;

	return false;
};
