import { newId } from 'utils';
import * as types from './types';

const initialState = {
	toastAlerts: []
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.ALERTS_ADD:
			return {
				...state,
				toastAlerts: [...state.toastAlerts, { ...action.data, id: newId('alert') }]
			};
		case types.ALERTS_REMOVE:
			return { ...state, toastAlerts: state.toastAlerts.filter(t => t.id !== action.id) };
		default:
			return state;
	}
}
