import Text from 'components/Text';
import React from 'react';
import translationHOC from 'hocs/translationHOC';
import PropTypes from 'prop-types';
import styled from './styles';

const TagNew = ({ isCategory = false, t }) => (
	<styled.Chip variant="" backgroundColor="redPressed" isCategory={isCategory}>
		<Text color="white" fontWeight="bold">
			{t('common.status.new')}
		</Text>
	</styled.Chip>
);

TagNew.propTypes = {
	isCategory: PropTypes.bool
};

export default translationHOC(TagNew);
