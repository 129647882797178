import styled from 'styled-components';
import Icon from 'components/Icon';
import { colors, font } from 'theme';

export default {
	LinkWrapper: styled.div`
		display: flex;
		color: ${colors.blue};
		align-items: center;
		font-size: ${font.base};
	`,
	StyledIcon: styled(Icon)`
		margin-right: 8px;
	`
};
