import { stringify } from 'qs';
import ms from 'utils/api/ms';
import {
	get as lodashGet,
	set as lodashSet,
	isEqual,
	isEmpty,
	merge,
	findLastIndex,
	get
} from 'lodash';
import { parseValue, isObject, formatMapperTemplate, getFieldsQueryFromMapper } from 'utils';
import { makeLabelValue, makeTemplateValue } from 'utils/translateComponent';
import { getPathFiltersParameters, getQueryFiltersParameters } from '../request';

// Default component forms values
export const defaultComponentsValuesMapper = {
	Checkbox: false,
	CheckList: [],
	ColorPicker: '',
	FieldsArray: [],
	Input: '',
	Map: null,
	Multiselect: [],
	ObjectCreator: {},
	Select: null,
	SelectMultilevel: Object.defineProperty([], 'last', { value: undefined }),
	Switch: false,
	Textarea: ''
};

export const formatDatagroup = data => {
	if (!data || !data.length || !Array.isArray(data)) return data;

	const isGroupType = data.filter(option => option.groupName);

	if (!isGroupType.length) return data;

	const groupedData = data.reduce((acc, item) => {
		const { groupName } = item;

		const typeGroup = groupName || 'noGroup';

		const verifyTypeGroup = itemTypeGroup => itemTypeGroup.groupType === typeGroup;

		if (!typeGroup) return acc;

		const someGroupedData = acc.length && acc.some(element => verifyTypeGroup(element));

		if (!someGroupedData) {
			acc.push({
				groupType: typeGroup,
				options: [{ ...item }]
			});
		} else {
			acc.forEach(element => {
				if (verifyTypeGroup(element)) {
					element.options.push({ ...item });
				}
			});
		}

		return acc;
	}, []);
	return groupedData;
};

export const normalizeAsyncFields = (fields, includeAsyncWrapper = false) =>
	fields.reduce((accum, field) => {
		const { componentAttributes = {}, component } = field;

		if (component === 'AsyncWrapper') {
			const { field: innerField } = componentAttributes;

			/* In some cases (ex. conditionals) the wrapper should be included in the array
			to verify the wrapper's display conditions */
			return includeAsyncWrapper ? [...accum, field, innerField] : [...accum, innerField];
		}

		return [...accum, field];
	}, []);

export const getAvailableFields = (fieldsGroup, fieldsComponents) => {
	const getComponentInfo = (componentInfo, component, id) => {
		const componentData = lodashGet(componentInfo, component);

		const isVisible = componentData && componentData.visible;

		if (id) return isVisible && componentData.id === id;

		return isVisible;
	};

	const fields = fieldsGroup.reduce((accum, group) => {
		const normalizedFields = normalizeAsyncFields(group.fields);

		return [...accum, ...normalizedFields];
	}, []);

	return fields.reduce((accum, field) => {
		const accumulator = { ...accum };

		const { name, component, id } = field;

		if (getComponentInfo(fieldsComponents[name], component, id)) accumulator[name] = field;

		return accumulator;
	}, {});
};

/**
 * valid if every field is readOnly
 * @param {object} fieldData
 */
export const isReadOnlyField = fieldData => {
	const { componentAttributes = {}, component } = fieldData;

	const readOnlyComponents = {
		Text: true,
		Link: true,
		Chip: true,
		map: true,
		MediumChip: true,
		UserImage: true,
		Image: true,
		Location: true,
		StatusChip: true,
		SmallChip: true,
		UserChip: true
	};

	if (readOnlyComponents[component]) return true;

	if (component === 'FieldsArray') {
		const { componentAttributes: componentAttributesFieldsArray } = fieldData;
		const { fields } = componentAttributesFieldsArray;

		const isAllReadOnly = fields.every(field => readOnlyComponents[field.component]);
		return isAllReadOnly;
	}

	if (component === 'Code') {
		const { canEdit = false } = componentAttributes;
		if (!canEdit) return true;
	}

	return false;
};

// Filter only values specificated in schema
const getValidFieldsForSend = (values, fields, registeredFields) =>
	Object.keys(fields).reduce((accum, fieldName) => {
		const fieldData = fields[fieldName];

		const fullFieldName = fieldData?.fieldsArrayChildrenName || fieldName;

		if (registeredFields && !registeredFields[fullFieldName]) return accum;

		let fieldValue = lodashGet(values, fieldName);

		if (fieldValue === undefined && fieldData)
			fieldValue = defaultComponentsValuesMapper[fieldData.component];

		if (fieldData && !isReadOnlyField(fieldData)) {
			const { componentAttributes = {} } = fieldData;

			if (componentAttributes.type === 'number') {
				const currentValue = parseValue(fieldValue);
				if (!currentValue && currentValue !== 0) return accum;
			}

			lodashSet(accum, fieldName, fieldValue);

			return accum;
		}

		return accum;
	}, {});

/**
 * Format any value for send data
 * @param {any} value
 * @param {object} fieldData
 * @param {object} registeredFields
 */
export const formatValue = (value, fieldData, registeredFields) => {
	const { component, componentAttributes = {}, fieldsArrayChildrenName, fieldName } =
		fieldData || {};

	if (component === 'FieldsArray') {
		if (!value || isEqual(value, ['']) || isEqual(value, [{}])) return [];

		const { fields: innerFields, uniqueField, additionalFields = {} } = componentAttributes;

		if (uniqueField) {
			const [fieldUnique] = innerFields;

			const { componentAttributes: uniqueFieldComponentAttributes } = fieldUnique;

			if (uniqueFieldComponentAttributes.type === 'number') return value.map(parseValue);

			return [...value];
		}

		return value.map((itemData, idx) => {
			const currentKey = Object.keys(additionalFields).find(key => {
				const [, indx] = key.split('_');
				return parseInt(indx, 10) === idx;
			});

			const currentFields = currentKey
				? [...innerFields, ...additionalFields[currentKey]]
				: innerFields;

			const innerFieldsObject = currentFields.reduce((accum, item) => {
				const accumulator = { ...accum };
				accumulator[item.name] = {
					...item,
					fieldsArrayChildrenName: `${fieldsArrayChildrenName || fieldName}.${idx}.${item.name}`
				};
				return accumulator;
			}, {});

			const innerValues = currentFields.reduce((accum, itemField) => {
				const accumulator = { ...accum };

				const {
					component: innerComponent,
					componentAttributes: innerComponentAttributes,
					name
				} = itemField;

				const innerData = lodashGet(itemData, name);

				const innerFieldData = {
					fieldName: name,
					fieldsArrayChildrenName: `${fieldsArrayChildrenName || fieldName}.${idx}.${name}`,
					component: innerComponent,
					componentAttributes: innerComponentAttributes
				};

				lodashSet(accumulator, name, formatValue(innerData, innerFieldData, registeredFields));

				return accumulator;
			}, {});

			return getValidFieldsForSend(innerValues, innerFieldsObject, registeredFields);
		});
	}

	const isSelectVal = val => {
		if (typeof val !== 'object') return false;

		const isSelect = /select/gi.test(component);

		return isSelect && 'value' in val && 'label' in val;
	};

	if (value instanceof Object) {
		if (Array.isArray(value)) {
			const processedValue = [...value.map(item => (isSelectVal(item) ? item.value : item))];
			if (value.last) {
				processedValue.last = value.last;
			}
			return processedValue;
		}

		return isSelectVal(value) ? value.value : value;
	}

	return componentAttributes.type === 'number' ? parseValue(value) : value;
};

/**
 * Format the fields and values for send fields with dotNotationName.
 * @param {string} accumName - name to current field.
 * @param {object} accumValues -values to which the current value will be added
 * @param {object} schemaFields - all fields from schema.
 * @param {object} values - all data to edit.
 * @param {object} registeredFields
 */
const addValuesForDotNotationFields = ({
	accumName,
	accumValues,
	schemaFields,
	values,
	registeredFields
}) => {
	const addValuesWithDotNotationHelper = keyName => {
		const currentFieldAttributes = lodashGet(schemaFields, keyName);

		const currentFieldData = lodashGet(values, keyName);

		if (isObject(currentFieldData) && !currentFieldAttributes) {
			Object.keys(currentFieldData).forEach(fieldKey => {
				const currentName = `${keyName}.${fieldKey}`;
				addValuesWithDotNotationHelper(currentName);
			});
		} else {
			const { component, componentAttributes } = currentFieldAttributes;

			const fieldData = { fieldName: keyName, component, componentAttributes };

			lodashSet(accumValues, keyName, formatValue(currentFieldData, fieldData, registeredFields));
		}
	};

	addValuesWithDotNotationHelper(accumName);

	return accumValues;
};

/**
 * Format the form values according to the API requirements.
 * @param {object} values - The form values to process.
 */
export const formatValues = (values, fields, registeredFields) => {
	const valuesFiltered = getValidFieldsForSend(values, fields, registeredFields);

	return Object.keys(valuesFiltered).reduce((form, field) => {
		const data = valuesFiltered[field];

		if (isObject(data) && !fields[field]) {
			return addValuesForDotNotationFields({
				accumName: field,
				accumValues: form,
				schemaFields: fields,
				values: valuesFiltered,
				registeredFields
			});
		}

		const { component, componentAttributes } = fields[field] || {};

		const fieldData = { fieldName: field, component, componentAttributes };

		return {
			...form,
			[field]: formatValue(data, fieldData, registeredFields)
		};
	}, {});
};

// Get Current field in MainForm section
export const fieldsGroupFields = fieldsGroup => {
	const fields = {};

	fieldsGroup.forEach(group => {
		group.fields.forEach(field => {
			fields[field.name] = { ...field };
		});
	});

	return fields;
};

/**
 * Get options to populate an async Select component via endpoint.
 * @param {string} The Search input value
 * @param {object} Necessary data for fetching options (usually componentAttributes.options)
 */
export const loadAsyncOptions = async (
	showImage,
	responseProperty,
	inputValue,
	componentOptions,
	currentOptions = [],
	data
) => {
	const {
		fieldName,
		endpoint,
		endpointParameters = [],
		valuesMapper = {},
		searchParam: rawSearchParam,
		labelPrefix,
		translateLabels,
		page,
		groupField,
		targetField
	} = componentOptions;

	let pathFilters;
	let filters;

	if (endpointParameters.length) {
		pathFilters = getPathFiltersParameters(endpointParameters, data);
		filters = getQueryFiltersParameters(endpointParameters, data, true, false);
	}

	const fieldsQuery = getFieldsQueryFromMapper(valuesMapper);

	const { url, httpMethod } = endpoint;

	const { functionMapper } = currentOptions;

	const searchParam = rawSearchParam || 'filters[search]';

	const searchParamQuery = searchParam && inputValue ? `${searchParam}=${inputValue}` : '';

	const config = {
		endpointParameters: pathFilters,
		data: filters || {},
		headers: { 'x-janis-page': page, 'x-janis-totals': false },
		extendConfig: {
			paramsSerializer(parameters) {
				let params = stringify(parameters, { encode: true, arrayFormat: 'indices' });

				if (searchParamQuery) params += `${params ? '&' : ''}${searchParamQuery}`;
				if (fieldsQuery && !targetField) params += `${params ? '&' : ''}${fieldsQuery}`;

				return params;
			}
		}
	};

	let response = url
		? await ms[httpMethod]({ url, ...config })
		: await ms.get({
				...endpoint,
				...config
		  });

	if (functionMapper) {
		response = { ...response, data: await functionMapper(response.data) };
	}

	const { label = 'name', value = 'id' } = valuesMapper;

	const mappedLabel = option => {
		if (isObject(label)) return formatMapperTemplate(label, option, makeTemplateValue);

		if (typeof label === 'string')
			return lodashGet(option, label) || lodashGet(option, value) || option.name || option.id;
	};

	const getRawOptions = responseData => {
		if (!responseProperty) return responseData;

		const responseItem =
			Array.isArray(responseData) && responseData.length ? responseData[0] : responseData;

		return responseProperty && lodashGet(responseItem, responseProperty);
	};

	const options = getRawOptions(response.data).reduce((accum, rawOption) => {
		const option = isObject(rawOption) ? rawOption : { [label]: rawOption, [value]: rawOption };

		if (currentOptions.length && currentOptions.find(opt => opt.value === option[value]))
			return accum;

		const labelArgs = [fieldName, mappedLabel(option), translateLabels, labelPrefix];

		const optionLabel = makeLabelValue(...labelArgs);
		const rawLabel = makeLabelValue(...labelArgs, false);

		const formattedOption = {
			value: option[value],
			label: optionLabel,
			rawLabel,
			optionData: { ...option }
		};
		const groupType = lodashGet(option, groupField);

		if (groupField) formattedOption.groupName = groupType;

		if (showImage) {
			const { firstname, lastname, image } = option;

			formattedOption.userData = {
				firstname,
				lastname,
				url: image
			};
		}

		return [...accum, formattedOption];
	}, []);

	const hasMore = response.data.length === 60;
	const optionsFormated = formatDatagroup(options);

	return {
		options: optionsFormated,
		hasMore,
		additional: {
			page: page + 1
		}
	};
};

/**
 * Format and Map options to populate an Select component via sourceField.
 * @param {string} The Search input value
 * @param {object} Necessary data for mapping and format options
 */
export const formatDynamicOptions = (showImage, componentOptions, currentOptions = [], data) => {
	const {
		fieldName,
		valuesMapper = {},
		labelPrefix,
		translateLabels,
		groupField,
		source
	} = componentOptions;

	const { functionMapper } = currentOptions;

	const rawOptions = get(data, source);

	const mappedOptions = functionMapper ? rawOptions.map(functionMapper) : rawOptions;

	const { label = 'name', value = 'id' } = valuesMapper;

	const mappedLabel = option => {
		if (isObject(label)) return formatMapperTemplate(label, option, makeTemplateValue);

		if (typeof label === 'string')
			return lodashGet(option, label) || lodashGet(option, value) || option.name || option.id;
	};

	const formattedRawOptions = Array.isArray(mappedOptions) ? mappedOptions : [mappedOptions];

	const options = formattedRawOptions.reduce((accum, rawOption) => {
		const option = isObject(rawOption) ? rawOption : { [label]: rawOption, [value]: rawOption };

		if (currentOptions.length && currentOptions.find(opt => opt.value === option[value]))
			return accum;

		const labelArgs = [fieldName, mappedLabel(option), translateLabels, labelPrefix];

		const optionLabel = makeLabelValue(...labelArgs);
		const rawLabel = makeLabelValue(...labelArgs, false);

		const formattedOption = {
			value: option[value],
			label: optionLabel,
			rawLabel,
			optionData: { ...option }
		};
		const groupType = lodashGet(option, groupField);

		if (groupField) formattedOption.groupName = groupType;

		if (showImage) {
			const { firstname, lastname, image } = option;

			formattedOption.userData = {
				firstname,
				lastname,
				url: image
			};
		}

		return [...accum, formattedOption];
	}, []);
	const optionsFormated = formatDatagroup(options);

	return optionsFormated;
};

// Get and set remotes options for filter
export const setRemoteOptions = async (
	showImage,
	responseProperty,
	inputValue,
	attrOptions,
	currentOptions,
	data
) => {
	try {
		return await loadAsyncOptions(
			showImage,
			responseProperty,
			inputValue,
			attrOptions,
			currentOptions,
			data
		);
	} catch (e) {
		return { options: [] };
	}
};

/**
 * Gets the fieldData from the formSection by its name
 * @param {object} formSection
 * @param {string} fieldName
 */
export const getFieldData = (schema = {}, fieldName) => {
	const { fieldsGroup } = schema;

	const fields = fieldsGroupFields(fieldsGroup);

	return fields[fieldName] || {};
};

/**
 * format the value for fields which receive value boolean and input with number type
 * @param {object} field
 * @param {object} data
 */
export const formattedInitialValues = (field = {}, data = {}) => {
	const { name, component, componentAttributes = {} } = field;
	const { type } = componentAttributes;

	let value = lodashGet(data, name);

	if (/Checkbox|Switch/gi.test(component)) {
		if (typeof value === 'string') {
			const values = ['null', 'undefined', '0'];
			if (/false/i.test(value) || values.some(val => value === val)) value = false;
		}
		value = !!value;
		lodashSet(data, name, value);
	}

	if (component === 'Input' && type === 'number' && !Number.isNaN(parseInt(value, 10))) {
		value = parseInt(value, 10);
		lodashSet(data, name, value);
	}

	return data;
};

/**
 * Fn for insert and modifed fields
 * @param {Array} fieldsGroup - form fieldsGroup
 * @param {Array} fields - form fields
 * @param {Object} componentMapping - Component mapping for insert or modified fields
 * @param {String} parentField - trigger field name
 * @param {String} prefix - prefix FieldsArray group identifier
 * @returns {Array}
 */
export const addRemoteFields = (
	fieldsGroup = [],
	fields = [],
	componentMapping = {},
	parentField,
	prefix
) => {
	let initiated = false;

	const makeField = field => ({
		...field,
		triggerParent: parentField,
		componentAttributes: {
			...(field.componentAttributes || {})
		}
	});

	return fieldsGroup.map(group => {
		const currentGroup = { ...group };

		const newFields = componentMapping[group.name] || {};

		const fieldsToInsert = Object.keys(newFields).reduce((accum, fieldKey) => {
			if (fieldKey === 'root') return [...accum, ...newFields.root];

			return [...accum, newFields[fieldKey]];
		}, []);

		if (fieldsToInsert.length) {
			fields.forEach(field => {
				if (!fieldsToInsert.includes(field.name)) return;

				const existField = currentGroup.fields.find(({ name }) => name === field.name);

				const fieldFormatted = makeField(field);

				// Add remote components for specific FieldsArray group

				if (prefix) {
					const [fieldsArrayName] = prefix.split('_');

					const fieldIdx = currentGroup.fields.findIndex(({ name }) => name === fieldsArrayName);

					if (fieldIdx === -1) return;

					const fieldsArrayAttr = currentGroup.fields[fieldIdx].componentAttributes;

					if (!fieldsArrayAttr.additionalFields) fieldsArrayAttr.additionalFields = {};

					// eslint-disable-next-line prefer-destructuring
					const additionalFields = fieldsArrayAttr.additionalFields;

					if (!additionalFields[prefix] || !initiated) additionalFields[prefix] = [];

					additionalFields[prefix].push(fieldFormatted);
				}

				// Add remote components in form group

				if (!existField && !prefix) currentGroup.fields.push(fieldFormatted);

				// Add remote components for FieldsArray groups

				if (existField && existField.component === 'FieldsArray') {
					const fieldIdx = currentGroup.fields.findIndex(({ name }) => name === field.name);

					const currentFieldAttrs = field.componentAttributes || {};
					const currentFieldAttrsFields = currentFieldAttrs.fields;

					const itemFields = currentFieldAttrsFields ? currentFieldAttrsFields.map(makeField) : [];

					currentGroup.fields[fieldIdx] = {
						...existField,
						componentAttributes: merge(existField.componentAttributes || {}, {
							...currentFieldAttrs,
							fields: itemFields
						})
					};
				}

				initiated = true;
			});

			initiated = false;
		}

		return currentGroup;
	});
};

/**
 * Fn for remove or modify remote fields
 * @param {Array} fieldsGroup - form fieldsGroup
 * @param {String} parentField - trigger field name
 * @param {String} prefix - prefix FieldsArray group identifier
 * @returns {Array}
 */
export const updateRemoteFields = (fieldsGroup = [], parentField, prefix = '') => {
	const getNewKey = key => {
		const [name, idx] = key.split('_');
		return `${name}_${parseInt(idx, 10) - 1}`;
	};

	return fieldsGroup.map(group => {
		const currentGroup = { ...group };

		currentGroup.fields = currentGroup.fields.reduce((accum, field) => {
			const currentField = { ...field };
			const fieldName = currentField.name;
			const attributes = currentField.componentAttributes;
			const isFieldsArrayComponent = currentField.component === 'FieldsArray';

			// Change and remove remote fields from additionalFields when remove group from FieldsArray

			if (isFieldsArrayComponent && fieldName === parentField && prefix) {
				const innerFields = attributes.additionalFields;

				if (innerFields && !isEmpty(innerFields)) {
					const innerFieldsKeys = Object.keys(innerFields);

					const keys = innerFieldsKeys.includes(prefix)
						? innerFieldsKeys
						: [...innerFieldsKeys, prefix];

					const keysSorted = keys.sort();

					const indexPrefix = keysSorted.findIndex(key => key === prefix);

					let groupIsDeleted = false;

					const additionalFieldsModified = keysSorted.reduce((accumulator, key, idx) => {
						if (key === prefix && !groupIsDeleted) {
							groupIsDeleted = true;
							return accumulator;
						}

						const currentInnerField = innerFields[key] || [];

						if (idx > indexPrefix) {
							const innerFieldsModified = currentInnerField.map(innerField => {
								const triggerParentSplitted = innerField.triggerParent
									.split(/([0-9]+)/g)
									.map(str => {
										const strToNumber = parseInt(str, 10);
										return Number.isNaN(strToNumber) ? str : strToNumber;
									});

								const indexToModified = findLastIndex(
									triggerParentSplitted,
									item => typeof item === 'number'
								);

								const triggerParentModified = triggerParentSplitted
									.map((item, index) => (index === indexToModified ? item - 1 : item))
									.join('');

								return { ...innerField, triggerParent: triggerParentModified };
							});

							return { ...accumulator, [getNewKey(key)]: innerFieldsModified };
						}

						return { ...accumulator, [key]: currentInnerField };
					}, {});

					attributes.additionalFields = additionalFieldsModified;
				}
			}

			// Remove remote fields from FieldsArray additionalFields

			const [fieldsArrayName] = prefix.split('_');

			if (
				isFieldsArrayComponent &&
				fieldName === fieldsArrayName &&
				fieldName !== parentField &&
				prefix
			) {
				const innerFields = attributes.additionalFields;

				if (innerFields && !isEmpty(innerFields) && innerFields[prefix])
					innerFields[prefix] = innerFields[prefix].filter(
						({ triggerParent }) => triggerParent !== parentField
					);
			}

			// Remove remote fields from FieldsArray fields

			if (isFieldsArrayComponent && !prefix)
				attributes.fields = attributes.fields.filter(
					({ triggerParent }) => triggerParent !== parentField
				);

			// Remove remote fields from root

			if (field.triggerParent === parentField) return accum;

			return [...accum, currentField];
		}, []);

		return currentGroup;
	});
};

export const stringHasDotNotation = valueToEvaluate => {
	const levels = valueToEvaluate.split('.');
	const hasDotNotation = levels.length > 1;
	return {
		hasDotNotation,
		parent: levels[hasDotNotation ? levels.length - 2 : 0],
		child: hasDotNotation ? levels[levels.length - 1] : null
	};
};

export const componentWithTranslateWrapper = {
	Chip: true,
	MediumChip: true,
	StatusChip: true,
	Text: true
};
