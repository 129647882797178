import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import Icon from 'components/Icon';
import { actions } from 'modules/page';
import styled from './styles';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

const getSize = size => {
	const widthSizes = {
		mobile: '290px',
		small: '360px',
		medium: '420px',
		large: '640px',
		xl: '800px'
	};

	return widthSizes[size];
};

const ModalComponent = props => {
	const {
		width,
		height,
		isOpen,
		styles,
		children,
		defaultTitle,
		handleCloseModal,
		toggleModalStatus
	} = props;

	const [isModalOpen, setIsModalOpen] = useState(isOpen);

	useEffect(
		() => {
			setIsModalOpen(isOpen);
		},
		[isOpen]
	);

	const getWidthSize = useCallback(() => getSize(width), [width]);

	const modalStyles = {
		content: styled.Modal({ width: getWidthSize(), height, styles }),
		overlay: styled.OverlayBackground
	};

	return (
		<Modal
			closeTimeoutMS={300}
			isOpen={isModalOpen}
			onAfterOpen={() => {
				toggleModalStatus(true);
			}}
			onAfterClose={() => {
				// if this not used, the modal reopens on esc (only in edit/create)
				setTimeout(() => {
					toggleModalStatus(false);
				}, 100);
			}}
			onRequestClose={handleCloseModal}
			style={modalStyles}
		>
			<styled.ModalTransitions />
			{defaultTitle && (
				<styled.TitleWrapper>
					<styled.Title>{defaultTitle}</styled.Title>
					<button type="button" onClick={handleCloseModal}>
						<Icon name="cross_light" color="black" size={24} />
					</button>
				</styled.TitleWrapper>
			)}
			{children}
		</Modal>
	);
};

ModalComponent.propTypes = {
	/** Contenido del Modal */
	children: PropTypes.node,
	/** Para agregar el header genérico de Título + icono de cierre */
	defaultTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	/** Determina si el Modal está visible o no.
	 * Puede controlarse dentro del mismo componente vía state
	 * o externamente desde el store modificándola como sea necesario. */
	isOpen: PropTypes.bool,
	/** Ancho del contenedor. Si no se especifica será medium. */
	width: PropTypes.oneOf(['mobile', 'small', 'medium', 'large', 'xl']),
	/** Alto del contenedor. Si no se especifica se adaptará al contenido. */
	height: PropTypes.number,
	styles: PropTypes.shape({}),
	/** Función que cerraria el Modal */
	handleCloseModal: PropTypes.func,
	/** Avisa al store de la apertura/cierre del modal */
	toggleModalStatus: PropTypes.func
};

ModalComponent.defaultProps = {
	width: 'medium',
	handleCloseModal: () => null
};

const mapDispatchToProps = {
	toggleModalStatus: actions.toggleModalStatus
};

export default connect(
	null,
	mapDispatchToProps
)(ModalComponent);
