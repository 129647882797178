import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import Icon from 'components/Icon';

const makeOnMouseDown = setValue => event => {
	setValue(null);
	event.stopPropagation();
};

const ClearIndicator = ({ innerProps, ...props }) =>
	components.ClearIndicator && (
		<components.ClearIndicator
			innerProps={{ ...innerProps, onMouseDown: makeOnMouseDown(props.setValue) }}
			{...props}
		>
			<Icon name="cross_circle_flat" size={16} />
		</components.ClearIndicator>
	);

ClearIndicator.propTypes = {
	setValue: PropTypes.func.isRequired
};

export default ClearIndicator;
