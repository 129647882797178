import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import loadScripts from 'utils/loadScripts';

const WithScripts = ({ scripts, children }) => {
	const { pathname } = useLocation();
	if (!children) return null;

	useEffect(
		() => {
			loadScripts(scripts, pathname);
		},
		[pathname]
	);
	return <React.Fragment>{children}</React.Fragment>;
};

WithScripts.propTypes = {
	children: PropTypes.node,
	scripts: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			script: PropTypes.string,
			paths: PropTypes.shape({
				include: PropTypes.arrayOf(PropTypes.string),
				exclude: PropTypes.arrayOf(PropTypes.string)
			}),
			environments: PropTypes.arrayOf(PropTypes.string)
		})
	).isRequired
};

export default WithScripts;
