import React from 'react';
import PropTypes from 'prop-types';
import Page from 'components/Page';
import { goToLogin } from 'utils/location';
import { map } from 'utils/mappers';
import { pageError as styled } from './styles';

class PageError extends React.PureComponent {
	static propTypes = {
		error: PropTypes.shape({
			status: PropTypes.number.isRequired,
			statusText: PropTypes.string
		})
	};

	getStatusData() {
		const {
			error: { status }
		} = this.props;

		const statusCode = status.toString().charAt(0) === '5' ? 500 : status;

		const message = map('statusError', statusCode);

		return {
			message,
			statusCode
		};
	}

	render() {
		const { statusCode, message } = this.getStatusData();

		if (statusCode === 401) {
			goToLogin();
			return null;
		}

		const title = `${message} | JANIS`;

		if (document.title !== title) document.title = title;

		return (
			<Page isErrorPage>
				<styled.Container>
					<styled.Text>
						{statusCode} - {message}
					</styled.Text>
					<styled.Image src={`/static/images/error_${statusCode}.svg`} alt={message} />
				</styled.Container>
			</Page>
		);
	}
}

export default PageError;
