import React from 'react';
import translationHOC from 'hocs/translationHOC';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import BackToLogin from 'components/AuthResources/BackToLogin';
import InputField from 'components/AuthResources/InputField';
import styled from 'components/AuthResources/styles';

const Form = ({ handleSubmit, clearClient, translateHelperString }) => (
	<styled.StyledForm onSubmit={handleSubmit}>
		<styled.FieldsContainer clientForm>
			<Field name="clientCode" type="text" component={InputField} />

			<styled.BottomContent>
				<styled.ButtonStyled type="submit" variant="contained">
					{translateHelperString('login.action.enter')}
				</styled.ButtonStyled>
			</styled.BottomContent>
		</styled.FieldsContainer>

		<BackToLogin action={clearClient} />
	</styled.StyledForm>
);

Form.propTypes = {
	clearClient: PropTypes.func,
	handleSubmit: PropTypes.func,
	translateHelperString: PropTypes.func
};

export default translationHOC(reduxForm({ form: 'client' })(Form));
