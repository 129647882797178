import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { newId } from 'utils';
import { styles as styled } from './styles';

const GroupSkeleton = ({ hasIcon }) => (
	<styled.Group>
		<styled.GroupHeader>
			{hasIcon && (
				<styled.Icon>
					<Skeleton circle width={24} height={24} />
				</styled.Icon>
			)}
			<styled.Title>
				<Skeleton height={14} />
			</styled.Title>
		</styled.GroupHeader>
		<styled.FieldWrapper key={newId('loading-field')}>
			<Skeleton width={150} />
			<styled.Input>
				<Skeleton />
			</styled.Input>
		</styled.FieldWrapper>
	</styled.Group>
);

GroupSkeleton.propTypes = {
	hasIcon: PropTypes.bool
};

export default GroupSkeleton;
