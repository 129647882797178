import React from 'react';
import PropTypes from 'prop-types';
import { StyledTitle } from './styles';

const Title = ({ children, uniqueContent = false }) => (
	<StyledTitle uniqueContent={uniqueContent}>{children}</StyledTitle>
);

Title.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	uniqueContent: PropTypes.bool
};

Title.defaultProps = {
	children: null,
	uniqueContent: false
};

export default Title;
