import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/Spinner';
import translationHOC from 'hocs/translationHOC';
import styled from '../styles';

const Actions = ({ isLoading, label, stickToForm, translateHelperString }) => (
	<styled.BottomContent stickToForm={stickToForm}>
		<styled.ButtonStyled variant="contained" type="submit" isLoading={isLoading}>
			{isLoading ? (
				<Spinner color="white" size={22} />
			) : (
				translateHelperString(`login.action.${label}`)
			)}
		</styled.ButtonStyled>

		{/* Hidden until the Send Token implementation actually takes place
			<styled.ButtonStyled>{t('login.action.sendToken')}</styled.ButtonStyled>
		*/}
	</styled.BottomContent>
);

Actions.propTypes = {
	isLoading: PropTypes.bool,
	/** Action name to complete the login.action.{label} translation for the Submit button */
	label: PropTypes.string,
	/** Purely aesthetic, if the Actions on mobile should be right after the form or at the end of the container */
	stickToForm: PropTypes.bool,
	translateHelperString: PropTypes.func
};

export default translationHOC(Actions);
