import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions } from 'modules/menu';
import Button from 'components/Button';
import { cross as styled } from './styles';

const Cross = ({ closeMenu }) => (
	<Button
		icon="cross_light"
		iconColor="black"
		rounded
		styles={styled.buttonStyles}
		onClick={() => closeMenu()}
	/>
);
Cross.propTypes = {
	closeMenu: PropTypes.func.isRequired
};

const mapDispatchToProps = {
	closeMenu: actions.close
};

export default connect(
	null,
	mapDispatchToProps
)(Cross);
