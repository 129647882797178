import styled, { css } from 'styled-components';
import { colors } from 'theme';
import { mediaBreaks } from 'devices';
import { mobileBarHeight } from 'components/Navbar/styles';

const { onlyDesktop, tabletLg: onlyMobile, onlyPrint } = mediaBreaks;

const scrollLockStyles = ({ scrollLock }) => {
	if (!scrollLock) return;

	return css`
		overflow: hidden;
		max-height: 100vh;
	`;
};

const StyledPage = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	min-height: calc(100% - ${props => (props.showToolbar ? '40px' : '0px')});
	background: ${colors.white};
	${({ root }) => root === 'Planning' && 'height: 100vh;'}
	${({ root }) => root !== 'create' && root !== 'Planning' && `position: fixed`};
	${onlyDesktop`
		${scrollLockStyles}
		padding-left: 64px;
	`}
	${onlyMobile`
		${props => props.root !== 'Planning' && `padding-bottom: ${mobileBarHeight}px;`}				
	`}
	${({ unpaidheader }) =>
		unpaidheader &&
		`
			margin-top: 64px;
			padding-bottom: 64px;
	`}

	${({ root }) =>
		root === 'create' &&
		`
			padding-bottom: 0px;
	`}
`;

export const StyledHeader = styled.div`
	min-height: 56px;
	align-items: center;
	flex-shrink: 0;
	${onlyDesktop`
		padding: 23px 26px 0;
		display: grid;
		grid-template-columns: 1fr max-content;
		grid-row-gap: 14px;
	`}
	${onlyMobile`
		padding: 15px 15px 12px;
	`}
	${props => props.styles}
	${onlyPrint`
		min-height: initial;
	`}
`;

export const StyledTitle = styled.h2`
	font-weight: 500;
	color: ${colors.black};
	${onlyDesktop`
		font-size: 26px;
	`}
	${onlyMobile`
		margin-bottom: ${({ uniqueContent }) => (uniqueContent ? '0px' : '15px')};
		font-size: 18px;
		line-height: 21px;
	`}
`;

export default StyledPage;
