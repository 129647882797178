import styled, { css } from 'styled-components';
import { colors } from 'theme';
import { mediaBreaks } from 'devices';
import Text from 'components/Text';

const { onlyDesktop, tabletLg: onlyMobile, onlyPrint } = mediaBreaks;

export const FieldWrapper = styled.div`
	margin-bottom: 25px;
	display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
	page-break-inside: avoid;
	align-self: flex-start;
	${props => !props.useFieldArray && 'align-items: center;'}
	${onlyDesktop`
		width: ${props => props.width || 100}%;
		${({ hasPaddingLeft }) => hasPaddingLeft && 'padding-left: 20px;'}
	`}
	${onlyMobile`
		width: 100%;
		${props => props.component !== 'Switch' && 'min-height: 58px;'}
		${({ isLastFieldClaim }) => isLastFieldClaim && 'min-height: 40px;'}
		${props =>
			!props.hideLabel &&
			props.component !== 'Switch' &&
			`
				flex-direction: column;
				align-items: flex-start;
			`}

	`}
	${onlyPrint`
		flex-direction: column;
		align-items: flex-start;
	`}
	&:first-child {
		margin-top: ${({ component }) => component === 'DateTimePicker' && '8px'};
	}
`;
export const CustomWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;
export const CustomText = styled(Text)`
	padding-bottom: 7px;
	border-bottom: 1px solid #ccc;
`;

export const FieldLabel = styled.label`
	font-size: 13px;
	line-height: 36px;
	font-weight: 500;
	color: ${colors.black};
	flex-shrink: 0;
	${onlyDesktop`
		${({ autoWidth, rightColumnLabel, isFirstElement, hasSpace }) =>
			rightColumnLabel
				? 'margin-right: 20px;'
				: `${
						!autoWidth || (hasSpace && isFirstElement)
							? css`
									width: 166px;
									align-self: flex-start;
							  `
							: `margin: 0px 20px 0px ${isFirstElement ? '0' : '30'}px;`
				  }`}
	`}
	${onlyMobile`
		flex-grow: 1;
		margin-right: 16px;
	`}
	${onlyPrint`
		line-height: initial;
		margin-bottom: 6px;
	`}
`;

export const styledMultiInput = {
	FieldsWrapper: styled.div`
		flex-direction: column;
		flex-grow: 1;
	`
};
export const makeChipStyles = ownProps => css`
	opacity: ${ownProps.disabled && '0.5'};
	white-space: nowrap;
	background-color: ${props => props.variant === 'outlined' && colors.white};
	min-width: 44px;
`;
export const CenteredContentWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const SelectMultilevelStyles = {
	Wrapper: styled.div`
		width: 100%;
	`,
	SelectWrapper: styled.div`
		width: 100%;
		margin-bottom: 20px;
	`
};

export const StyledText = styled(Text)`
	margin-top: 10px;
`;
