import React from 'react';
import { translateHelperString, translateHelperValue } from 'utils/translate';
import { translateHelperComponent, translateValueComponent } from 'utils/translateComponent';
import { withTranslation } from 'react-i18next';

export default function translationHOC(ComponentToTranslate) {
	return withTranslation()(props => (
		<ComponentToTranslate
			{...props}
			// format the key in three levels and returns the translation as component with underline (return Component)
			t={translateHelperComponent}
			// format the key in four levels and returns the translation as component with underline (return Component)
			translateValueComponent={translateValueComponent}
			//	format the key in trhee levels and returns the translation as string without underline (return string)
			translateHelperString={translateHelperString}
			//	format the key in four levels and returns the translation as string without underline (return string)
			translateHelperValue={translateHelperValue}
		/>
	));
}
