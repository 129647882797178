import React from 'react';
import PropTypes from 'prop-types';
import { currentEnvironment } from 'config/environment';
import { useDevices } from 'utils/hooks';
import Spinner from 'components/Spinner';
import janisLogoBase from 'images/janis-logo-base.svg';
import janisLogoBeta from 'images/janis-logo-beta.svg';
import janisLogoQA from 'images/janis-logo-qa.svg';
import backgroundLeft from 'images/login-background-1.svg';
import backgroundRight from 'images/login-background-2.svg';
import Footer from './Footer';
import styled from './styles';

const getLogoSource = () => {
	if (currentEnvironment === 'beta') return janisLogoBeta;
	if (currentEnvironment === 'QA') return janisLogoQA;

	return janisLogoBase;
};

const AuthWrapper = ({ children, isLoading, isAuthScreen }) => {
	const { onlyDesktop } = useDevices();

	return (
		<>
			<styled.Wrapper isAuthScreen={isAuthScreen}>
				<styled.LogoWrapper isAuthScreen={isAuthScreen}>
					<img src={getLogoSource()} alt="Janis" />
				</styled.LogoWrapper>

				<styled.Content isAuthScreen={isAuthScreen}>
					{isLoading && (
						<styled.SpinnerWrapper>
							<Spinner />
						</styled.SpinnerWrapper>
					)}

					{children}
				</styled.Content>

				<Footer />
			</styled.Wrapper>
			{onlyDesktop && !isAuthScreen && (
				<styled.BackgroundWrapper>
					<img src={backgroundLeft} alt="Janis" />
					<img src={backgroundRight} alt="Janis" />
				</styled.BackgroundWrapper>
			)}
		</>
	);
};

AuthWrapper.propTypes = {
	/** The content to show within the white box */
	children: PropTypes.node,
	/** The Authorize screen has minor layout differences */
	isAuthScreen: PropTypes.bool,
	isLoading: PropTypes.bool
};

export default AuthWrapper;
