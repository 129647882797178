import * as types from './types';

const MAXIMUM_LOGS = 800;

const initialState = {
	logs: [],
	servers: [
		{
			label: 'front.janis.in',
			value: 'https://views.janisdev.in'
		}
	],
	serverSelected: {},
	status: 'unselected',
	showInBrowserConsole: false
};

const logsQuantityController = (currentLogs, newLog) => {
	if (currentLogs.length === MAXIMUM_LOGS) return currentLogs.slice(1).concat([newLog]);

	return [...currentLogs, newLog];
};

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case types.SET_ALL_SERVERS:
			return {
				...state,
				servers: action.servers
			};
		case types.SET_CURRENT_SERVER:
			return {
				...state,
				serverSelected: action.server
			};
		case types.SET_LOG:
			return {
				...state,
				logs: logsQuantityController(state.logs, action.log),
				hasLogs: true
			};
		case types.CLEAR_LOGS:
			return {
				...state,
				logs: [],
				hasLogs: false
			};
		case types.SET_STATUS:
			return {
				...state,
				status: action.status
			};
		case types.TOGGLE_SHOW_IN_BROWSER_CONSOLE:
			return {
				...state,
				showInBrowserConsole: !state.showInBrowserConsole
			};
		case types.PAUSE_LOGS:
			return {
				...state,
				status: 'paused'
			};
		case types.RESUME_LOGS:
			return {
				...state,
				status: 'online'
			};
		default:
			return state;
	}
}
