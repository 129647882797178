import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions } from 'modules/navbar';
import OnlyDev from 'components/OnlyDev';
import translationHOC from 'hocs/translationHOC';
import { useDevices } from 'utils/hooks';
import dates from 'utils/date';
import { Transition } from 'react-spring/renderprops';
import UserImage from 'components/UserImage';
import Button from 'components/Button';
import Icon from 'components/Icon';
import LanguageSelector from 'components/LanguageSelector';
import Logout from './Logout';
import DevTools from './DevTools';
import { menu as styled } from './styles';

const ProfileDetail = ({ isNavbarOpen, isOpen, close, page, userData, t }) => {
	const { onlyDesktop } = useDevices();

	const {
		name,
		firstname,
		lastname,
		email,
		images: { original: image },
		profileName: profile,
		tname: company,
		updated_at: updatedAt,
		isDev
	} = userData || {};

	const imageUrl = `${image}&s=${onlyDesktop ? 140 : 100}`;
	const timeStamp = updatedAt * 1000;

	const date = dates.getTimeByTimeStamp(timeStamp, 'dd/MM/yyyy');
	const time = dates.getTimeByTimeStamp(timeStamp, 'HH:mm');

	const content = (
		<>
			<styled.Detail>
				{onlyDesktop ? (
					<styled.Close icon="cross_light" iconColor="blue" rounded onClick={close} />
				) : (
					<styled.Title>{t('common.title.userProfile')}</styled.Title>
				)}

				<UserImage url={imageUrl} size="extralarge" firstname={firstname} lastname={lastname} />

				<styled.Chip backgroundColor="green">
					<styled.Profile>{profile}</styled.Profile>
				</styled.Chip>
				<styled.Text bold>{name}</styled.Text>
				<styled.Text>{company}</styled.Text>
				<styled.Email>{email}</styled.Email>
				<styled.Link href="/id/account/edit">
					<Button icon="edit" variant="contained" onClick={close}>
						{t('common.action.edit')}
					</Button>
				</styled.Link>
				<LanguageSelector isProfileDetailOpen={isOpen} />
			</styled.Detail>
			<OnlyDev>
				<DevTools page={page} />
			</OnlyDev>
			{date && time && (
				<styled.Container>
					<styled.ActiveIcon>
						<Icon name="clock" color="blue" size={25} />
					</styled.ActiveIcon>
					<styled.ActiveText>
						{t('common.title.activeSince')}
						{`: ${date} `}
						{t('common.word.at')}
						{` ${time}`}
					</styled.ActiveText>
				</styled.Container>
			)}
			<styled.Container>
				<Logout />
			</styled.Container>
		</>
	);

	return onlyDesktop ? (
		<styled.DesktopContainer isDev={isDev} isOpen={isOpen} isNavbarOpen={isNavbarOpen}>
			{content}
		</styled.DesktopContainer>
	) : (
		<Transition
			items={isOpen}
			from={{ opacity: 0 }}
			enter={{ opacity: 1 }}
			leave={{ opacity: 0 }}
			config={{ duration: 100 }}
		>
			{show => show && (() => <styled.MobileContainer>{content}</styled.MobileContainer>)}
		</Transition>
	);
};

ProfileDetail.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	isNavbarOpen: PropTypes.bool.isRequired,
	close: PropTypes.func,
	page: PropTypes.shape({}),
	userData: PropTypes.shape({})
};

const mapStateToProps = state => ({
	isOpen: state.navbar.isProfileDetailOpen,
	isNavbarOpen: state.navbar.isOpen,
	page: state.page,
	userData: state.user
});

const mapDispatchToProps = {
	close: actions.close
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(translationHOC(ProfileDetail));
