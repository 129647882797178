import React from 'react';
import PropTypes from 'prop-types';
import translationHOC from 'hocs/translationHOC';
import LanguageSelector from 'components/LanguageSelector';
import styled from './styles';

const Footer = ({ translateHelperString }) => (
	<styled.Footer>
		<styled.Link href="https://janis.im/politica-de-privacidad/" target="_blank">
			{translateHelperString('common.title.privacyPolicy')}
		</styled.Link>

		<LanguageSelector isAuthWrapper />
	</styled.Footer>
);

Footer.propTypes = {
	translateHelperString: PropTypes.func
};

export default translationHOC(Footer);
