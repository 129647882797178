import styled from 'styled-components';
import { mediaBreaks } from 'devices';
import { colors } from 'theme';

const { mobile } = mediaBreaks;
const { white } = colors;

export default {
	PopupRef: styled.div``,
	PopupBg: styled.div`
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 998;
		background: rgba(39, 57, 71, 0.2);
	`,
	PopupContainer: styled.div`
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		position: fixed;
		padding: ${({ timeValid }) => (!timeValid ? '10px 60px 3px 60px' : '10px 60px 35px 60px')};
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 999;
		background: ${white};
		box-shadow: 0px 2px 5px rgba(39, 57, 71, 0.154608);
		${mobile`
			width: 75%;
			padding-inline: 40px;
		`}
	`,
	CloseButton: styled.button`
		position: absolute;
		right: 5px;
		top: 5px;
	`
};
