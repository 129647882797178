import styled, { css } from 'styled-components';
import { placeholder } from 'polished';
import { colors, timingFunctions, mixins } from 'theme';
import { mediaBreaks } from 'devices';

const { onlyDesktop, tabletLg: onlyMobile } = mediaBreaks;

export const back = {
	Container: styled.div`
		display: flex;
		justify-content: center;
		align-items: center;
		width: 0;
		opacity: 0;
		overflow: hidden;
		${props => mixins.transition('width, opacity', props.noTransition ? '0s' : '0.25s')};

		${props =>
			props.isVisible &&
			css`
				opacity: 1;
				${onlyDesktop`
					margin-right: 10px;
					width: 58px;
				`}
				${onlyMobile`
					width: auto;
				`}
			`}
	`
};

export const searchbar = {
	Searchbar: styled.div`
		display: flex;
		align-items: center;
		${onlyDesktop`
			margin-top: 23px;
		`}
		${onlyMobile`
			width: 100%;
			margin-right: 12px;
		`}
	`
};

export const searchInput = {
	Container: styled.label`
		position: relative;
		border: 1px solid ${colors.grey};
		display: flex;
		align-items: center;
		flex-grow: 1;
		height: 40px;
		padding-left: 10px;
		transition: flex-grow 0.25s ${timingFunctions.standard};
	`,
	Input: styled.input`
		flex-grow: 1;
		margin-left: 16px;
		height: 100%;
		color: ${colors.darkGrey};
		font-size: 13px;
		border: none;
		${placeholder({ color: colors.darkGrey })}
	`,
	crossIconStyles: css`
		position: absolute;
		fill: ${colors.grey};
		right: 10px;
		transition: fill 0.2s ${timingFunctions.standard};

		&:hover {
			fill: ${colors.black};
		}
	`
};

export const InputStyled = styled.input.attrs({ placeholder: 'Buscar...' })`
	flex-grow: 1;
	margin-left: 16px;
	height: 100%;
	color: ${({ searchClient }) => (searchClient ? `${colors.black}` : `${colors.darkGrey}`)};
	font-size: 13px;
	border: none;
	${placeholder({ color: colors.darkGrey })}
`;

export const crossIconStyles = css`
	position: absolute;
	fill: ${colors.grey};
	right: 10px;
	transition: fill 0.2s ${timingFunctions.standard}, opacity 0.1s ${timingFunctions.standard};
	transition-property: fill, opacity, visibility;
	visibility: hidden;
	opacity: 0;
	cursor: pointer;

	${props =>
		props.isVisible &&
		css`
			opacity: 1;
			visibility: visible;
			transition-duration: 0.2s;
		`}

	&:hover {
		fill: ${colors.black};
	}
`;
