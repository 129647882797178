import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import styles from './styles';

const Cross = ({ searchText, setSearchText }) => (
	<Icon
		name="cross_circle_flat"
		onClick={() => setSearchText('')}
		size={16}
		styles={styles.crossIconStyles}
		isVisible={!!searchText}
	/>
);

Cross.propTypes = {
	searchText: PropTypes.string,
	setSearchText: PropTypes.func
};

export default Cross;
