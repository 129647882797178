import { css } from 'styled-components';
import { mediaBreaks } from 'devices';
import { palette } from '@janiscommerce/ui-web';

const timingFunctions = {
	standard: 'cubic-bezier(0.4, 0, 0.2, 1)',
	decelerate: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
	accelerate: 'cubic-bezier(0.4, 0.0, 1, 1)'
};

const theme = {
	colors: { ...palette },
	font: {
		family: `
		'Roboto',
		sans-serif
		`,
		base: '13px',
		baseSmall: '12px',
		medium: '14px',
		small: '11px',
		xsmall: '9px',
		large: '16px',
		xlarge: '18px',
		xxlarge: '20px'
	},
	transition: `all 250ms ${timingFunctions.standard}`,
	timingFunctions,
	mixins: {
		flexCenter: css`
			display: flex;
			align-items: center;
			justify-content: center;
		`,
		placeholder(styles) {
			return css`
				&::-moz-placeholder {
					${styles}
				}
				&::-webkit-input-placeholder {
					${styles}
				}
				&:-moz-placeholder {
					${styles}
				}
				&:-ms-input-placeholder {
					${styles}
				}
				&::placeholder {
					${styles}
				}
			`;
		},
		transition(property = 'all', time = '200ms') {
			let transitionProperty;
			if (property.includes(',')) transitionProperty = `transition-property: ${property}`;

			const transition = `${property.split(',')[0]} ${time} ${timingFunctions.standard}`;

			return css`
				transition: ${transition};
				${transitionProperty};
			`;
		},
		scrollbar(thumbColor, shadowColor, hide = false) {
			const { colors } = theme;
			return css`
				&::-webkit-scrollbar {
					width: 10px;
					display: ${hide && 'none'};
				}
				&::-webkit-scrollbar-track {
					box-shadow: inset 0 0 6px ${shadowColor || colors.white};
					display: ${hide && 'none'};
				}
				&::-webkit-scrollbar-thumb {
					height: 5px;
					width: 4px;
					border-radius: 50px;
					background-color: ${thumbColor};
					display: ${hide && 'none'};
				}
			`;
		}
	}
};

export default theme;

export const { colors, icon, font, transition, mixins } = theme;

/** Search for provided color (by name) in theme and returns its hex code.
 * If not found, returns the input (either name or hex code).
 * @param {string} color (name or hex code)
 */
export const findColorInTheme = color => theme.colors[color] || color;

export { timingFunctions };

/** Hide element on printable version */
export const hideOnPrinter = css`
	${mediaBreaks.onlyPrint`
		display: none;
	`}
`;
