import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Icon from 'components/Icon';
import { actions, selectors } from 'modules/menu';
import { crossIconStyles } from './styles';

const Cross = ({ clearSearchTerm, hasSearchTerm, size }) => (
	<Icon
		name="cross_circle_flat"
		onClick={() => clearSearchTerm()}
		size={size || 16}
		styles={crossIconStyles}
		isVisible={hasSearchTerm}
	/>
);

Cross.propTypes = {
	clearSearchTerm: PropTypes.func.isRequired,
	hasSearchTerm: PropTypes.bool.isRequired,
	size: PropTypes.number
};

const mapStateToProps = state => ({
	hasSearchTerm: selectors.hasSearchTerm(state)
});

const mapDispatchToProps = {
	onChange: event => actions.changeSearchTerm(event.target.value),
	clearSearchTerm: actions.clearSearchTerm
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Cross);
