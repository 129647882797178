import {
	fetchPreviewDataSuccess,
	getCurrentPreviewProps,
	getCurrentRowCollapsePreviewProps
} from 'modules/preview/actions';

export const getCurrentPreview = (type, getState, section) => {
	const state = getState();

	const getPreviewProps =
		type === 'preview' ? getCurrentPreviewProps : getCurrentRowCollapsePreviewProps;

	return getPreviewProps(state, section);
};

export const previewDependencies = ({ getState, dispatch, field, value, type, section }) => {
	const { name: previewName, currentPreviewData } = getCurrentPreview(type, getState, section);
	dispatch(fetchPreviewDataSuccess({ ...currentPreviewData, [field]: value }, previewName));
};
