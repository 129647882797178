import { isObject } from 'utils';

/**
 * It takes an array of filters and an object of applied filters and returns an array of required
 * filters that are not applied
 * @param [filters] - An array of filters.
 * @param [appliedFilters] - The object containing the applied filters.
 * @returns A boolean that indicates if there are required filters that are not in the applied filters.
 */
const hasRequiredFilters = (filters = [], appliedFilters = {}) => {
	if (!Array.isArray(filters) || !isObject(appliedFilters)) return false;
	if (!filters.length) return false;

	const requiredFiltersName = filters.filter(filter => filter.required).map(filter => filter.name);
	if (!requiredFiltersName.length) return false;

	const requiredFilters = requiredFiltersName.filter(requiredFilterName => {
		const requiredFilter = appliedFilters[requiredFilterName];
		return (
			!requiredFilter || (requiredFilter && Array.isArray(requiredFilter) && !requiredFilter.length)
		);
	});

	return !!requiredFilters.length;
};

export default hasRequiredFilters;
