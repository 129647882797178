import styled, { css } from 'styled-components';
import { placeholder } from 'polished';
import { colors, timingFunctions } from 'theme';

export default {
	Container: styled.label`
		margin: ${({ hasResult }) => (!hasResult ? '20px 0 70px' : '20px 0 0px')};
		position: relative;
		border: 1px solid ${colors.grey};
		display: flex;
		align-items: center;
		flex-grow: 1;
		height: 40px;
		padding-left: 10px;
		transition: flex-grow 0.25s ${timingFunctions.standard};
	`,
	Input: styled.input.attrs({ placeholder: 'Buscar...' })`
		flex-grow: 1;
		margin-left: 16px;
		height: 100%;
		color: ${colors.darkGrey};
		font-size: 13px;
		border: none;
		${placeholder({ color: colors.darkGrey })}
	`,
	crossIconStyles: css`
		position: absolute;
		fill: ${colors.grey};
		right: 10px;
		transition: fill 0.2s ${timingFunctions.standard}, opacity 0.1s ${timingFunctions.standard};
		transition-property: fill, opacity, visibility;
		visibility: hidden;
		opacity: 0;
		cursor: pointer;

		${props =>
			props.isVisible &&
			css`
				opacity: 1;
				visibility: visible;
				transition-duration: 0.2s;
			`}

		&:hover {
			fill: ${colors.black};
		}
	`
};
