import React from 'react';
import PropTypes from 'prop-types';
import translationHOC from 'hocs/translationHOC';
import Image from 'components/Image';
import Text from 'components/Text';
import Mail from 'images/mailing-icn.svg';
import styled from './styles';

const SuccessMessage = ({ email, translateHelperString }) => (
	<styled.SuccessMessage>
		<styled.ImageWrapper>
			<Image url={Mail} width={100} />
		</styled.ImageWrapper>
		<Text fontSize="base">{translateHelperString('login.titles.weSentAnEmailToYourAccount')}</Text>
		<Text color="blue" fontSize="medium">
			{email}
		</Text>
		<Text fontSize="base">
			{translateHelperString('login.titles.pleaseRememberToCheckSpamAndFollowInstructions')}
		</Text>
	</styled.SuccessMessage>
);

SuccessMessage.propTypes = {
	email: PropTypes.string,
	translateHelperString: PropTypes.func
};

export default translationHOC(SuccessMessage);
