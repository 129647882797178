import React from 'react';
import translationHOC from 'hocs/translationHOC';
import styled from './styles';

const TextContent = ({ t }) => (
	<styled.PopupTextContainer>
		<styled.PopupText>{t('common.title.subscriptionWillBeCancelled')}</styled.PopupText>
	</styled.PopupTextContainer>
);

export default translationHOC(TextContent);
