import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import { useSearch } from 'utils/hooks';
import translationHOC from 'hocs/translationHOC';
import SearchResult from './components/SearchResult';
import SearchInput from './components/SearchInput';

const SearchModal = ({ t, isOpen, toggleSearch }) => {
	const [searchText, setSearchText] = useState('');
	const { textStrong, isLoading, dataResponse, getMenuCategories } = useSearch();

	const handleCloseModal = () => {
		setSearchText('');
		toggleSearch();
	};

	useEffect(
		() => {
			getMenuCategories(searchText);
		},
		[searchText, isOpen]
	);

	return (
		<Modal
			isOpen={isOpen}
			handleCloseModal={handleCloseModal}
			defaultTitle={t('common.action.search')}
			width="large"
		>
			<SearchInput searchText={searchText} setSearchText={setSearchText} />
			<SearchResult
				searchText={searchText}
				responseSearch={dataResponse}
				isLoading={isLoading}
				textStrong={textStrong}
			/>
		</Modal>
	);
};

SearchModal.propTypes = {
	isOpen: PropTypes.bool,
	toggleSearch: PropTypes.func
};
SearchModal.defaultProps = {
	isOpen: false,
	toggleSearch: () => {}
};

export default translationHOC(SearchModal);
