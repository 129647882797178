import React, { useContext } from 'react';
import Form from './Form';
import { ContentContext } from '../../Content';

const ClientForm = props => {
	const { clientCode, currentForm } = useContext(ContentContext);

	if (currentForm !== 'client') return null;

	return <Form initialValues={{ clientCode }} {...props} />;
};

export default ClientForm;
