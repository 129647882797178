import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from './styles';

const ClientSkeletonItem = () => (
	<styled.ClientItem>
		<styled.ClientImage>
			<Skeleton width="100%" height="100%" />
		</styled.ClientImage>
		<styled.ClientName>
			<Skeleton count={2} height={24} width={70} />
		</styled.ClientName>
	</styled.ClientItem>
);

export default ClientSkeletonItem;
