import { createGlobalStyle } from 'styled-components';
import { colors, mixins } from 'theme';

export default createGlobalStyle`
	* {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		border: 0;
	}

	body {
		margin: 0;
		padding: 0;
		font-family: 'Roboto', sans-serif;
		background: ${colors.lightGrey};
		${mixins.scrollbar(colors.grey, colors.white, true)}
	}

	#root {
		background: ${colors.white};
	}

	p {
		font-size: 13px;
	}

	button {
		background: none;
		outline: 0;
		cursor: pointer;
		padding: 0;
		margin: 0;
		border: 0;

		&:disabled {
			cursor: default;
		}
	}

	a,
	image {
		display: inline-block;
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	button {
		color: inherit;
	}

	button,
	input,
	textarea {
		font: inherit;
	}

	input:focus {
		outline: none;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active  {
		-webkit-box-shadow: 0 0 0 30px white inset;
	}
`;
