import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { clientsModal as styled } from '../../styles';

const ClientItem = ({
	clientMainColor,
	clientCode,
	clientName,
	clientImage,
	clientCountry,
	selectClientMethod,
	isCurrent
}) => {
	const [showImage, setShowImage] = useState(!!clientImage);
	const [loadingImg, setLoadingImg] = useState(true);

	return (
		<styled.ClientItem
			isCurrent={isCurrent}
			disabled={isCurrent}
			onClick={() => selectClientMethod(clientCode)}
		>
			{showImage ? (
				<styled.ClientImage
					src={clientImage}
					mainColor={clientMainColor}
					onError={() => setShowImage(false)}
					onLoad={() => setLoadingImg(false)}
					loadingImg={loadingImg}
				/>
			) : (
				<styled.ClientBadge>{clientName.charAt(0).toUpperCase()}</styled.ClientBadge>
			)}
			<styled.ClientName>
				{clientName}
				<span>{clientCountry}</span>
			</styled.ClientName>
		</styled.ClientItem>
	);
};

ClientItem.propTypes = {
	clientMainColor: PropTypes.string,
	clientCode: PropTypes.string,
	clientName: PropTypes.string,
	clientImage: PropTypes.string,
	clientCountry: PropTypes.string,
	isCurrent: PropTypes.bool,
	selectClientMethod: PropTypes.func
};
export default ClientItem;
