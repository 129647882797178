import styled from 'styled-components';
import Text from 'components/Text';
import { font } from 'theme';

const { medium } = font;

export default {
	PopupTextContainer: styled.div`
		display: flex;
		justify-content: center;
		margin-top: 26px;
		margin-bottom: 33px;
	`,
	PopupText: styled(Text)`
		font-size: ${medium};
		line-height: 16px;
		text-align: center;
	`
};
