import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createFormSection } from 'components/FormSection';

class FormSectionModule extends React.PureComponent {
	constructor(props) {
		super(props);

		this.formSection = createFormSection(
			props.name,
			{ isEditSection: false, floatingLabels: true },
			props.onSuccessCallback
		);
	}

	makeSchema = () => {
		const {
			endpoint: target,
			endpointParameters: targetEndpointParameters,
			hideUserCreated,
			hideUserModified,
			schema: fields,
			source,
			sourceEndpointParameters,
			currentViewData = {},
			requestFields,
			name
		} = this.props;

		const fieldsGroup = [
			{
				name: `${name}Group`,
				translateLabel: false,
				fields: fields.map(fieldSchema => ({
					...fieldSchema,
					componentAttributes: fieldSchema.componentAttributes || {}
				}))
			}
		];

		return {
			source,
			sourceEndpointParameters,
			target,
			currentViewData,
			targetEndpointParameters,
			fieldsGroup,
			hideUserCreated,
			hideUserModified,
			requestFields,
			name
		};
	};

	render() {
		const FormSection = this.formSection;

		return <FormSection schema={this.makeSchema()} isEmpty={!this.props.source} />;
	}
}

FormSectionModule.propTypes = {
	endpoint: PropTypes.shape({}),
	currentViewData: PropTypes.oneOfType([
		PropTypes.shape({}),
		PropTypes.arrayOf(PropTypes.shape({}))
	]),
	endpointParameters: PropTypes.arrayOf(PropTypes.shape({})),
	hideUserCreated: PropTypes.bool,
	hideUserModified: PropTypes.bool,
	schema: PropTypes.arrayOf(PropTypes.shape({})),
	source: PropTypes.shape({}),
	sourceEndpointParameters: PropTypes.arrayOf(PropTypes.shape({})),
	onSuccessCallback: PropTypes.func,
	name: PropTypes.string,
	requestFields: PropTypes.shape({})
};

export default connect()(FormSectionModule);
