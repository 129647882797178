import { useState } from 'react';

const useSwipe = () => {
	const [touchStart, setTouchStart] = useState({ x: null, y: null });
	const [touchEnd, setTouchEnd] = useState({ x: null, y: null });

	const minSwipeDistance = 50;

	const onTouchStart = e => {
		setTouchEnd({ x: null, y: null });
		setTouchStart({ x: e.targetTouches[0].clientX, y: e.targetTouches[0].clientY });
	};

	const onTouchMove = e => {
		setTouchEnd({ x: e.targetTouches[0].clientX, y: e.targetTouches[0].clientY });
	};

	const onTouchEnd = callback => {
		if (!touchStart.x || !touchEnd.x || !touchStart.y || !touchEnd.y) return;

		const deltaX = touchStart.x - touchEnd.x;
		const deltaY = touchStart.y - touchEnd.y;

		const isHorizontalSwipe = Math.abs(deltaX) > Math.abs(deltaY);

		if (isHorizontalSwipe && Math.abs(deltaX) > minSwipeDistance) {
			const isLeftSwipe = deltaX > 0;
			callback(isLeftSwipe ? 'next' : 'prev');
		}
	};

	return { onTouchStart, onTouchMove, onTouchEnd };
};

export default useSwipe;
