import ClearIndicator from './ClearIndicator';
import Control from './Control';
import DropdownIndicator from './DropdownIndicator';
import Option from './Option';
import Placeholder from './Placeholder';
import ValueContainer from './ValueContainer';

export default {
	ClearIndicator,
	Control,
	DropdownIndicator,
	Option,
	Placeholder,
	ValueContainer
};
