import { useEffect } from 'react';

/**
 * Custom hook for execute handler on press escape
 * @param {func} onEscape
 */
const useEscape = onEscape => {
	useEffect(() => {
		const handleEsc = event => {
			if (event.keyCode === 27) onEscape();
		};

		window.addEventListener('keydown', handleEsc);

		return () => {
			window.removeEventListener('keydown', handleEsc);
		};
	}, []);
};

export default useEscape;
