import * as types from './types';

export const setCurrentServer = server => ({
	type: types.SET_CURRENT_SERVER,
	server
});

export const setAllServers = servers => ({
	type: types.SET_ALL_SERVERS,
	servers
});

export const setLogAction = log => ({
	type: types.SET_LOG,
	log
});

export const setLog = log => (dispatch, getState) => {
	const { logs } = getState();
	if (logs.status === 'paused') return false;

	dispatch(setLogAction(log));

	if (logs.showInBrowserConsole) console.log(log); // eslint-disable-line
};

export const clearLogs = () => ({
	type: types.CLEAR_LOGS
});

export const setStatus = status => ({
	type: types.SET_STATUS,
	status
});

export const pauseLogs = () => ({
	type: types.PAUSE_LOGS
});

export const resumeLogs = () => ({
	type: types.RESUME_LOGS
});

export const togglePauseLogs = () => (dispatch, getState) => {
	const { status } = getState().logs;

	if (status === 'paused') return dispatch(resumeLogs());

	dispatch(pauseLogs());
};

export const toggleShowInBrowserConsole = () => ({
	type: types.TOGGLE_SHOW_IN_BROWSER_CONSOLE
});
