import { createSelector } from 'reselect';
import { getCurrentProps, getLabelValue } from 'utils/selectors';

const getAppliedFilters = state => state.appliedFilters;

export const hasFiltersApplied = createSelector(
	getAppliedFilters,
	appliedFilters => Object.keys(appliedFilters || {}).length > 0
);

// Function for get label value for browse filters (input|select)
export const getFilterLabel = () =>
	createSelector(
		[getCurrentProps],
		props => getLabelValue(props)
	);
