import * as types from './types';

const initialState = {
	isReady: false,
	isFetching: true,
	filters: [],
	defaultValues: {},
	appliedFilters: {},
	filtersAreApplied: true,
	applyFiltersToggle: true,
	useUrlFilters: false,
	useUpdateUrlFilters: false,
	error: false
};

const normalizeFilters = fields =>
	fields.map(field => {
		const { componentAttributes = {} } = field;
		return {
			...field,
			remote: (componentAttributes.options || {}).scope === 'remote',
			attributes: field.attributes || {}
		};
	});

const mergeState = (state, action, properties) => ({
	...state,
	[action.meta.name]: {
		...state[action.meta.name],
		...properties
	}
});

export default (state = {}, action) => {
	switch (action.type) {
		case types.ADD_FILTERS:
			return mergeState(state, action, {
				filters: normalizeFilters(action.filters)
			});

		case types.ADD_INITIAL_FILTERS_VALUES:
			return mergeState(state, action, {
				appliedFilters: {
					...state[action.meta.name].appliedFilters,
					...action.values
				},
				defaultValues: {
					...state[action.meta.name].defaultValues,
					...action.defaultValues
				}
			});

		case types.CHANGE_FILTER:
			return mergeState(state, action, {
				appliedFilters: {
					...state[action.meta.name].appliedFilters,
					[action.name]: action.value
				}
			});

		case types.CHANGE_FILTERS:
			return mergeState(state, action, {
				appliedFilters: action.values
			});

		case types.CLEAR_FILTER: {
			const appliedFilters = { ...state[action.meta.name].appliedFilters };
			delete appliedFilters[action.name];

			return mergeState(state, action, {
				appliedFilters
			});
		}

		case types.CLEAR_ALL_FILTERS:
			return mergeState(state, action, {
				appliedFilters: { ...state[action.meta.name].defaultValues }
			});

		case types.APPLY_FILTERS:
			return mergeState(state, action, {
				filtersAreApplied: action.value
			});

		case types.APPLY_FILTERS_TOGGLE:
			return mergeState(state, action, {
				applyFiltersToggle: !state[action.meta.name].applyFiltersToggle
			});

		case types.USE_URL_FILTERS:
			return mergeState(state, action, {
				useUrlFilters: action.useUrlFilters,
				useUpdateUrlFilters: action.useUpdateUrlFilters
			});

		case types.IS_READY:
			return mergeState(state, action, {
				isReady: action.isReady
			});

		case types.CREATE_FILTERS:
			return {
				...state,
				[action.name]: initialState
			};

		case types.DELETE_FILTERS:
			return Object.keys(state).reduce((accum, key) => {
				const accumulator = { ...accum };
				if (action.name !== key) accumulator[key] = state[key];
				return accumulator;
			}, {});

		default:
			return state;
	}
};
