import styled from 'styled-components';
import { mediaBreaks } from 'devices';
import { colors, font } from 'theme';

const { onlyDesktop, tabletLg: onlyMobile } = mediaBreaks;

export default {
	TitleContainer: styled.div`
		display: flex;
		flex-direction: column;
		align-items: center;
	`,
	Title: styled.h1`
		color: ${colors.darkGreyPressed};
		font-family: ${font.family};
		font-size: ${font.large};
		font-weight: 500;
		line-height: 19px;
		height: 24px;
		${onlyDesktop`
			margin-bottom: 6px;
		`}
		${onlyMobile`
			margin-bottom: 6px;
		`}
	`,
	SubTitle: styled.h3`
		color: ${colors.darkGreyPressed};
		font-family: ${font.family};
		font-size: ${font.medium};
		line-height: 16px;
		font-weight: normal;
	`,
	ClientButton: styled.button`
		color: ${colors.blue};
		display: inline-flex;
		align-items: center;
		line-height: 20px;
		svg {
			margin-left: 9px;
		}
	`
};
