import React from 'react';
import PropTypes from 'prop-types';
import translationHOC from 'hocs/translationHOC';
import Text from 'components/Text';
import Icon from 'components/Icon';
import styled from '../styles';

const PreAuthError = ({ errorMessage, missingParams = [], t }) => (
	<>
		<styled.Header>
			<Text fontSize="xxlarge" fontWeight="medium">
				{t('common.title.somethingWentWrong')}
			</Text>
		</styled.Header>

		<styled.Message>
			<styled.PreAuthErrorMessage>{errorMessage}</styled.PreAuthErrorMessage>
			{!!missingParams.length && (
				<styled.MessageList>
					{missingParams.map(param => (
						<li key={param}>
							<Icon name="cross_big_circle_bold" size={18} color="statusRed" /> {param}
						</li>
					))}
				</styled.MessageList>
			)}
		</styled.Message>
	</>
);

PreAuthError.propTypes = {
	errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	missingParams: PropTypes.arrayOf(PropTypes.string)
};

export default translationHOC(PreAuthError);
