import styled from 'styled-components';
import { colors, font } from 'theme';
import { mediaBreaks } from 'devices';

const { tabletLg: onlyMobile } = mediaBreaks;

export default {
	Container: styled.div`
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;
		${onlyMobile`
			width: 100%;
			max-width: 420px;
		`}
	`,
	TitleContainer: styled.div`
		display: flex;
		flex-direction: column;
		${onlyMobile`
			align-items: center;
		`}
	`,
	Title: styled.h1`
		color: ${colors.black};
		font-family: ${font.family};
		font-size: 26px;
		font-weight: 500;
		line-height: 36px;
		${onlyMobile`
			margin-top: 25px;
		`}
	`,
	NoTokenError: styled.div`
		font-size: ${font.medium};
		color: ${colors.statusRed};
		margin-bottom: auto;
		text-align: center;
	`
};
