import ms from 'utils/api/ms';

let translationKeys = {};
let seconds = 5;
let timer = null;

const saveRequest = () => {
	const payload = Object.keys(translationKeys).map(key => translationKeys[key]);

	// reset keys container
	translationKeys = {};

	ms.post({
		service: 'i18n',
		namespace: 'translate-batch',
		method: 'post',
		data: payload
	});
};

// Clear timer interval and reset timer and seconds values
const restartTimer = () => {
	clearInterval(timer);
	timer = null;
	seconds = 5;
};

/**
 * Accumulate and save translation keys
 * @param {string} translationKey
 * @param {object} translateKeyData
 */
const saveTranslationKeys = (translationKey, translateKeyData) => {
	translationKeys[translationKey] = translateKeyData;

	if (timer) return;

	timer = setInterval(() => {
		--seconds;
		if (seconds === 0) {
			restartTimer();
			saveRequest();
		}
	}, 1000);
};

export default saveTranslationKeys;
