/* eslint-disable no-param-reassign */
import { makeFetchDataParams } from 'utils';
import { callRequest, getPathFiltersParameters, getQueryFiltersParameters } from 'utils/request';
import { updateUrlFilters } from 'utils/location';
import { executeGetAsyncDependencies } from 'modules/asyncDependencies/actions';
import * as types from './types';

export const createPage = schema => ({
	type: types.CREATE_PAGE,
	schema
});

export const actionGoBack = boolean => ({
	type: types.GO_BACK,
	payload: boolean
});

export const fetchMainDataSuccess = data => ({
	type: types.FETCH_MAIN_DATA_SUCCESS,
	data
});

export const cleanListData = () => ({
	type: types.CLEAN_LIST_DATA
});

export const fetchWarehouseDataSuccess = data => ({
	type: types.FETCH_WAREHOUSE_DATA_SUCCESS,
	data
});

export const setDataLoading = (section, isLoading) => ({
	type: types.SET_LOADING,
	isLoading,
	section
});

export const setDataError = (section, status) => ({
	type: types.SET_ERROR,
	section,
	status
});

const getCurrentState = (state = {}) => {
	const { createRoute = {} } = state;
	return createRoute;
};

const mainSection = 'mainInfo';
const warehouseSection = 'warehouseInfo';

export const getMainData = (
	appliedFilters,
	sorting,
	pageNumber = 1,
	applyFiltersToggle,
	hasRequiredFilters,
	isFetching
) => async (dispatch, getState) => {
	isFetching.current = true;

	const { schema = {} } = getCurrentState(getState());
	const {
		shippingData: { source = {}, filters: schemaFilters = [], endpointParameters = {} },
		dependencies,
		name
	} = schema;

	const { filters } = makeFetchDataParams(appliedFilters);
	const { filters: queryFilters } = getQueryFiltersParameters(endpointParameters);
	const pathParameters = getPathFiltersParameters(endpointParameters);

	const data = {
		filters: {
			...queryFilters,
			...filters
		}
	};
	const hasFilter = !!Object.keys(filters).length;
	const headers = { 'x-janis-page': hasFilter ? 1 : pageNumber };

	updateUrlFilters({ filters, ...sorting }, schemaFilters);

	if (hasRequiredFilters) {
		isFetching.current = false;

		dispatch(cleanListData());
		return null;
	}

	const setLoading = isLoading => dispatch(setDataLoading(mainSection, isLoading));

	setLoading(true);
	try {
		const responseData = await callRequest(
			source,
			{ ...sorting, filters: data.filters },
			pathParameters,
			headers
		);

		if (dependencies && responseData?.length)
			dispatch(executeGetAsyncDependencies(dependencies, () => responseData, name, 'browse'));
		dispatch(
			fetchMainDataSuccess({
				responseData,
				hasFilter,
				applyFiltersToggle
			})
		);
		isFetching.current = false;
		return responseData;
	} catch ({ response = {} }) {
		dispatch(setDataError(mainSection, { status: response.status }));
		isFetching.current = false;
	}
};

const getWarehouseData = () => async (dispatch, getState) => {
	const { schema = {} } = getCurrentState(getState());

	const {
		warehouseData: { source = {}, endpointParameters = {} }
	} = schema;

	const { filters } = getQueryFiltersParameters(endpointParameters);
	const pathParameters = getPathFiltersParameters(endpointParameters);

	const setLoading = isLoading => dispatch(setDataLoading(warehouseSection, isLoading));

	setLoading(true);

	try {
		const responseData = await callRequest(source, { filters }, pathParameters);
		dispatch(fetchWarehouseDataSuccess(responseData));
	} catch ({ response = {} }) {
		dispatch(setDataError(warehouseSection, { status: response.status }));
	}
};

export const initPage = () => (dispatch, getState) => {
	const { filters = {}, page } = getState();
	const { appliedFilters } = filters.createRoute || {};
	const { schema } = page;

	dispatch(createPage(schema));
	dispatch(getWarehouseData(appliedFilters));
};

export const selectWarehouse = warehouse => ({ type: types.SELECT_WAREHOUSE, warehouse });

export const changeSelectedRowsRoute = (allRowsSelected, selectedRows) => ({
	type: types.CHANGE_SELECTED_ROWS,
	allRowsSelected,
	selectedRows
});
export const isChangeCreateStep = isCreateRouteStep => ({
	type: types.CHANGE_STEP,
	isCreateRouteStep
});

export const sendDataCarrier = dataCarrier => ({
	type: types.SEND_DATA_CARRIER,
	dataCarrier
});

export const nextPage = () => ({
	type: types.NEXT_PAGE
});

export const cleanState = () => ({
	type: types.CLEAN_STATE
});
