import React, { useState, useEffect } from 'react';
import translationHOC from 'hocs/translationHOC';
import { difference } from 'lodash';
import { parse } from 'qs';
import AuthWrapper from 'components/AuthWrapper';
import Header from './components/Header';
import PreAuthError from './components/PreAuthError';
import PreFooter from './components/PreFooter';
import ScopeDetails from './components/ScopeDetails';
import { authorize, translateScope } from './utils';

/* eslint-disable camelcase */
const getAuthorizeParams = ({ client_id, redirect_uri, response_type, ...params }) => {
	const authParams = {
		client_id,
		redirect_uri,
		response_type
	};

	const optionalParams = ['scope', 'state', 'code_challenge', 'code_challenge_method'];
	return optionalParams.reduce((acum, param) => {
		if (!params[param]) {
			return acum;
		}

		return {
			...acum,
			[param]: params[param]
		};
	}, authParams);
};
/* eslint-enable */

const Authorize = ({ t }) => {
	const [isLoading, toggleLoading] = useState(true);
	const [errorData, setError] = useState({ error: false, preAuthError: false, message: '' });
	const [authData, setAuthData] = useState();

	const requiredParams = ['client_id', 'redirect_uri', 'response_type'];

	/** All the query string params */
	const params = parse(window.location.search, { ignoreQueryPrefix: true });

	/** Check if all the required params are set */
	const paramsOk = requiredParams.length === requiredParams.filter(param => params[param]).length;

	/** List the missing params in an array */
	const missingParams = difference(requiredParams, Object.keys(params));

	const { scope } = params;

	const { error, preAuthError, message: errorMessage } = errorData;

	const callAuthorize = method => {
		authorize(method, getAuthorizeParams(params), { setAuthData, setError, toggleLoading });
	};

	useEffect(() => {
		if (paramsOk) {
			callAuthorize('pre-authorize');
		} else {
			setError({
				error: true,
				preAuthError: true,
				message: `${t('common.message.missingParameters')}:`
			});

			toggleLoading(false);
		}
	}, []);

	return (
		<AuthWrapper isLoading={isLoading} isAuthScreen>
			{/* Display an error instead of the content in case of pre-authorize failure */}
			{preAuthError && <PreAuthError missingParams={missingParams} errorMessage={errorMessage} />}

			{authData && (
				<>
					<Header
						appName={authData.name}
						appLogo={authData.logoUrl}
						error={error && errorMessage}
					/>

					{scope && <ScopeDetails appName={authData.name} scope={translateScope(scope)} />}

					<PreFooter callAuthorize={callAuthorize} qsParams={params} />
				</>
			)}
		</AuthWrapper>
	);
};

export default translationHOC(Authorize);
