import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import { Transition } from 'react-spring/renderprops';
import styled from './styles';

// To avoid unnecessary dependencies.
const capitalize = string => string.replace(string[0], string[0].toUpperCase());
class Toast extends PureComponent {
	timeout = false;

	state = {
		isFocused: false,
		isOpen: true
	};

	componentDidMount() {
		this.timeout = setTimeout(() => {
			if (!this.state.isFocused) {
				this.setState({ isOpen: false });
			}
			clearTimeout(this.timeout);
			this.timeout = false;
		}, this.props.timeout);
	}

	componentWillUnmount() {
		clearTimeout(this.timeout);
		this.timeout = false;
	}

	checkTimeoutOnLeave = () => {
		if (!this.timeout) {
			this.setState({ isOpen: false, isFocused: false });
		}
	};

	alertType = (type, customTitle) => {
		const { defaultConfig } = this.props;
		const defaultTitle = (type && defaultConfig[type].title) || type || '';
		const title = customTitle || !type ? customTitle : capitalize(defaultTitle);

		switch (type) {
			case 'error':
				return { title, icon: 'cross_big_circle_bold' };
			case 'warning':
				return { title, icon: 'exclamation_triangle_bold' };
			case 'notice':
				return { title, icon: 'info_circle_bold' };
			case 'success':
				return { title, icon: 'check_circle_bold' };
			default:
				return { title };
		}
	};

	render() {
		const { id, index, message, removeAlert, title, type } = this.props;
		return (
			<Transition
				items={this.state.isOpen}
				from={{ transform: 'translate3d(0,-40px,0)', opacity: 0 }}
				enter={{ transform: 'translate3d(0,0px,0)', opacity: 1 }}
				leave={{ transform: 'translate3d(0,-40px,0)', opacity: 0 }}
				delay={index * 100}
				onDestroyed={() => {
					setTimeout(() => {
						removeAlert(id);
					});
				}}
			>
				{show =>
					show &&
					(props => (
						<styled.ToastBody
							index={index}
							isOpen={this.state.isOpen}
							type={type}
							style={props}
							onMouseEnter={() => this.setState({ isFocused: true })}
							onMouseLeave={() => this.checkTimeoutOnLeave()}
						>
							{(title || type) && (
								<styled.TitleWrapper>
									{type && <Icon name={this.alertType(type).icon} color="white" />}
									<styled.Title hasIcon={!!type}>{this.alertType(type, title).title}!</styled.Title>
								</styled.TitleWrapper>
							)}
							<styled.Message titleHasIcon={!!type}>{message}</styled.Message>
							{type && (
								<styled.CrossIcon
									name="cross_light"
									color="white"
									size={24}
									onClick={() =>
										this.setState({
											isOpen: false
										})
									}
								/>
							)}
						</styled.ToastBody>
					))
				}
			</Transition>
		);
	}
}

Toast.propTypes = {
	/** Valores por default para títulos según el tipo de alerta.
	 * (En caso de necesitar traducciones y no tener que definir un title repetido en cada instancia) */
	defaultConfig: PropTypes.shape({
		error: PropTypes.shape({ title: PropTypes.string }),
		warning: PropTypes.shape({ title: PropTypes.string }),
		notice: PropTypes.shape({ title: PropTypes.string }),
		success: PropTypes.shape({ title: PropTypes.string })
	}),
	/** Id para identificar la alerta dentro del array y eliminarla. */
	id: PropTypes.string,
	/** Index de cada alerta para asignarles un delay individual y que no se muestren todas en el mismo instante. */
	index: PropTypes.number,
	/** Mensaje. */
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	/** Función que debe eliminar la alerta a cerrarse del array de alertas.
	 * Se le pasa la prop id para identificar la alerta. */
	removeAlert: PropTypes.func,
	/** Tiempo (en milisegundos) que tarda en desaparecer automáticamente la notificación. */
	timeout: PropTypes.number,
	/** Título custom para usar en alertas sin tipo o para modificar el título por defecto (el tipo de la alerta) */
	title: PropTypes.string,
	/** Tipo de alerta, puede ser "error" (roja), "warning" (amarilla), "notice" (celeste), o "success" (verde). */
	type: PropTypes.oneOf(['error', 'warning', 'notice', 'success'])
};

export default Toast;
