import { scrollToTop } from 'utils';
import { getCurrentBrowse } from '../actions-utils';
import { fetchData } from './data';

export const fetchNextPage = (currentBrowse, scrollContainer) => async (dispatch, getState) => {
	const state = getCurrentBrowse(getState(), currentBrowse);
	scrollContainer.scrollTo(0, 0);

	if (state.isFetching) return false;

	await dispatch(fetchData({ page: state.page + 1, currentTotal: state.total })(currentBrowse));
	scrollToTop();
};

export const fetchPrevPage = (currentBrowse, scrollContainer) => async (dispatch, getState) => {
	const state = getCurrentBrowse(getState(), currentBrowse);
	scrollContainer.scrollTo(0, 0);
	if (state.isFetching) return false;

	await dispatch(fetchData({ page: state.page - 1, currentTotal: state.total })(currentBrowse));
	scrollToTop();
};

export const fetchPage = (page, currentBrowse, scrollContainer) => async (dispatch, getState) => {
	const state = getCurrentBrowse(getState(), currentBrowse);
	scrollContainer.scrollTo(0, 0);
	// if it's fetching data or ask for currentPage
	if (state.isFetching || page === state.page) return false;

	await dispatch(fetchData({ page, currentTotal: state.total })(currentBrowse));
	scrollToTop();
};
