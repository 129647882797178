import { isObject } from 'utils';
import {
	createElement,
	appendElement,
	checkIfScriptExist,
	deleteScript,
	hasToLoadScript,
	checkIfScriptExistById
} from './utils';

/**
 * The function `loadScripts` loads scripts based on specified parameters, such as environments, paths,
 * and script name, and returns the appended element.
 * @param {{
 * id: string,
 * script: string,
 * source: string,
 * environments: [string],
 * paths: {include: [string], exclude: [string]
 * }}} data - data to create script
 * @param {string} currentLocation - currentLocation to match
 * @returns The function `loadScripts` returns the result of calling the `appendElement` function with
 * the result of calling the `createElement` function with the `script` parameter.
 */
const loadScript = (data = {}, currentLocation) => {
	try {
		if (!isObject(data) || !Object.keys(data).length) return null;

		if (!currentLocation) return null;

		const { environments = [], paths, script, src, id } = data;
		if (!Array.isArray(environments)) return null;
		if (!id) return null;

		const idString = String(id);

		if (!src && !script) return null;

		const stringScript = String(script || '');
		const stringSrc = String(src || '');
		if (
			(checkIfScriptExist(id, stringSrc, stringScript) || checkIfScriptExistById(id)) &&
			!hasToLoadScript(String(currentLocation), environments, paths)
		) {
			return deleteScript(id);
		}

		if (checkIfScriptExist(idString, stringSrc, stringScript)) return null;
		if (!hasToLoadScript(String(currentLocation), environments, paths)) return null;

		return appendElement(createElement(stringScript, stringSrc, idString));
	} catch (reason) {
		return null;
	}
};

/**
 * The function `loadScripts` loads multiple scripts based on the provided data and current location.
 * @param {Array.<{}>} scriptsData - An array of script data objects. Each object represents a script to be loaded
 * and should have the following properties:
 * @param {string} currentLocation - The currentLocation parameter is the location or URL of the current page or
 * script. It is used to determine the base URL for loading the scripts.
 * @returns the result of the `forEach` method, which is `undefined`.
 */
const loadScripts = (scriptsData = [], currentLocation) => {
	if (!scriptsData || !Array.isArray(scriptsData)) return null;

	const validParams = scriptsData.filter(
		script => isObject(script) && !!Object.keys(script).length
	);

	if (!validParams.length) return null;

	return validParams.forEach(data => loadScript(data, currentLocation));
};

export default loadScripts;
