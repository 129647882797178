/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/Text';
import Skeleton from 'react-loading-skeleton';
import translationHOC from 'hocs/translationHOC';
import { translateHelperString } from 'utils/translate';
import { normalize } from 'utils/string';
import styled from './styles';

const parseURl = url => {
	const parsedUrl = url.split('?')[0];
	return parsedUrl.charAt(0) !== '/' ? `/${url}` : parsedUrl;
};

const formatText = (search, word) => {
	if (!word.length) return;
	const translateWord = translateHelperString(`views.menu.${word}`);
	return normalize(translateWord).replace(
		new RegExp(`(${normalize(search.trim())})`, 'i'),
		'<strong>$1</strong>'
	);
};

const SearchResult = ({ t, responseSearch, isLoading, searchText }) => {
	if (isLoading)
		return (
			<styled.LoadContainer>
				<styled.SkeletonWrapper margin="10px 0 20px">
					<Skeleton width="30%" height="12px" />
				</styled.SkeletonWrapper>
				<styled.SkeletonWrapper>
					<Skeleton width="100%" height="12px" />
				</styled.SkeletonWrapper>
				<styled.SkeletonWrapper>
					<Skeleton width="70%" height="12px" />
				</styled.SkeletonWrapper>
			</styled.LoadContainer>
		);

	return responseSearch.length ? (
		<>
			<styled.SubTitle fontSize="medium" fontWeight="medium">
				{t('views.search.pages')}
			</styled.SubTitle>
			<styled.SearchResult>
				{responseSearch.map(
					({ url, id, name }) =>
						url && (
							<styled.ResultItem key={`${id}_${name}`}>
								<styled.Link href={url}>
									<Text icon="circle_flat" iconColor="blue" color="black" fontSize="medium">
										<styled.Strong
											dangerouslySetInnerHTML={{ __html: formatText(searchText, name) }}
										/>
									</Text>
									<styled.ItemUrl fontSize="medium">{parseURl(url)}</styled.ItemUrl>
								</styled.Link>
							</styled.ResultItem>
						)
				)}
			</styled.SearchResult>
		</>
	) : (
		!!searchText && (
			<styled.NoResult>
				<Text fontSize="base" color="darkGrey">
					{t('views.search.notResults')}
				</Text>
			</styled.NoResult>
		)
	);
};

SearchResult.propTypes = {
	responseSearch: PropTypes.arrayOf(PropTypes.shape({})),
	isLoading: PropTypes.bool,
	textStrong: PropTypes.string,
	searchText: PropTypes.string
};
SearchResult.defaultProps = {
	responseSearch: [],
	isLoading: false,
	textStrong: '',
	searchText: ''
};

export default translationHOC(SearchResult);
