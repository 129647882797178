/* eslint-disable no-console */
import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { getAuthCookieDecoded } from 'utils/auth';
import { useLocation } from 'react-router-dom';
import Axios from 'axios';
import Cookies from 'js-cookie';
import { DOMAIN } from 'config/endpoints';

const URL_WEBSOCKET = `wss://websocket.notification${DOMAIN}`;

export const WebSocketContext = createContext(null);

// REFACTOR provisional add client to apply websocket only to carrefour for MVP
const { tcode: janisClient = '' } = getAuthCookieDecoded() || {};

// REFACTOR provisional load websocket in specific path for MVP

const WithWebSocket = ({ children }) => {
	const [websocketInfo, setWebsocketInfo] = useState(null);
	const [wsToken, setWsToken] = useState('');

	const workerRef = useRef(null);
	const { pathname } = useLocation();
	const enablePaths = ['/delivery/shipping/pick-up', '/delivery/shipping/express'];
	const activeViews = enablePaths.includes(pathname);

	const getAuthToken = async () => {
		const secret = Cookies.get('JANIS_ACCESS_TOKEN');

		try {
			const { data: apiData } = await Axios.get(`https://notification${DOMAIN}/api/access-token`, {
				headers: {
					'Janis-Api-Secret': secret,
					'Janis-Api-Key': 'Bearer',
					'Janis-Client': janisClient
				}
			});
			setWsToken(apiData.token);
		} catch (err) {
			console.warn({ err });
			setWsToken('');
		}
	};

	/**
	 * Handles messages received from a shared worker, parsing and processing the data accordingly.
	 * If a request for a new token is received, it triggers the retrieval of a new authentication token.
	 * Parses JSON data and sets WebSocket information based on the response.
	 * @param {MessageEvent} event - The event containing the data from the shared worker.
	 */
	const handleMessage = event => {
		let dataToJson = null;
		const { data: dataSharedWorker } = event;
		const { requestNewToken } = dataSharedWorker;

		if (requestNewToken) {
			getAuthToken();
		}

		try {
			dataToJson = JSON.parse(dataSharedWorker);
		} catch (reason) {
			dataToJson = String(dataSharedWorker);
		}

		const response = dataToJson.details || dataToJson;

		if (response) {
			const parseResponse = typeof response === 'string' ? JSON.parse(response) : response;
			parseResponse.event = dataToJson.event || '';

			setWebsocketInfo(parseResponse);
		}
	};

	/**
	 * Handles  SharedWorker errors by logging the error details.
	 * @param {Event} event - The event containing the error details.
	 */
	const handleError = async event => {
		console.error('error SharedWorker :>> ', event);
	};

	/**
	 * Instantiates a Shared Worker and provides the necessary data for WebSocket setup.
	 * Manages messages and errors received from the Shared Worker.
	 * @returns {void}
	 */
	const sharedWorkerEventManager = () => {
		try {
			if (typeof SharedWorker === 'undefined') {
				throw new Error('This browser does not support Shared Workers');
			}

			if (!wsToken || !activeViews) return;

			workerRef.current = new SharedWorker(`/static/js/webSocketWorker.js`, {
				name: 'webSocketWorker'
			});

			if (workerRef.current) {
				workerRef.current.port.start();

				workerRef.current.port.postMessage({
					URL: URL_WEBSOCKET.concat(`?authToken=${wsToken}`),
					janisClient,
					enablePaths: activeViews
				});

				workerRef.current.port.onmessage = handleMessage;

				workerRef.current.port.onerror = handleError;
			}
		} catch (error) {
			console.error(error.message);
		}
	};

	useEffect(() => {
		if (!wsToken && activeViews) {
			getAuthToken();
		}
	}, []);

	useEffect(
		() => {
			sharedWorkerEventManager();
		},
		[wsToken]
	);

	return <WebSocketContext.Provider value={websocketInfo}>{children}</WebSocketContext.Provider>;
};

WithWebSocket.propTypes = {
	children: PropTypes.node.isRequired
};

WithWebSocket.displayName = 'WithWebSocket';

export const useWebSocket = () => useContext(WebSocketContext);

export default WithWebSocket;
