import React from 'react';
import Categories from './Categories';
import Subcategories from './Subcategories';
import { categoryMenu as styled } from './styles';
import EmptySearchText from './EmptySearchText';

const CategoryMenu = () => (
	<styled.CategoryMenu>
		<Categories />
		<Subcategories />
		<EmptySearchText />
	</styled.CategoryMenu>
);

export default CategoryMenu;
