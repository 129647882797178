import { callRequest, extendConfigForArrays } from 'utils/request';
import ms from './ms';

/**
 * Api for get user data
 * @param {string} id - the user id
 * @param {object} source - source specific
 * @return {object}
 * @example getUserData() // { janisClient: "fizzmod" }
 */
export const getUserData = async (id, source) => {
	const endpoint = source || {
		service: 'id',
		namespace: 'public-user',
		method: 'list'
	};
	// default source is id/public-user

	const { method } = endpoint;

	const response =
		method === 'list'
			? await callRequest(endpoint, { filters: { id } })
			: await callRequest(endpoint, null, { id });

	// when it's api list, use id as filter and return first element
	return Array.isArray(response) ? response[0] : response;
};

/**
 * Api for Login user
 * @return {object}
 * @example LoginUser()
 * 	{
 *		"auth": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXV...",
 *		"loggedKey": "nURWdXeX4b5tkaOH5cFIZ5Abuirxc7HF4hEEab47"
 *	}
 */
export const loginUser = async data => {
	try {
		const response = await ms.post({
			service: 'id',
			namespace: 'user',
			method: 'login',
			data
		});

		return response;
	} catch ({ response }) {
		throw response;
	}
};

/**
 * Api for confirm/reject user access and set a password
 * @param {string} url - the schema provided url
 * @param {object} data - { token: string, confirm: boolean, password: string }
 * @return {object}
 */

export const userConfirmation = async args => {
	try {
		const response = await ms.post(args);
		return response;
	} catch ({ response }) {
		throw response;
	}
};

/**
 *  Api for recover password by email
 * @param {object} data - can either be { email, state } or just { email }
 */
export const recoveryPassword = async data => {
	try {
		const response = await ms.post({
			data,
			service: 'id',
			namespace: 'user',
			method: 'password-recovery'
		});

		return response;
	} catch ({ response }) {
		throw response;
	}
};

/**
 * Api for get janis clients
 */
export const getClients = async page =>
	ms.get({
		data: {
			sortBy: 'name',
			sortDirection: 'asc',
			filters: { status: 'active' }
		},
		headers: {
			'x-janis-page-size': 60,
			'x-janis-page': page
		},
		service: 'id',
		namespace: 'client',
		method: 'list',
		extendConfig: extendConfigForArrays()
	});

/**
 * Api for change janis client
 * @param {string} client
 */
export const changeClient = client =>
	ms.post({
		service: 'id',
		namespace: 'user',
		method: 'changeClient',
		data: { client }
	});

export const getClientsByName = async search =>
	ms.get({
		data: {
			sortBy: 'name',
			sortDirection: 'asc',
			filters: { status: 'active', name: search }
		},
		service: 'id',
		namespace: 'client',
		method: 'list',
		extendConfig: extendConfigForArrays()
	});
