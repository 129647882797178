import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions } from 'modules/navbar';
import { actions as menuActions } from 'modules/menu';
import Icon from 'components/Icon';
import { iconName as iconNamePropType } from 'utils/prop-types';
import { useDevices } from 'utils/hooks';
import { goToURL } from 'utils/location';
import { translateHelperString } from 'utils/translate';
import SearchModal from './SearchModal';
import ProfileModule from './ProfileModule';
import { configModules as styled } from './styles';

const Module = ({ icon, activeHover, text, onClick, isActive }) => {
	const { onlyDesktop } = useDevices();
	const mobileColor = isActive ? 'blue' : 'darkGrey';
	const isSearch = text === 'search';
	return (
		<styled.ModuleContainer
			onClick={onClick}
			isActive={isActive}
			activeHover={isSearch || activeHover}
		>
			<styled.IconContainer>
				<Icon name={icon} color={onlyDesktop ? 'white' : mobileColor} />
			</styled.IconContainer>
			{onlyDesktop && <styled.Text>{translateHelperString(`common.action.${text}`)}</styled.Text>}
		</styled.ModuleContainer>
	);
};

Module.propTypes = {
	activeHover: PropTypes.bool,
	icon: iconNamePropType,
	text: PropTypes.string,
	onClick: PropTypes.func,
	isActive: PropTypes.bool
};

const ConfigModules = ({
	openNavbar,
	closeMenu,
	isOpen,
	goToHome,
	isProfileDetailOpen,
	isOpenSearch,
	openSearch,
	closeSearch
}) => {
	const { tabletLg: onlyMobile } = useDevices();
	const toggleMenu = () => (isOpen ? closeMenu(true, onlyMobile) : openNavbar(onlyMobile));
	const toggleSearch = () => (isOpenSearch ? closeSearch() : openSearch());

	return (
		<styled.Container>
			{onlyMobile && (
				<Module
					icon="home"
					isActive={window.location.pathname === '/' && !isOpen && !isProfileDetailOpen}
					onClick={goToHome}
				/>
			)}
			{/* <Module icon="gear" text="CONFIGURACIONES" />
			<Module icon="bell" text="NOTIFICACIONES" /> */}
			<Module
				icon="complaint"
				text="news"
				activeHover
				onClick={() => goToURL('https://docs.janis.im/changelog', '_blank')}
			/>
			<Module icon="search" text="search" isActive={isOpenSearch} onClick={toggleSearch} />
			<SearchModal toggleSearch={toggleSearch} isOpen={isOpenSearch} />
			{onlyMobile && <Module icon="menu" isActive={isOpen} onClick={toggleMenu} />}
			<ProfileModule />
		</styled.Container>
	);
};

ConfigModules.propTypes = {
	goToHome: PropTypes.func,
	openNavbar: PropTypes.func,
	closeMenu: PropTypes.func,
	isOpen: PropTypes.bool,
	isProfileDetailOpen: PropTypes.bool,
	isOpenSearch: PropTypes.bool,
	openSearch: PropTypes.func,
	closeSearch: PropTypes.func
};

const mapStateToProps = state => ({
	isOpen: state.navbar.isOpen,
	isProfileDetailOpen: state.navbar.isProfileDetailOpen,
	isOpenSearch: state.navbar.isOpenSearch
});

const mapDispatchToProps = {
	openNavbar: actions.enter,
	closeNavbar: actions.leave,
	closeMenu: menuActions.closeMenu,
	openSearch: actions.openSearch,
	closeSearch: actions.closeSearch
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ConfigModules);
