import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import Title from './Title';
import TextContent from './TextContent';
import ProgressBar from './ProgressBar';
import styled from './styles';

const UnpaidPopup = ({ closePopup, time, invalidTime }) => (
	<styled.PopupRef>
		<styled.PopupBg />
		<styled.PopupContainer timeValid={!invalidTime}>
			<Title />
			<TextContent />
			{invalidTime ? (
				<styled.CloseButton onClick={closePopup}>
					<Icon name="cross_light" color="black" size={20} />
				</styled.CloseButton>
			) : (
				<ProgressBar value={time} />
			)}
		</styled.PopupContainer>
	</styled.PopupRef>
);

UnpaidPopup.propTypes = {
	closePopup: PropTypes.func.isRequired,
	invalidTime: PropTypes.bool.isRequired,
	time: PropTypes.number
};

export default UnpaidPopup;
