import Text from 'components/Text';
import styled from 'styled-components';
import { colors, font, mixins } from 'theme';

export default {
	SearchResult: styled.ul`
		list-style: none;
		max-height: 270px;
		${mixins.scrollbar(colors.lightGreyPressed)}
		overflow-y: scroll;
	`,
	SubTitle: styled(Text)`
		letter-spacing: 1px;
		text-transform: uppercase;
		margin: 20px 0;
	`,
	ResultItem: styled.li`
		cursor: pointer;
		padding: 10px 3px;
		height: 65px;
		&:hover {
			background-color: ${colors.lightGreyHover};
		}
	`,
	Link: styled.a`
		width: 100%;
	`,
	ItemUrl: styled(Text)`
		margin-top: 10px;
		margin-left: 25px;
		font-weight: 300;
	`,
	LoadContainer: styled.div`
		width: 100%;
		height: 90px;
		margin: 13px auto;
	`,
	SkeletonWrapper: styled.div`
		margin: ${({ margin }) => margin || '8px auto'};
	`,
	Strong: styled.div`
		font-weight: bold;
		font-size: ${font.medium};
		& > strong {
			color: ${colors.blue};
		}
	`,
	NoResult: styled.div`
		margin: 20px 0 40px;
	`
};
