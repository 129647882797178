import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectors, actions } from 'modules/menu';
import { renderCategories } from './Categories';
import { subcategories as styled } from './styles';

const Subcategories = ({ categories, closeMenu, enter, isDev, selectCategory }) => (
	<styled.Subcategories enter={enter}>
		{renderCategories(categories, selectCategory, closeMenu, isDev)}
	</styled.Subcategories>
);
Subcategories.propTypes = {
	categories: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			children: PropTypes.arrayOf(
				PropTypes.shape({
					name: PropTypes.string
				})
			)
		})
	),
	closeMenu: PropTypes.func,
	enter: PropTypes.bool.isRequired,
	isDev: PropTypes.bool,
	selectCategory: PropTypes.func
};

Subcategories.defaultProps = {
	isDev: false,
	selectCategory: () => null
};

const mapStateToProps = state => ({
	categories: selectors.getCurrentFilteredSubcategories(state),
	enter: !!selectors.getSelectedCategory(state),
	isDev: state.user.isDev
});
const mapDispatchToProps = {
	closeMenu: actions.close,
	selectCategory: actions.addSelectedCategory
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Subcategories);
