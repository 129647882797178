import * as types from './types';

const mergeState = (state, action, properties) => ({
	...state,
	[action.name]: {
		...state[action.name],
		...properties
	}
});

export default function reducer(state = {}, action) {
	switch (action.type) {
		case types.FETCH_DATA_REQUEST:
			return mergeState(state, action, {
				isFetching: true,
				isReady: false,
				hasError: false
			});

		case types.FETCH_DATA_SUCCESS:
			return mergeState(state, action, {
				isFetching: false,
				currentData: action.data,
				isReady: true,
				error: false,
				errorData: {}
			});

		case types.FETCH_DATA_FAILURE:
			return mergeState(state, action, {
				isFetching: false,
				isReady: true,
				error: true,
				errorData: action.status
			});

		case types.RELOAD_DATA:
			return mergeState(state, action, {
				reloadMonitor: action.reloadMonitor
			});

		default:
			return state;
	}
}
