import styled from 'styled-components';
import { colors } from 'theme';
import Button from 'components/Button';
import { mediaBreaks } from 'devices';

const { onlyDesktop, tabletLg: onlyMobile } = mediaBreaks;

export default {
	Actions: styled.div`
		display: flex;
		align-items: center;
		flex-direction: column;
	`,
	Button: styled(Button)`
		width: 100%;
		margin-bottom: 10px;
		${props =>
			props.variant === 'outlined' &&
			`
				color: ${colors.darkGrey};

				&:hover {
					color: ${colors.darkGreyHover};
				};

				&:active {
					color: ${colors.darkGreyPressed};
				}
			`}
	`,
	FieldsContainer: styled.div`
		display: flex;
		flex-direction: column;
		${onlyDesktop`
			margin-top: 35px;
		`}
		${onlyMobile`
			margin-top: 20px;
		`}
	`
};
