import { fetchMenu } from 'modules/menu/actions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'utils';
import { normalize } from 'utils/string';
import { translateHelperString } from 'utils/translate';
import useDevices from './useDevices';

const parseURL = (url = '') =>
	url
		.split('?')[0]
		.split('/')
		.slice(1, 3)
		.join('');

const useSearch = () => {
	const { tabletLg: onlyMobile } = useDevices();
	const dataMenu = useSelector(state => state.menu.source);
	const isDev = useSelector(state => state.user.isDev);
	const [dataResponse, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();

	const hasDataMenu = () => {
		if (!dataMenu.length && onlyMobile) dispatch(fetchMenu());
	};

	useEffect(() => {
		hasDataMenu();
	}, []);

	const getMenuItems = items =>
		items.reduce((accum, itemMenu) => {
			accum.push(itemMenu);

			if (itemMenu.children && itemMenu.children.length) {
				const itemsChildren = getMenuItems(itemMenu.children);
				return [...accum, ...itemsChildren];
			}

			return accum;
		}, []);

	const menuData = getMenuItems(dataMenu);
	const mergeDataMenu = [...new Set(menuData)];

	const menuFilderedIsDev = !isDev
		? mergeDataMenu.filter(({ onlyDev }) => !onlyDev)
		: mergeDataMenu;

	const filteredSearch = (searchText, menu = []) =>
		menu.filter(category => {
			const name = translateHelperString(`views.menu.${category.name}`);
			const categoryIncludesSearchTerm = normalize(name.toLowerCase()).includes(
				normalize(searchText.trim().toLowerCase())
			);

			const categoryURL = parseURL(category.url);

			const categoryIncludesSearchTermUrl = normalize(categoryURL.toLowerCase()).includes(
				normalize(searchText.trim().toLowerCase())
			);

			return !!categoryIncludesSearchTerm || !!categoryIncludesSearchTermUrl;
		});

	const debouceResult = delay => debounce(() => setIsLoading(false), delay);

	const getMenuCategories = searchText => {
		if (!searchText) {
			setIsLoading(false);
			return setData([]);
		}
		setIsLoading(true);

		const results = filteredSearch(searchText, menuFilderedIsDev);

		if (results.length || (!results.length && searchText)) debouceResult(400)();
		setData(results);
	};

	return {
		getMenuCategories,
		dataResponse,
		isLoading
	};
};

export default useSearch;
