import { useEffect } from 'react';
import { useDevices } from 'utils/hooks';

// https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
const setMobileScrollLock = (isScrollLock, ref = {}) => {
	const element = ref.current;

	if (!element) return;

	const windowScrollY = window.scrollY;

	if (!isScrollLock) {
		const elementScrollY = element.style.top;

		element.style.position = '';
		element.style.top = '';

		window.scrollTo(0, window.parseInt(elementScrollY || '0') * -1);
	} else {
		element.style.position = 'fixed';
		element.style.top = `-${windowScrollY}px`;
	}
};

/**
 *
 * @param {boolean} isScrollLock
 * @param {object} ref
 * @returns {null}
 */
const useMobileScrollLock = (isScrollLock, ref) => {
	const { tabletLg: onlyMobile } = useDevices();

	useEffect(
		() => {
			if (onlyMobile) setMobileScrollLock(isScrollLock, ref);
		},
		[isScrollLock]
	);

	return null;
};

export default useMobileScrollLock;
