import { camelCase } from 'lodash';
import { stringify, parse } from 'qs';
import { getAuthCookieData } from 'utils/auth';
import { goToURL } from 'utils/location';
import { map } from 'utils/mappers';
import { translateHelperString as translateHelper } from 'utils/translate';
import { callRequest } from 'utils/request';

const goToLogin = () => {
	const { pathname, search, hash } = window.location;
	const redirURL = encodeURIComponent(`${pathname}${search}${hash}`);

	const { login_hint: loginHint } = parse(search, { ignoreQueryPrefix: true });

	const searchParams = loginHint ? { redir: redirURL, login_hint: loginHint } : { redir: redirURL };

	window.location.href = `/login${stringify(searchParams, { addQueryPrefix: true })}`;
};

/** Process authorization requests (pre-authorize or authorize) *
 * @param {string} method - the source method (either pre-authorize or authorize)
 * @param {object} qsparams - the params from the query string that should be sent to the API
 * @param {object} actions - the state update actions to perform ({ setAuthData, setError, toggleLoading })
 */
export const authorize = async (method, qsparams, actions) => {
	const { setAuthData, setError, toggleLoading } = actions;

	toggleLoading(true);

	const isPreAuthorize = method === 'pre-authorize';

	const params = isPreAuthorize ? qsparams : stringify(qsparams);
	const headers = isPreAuthorize ? {} : { 'content-type': 'application/x-www-form-urlencoded' };

	try {
		// Call fn for set janis-client and check if logged
		getAuthCookieData();

		const response = await callRequest(
			{ service: 'id', namespace: 'oauth', method },
			params,
			{ v: '2.0' },
			{ ...headers },
			true
		);

		const { redirectUrl } = response;

		if (redirectUrl) return goToURL(redirectUrl);

		if (isPreAuthorize) {
			setAuthData(response);
			toggleLoading(false);
		}
	} catch (error) {
		const { response = {} } = error;
		const { data, status } = response;

		if (status === 401) return goToLogin();

		const message = data.message
			? translateHelper(data.message, data.messageVariables)
			: map('statusError', status);

		setError({ error: true, preAuthError: isPreAuthorize, message });

		toggleLoading(false);
	}
};

/** Parses and translates the full scope keys.
 * @param {string} key - The complex scope key (ex: 'oms:order:read', 'oms:order:*' or 'oms:*:*')
 * @returns {string} - The whole translation of the three separate parts of the scope key.
 */
const parseScope = key => {
	const [service, entity, action] = key.split(':');

	const translatedAction = translateHelper(
		`id.oauth.action.${action === '*' ? 'allActions' : action}`
	);
	const translatedService = translateHelper(`id.oauth.service.${service}`);
	const translatedEntity = translateHelper(
		`id.oauth.entity.${entity === '*' ? 'allEntities' : entity}`
	);

	return `${translatedAction} ${translatedService} ${translatedEntity}`;
};

/** Turns the scope elements into their translated versions.
 * @param {string} scope - scope param string from url.
 * Can be simple (ex: 'profile' or 'offline_access') or complex (ex: 'oms:order:read', 'oms:order:*' or 'oms:*:*')
 * @returns {array} - an array with all the translated scope elements ready for rendering.
 */
export const translateScope = scope =>
	scope.split(' ').map(key => {
		if (key.includes(':')) return parseScope(key);

		return translateHelper(`id.oauth.scope.${camelCase(key)}`);
	});
