/* eslint-disable no-param-reassign */
import { makeFetchDataParams } from 'utils';
import { callRequest, getPathFiltersParameters, getQueryFiltersParameters } from 'utils/request';
import { updateUrlFilters } from 'utils/location';
import { addAlert } from 'modules/alerts/actions';
import simulateRouteByOptimize from 'services/tms/simulateRoute';
import { schema as createRouteSchema } from '../../pages/CreateRouteV2/schema';
import * as types from './types';

export const createPage = schema => ({
	type: types.CREATE_PAGE,
	schema
});

export const actionGoBack = boolean => ({
	type: types.GO_BACK,
	payload: boolean
});

export const fetchMainDataSuccess = data => ({
	type: types.FETCH_MAIN_DATA_SUCCESS,
	data
});

export const cleanListData = () => ({
	type: types.CLEAN_LIST_DATA
});

export const setDataLoading = (section, isLoading) => ({
	type: types.SET_LOADING,
	isLoading,
	section
});

export const setDataError = (section, status) => ({
	type: types.SET_ERROR,
	section,
	status
});
export const setStops = (section, stops) => ({
	type: types.SET_STOPS,
	section,
	stops
});

export const setRouteData = (section, routeData) => ({
	type: types.SET_ROUTE_DATA,
	section,
	routeData
});

export const updateFields = (section, fields) => ({
	type: types.UPDATE_SEVERAL_FIELDS,
	section,
	fields
});

const getCurrentState = (state = {}) => {
	const { createRoute = {} } = state;
	return createRoute;
};

export const changeSelectedRowsRoute = (allRowsSelected, selectedRows) => ({
	type: types.CHANGE_SELECTED_ROWS,
	allRowsSelected,
	selectedRows
});

export const isChangeCreateStep = isCreateRouteStep => ({
	type: types.CHANGE_STEP,
	isCreateRouteStep
});

export const sendDriverData = driverData => ({
	type: types.SEND_DRIVER_DATA,
	driverData
});

export const cleanState = () => ({
	type: types.CLEAN_STATE
});

export const stopsColorByWarehouseId = payload => ({
	type: types.STOPS_COLOR,
	payload
});

export const getMainData = (
	appliedFilters,
	sorting,
	pageNumber = 1,
	applyFiltersToggle,
	hasRequiredFilters,
	isFetching
) => async (dispatch, getState) => {
	isFetching.current = true;

	const { schema = {} } = getCurrentState(getState());
	const { source = {}, filters: schemaFilters = [], endpointParameters = [] } = schema;

	const { filters } = makeFetchDataParams(appliedFilters) || {};
	const { filters: queryFilters } = getQueryFiltersParameters(endpointParameters) || {};
	const pathParameters = getPathFiltersParameters(endpointParameters);

	const data = {
		filters: {
			...queryFilters,
			...filters
		}
	};
	const hasFilter = !!Object.keys(filters).length;
	const headers = { 'x-janis-page': hasFilter ? 1 : pageNumber };

	updateUrlFilters({ filters, ...sorting }, schemaFilters);

	if (hasRequiredFilters) {
		isFetching.current = false;

		dispatch(cleanListData());
		return null;
	}

	dispatch(setDataLoading('mainInfo', true));
	try {
		const responseData = await callRequest(
			source,
			{ ...sorting, filters: data.filters },
			pathParameters,
			headers
		);

		dispatch(
			fetchMainDataSuccess({
				responseData,
				hasFilter,
				applyFiltersToggle
			})
		);
		isFetching.current = false;
		return responseData;
	} catch ({ response = {} }) {
		dispatch(
			addAlert({
				message: response?.data?.message,
				type: 'error',
				id: `shippings-ready-to-route_api_error_message`
			})
		);
		dispatch(setDataError('mainInfo', { status: response.status }));
		isFetching.current = false;
	}
};

export const initPage = () => (dispatch, getState) => {
	const { page } = getState();
	const { schema } = page;
	const { creationStep, target } = createRouteSchema || {};

	dispatch(createPage({ ...schema, creationStep, target }));
};

export const formatStops = (stops, warehouses, shippings) => {
	const formattedStops = stops.reduce((acc, act) => {
		const stop = [];
		if (act.kind === 'warehouse') {
			const currentWarehouse = warehouses[act.warehouseId];
			const isReturn = act.actions[0].type === 'dropoff';
			stop.push({
				...act,
				...currentWarehouse,
				type: 'warehouse',
				action: !isReturn ? act.actions[0].type : 'return',
				coordinates: act.coordinates
			});
		} else {
			const completeActions = act.actions.map(currentAction => ({
				...shippings[currentAction.shippingId],
				...currentAction
			}));
			stop.push({
				...act,
				actions: completeActions,
				type: 'shipping',
				id: crypto.randomUUID()
			});
		}
		return [...acc, ...stop];
	}, []);

	return formattedStops.map(stop => {
		const isWarehouse = stop.type === 'warehouse';
		return {
			...stop,
			stopId: stop.id,
			...(isWarehouse && { name: stop.name })
		};
	});
};

export const getStopsInfo = body => async dispatch => {
	dispatch(setDataLoading('mainInfo', true));
	try {
		const response = await simulateRouteByOptimize(body);

		const { schedule, shippings, warehouses, stops = [], canBeOptimized = {} } = response || {};

		const stopsToRender = formatStops(stops, warehouses, shippings);

		dispatch(
			updateFields('mainInfo', {
				stops: stopsToRender,
				selectedSchedule: schedule,
				canOptimize: !!canBeOptimized?.value
			})
		);
		return stopsToRender;
	} catch ({ response = {} }) {
		dispatch(
			addAlert({
				message: response?.data?.message,
				type: 'error',
				id: `simulate_api_error_message`
			})
		);
		dispatch(
			updateFields('mainInfo', {
				errorStatus: { status: response.status },
				isLoading: false
			})
		);

		return [];
	}
};
