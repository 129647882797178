import { callRequest, getQueryFiltersParameters, getPathFiltersParameters } from 'utils/request';
import { uniqBy } from 'lodash';
import { setRemoteActionsCache } from 'modules/page/actions';
import {
	FETCH_MASSIVE_ACTIONS_REQUEST,
	FETCH_MASSIVE_ACTIONS_SUCCESS,
	FETCH_MASSIVE_ACTIONS_FAILURE,
	SHOW_MASSIVE_ACTIONS_CHECKBOXES,
	CHANGE_SELECTED_ROWS
} from '../types';

const fetchMassiveActionsRequest = () => currentBrowse => ({
	type: FETCH_MASSIVE_ACTIONS_REQUEST,
	meta: { name: currentBrowse }
});

const fetchMassiveActionsSuccess = massiveActions => currentBrowse => ({
	type: FETCH_MASSIVE_ACTIONS_SUCCESS,
	hasMassiveActions: !!massiveActions.length,
	meta: { name: currentBrowse }
});

const fetchMassiveActionsFailure = status => currentBrowse => ({
	type: FETCH_MASSIVE_ACTIONS_FAILURE,
	status,
	meta: { name: currentBrowse }
});

export const fetchMassiveActions = () => currentBrowse => async (dispatch, getState) => {
	const { schema } = getState().page;

	const {
		massiveActions: {
			source,
			endpointParameters = [],
			actions: schemaActions = [],
			title,
			translateTitle
		}
	} = schema;

	dispatch(fetchMassiveActionsRequest()(currentBrowse));

	const saveActions = actions => {
		dispatch(fetchMassiveActionsSuccess(actions)(currentBrowse));

		const cacheActions = { actions, titleSettings: { title, translateTitle } };

		dispatch(setRemoteActionsCache(cacheActions));
	};

	try {
		const filters = getQueryFiltersParameters(endpointParameters, {}, true);
		const pathParameters = getPathFiltersParameters(endpointParameters, {});

		const remoteActions = source ? await callRequest(source, filters, pathParameters) : [];

		const actions = uniqBy([...remoteActions, ...schemaActions], 'name');

		saveActions(actions);
	} catch ({ response }) {
		if (schemaActions.length) saveActions(schemaActions);
		else dispatch(fetchMassiveActionsFailure({ status: response.status })(currentBrowse));
	}
};

export const toggleShowMassiveActionsCheckboxes = () => currentBrowse => dispatch =>
	dispatch({
		type: SHOW_MASSIVE_ACTIONS_CHECKBOXES,
		meta: { name: currentBrowse }
	});

export const changeSelectedRows = (allRowsSelected, selectedRows) => currentBrowse => dispatch =>
	dispatch({
		type: CHANGE_SELECTED_ROWS,
		allRowsSelected,
		selectedRows,
		meta: { name: currentBrowse }
	});
