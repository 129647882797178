import React from 'react';
import styled from './styles';

const Spinner = () => (
	<styled.SpinnerWrapper>
		<styled.Spinner />
	</styled.SpinnerWrapper>
);

export default Spinner;
