import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import translationHOC from 'hocs/translationHOC';
import { Field, reduxForm } from 'redux-form';
import { minLength, maxLength, required } from 'utils/redux-form-validation';
import { userConfirmation } from 'utils/api';
import { setAuthCookie } from 'utils/auth';
import { base64Decode } from 'utils/string';
import { actions as toastActions } from 'modules/alerts';
import { map } from 'utils/mappers';
import { validateURL } from 'utils/url/validateURL';
import InputField from 'components/AuthResources/InputField';
import styled from './styles';

const passwordMinLengthValidator = minLength(8);
const passwordMaxLengthValidator = maxLength(50);

const passwordEqualityValidator = (value, allValues) =>
	value !== allValues.password ? 'common.message.passwordsDoNotMatch' : undefined;

const getRedirURL = (response = {}) => {
	try {
		const { redir } = JSON.parse(base64Decode(response.state));
		const validRedir = validateURL(redir);
		return validRedir;
	} catch {
		return '/';
	}
};

const UserConfirmationForm = props => {
	const {
		addAlert,
		handleSubmit,
		invalid,
		target = {},
		toggleLoader,
		token,
		recovery,
		t,
		translateHelperString
	} = props;

	const submitForm = async (confirm, values = {}) => {
		toggleLoader();

		let data = {};

		if (confirm) {
			data = { confirm, token, password: values.password };
		} else {
			data = { confirm, token };
		}

		const { url } = target;

		const requestData = recovery
			? {
					service: 'id',
					namespace: 'user',
					method: 'changePassword',
					data: { passwordToken: token, password: values.password }
			  }
			: { url, data };

		try {
			const response = await userConfirmation(requestData);

			toggleLoader();

			addAlert({
				message: t(`common.message.${recovery ? 'recoveryPassword' : 'userWelcome'}SuccessMessage`),
				type: 'success',
				id: 'ok'
			});

			setTimeout(() => {
				const { data: responseData = {} } = response;

				if (recovery) {
					setAuthCookie(responseData);
				}

				window.location.href = getRedirURL(responseData);
			}, 3000);
		} catch ({ data: errorData }) {
			const message = errorData.message
				? t(errorData.message, errorData.messageVariables)
				: map('statusError', errorData.status);

			toggleLoader();

			addAlert({ message, type: 'error', id: 'error' });
		}
	};

	const commonProps = { component: InputField, type: 'password', recovery };

	return (
		<form onSubmit={handleSubmit(values => submitForm(true, values))}>
			<styled.FieldsContainer>
				<Field
					name="password"
					validate={[passwordMinLengthValidator, passwordMaxLengthValidator, required]}
					{...commonProps}
				/>

				<Field name="confirmPassword" validate={[passwordEqualityValidator]} {...commonProps} />
			</styled.FieldsContainer>

			<styled.Actions>
				<styled.Button type="submit" variant="contained" color="blue" disabled={invalid}>
					{translateHelperString('common.action.confirm')}
				</styled.Button>

				{!recovery && (
					<styled.Button type="button" variant="outlined" onClick={() => submitForm(false)}>
						{translateHelperString('common.action.reject')}
					</styled.Button>
				)}
			</styled.Actions>
		</form>
	);
};

UserConfirmationForm.propTypes = {
	handleSubmit: PropTypes.func,
	invalid: PropTypes.bool,
	target: PropTypes.shape({
		url: PropTypes.string,
		httpMethod: PropTypes.string
	}),
	token: PropTypes.string,
	toggleLoader: PropTypes.func
};

const mapDispatchToProps = {
	addAlert: toastActions.addAlert
};

const mapStateToProps = state => ({
	target: state.page.schema.target
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	translationHOC(
		reduxForm({
			form: 'userConfirmation'
		})(UserConfirmationForm)
	)
);
