import React from 'react';
import PropTypes from 'prop-types';
import { styles as stylesPropType, iconName as iconNamePropType } from 'utils/prop-types';
import { Button as ButtonComponent } from '@janiscommerce/ui-web';

const Button = ({
	children,
	color,
	icon,
	iconColor,
	iconSize,
	fontColor,
	rounded,
	styles,
	variant,
	...props
}) => (
	<ButtonComponent
		color={color}
		icon={icon}
		iconColor={iconColor || fontColor}
		fontColor={fontColor}
		hasText={!!children && !props.hideLabel}
		rounded={rounded}
		styles={styles}
		variant={variant}
		hasIcon={!!icon}
		tabIndex="0"
		iconSize={iconSize}
		{...props}
	>
		{children}
	</ButtonComponent>
);

Button.propTypes = {
	/** Contenido del boton */
	children: PropTypes.node,
	/** Color del boton */
	color: PropTypes.string,
	/** Nombre del icono */
	icon: iconNamePropType,
	/** Color del icono */
	iconColor: PropTypes.string,
	/** Tamaño del icono */
	iconSize: PropTypes.number,
	/** color del texto */
	fontColor: PropTypes.string,
	/** Si es true, el boton tiene los bordes redondeados */
	rounded: PropTypes.bool,
	styles: stylesPropType,
	variant: PropTypes.oneOf(['cleaned', 'contained', 'outlined'])
};

Button.defaultProps = {
	color: 'blue',
	rounded: false,
	variant: 'cleaned'
};

export default Button;
