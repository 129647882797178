import React from 'react';
import { element, func } from 'prop-types';
import Page, { Header, Title } from 'components/Page';
import translationHOC from 'hocs/translationHOC';
import PageError from 'pages/PageError';
import { getLocationParts } from 'utils/location';
import { camelCase } from 'lodash';

class PageErrorBoundary extends React.PureComponent {
	static propTypes = {
		children: element,
		translateHelperString: func
	};

	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
			notFound: false
		};
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	setPageTitle() {
		// eslint-disable-next-line no-unused-vars
		const [service, namespace, method] = getLocationParts();
		const entityKey = this.props.translateHelperString(`common.entityName.${camelCase(namespace)}`);
		const pageKey = method
			? this.props.translateHelperString(`common.title.${camelCase(method)}`)
			: '';
		const title = `${entityKey} ${pageKey} | JANIS`;

		if (document.title !== title) document.title = title;
	}

	componentDidCatch(error = {}, info) {
		const { code } = error;
		// You can also log the error to an error reporting service
		console.error(error, info);
		this.setState({ notFound: code === 'MODULE_NOT_FOUND' });
	}

	render() {
		const { notFound, hasError } = this.state;

		this.setPageTitle();

		if (hasError) {
			if (notFound) return <PageError error={{ status: 404 }} />;

			// You can render any custom fallback UI
			return (
				<Page>
					<Header>
						<Title>{this.props.t('common.title.welcomeToJanis')}</Title>
					</Header>

					<p>Something went wrong.</p>
				</Page>
			);
		}

		return this.props.children;
	}
}

export default translationHOC(PageErrorBoundary);
