import React from 'react';
import { components } from 'react-select';

export const ControlContext = React.createContext({
	isFocused: false
});

const Control = props => (
	<ControlContext.Provider value={{ isFocused: props.isFocused }}>
		<components.Control {...props} />
	</ControlContext.Provider>
);

export default Control;
