import React, { useState } from 'react';
import PropTypes from 'prop-types';
import translationHOC from 'hocs/translationHOC';
import Skeleton from 'react-loading-skeleton';
import { stringify } from 'qs';
import Image from 'components/Image';
import Spinner from 'components/Spinner';
import { getUrlWithEndpointParameters } from 'utils';
import { useDevices } from 'utils/hooks';
import { base64Encode } from 'utils/string';
import { validateURL } from 'utils/url/validateURL';
import styled from './styles';

const makeUrlProvider = (url, providerName, redirectPath) => {
	let filters = null;

	if (redirectPath) {
		const validURL = validateURL(redirectPath);
		const data = { redir: validURL };
		filters = { state: base64Encode(JSON.stringify(data)) };
	}

	let urlWithParameters = getUrlWithEndpointParameters(url, { name: providerName });

	if (filters) urlWithParameters = `${urlWithParameters}?${stringify(filters)}`;

	return urlWithParameters;
};

const ProvidersSelector = ({
	isLoading,
	providers,
	providerUrl,
	redirectPath,
	translateHelperString
}) => {
	const [isRedirecting, setRedirecting] = useState(false);

	const { onlyDesktop } = useDevices();

	if (!isLoading && (!providerUrl || !providers.length)) return null;

	const goToUrl = url => {
		setRedirecting(true);
		window.location.href = url;
	};

	return (
		<styled.Container>
			{onlyDesktop && <styled.Text>{translateHelperString('login.titles.orElse')}</styled.Text>}

			{isLoading ? (
				<styled.SkeletonWrapper>
					<Skeleton height={42} circle />
				</styled.SkeletonWrapper>
			) : (
				providers.map(({ name, title, logoUrl }) => {
					const url = makeUrlProvider(providerUrl, name, redirectPath);

					return (
						<styled.Button
							key={name}
							variant="outlined"
							color="black"
							disabled={isRedirecting}
							onClick={() => {
								goToUrl(url);
							}}
						>
							{isRedirecting ? (
								<Spinner size={22} />
							) : (
								<styled.Provider>
									<Image url={logoUrl} width={20} />
									{`${translateHelperString('login.action.continueWith')} ${title}`}
								</styled.Provider>
							)}
						</styled.Button>
					);
				})
			)}
		</styled.Container>
	);
};

ProvidersSelector.propTypes = {
	isLoading: PropTypes.bool,
	providers: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			title: PropTypes.string
		})
	),
	providerUrl: PropTypes.string,
	redirectPath: PropTypes.string,
	translateHelperString: PropTypes.func
};

export default translationHOC(ProvidersSelector);
