import { useEffect, useState } from 'react';
import { getVerifiedCookie } from 'utils/auth';
import { useLocation } from 'react-router-dom';

const useUserIsLogged = () => {
	const [userIsLogged, setUserIsLogged] = useState(false);
	const [loading, setLoading] = useState(true);
	const { pathname } = useLocation();

	const fetchData = async () => {
		const logged = await getVerifiedCookie();
		setUserIsLogged(logged);
		setLoading(false);
	};

	useEffect(
		() => {
			fetchData();
		},
		[pathname]
	);

	return {
		userIsLogged,
		loading
	};
};

export default useUserIsLogged;
