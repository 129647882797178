import { isEmpty, isObject, isArray, get, set } from 'lodash';
import { stringify } from 'qs';
import { makeFetchDataParams, getUrlWithEndpointParameters } from 'utils';
import { getValueMapped } from 'utils/mappers';
import ms from 'utils/api/ms';

const uniqueData = (data, dynamicValue) => {
	const flatData = data.flatMap(row => get(row, dynamicValue)).filter(Boolean);
	const uniqData = new Set(flatData);
	return [...uniqData];
};

const getParametersByType = (
	endpointParameters,
	data,
	types,
	useFilters,
	useFalsieValues = true
) => {
	if (!endpointParameters) return null;

	const filteredEndpointParameters = endpointParameters.filter(itemParameters =>
		types.includes(itemParameters.target)
	);

	const useTargetQuery = filteredEndpointParameters.some(
		itemParameters => itemParameters.target === 'query'
	);

	// eslint-disable-next-line no-console
	if (useTargetQuery) console.warn('Deprecation warning for type "query" in endpointParameters');

	const filtersObject = filteredEndpointParameters.reduce(
		(acc, { name, value, target, mapper }) => {
			const accumulator = { ...acc };

			const getDynamicValue = () => {
				if (!isObject(data)) return data;

				const dynamicValue = isArray(data)
					? uniqueData(data, value.dynamic)
					: get(data, value.dynamic);

				const validateValue = val => isObject(val) && 'value' in val && 'label' in val;

				const isArrayValue = isArray(dynamicValue);

				if (validateValue(dynamicValue)) return dynamicValue.value;

				if (isArrayValue) {
					return dynamicValue.length
						? dynamicValue.map(val => (validateValue(val) ? val.value : val))
						: undefined;
				}

				return dynamicValue;
			};

			const rawValue = value.static || getDynamicValue();

			const mappedValue = getValueMapped(rawValue, mapper, data);

			const processedValue =
				!useFalsieValues && (mappedValue === null || mappedValue === '') ? undefined : mappedValue;

			if (target === 'filter') set(accumulator, `filters.${name}`, processedValue);
			else set(accumulator, name, processedValue);

			return accumulator;
		},
		{}
	);

	const isEmptyObj = isEmpty(filtersObject);

	if (isEmptyObj) return null;

	if (useFilters && useTargetQuery) return makeFetchDataParams(filtersObject);

	return filtersObject;
};

/**
 * Process the query type filter and return filters object for call request
 * @param {Array} endpointParameters
 * @param {object} data
 * @param {boolean} useFilters
 */
export const getQueryFiltersParameters = (
	endpointParameters,
	data = {},
	useFilters = false,
	useFalsieValues
) =>
	getParametersByType(
		endpointParameters,
		data,
		['query', 'body', 'filter', 'queryString'],
		useFilters,
		useFalsieValues
	);

/**
 * Process the path type filter and return pathParameters object for call request
 * @param {Array} endpointParameters
 * @param {object} data
 * @param {boolean} useFilters
 */
export const getPathFiltersParameters = (endpointParameters, data = {}) =>
	getParametersByType(endpointParameters, data, ['path']);
/**
 *
default Config for requests with query strings with multivalue arrays
 */
export const extendConfigForArrays = (encode = false) => ({
	paramsSerializer(parameters) {
		return stringify(parameters, { encode, arrayFormat: 'indices' });
	}
});

/**
 *
take endpointParameters and return the necesary format for replace params to path url
 */
export const formattedEndpointParameters = (endpointParameters, data) => {
	const mappedEndpointParameters = () => {
		if (endpointParameters) {
			return Object.keys(endpointParameters).reduce((accum, param) => {
				const currentParam = get(endpointParameters, param);
				const accumulator = { ...accum };
				accumulator[param] = get(data, currentParam);
				return accumulator;
			}, {});
		}

		return {};
	};

	return Array.isArray(endpointParameters)
		? getPathFiltersParameters(endpointParameters, data)
		: mappedEndpointParameters();
};

/**
 *
take endpointParameters and return the necesary format for replace the filters to url
 */
export const filtersFormatted = (endpointParameters, apiMethod, data) => {
	const currentFilters = () =>
		getQueryFiltersParameters(endpointParameters, data, apiMethod === 'list');

	return Array.isArray(endpointParameters) ? currentFilters() : null;
};

/**
 * if some value to params is null or undefined return true
 * @param {object} params
 */
export const isEmptyParam = params =>
	isObject(params) &&
	Object.keys(params).some(
		parameter => params[parameter] === undefined || params[parameter] === null
	);

export const getRouterEndpoint = async (sourceEndpoint, endpointParameters, filters) => {
	const { service, namespace, method } = sourceEndpoint;

	const { endpoint } = await ms.getEndpointData(service, namespace, method);

	let endpointUrl = getUrlWithEndpointParameters(endpoint, endpointParameters);

	const hasFilters = filters && !!Object.keys(filters).length;

	if (hasFilters) {
		const linkHasQuery = endpointUrl.includes('?');
		endpointUrl = `${endpointUrl}${linkHasQuery ? '&' : '?'}${stringify(filters)}`;
	}

	return endpointUrl;
};

/**
 * Method for make common request
 * @param {object} source - sourceEndpoint Object
 * @param {object} dataFilters - Filters or data for request
 * @param {object} endpointParameters - endpointParameters parsed
 * @param {object} headers - headers for request
 * @param {boolean} encode - encode or not params
 * @param {object} customExtendConfig - custom config extend
 */
export const callRequest = async (
	source,
	dataFilters = null,
	endpointParameters = null,
	headers = null,
	encode = false,
	customExtendConfig = null
) => {
	const { httpMethod: methodHttp, url } = source;

	const httpMethod = methodHttp && methodHttp.toLowerCase();

	const config = {
		data: dataFilters,
		endpointParameters,
		headers: headers || {},
		extendConfig: customExtendConfig || extendConfigForArrays(encode)
	};

	const response = url
		? await ms[httpMethod]({ url, ...config })
		: await ms.get({
				...source,
				...config
		  });

	return response.data;
};

/**
 * Call request for find multiple ids
 * This function returns an object with keys by ids with your data
 * If not found some id return id with null value,
 * If fails request return object with keys ids with null values
 *
 * @param {object} source - sourceEndpoint Object
 * @param {array} ids - array of string ids
 * @param {string=id} idField - field name for filter and find in response
 */
export const getListDataByIds = async (source, ids, idField = 'id') => {
	const errorData = ids.reduce((accum, id) => ({ ...accum, [id]: id }), {});

	try {
		const listData = await callRequest(source, {
			filters: { [idField]: ids }
		});

		return ids.reduce(
			(accum, id) => ({
				...accum,
				[id]: listData.find(item => item[idField] === id) || null
			}),
			{}
		);
	} catch (error) {
		return errorData;
	}
};

/**
 * Formatea los endpointParameters y devuelve por separado pathParameters y filtros
 *
 * @param {array} endpointParameters - endpointParameters
 * @param {object} data - current view Data
 */
export const getFormattedEndpointParameters = (endpointParameters, data) => {
	// get params with type path
	const pathParameters = getPathFiltersParameters(endpointParameters, data);

	// get filters with type query
	const rawFilters = getQueryFiltersParameters(endpointParameters, data);

	// formatted filters for the request
	const formattedFilters = rawFilters ? makeFetchDataParams(rawFilters) : rawFilters;

	return {
		endpointParameters: pathParameters || {},
		filters: formattedFilters ? { ...formattedFilters.filters } : {}
	};
};
