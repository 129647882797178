import { callRequest, getQueryFiltersParameters } from 'utils/request';
import { setToStorage, getFromStorage } from 'utils/storage';

const source = {
	service: 'management',
	namespace: 'motive',
	method: 'list'
};

const endpointParameters = [
	{
		name: 'status',
		target: 'filter',
		value: {
			static: 'active'
		}
	},
	{
		name: 'service',
		target: 'filter',
		value: {
			static: 'tms'
		}
	},
	{
		name: 'module',
		target: 'filter',
		value: {
			static: 'driver-availability'
		}
	},
	{
		name: 'action',
		target: 'filter',
		value: {
			static: 'not-available'
		}
	}
];

/**
 * It gets motives from the cache if they are there, otherwise it gets them from the server and
 * caches them
 * @param {string[]}  [ids] - an array of motives ids
 * @returns {[{motive}]} An array of motives.
 */
const getMotivesById = async ids => {
	try {
		if (!Array.isArray(ids) || !ids.length) return [];

		const cache = getFromStorage('motives') || [];
		const motivesInCache = cache.filter(warehouse => ids.includes(warehouse.id));

		const idsWithoutCache = ids.reduce((acc, act) => {
			const warehouse = cache.find(cacheWarehouse => cacheWarehouse.id === act);
			if (!warehouse) return [...acc, { id: act }];
			return acc;
		}, []);

		if (motivesInCache.length && !idsWithoutCache.length) return motivesInCache;

		const filters = getQueryFiltersParameters(endpointParameters, idsWithoutCache);

		const response = await callRequest(source, filters);

		const mergeResponse = [...motivesInCache, ...response];

		setToStorage('motives', mergeResponse, 5);
		return mergeResponse;
	} catch (reason) {
		// eslint-disable-next-line no-console
		console.warn('reason >>', reason);
	}
};

export default getMotivesById;
