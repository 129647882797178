import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDevices } from 'utils/hooks';

const UseDevicesWrapper = ({ children }) => {
	const { onlyDesktop, tabletLg: onlyMobile } = useDevices();

	return (
		<Fragment>
			{children({
				onlyDesktop,
				onlyMobile
			})}
		</Fragment>
	);
};

UseDevicesWrapper.propTypes = {
	children: PropTypes.func
};

export default UseDevicesWrapper;
