import styled from 'styled-components';
import { colors } from 'theme';

const { statusRed, grey } = colors;

export default {
	ProgressFill: styled.progress`
		height: 4px;
		width: ${({ progress, time }) => ((time - progress) / time) * 100}%;
		transition: width 1s linear;
		transform: scaleX(-1);
		&::-webkit-progress-bar {
			background-color: ${statusRed};
			border: none;
		}
		&::-webkit-progress-value {
			background-color: ${grey};
			border: none;
			transform: scaleX(-1);
			transition: width 1s linear;
		}
		&::before {
			content: '';
			position: absolute;
			margin: 2px;
			inset: 0 100% 0 0;
			border-radius: inherit;
			background-color: ${grey};
			transition: width 1s linear;
		}
	`
};
