import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty as isEmptyLodash } from 'lodash';
import { actions, selectors } from 'modules/formSection';
import SectionLoader from 'components/SectionLoader';
import Spinner from 'components/Spinner';
import { content as styled } from './styles';

const FormSection = ({
	fetchData,
	preloadData,
	isEmpty,
	isFetching,
	children,
	isReady,
	preloadDataIsReady,
	schema = {},
	isEditSection
}) => {
	useEffect(
		() => {
			if (fetchData) {
				const data = isEmpty ? {} : preloadData;

				if (!isEmptyLodash(preloadData)) fetchData(preloadData);

				if (!preloadData || isEmpty) fetchData(data);
			}
		},
		[preloadDataIsReady]
	);

	if (isReady) {
		return (
			<>
				{children}
				{isFetching && (
					<styled.SpinnerWrapper>
						<Spinner />
					</styled.SpinnerWrapper>
				)}
			</>
		);
	}

	return <SectionLoader schemaData={schema} isEditSection={isEditSection} />;
};

FormSection.propTypes = {
	isReady: PropTypes.bool,
	preloadDataIsReady: PropTypes.bool,
	isFetching: PropTypes.bool,
	children: PropTypes.node,
	preloadData: PropTypes.shape({}),
	schema: PropTypes.shape({}),
	fetchData: PropTypes.func,
	isEmpty: PropTypes.bool,
	isEditSection: PropTypes.bool
};

const makeStateToProps = () => (state, props) => ({
	isFetching: selectors.getCurrentFormSection(state, props).isFetching,
	isReady: selectors.getCurrentFormSection(state, props).isReady
});

const mapDispatchToProps = (dispatch, props) => ({
	fetchData: data => dispatch(actions.fetchData(data)(props.formSection))
});

export default connect(
	makeStateToProps,
	mapDispatchToProps
)(FormSection);
