import React from 'react';
import PropTypes from 'prop-types';
import Toast from 'components/Toast';
import { connect } from 'react-redux';
import { actions } from 'modules/alerts';

const ToastGlobal = ({ toastAlerts, removeAlert }) => (
	<Toast alerts={toastAlerts} removeAlert={removeAlert} />
);

ToastGlobal.propTypes = {
	removeAlert: PropTypes.func,
	toastAlerts: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			message: PropTypes.string,
			title: PropTypes.string,
			type: PropTypes.oneOf(['error', 'warning', 'notice', 'success'])
		})
	)
};

const mapDispatchToProps = {
	removeAlert: actions.removeAlert
};

const mapStateToProps = state => ({
	toastAlerts: state.alerts.toastAlerts
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ToastGlobal);
