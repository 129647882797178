import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import store from 'store';
import { getAuthCookieDecoded } from 'utils/auth';
import { isValidTranslationKey, formatStringKey } from 'utils/string';
import missingKeyHandler from './utils/missingKeyHandler';
import translationKeyPostProcessor from './utils/translationKeyPostProcessor';
import backend from './utils/backend';

const { locale: tlanguage } = getAuthCookieDecoded() || {};

const language = store.get('preselectedLanguage') || store.get('selectedLanguage') || tlanguage;

if (language) localStorage.setItem('currentLanguage', language);

const detection = {
	order: ['localStorage', 'navigator'],
	lookupLocalStorage: 'currentLanguage',
	caches: ['localStorage']
};

i18n
	.use(LanguageDetector)
	.use(XHR)
	.use(initReactI18next)
	.use(translationKeyPostProcessor)
	.init({
		detection,
		backend,
		fallbackLng: 'en-US',
		load: 'currentOnly',
		debug: false,
		nsSeparator: '.',
		interpolation: {
			escapeValue: false // not needed for react!!
		},
		postProcess: ['translationKeyPostProcessor'],
		// save missing for trigger missingKeyHandler function
		saveMissing: true,
		missingKeyHandler,
		parseMissingKeyHandler(key) {
			return isValidTranslationKey(key, 1) ? formatStringKey(key) : key;
		},
		// react i18next special options (optional)
		react: {
			wait: false,
			bindI18n: 'languageChanged loaded',
			bindStore: 'added removed',
			nsMode: 'fallback',
			useSuspense: false
		}
	});

i18n.on('languageChanged', lng => {
	if (lng !== 'en-US' && !i18n.getDataByLanguage(lng)) {
		i18n.changeLanguage('en-US');
	}
});

export default i18n;
