import React, { PureComponent, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions, selectors } from 'modules/page';
import loadScriptClaspo from 'utils/loadScriptClaspo';
import PageError from 'pages/PageError';
import PageErrorBoundary from './PageErrorBoundary';
import Loading from './Loading';

class PageCustomLoader extends PureComponent {
	componentMapping = {
		'/': lazy(() => import('pages/Home'))
	};

	state = {
		isMounted: false
	};

	componentDidMount() {
		loadScriptClaspo();
		this.props.fetchClientData();
		this.setState({ isMounted: true });
	}

	componentWillUnmount() {
		this.props.handlerChangePage();
	}

	getPage(component) {
		this.componentMapping[component] = lazy(() => import(`pages/${component}`));
		return this.componentMapping[component];
	}

	render() {
		const { isMounted } = this.state;
		const { rootComponent, hasErrors, hasJanisClient, errorDetails, url } = this.props;
		const Component = this.componentMapping[rootComponent || url] || this.getPage(rootComponent);
		const isReadyForRenderComponent = isMounted && hasJanisClient;

		if (hasErrors) return <PageError error={errorDetails} />;

		return isReadyForRenderComponent ? (
			<PageErrorBoundary>
				<Suspense fallback={<Loading />}>
					<Component />
				</Suspense>
			</PageErrorBoundary>
		) : (
			<Loading />
		);
	}
}

PageCustomLoader.propTypes = {
	fetchClientData: PropTypes.func.isRequired,
	handlerChangePage: PropTypes.func.isRequired,
	hasErrors: PropTypes.bool.isRequired,
	errorDetails: PropTypes.shape({
		status: PropTypes.number
	}),
	hasJanisClient: PropTypes.string,
	rootComponent: PropTypes.string,
	url: PropTypes.string
};

const mapStateToProps = state => ({
	hasErrors: selectors.hasErrors(state),
	errorDetails: selectors.errorDetails(state),
	hasJanisClient: state.page.janisClient,
	rootComponent: selectors.getRootComponent(state)
});

const mapDispatchToProps = {
	fetchClientData: actions.fetchClientData,
	handlerChangePage: actions.handlerChangePage
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PageCustomLoader);
