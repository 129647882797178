export const FETCH_DATA_REQUEST = 'formSection/FETCH_DATA_REQUEST';
export const FETCH_DATA_FAILURE = 'formSection/FETCH_DATA_FAILURE';
export const FETCH_DATA_SUCCESS = 'formSection/FETCH_DATA_SUCCESS';
export const SAVE_DATA_REQUEST = 'formSection/SAVE_DATA_REQUEST';
export const SAVE_DATA_FAILURE = 'formSection/SAVE_DATA_FAILURE';
export const SAVE_DATA_SUCCESS = 'formSection/SAVE_DATA_SUCCESS';
export const SET_AFFECTED_FIELD = 'formSection/SET-AFFECTED-FIELD';
export const SET_NAVIGATION_FLAG = 'formSection/SET_NAVIGATION_FLAG';
export const SET_FIELD_COMPONENT = 'formSection/SET_FIELD_COMPONENT';
export const INIT_FORM_SECTION = 'formSection/INIT_FORM_SECTION';
export const UPDATE_FORM_SECTION = 'formSection/UPDATE_FORM_SECTION';
export const DESTROY_FORM_SECTION = 'formSection/DESTROY_FORM_SECTION';
export const SET_REMOTE_FIELDS = 'formSection/SET_REMOTE_FIELDS';
