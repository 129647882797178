import styled from 'styled-components';
import { colors, font, timingFunctions } from 'theme';
import { mediaBreaks } from 'devices';
import Button from 'components/Button';
import { Link } from 'react-router-dom';

const { onlyDesktop, tabletLg: onlyMobile } = mediaBreaks;

export default {
	Container: styled.div`
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;
		${onlyMobile`
			width: 100%;
			max-width: 420px;
			height: 100%;
	`}
	`,
	FieldsContainer: styled.div`
		display: flex;
		flex-direction: column;
		${onlyDesktop`
			margin-top: 18px;
		`}
		${onlyMobile`
			${props => !props.singleField && 'margin-top: 20px;'}
			${props => props.clientForm && 'margin-top: 48px;'}
			height: ${props => (props.hasSubtitle ? '104px' : '120px')};
			justify-content: flex-end;
		`}
	`,
	StyledForm: styled.form`
		height: 100%;
		min-height: 220px;
		display: flex;
		flex-direction: column;
	`,
	LinkContainer: styled.div`
		display: flex;
		color: ${colors.blue};
		font-family: ${font.family};
		font-size: ${font.medium};
		line-height: 20px;
		font-weight: 500;
		justify-content: space-between;
		border-top: 1px solid ${colors.greyHover};
		margin-top: 22px;
		padding-top: 18px;
		> * {
			${props => props.center && 'margin: auto;'}
			transition: all 0.2s ${timingFunctions.standard};
			border-bottom: 1px solid transparent;
			&:hover,
			&:active {
				color: ${colors.blueHover};
				border-bottom-color: ${colors.blueHover};
			}
		}
		${onlyMobile`
			margin-bottom: 0px;
		`}
	`,
	LinkStyled: styled(Link)`
		width: 100%;
		font-family: ${font.family};
		font-size: ${font.medium};
		font-weight: 500;
		color: ${colors.blue};
		transition: color 0.2s ${timingFunctions.standard};
		line-height: 42px;
		text-align: center;
		margin: 10px auto 0;
		&:hover,
		&:active {
			color: ${colors.blueHover};
		}
	`,
	ButtonStyled: styled(Button)`
		font-size: ${font.medium};
		width: 100%;
		height: 42px;
		${props =>
			props.isLoading &&
			`
				pointer-events:none;
				user-select: none;
		`}
		&:first-child:not(:last-child) {
			margin-bottom: 10px;
		}
	`,
	BackToLoginButton: styled.button`
		display: flex;
		align-items: center;
		margin: auto;
		position: relative;
		svg {
			position: absolute;
			left: -24px;
			transition: fill 0.2s ${timingFunctions.standard};
		}
		&:hover,
		&:active {
			svg {
				fill: ${colors.blueHover};
			}
		}
	`,
	ErrorMessage: styled.p`
		color: ${colors.statusRed};
		font-family: ${font.family};
		font-size: ${font.medium};
		line-height: 16px;
		text-align: center;
	`,
	BottomContent: styled.div`
		display: flex;
		flex-direction: column;
		${props => !props.stickToForm && 'margin-top: auto;'}
	`,
	TogglePasswordButton: styled(Button)`
		width: 36px;
		height: 36px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		bottom: ${props => (props.hasError ? 36 : 28)}px;
		right: 0px;
	`
};
