import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@janiscommerce/ui-web';
import { getInitialsTheme, getUserColor } from './utils';

const UserImage = ({ firstname, lastname, mainColor, size, url, rounded }) => {
	const initials = getInitialsTheme(firstname, lastname) || null;
	const currentColor = initials ? getUserColor(initials) : null;

	return (
		<Avatar
			firstname={firstname}
			lastname={lastname}
			mainColor={mainColor || currentColor}
			size={size}
			url={url}
			rounded={rounded}
		/>
	);
};

UserImage.propTypes = {
	firstname: PropTypes.string,
	lastname: PropTypes.string,
	mainColor: PropTypes.string,
	size: PropTypes.oneOfType([
		PropTypes.oneOf(['small', 'medium', 'large', 'extralarge', 'auto']),
		PropTypes.number
	]),
	rounded: PropTypes.bool,
	url: PropTypes.string
};

export default UserImage;
