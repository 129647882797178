import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import translationHOC from 'hocs/translationHOC';
import { parse, stringify } from 'qs';
import { base64Decode } from 'utils/string';
import { setAuthCookie } from 'utils/auth';

const goToPath = (path, query) => {
	window.location.href = `${path}${stringify(query, { addQueryPrefix: true })}`;
};

const goBackToLogin = query => {
	goToPath('/login', query);
};

const ProviderCallback = ({ translateHelperString }) => {
	const genericErrorMessage = translateHelperString('common.message.somethingWentWrong');

	const getAuthenticationData = () => {
		const params = parse(window.location.search, { ignoreQueryPrefix: true });

		const {
			access_token: accessToken,
			id_token: idToken,
			error_description: errorDescription,
			error,
			state
		} = params;

		let stateDecoded;
		try {
			stateDecoded = state ? JSON.parse(base64Decode(state)) : {};
		} catch (err) {
			stateDecoded = {};
		}

		// If exists error in params return to login and show it
		if (error) {
			return goBackToLogin({
				error,
				error_description: errorDescription,
				redir: stateDecoded.redir
			});
		}

		// If not exists any token, return to login with generic error
		if (!(accessToken && idToken)) {
			return goBackToLogin({
				error: genericErrorMessage,
				redir: stateDecoded.redir
			});
		}

		// If exists tokens, set cookies and go to param state.redir path or go to home
		try {
			setAuthCookie(params);
		} catch {
			// If fails sets cookies, return to login with generic error
			return goBackToLogin({
				error: genericErrorMessage,
				redir: stateDecoded.redir
			});
		}

		if (stateDecoded.redir) goToPath(decodeURIComponent(stateDecoded.redir));
		else goToPath('/');
	};

	useEffect(() => {
		getAuthenticationData();
	}, []);

	return <div />;
};

ProviderCallback.propTypes = {
	translateHelperString: PropTypes.func
};

export default translationHOC(ProviderCallback);
