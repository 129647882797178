import styled from 'styled-components';
import Chip from 'components/Chip';
import { colors, font } from 'theme';
import { mediaBreaks } from 'devices';

const { onlyDesktop, onlyPrint } = mediaBreaks;

export default {
	UserChip: styled(Chip)`
		&& {
			pointer-events: initial;
			height: 35px;
			padding-left: ${props => (props.showFull ? '0' : '6px')};
			background: ${({ backgroundColor }) => backgroundColor || colors.white};
			${props =>
				props.showFull
					? `
					max-width: inherit;
					&:hover,
					&:active {
						background: ${props.backgroundColor || colors.white};
					}`
					: `
					cursor: pointer;
			`}
			${props =>
				props.inactive &&
				`
				&:hover {
					background: ${colors.white};
				}
				&:active {
					border-color: #EAEBED;
				}
		`}
		}
	`,
	InnerWrapper: styled.div`
		display: flex;
		line-height: initial;
	`,
	UserImageWrapper: styled.div`
		margin-right: ${props => (props.showFull ? '8px' : '5px')};
		display: flex;
		align-items: center;
		${props => props.inactive && 'opacity: 0.5;'}
	`,
	UserDataWrapper: styled.div`
		margin-top: 2px;
		display: flex;
		flex-direction: column;
		${props => props.isLoading && 'align-items: flex-start;'}
		${props => !props.showFull && 'max-width: 100px;'}
		& > div {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			text-align: left;
		}
		${onlyPrint`
			max-width: initial;
		`}
	`,
	UserName: styled.div`
		color: ${props => (props.inactive ? colors.grey : colors.black)};
		font-size: ${font.base};
		line-height: 16px;
	`,
	UserNameAfterRender: styled.div`
		font-size: ${font.baseSmall};
		line-height: 14px;
		color: ${props => (props.inactive ? colors.grey : colors.darkGrey)};
	`,
	ErrorText: styled.span`
		color: ${colors.darkGrey};
		overflow: hidden;
		text-overflow: ellipsis;
		align-self: center;
	`,
	Tooltip: styled.div`
		min-width: 232px;
		height: 76px;
		border-bottom: 1px solid ${colors.blue};
		padding: 20px 16px;
		box-shadow: 0 2px 5px 0 rgba(39, 57, 71, 0.15);
		position: absolute;
		background: ${colors.white};
		${({ firstRow }) => (firstRow ? 'top:  38px' : 'bottom:  38px')};
		z-index: 1;
		${onlyDesktop`
			left: -25%;
		`}
	`
};
