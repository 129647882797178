import {
	getPathFiltersParameters,
	getQueryFiltersParameters,
	isEmptyParam,
	callRequest
} from 'utils/request';
import { map } from 'utils/mappers';
import { get, set, isEqual } from 'lodash';
import { chunkArray } from './array';

/**
 *
 * @param {Object} param
 * @param response {object}
 * @param getData {object || function}
 * @param callback {function}
 * @param dependency {object}
 * @param sectionType {string}
 * @returns
 */
const getCurrentData = ({
	response,
	getData,
	callback,
	dependency,
	sectionType,
	setEditDependencies
}) => {
	const { name, endpointParameters, targetField, searchMethod = 'find' } = dependency;

	const data = sectionType === 'browse' || sectionType === 'monitor' ? getData() : getData;

	// Edit globalDependencies, formSections, mainForm, Preview, RowPreview
	if (sectionType !== 'browse' && sectionType !== 'monitor') {
		callback(false, name, response, targetField);

		const currentData = { ...data };

		set(currentData, targetField, response);
		if (setEditDependencies) setEditDependencies({ [targetField]: response });
		return currentData;
	}

	// Browse and Monitor
	const compareRowValues = (responseItem, value) =>
		Array.isArray(value)
			? value.some(item => isEqual(item, responseItem))
			: isEqual(value, responseItem);

	const currentData = data.reduce((accum, row) => {
		const res = response[searchMethod](item =>
			endpointParameters.every(({ name: paramName, value, mapper }) => {
				const dynamicValue = get(row, value.dynamic);

				const rawValue = value.static || dynamicValue;
				const rowValueToFilter = mapper ? map(mapper, rawValue) : rawValue;
				const responseItem = get(item, paramName);

				return compareRowValues(responseItem, rowValueToFilter);
			})
		);

		if (
			targetField &&
			row[targetField] &&
			(row[targetField].length || Object.entries(row[targetField]).length)
		)
			return [...accum, row];

		if (res) set(row, targetField, res);

		return [...accum, row];
	}, []);

	callback(false, name, currentData);

	return getData;
};

const processMultipleResponses = async (source, filters, pathParameters) => {
	let page = 1;

	const fetchAndMergeRecursively = async value => {
		if (value.length < 60) return value;

		page++;

		const response = await callRequest(source, filters, pathParameters, { 'x-janis-page': page });
		const mergeResponse = [...value, ...response];

		if (response.length < 60) return mergeResponse;

		return fetchAndMergeRecursively(mergeResponse);
	};

	const firstResponse = await callRequest(source, filters, pathParameters);

	if (!Array.isArray(firstResponse)) return firstResponse;

	const accumResponse = await fetchAndMergeRecursively(firstResponse);

	return accumResponse;
};

/**
 *
 * @param {Array} dependencies - async dependencies
 * @param {Array or Object} currentViewData - current view data
 * @param {function} callback - funcion para realizar al finalizar cada request
 */
export const getAsyncDependencies = (
	dependencies = [],
	getData,
	callback,
	sectionType,
	nestedError = false,
	auxData,
	dataPerColumn
) =>
	dependencies.forEach(async dependency => {
		const { name, endpointParameters, source, dependencies: innerDependencies = [] } = dependency;

		try {
			// handle innerDependencies errors
			if (nestedError) throw new Error('Inner dependencies error');

			let data = sectionType === 'browse' || sectionType === 'monitor' ? getData() : getData;

			if (sectionType === 'monitor' && auxData?.length) {
				data = auxData;
			}
			const getDependencies = async actualData => {
				const pathParameters = getPathFiltersParameters(endpointParameters, actualData);

				const filters = getQueryFiltersParameters(endpointParameters, actualData, true) || {};

				if (
					isEmptyParam(pathParameters) ||
					isEmptyParam(filters) ||
					('filters' in filters && isEmptyParam(filters.filters))
				)
					return callback('skip', name);

				const response = await processMultipleResponses(source, filters, pathParameters);

				const currentDataParams = {
					response,
					getData,
					callback,
					dependency,
					sectionType,
					setEditDependencies: sectionType === 'formSection' && auxData
				};

				if (sectionType === 'monitor' && auxData?.length) {
					currentDataParams.getData = () => auxData;
				}
				const currentData = getCurrentData({ ...currentDataParams });

				if (innerDependencies.length && currentData) {
					return getAsyncDependencies(
						innerDependencies,
						currentData,
						callback,
						sectionType,
						false,
						auxData
					);
				}
			};

			if (sectionType === 'monitor' && dataPerColumn?.length) {
				dataPerColumn.map(async columnData => {
					if (columnData.length > 60) {
						const columnSubArrays = chunkArray(columnData);
						columnSubArrays.forEach(columnSubArray => {
							getDependencies(columnSubArray);
						});
					}
					getDependencies(columnData);
				});
				return;
			}
			getDependencies(data);
		} catch ({ message }) {
			console.error(`get dependencies error -${message}`);

			callback(message, name);

			if (innerDependencies.length)
				return getAsyncDependencies(innerDependencies, null, callback, sectionType, message);
		}
	});
