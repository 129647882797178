import React from 'react';
import PropTypes from 'prop-types';
import { currentEnvironment } from 'config/environment';
import Icon from 'components/Icon';
import { janisLogo as styled } from './styles';

const getIconName = () => {
	if (currentEnvironment === 'beta') return 'logo_janis_dev';
	if (currentEnvironment === 'QA') return 'logo_janis_qa';

	return 'logo_janis';
};

const JanisLogo = ({ isOpen }) => (
	<styled.Container isOpen={isOpen}>
		{isOpen ? (
			<Icon
				name={getIconName()}
				color="darkGreyPressed"
				width={130}
				height={20}
				viewBox="0 0 5100 1024"
			/>
		) : (
			<Icon name="iso_janis" color="darkGreyPressed" size={34} />
		)}
	</styled.Container>
);

JanisLogo.propTypes = {
	isOpen: PropTypes.bool
};

export default JanisLogo;
