import React, { useState } from 'react';
import { connect } from 'react-redux';
import { actions as toastActions } from 'modules/alerts';
import { callRequest } from 'utils/request';
import { translateHelperComponent as t } from 'utils/translateComponent';
import { map } from 'utils/mappers';
import Modal from './Modal';

const TranslateWrapper = ({ children, args, addAlert }) => {
	const [isOpen, toggleModal] = useState(false);
	const [item, setItem] = useState([]);

	const fetchData = async () => {
		try {
			const response = await callRequest(
				{
					service: 'i18n',
					namespace: 'translate',
					method: 'list'
				},
				{ filters: { fullKey: args } }
			);

			setItem(response);
			toggleModal(true);
		} catch ({ response = {} }) {
			const { data = {} } = response;

			const message = data.message
				? t(data.message, data.messageVariables)
				: map('statusError', data.status);

			addAlert({ type: 'error', id: 'error', message });
		}
	};

	const handleClick = e => {
		e.preventDefault();
		e.stopPropagation();
		fetchData();
	};

	return (
		<>
			<span
				onClick={handleClick}
				style={{ borderBottom: '1px solid red', cursor: 'pointer' }}
				onKeyDown={() => {}}
				role="button"
				tabIndex={0}
			>
				{children}
			</span>
			<Modal isOpen={isOpen} toggleModal={toggleModal} item={item} />
		</>
	);
};

const mapDispatchToProps = {
	addAlert: toastActions.addAlert
};

export default connect(
	null,
	mapDispatchToProps
)(TranslateWrapper);
