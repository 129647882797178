import { flow } from 'lodash';

const { demodulize, underscore, humanize } = require('i')();

/**
 * Normalize a string removing accents
 * check out {@link https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript/37511463#37511463}
 */
export const normalize = str => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

/**
 * Encode string to base64
 * @param {string} stringVal
 */
export const base64Encode = stringVal => btoa(stringVal);

/**
 * Decode string base64
 * @param {string} stringVal
 */
export const base64Decode = stringVal => atob(stringVal);

/**
 * Check if is a translation key
 * @param {string} value
 * @returns {boolean}
 */
export const isTranslationKey = value =>
	!!value && typeof value === 'string' && value.split('.').filter(Boolean).length >= 3;

/**
 * Check if text have max dots quantity
 * @param {string} text
 * @param {number} maxDots
 * @returns {boolean}
 * @example
 * maxDotsValidation('tracking.machine', 1)
 * return true
 * maxDotsValidation('tracking', 1)
 * return false
 */
const maxDotsValidation = (text, maxDots) => {
	if (!text) return;
	const regex = /\./g;
	const matches = text.match(regex);
	return matches?.length <= maxDots;
};

/**
 * Check if translation key is valid
 * @param {string} key
 * @param {number} minDots
 * @param {number} maxDots
 * @returns {boolean}
 */
export const isValidTranslationKey = (key, minDots = 2, maxDots = null) => {
	const isValidSize = maxDots ? maxDotsValidation(key, maxDots) : false;
	if (maxDots && !isValidSize) return false;

	const regex = new RegExp(`([A-Za-z0-9]+\\.){${minDots},}[A-Za-z0-9]+$`, 'g');
	regex.lastIndex = 0;
	return regex.test(key) && !/ /.test(key);
};

const formatStringFn = flow([demodulize, underscore, humanize]);

/**
 * Format string for humanization
 * @param {string} value
 * @returns {string}
 */
export const formatStringKey = value => formatStringFn(value);

/**
 * Format string with dash for translate
 * @param {string} string
 * @returns {string}
 * @example
 * formatStringWithDash('file-delete')
 * return 'File delete'
 */
export const formatStringWithDash = string =>
	string.replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase());

/**
 * Return a translate created by params
 * @param {string} label
 * @param {boolean | string} translateValue
 * @param {string} firstTwoLevels
 * @returns {object}
 * @example
 * formatTranslate( 'tracking-machine', true, 'views.sectionfield' )
 * return {
    "label": "views.labelfield.trakingMachine",
    "isTranslate": true
}
 */
export const formatTranslate = (label, translateValue, firstTwoLevels) => {
	if (isTranslationKey(label)) return { label, isTranslate: true };
	if (
		(typeof translateValue === 'string' && !isValidTranslationKey(translateValue, 1, 1)) ||
		!translateValue
	)
		return { label, isTranslate: false };

	const formattedLabel = formatStringWithDash(label);
	const firstLabels = typeof translateValue === 'boolean' ? firstTwoLevels : translateValue;

	return (typeof translateValue === 'string' && !isValidTranslationKey(translateValue, 1, 1)) ||
		!translateValue
		? { label, isTranslate: false }
		: { label: `${firstLabels}.${formattedLabel}`, isTranslate: true };
};
