import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { colors, mixins, timingFunctions, font, hideOnPrinter } from 'theme';
import { mediaBreaks } from 'devices';
import LinkComponent from 'components/Link';

const { onlyDesktop, tabletLg: onlyMobile } = mediaBreaks;

export const openedNavbarWidth = 220;
export const closedNavbarWidth = 64;
export const mobileBarHeight = 45;

export const ModuleContainerStyles = css`
	width: 100%;
	text-align: left;
	${mixins.flexCenter}
	${onlyDesktop`
		min-height: 36px;
		margin-top: 8px;
		flex-shrink: 0;
		&:first-child {
			margin-top: 0;
		}
	`}
`;

export const IconContainer = styled.div`
	${mixins.flexCenter}
	flex-shrink: 0;
	${onlyDesktop`
		width: ${closedNavbarWidth}px;
	`}
	${onlyMobile`
		width: 29px;
		margin-right: 16px;
	`}
`;

export const Text = styled.span`
	flex-grow: 1;
	color: ${colors.white};
`;

export const background = {
	Background: styled.div`
		background-color: rgba(39, 57, 71, 0.15);
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		position: fixed;
		z-index: 9;
		visibility: hidden;
		pointer-events: ${props => (props.isVisible ? 'auto' : 'none')};
		transition-timing-function: ${timingFunctions.standard};
		transition-property: opacity, visibility;
		transition-duration: 150ms;
		opacity: 0;

		${props =>
			props.isVisible &&
			css`
				transition-duration: 200ms;
				opacity: 1;
				visibility: visible;
			`}
	`
};

export const configModules = {
	Container: styled.div`
		display: flex;
		margin-top: auto;
		${onlyDesktop`
			width: ${openedNavbarWidth}px;
			flex-direction: column;
	`}
		${onlyMobile`
			width: 100%;
			border-top: 1px solid ${colors.lightGrey};
		`}
	`,
	IconContainer: styled.div`
		${onlyDesktop`
			width: 64px;
			text-align: center;

		`}
		${onlyMobile`
			height: 24px;
		`}
	`,
	ModuleContainer: styled.button`
		${ModuleContainerStyles}
		${props =>
			props.isActive &&
			onlyMobile`
			border-bottom: 2px solid ${colors.blue}
		`}
		${({ activeHover }) =>
			activeHover &&
			css`
				transition: background-color 0.2s ease-in-out;
				${onlyDesktop`
					&:hover {
						background-color: ${colors.blackHover};
					}
				`}
			`}
	`,
	Text: styled(Text)`
		font-size: 11px;
		text-transform: uppercase;
	`
};
export const selectModule = css`
	&:after {
		content: '';
		display: block;
		position: absolute;
		right: 0;
		border-top: 7px solid transparent;
		border-bottom: 7px solid transparent;
		border-right: 7px solid ${colors.white};
	}
`;
export const navbar = {
	Nav: styled.nav`
		display: flex;
		position: fixed;
		left: 0;
		overflow: hidden;
		width: 100%;
		z-index: 10;
		${onlyDesktop`
			flex-direction: column;
			max-width: ${props => (props.isOpen ? openedNavbarWidth : closedNavbarWidth)}px;
			top: 0;
			height: 100vh;
			background-color: ${colors.black};
			transition: max-width 0.2s ${timingFunctions.standard};
			${props =>
				!props.isOpen &&
				css`
					transition-duration: 0.15s;
				`}
			`}
		${onlyMobile`
				background-color: ${colors.white};
				right: 0;
				bottom: 0;
				height: ${mobileBarHeight}px;
		`}
		${hideOnPrinter}
	`,
	Menu: styled.div`
		padding: 12px 0;
		border-top: 1px solid ${colors.blackHover};
		border-bottom: 1px solid ${colors.blackHover};
	`
};

const ModuleContainerLink = styled(LinkComponent)`
	${ModuleContainerStyles}
	${props =>
		props.isActive &&
		onlyMobile`
			border-bottom: 2px solid ${colors.blue}
	`}
	${onlyDesktop`
		background-color: ${props => props.isSelected && rgba('#fff', 0.2)};
		${mixins.transition('background-color', '150ms')};
		flex-wrap: wrap;

		&:hover {
			background-color: ${rgba('#fff', 0.2)};
			transition-duration: 200ms;
		}
		${props => props.isSelected && selectModule}
	`}

	${onlyMobile`
		height: 48px;
		flex-shrink: 0;
	`}

	a {
		display: flex;
		width: 100%;
		align-items: center;
	}
`;

export const modules = {
	Container: styled.div`
		position: relative;
		display: flex;
		flex-direction: column;
		margin-bottom: auto;
		${onlyDesktop`
			width: ${openedNavbarWidth}px;
			padding:  12px 0;
			${mixins.scrollbar(colors.darkGrey, colors.black)}
			overflow-y: auto;
		`}
		${onlyMobile`
			width: 100%;
			padding: 70px 15px 0;
			flex-shrink: 0;
		`}
	`,
	ModuleContainerLink,
	ModuleContainerButton: ModuleContainerLink.withComponent('button'),
	iconColor: colors.white,
	IconContainer,
	Text: styled(Text)`
		flex-shrink: 0;
		font-size: ${font.medium};
	`
};

export const janisLogo = {
	Container: styled.div`
		flex-shrink: 0;
		${mixins.flexCenter}
		width:  ${props => (props.isOpen ? openedNavbarWidth : closedNavbarWidth)}px;
		height: 65px;
	`
};

export const mobileMenu = {
	Wrapper: styled.div`
		background-color: ${colors.black};
		display: flex;
		flex-direction: column;
		position: fixed;
		z-index: 9;
		left: 0;
		right: 0;
		height: calc(100% - ${mobileBarHeight}px);
		overflow-y: auto;
	`,
	Header: styled.div`
		color: ${colors.white};
		font-size: ${font.medium};
		line-height: 16px;
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-bottom: 1px solid ${colors.blackHover};
		margin-bottom: 10px;
		flex-shrink: 0;
		position: fixed;
		background: ${colors.black};
		width: 100%;
		top: 0;
		z-index: 1;
	`
};
