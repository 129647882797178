import i18n from 'i18next';
import store from 'store';
import { isValidTranslationKey } from 'utils/string';
import saveTranslationKeys from './saveTranslationKeys';

const namespaces = {};
const keys = {};

const useDateTranslations = store.get('useDateTranslations');

const missingKeyHandler = (lng, ns, key) => {
	const [namespace] = key.split('.');

	// Find current namespace and check if not get ns again
	if (!namespaces[namespace] && !namespaces[ns] && !/ /.test(namespace))
		namespaces[namespace] = i18n.loadNamespaces(namespace);

	// if useDateTranslations is true not create a missing keys here
	if (useDateTranslations) return;

	if (namespaces[ns]) {
		namespaces[ns].then(() => {
			const translationKey = `${ns}.${key}`;

			if (!keys[translationKey]) {
				keys[translationKey] = true;

				if (!isValidTranslationKey(translationKey)) return;

				if (!i18n.exists(translationKey) && process.env.NODE_ENV !== 'development') {
					const translationKeyData = { key, namespace: ns };

					saveTranslationKeys(translationKey, translationKeyData);
				}
			}
		});
	}
};

export default missingKeyHandler;
