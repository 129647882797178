import { isObject, debounce, makeFetchDataParams } from 'utils';
import { updateUrlFilters, getParsedParams, getCurrentHistory } from 'utils/location';
import { makeDateTimePickerRelativeValues } from 'utils/dates';
import * as types from './types';

const history = getCurrentHistory();

const isEmptyValue = value => value === '' || value === null;

export const getCurrentValue = option =>
	isObject(option) && 'value' in option ? option.value : option;

export const handleApplyFiltersToggle = () => currentFilters => (dispatch, getState) => {
	const state = getState();

	const { appliedFilters, useUrlFilters, useUpdateUrlFilters, filters } = state.filters[
		currentFilters
	];

	const currentParams = makeFetchDataParams(appliedFilters);

	if (useUrlFilters && useUpdateUrlFilters) updateUrlFilters(currentParams, filters);

	dispatch({
		type: types.APPLY_FILTERS_TOGGLE,
		meta: { name: currentFilters }
	});
};

export const addFilters = filters => currentFilters => ({
	type: types.ADD_FILTERS,
	meta: { name: currentFilters },
	filters
});

export const changeFilter = (name, value) => currentFilters => ({
	type: types.CHANGE_FILTER,
	meta: { name: currentFilters },
	name,
	value
});

export const changeFilters = values => currentFilters => ({
	type: types.CHANGE_FILTERS,
	values,
	meta: { name: currentFilters }
});

const clearFilter = name => currentFilters => ({
	type: types.CLEAR_FILTER,
	meta: { name: currentFilters },
	name
});

export const clearAllFiltersAction = () => currentFilters => ({
	type: types.CLEAR_ALL_FILTERS,
	meta: { name: currentFilters }
});

export const useUrlFiltersAction = (useUrlFilters, useUpdateUrlFilters) => currentFilters => ({
	type: types.USE_URL_FILTERS,
	meta: { name: currentFilters },
	useUrlFilters,
	useUpdateUrlFilters
});

export const isReadyAction = isReady => currentFilters => ({
	type: types.IS_READY,
	meta: { name: currentFilters },
	isReady
});

const fetchDataDebounced = debounce(
	(dispatch, currentFilters) => dispatch(handleApplyFiltersToggle()(currentFilters)),
	500
);

export const setApplyFiltersFlag = value => currentFilters => ({
	type: types.APPLY_FILTERS,
	meta: { name: currentFilters },
	value
});

export const clearSingleFilter = name => currentFilters => dispatch => {
	dispatch(clearFilter(name)(currentFilters));
	dispatch(handleApplyFiltersToggle()(currentFilters));
	dispatch(setApplyFiltersFlag(true)(currentFilters));
};

export const clearAllFilters = () => currentFilters => dispatch => {
	dispatch(clearAllFiltersAction()(currentFilters));
	dispatch(handleApplyFiltersToggle()(currentFilters));
	dispatch(setApplyFiltersFlag(true)(currentFilters));
};

export const changeFilterHelper = (
	name,
	currentValue,
	applyFilterValue = false
) => currentFilters => dispatch => {
	if (isEmptyValue(currentValue)) dispatch(clearFilter(name)(currentFilters));
	else dispatch(changeFilter(name, currentValue)(currentFilters));

	dispatch(setApplyFiltersFlag(applyFilterValue)(currentFilters));
};

/**
 * Action to apply filters simultaneously
 * @param {object} values
 */
export const changeAndApplyFilters = values => currentFilters => dispatch => {
	dispatch(changeFilters(values)(currentFilters));
	dispatch(handleApplyFiltersToggle()(currentFilters));
};

/**
 * Action to apply filters one by one
 * @param {string} name
 * @param {string|number|array} currentValue
 */
export const changeAndApplyFilter = (name, currentValue) => currentFilters => dispatch => {
	dispatch(changeFilterHelper(name, currentValue)(currentFilters));
	dispatch(handleApplyFiltersToggle()(currentFilters));
};

/**
 * Action to apply filters one by one debounced
 * @param {string|number|array} currentValue
 */
export const changeAndApplyFilterDebounced = (name, currentValue) => currentFilters => dispatch => {
	dispatch(changeFilterHelper(name, currentValue)(currentFilters));
	fetchDataDebounced(dispatch, currentFilters);
};

/**
 * Check current filters in URL for dispatch actions for change filters in store
 * @param {object} parsedParams
 */
export const checkUrlFilter = parsedParams => currentFilters => (dispatch, getState) => {
	const { filters: stateFilters } = getState();

	const filtersModule = stateFilters[currentFilters];

	const filtersComponents = filtersModule.filters || [];

	Object.keys(parsedParams).forEach(keyParam => {
		const valueParam = parsedParams[keyParam];

		if (keyParam === 'filters') {
			const filters = valueParam;
			const filterKeys = Object.keys(filters);

			filterKeys.forEach(key => {
				const currentFilterComponent = filtersComponents.find(filter => filter.name === key);
				const filterValue = makeDateTimePickerRelativeValues(currentFilterComponent, filters);

				dispatch(changeFilter(key, filterValue || filters[key])(currentFilters));
			});
		}
	});
};

/**
 * Set initial filters values for filters applied filters
 * @param {object} values
 */
export const setInitialFiltersValues = (values, defaultValues) => currentFilters => ({
	type: types.ADD_INITIAL_FILTERS_VALUES,
	meta: { name: currentFilters },
	values,
	defaultValues
});

export const createFilters = name => ({
	type: types.CREATE_FILTERS,
	name
});

export const deleteFilters = key => ({
	type: types.DELETE_FILTERS,
	name: key
});

/**
 * History listener for refresh filters and data
 */
const historyListener = currentFilters => (dispatch, getState) => {
	const currentPath = window.location.pathname;
	let currentLocationSearch = null;

	const unlisten = history.listen((location, action) => {
		const { pathname, search } = location;

		//	If page is changed call unlisten() for remove listener created.
		if (action === 'PUSH' && pathname !== currentPath) {
			currentLocationSearch = null;
			return unlisten();
		}

		// If change history by arrows in browser call handleFetchData() for update filters applyed and data
		if (action === 'POP' && pathname === currentPath && currentLocationSearch !== search) {
			const state = getState();

			const parsedParams = getParsedParams(state.filters[currentFilters]);

			dispatch(checkUrlFilter(parsedParams)(currentFilters));

			dispatch(handleApplyFiltersToggle()(currentFilters));
		}

		currentLocationSearch = search;
	});
};

/**
 * Function set initial data for make filters
 *
 * @param {string} name - filters instance identifier
 * @param {array} filters - Filters components array
 * @param {boolean} useUrlFilters - Use o not the url params for make filters
 * @param {object} initialFiltersValues - Object with initial filters applied
 */
export const setInitialFiltersData = (
	name,
	filters,
	useUrlFilters = false,
	useUpdateUrlFilters = false,
	initialFiltersValues,
	initialDefaultValues
) => (dispatch, getState) => {
	// Add fields filters
	dispatch(addFilters(filters)(name));

	dispatch(useUrlFiltersAction(useUrlFilters, useUpdateUrlFilters)(name));

	const state = getState();

	// Check if has filter applyed in url params
	const parsedParams = getParsedParams(state.filters[name]);
	if (useUrlFilters) {
		dispatch(checkUrlFilter(parsedParams)(name));

		dispatch(historyListener(name));
	}

	if (initialFiltersValues || initialDefaultValues)
		dispatch(setInitialFiltersValues(initialFiltersValues, initialDefaultValues)(name));

	if (initialDefaultValues) {
		Object.keys(initialDefaultValues).forEach(filterKey => {
			if (!parsedParams?.filters[filterKey]) {
				dispatch(changeFilter(filterKey, initialDefaultValues[filterKey])(name));
				updateUrlFilters(initialDefaultValues, filters);
			}
		});
	}

	dispatch(isReadyAction(true)(name));
};
