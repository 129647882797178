import { I18N_CDN_HOST } from 'config/endpoints';
import ms from 'utils/api/ms';
import { setToStorage, getFromStorage } from 'utils/storage';

const backendConfig = {
	loadPath: `${I18N_CDN_HOST}/translates/{{lng}}/{{ns}}.json`,
	ajax(url, options, callback) {
		// Not get default translation.json
		const validation = !/translation\.json/.test(url) && !/\/\.json/.test(url) && !/ /.test(url);

		if (options && validation) {
			const keyNs = url.split(I18N_CDN_HOST)[1];

			const isCacheableNs = /common\.json|views\.json/.test(keyNs);

			const nsCached = isCacheableNs && getFromStorage(keyNs);

			// Return translation json cached
			if (nsCached) {
				try {
					return callback(JSON.stringify(nsCached), { status: 200 });
				} catch (error) {
					console.error(error);
				}
			}

			ms.get({ url, cancelOverlappingRequests: false })
				.then(({ data, statusCode: status }) => {
					// Cache more used translations json
					if (isCacheableNs) setToStorage(keyNs, data, 30);
					callback(JSON.stringify(data), { status });
				})
				.catch(() => {
					callback(null, { status: 404 });
				});
		} else {
			callback('{}', { status: 404 });
		}
	}
};

export default backendConfig;
