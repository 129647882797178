import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import styled from './styles';

const Checkbox = props => (
	<styled.Container checked={props.checked} rounded={props.rounded} styles={props.styles}>
		<styled.Input {...props} type="checkbox" autoComplete={props.autoComplete ? 'on' : 'off'} />
		<Icon
			name="check_bold"
			styles={styled.iconCheckStyles}
			checked={props.checked}
			rounded={props.rounded}
		/>
	</styled.Container>
);

Checkbox.propTypes = {
	/** Si es true habilita el autoComplete */
	autoComplete: PropTypes.bool
};

Checkbox.defaultProps = {
	autoComplete: false
};

export default Checkbox;
