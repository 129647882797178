import styled from 'styled-components';

export default {
	TooltipWrapper: styled.div`
		position: relative;
		cursor: ${props => (props.hasClick ? 'pointer' : 'auto')};
		-webkit-tap-highlight-color: transparent;
	`,
	Tooltip: styled.div`
		${props => !props.isFocused && 'visibility: hidden'};
	`,
	TooltipLabel: styled.div`
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	`
};
