import * as types from './types';

const initialState = {};

const mergeState = (state, action, properties) => ({
	...state,
	[action.meta.name]: {
		...state[action.meta.name],
		...properties
	}
});

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.SET_ASYNC_DEPENDENCIES_LOADING:
			return mergeState(state, action, {
				[action.dependencyName]: {
					isLoading: true,
					hasError: false
				}
			});

		case types.SET_ASYNC_DEPENDENCIES_SUCCESS:
			return mergeState(state, action, {
				[action.dependencyName]: {
					isLoading: false,
					hasError: false,
					data: action.data || {}
				}
			});

		case types.SET_ASYNC_DEPENDENCIES_ERROR:
			return mergeState(state, action, {
				[action.dependencyName]: {
					isLoading: false,
					hasError: true
				}
			});

		default:
			return state;
	}
}
