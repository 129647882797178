import { getPageTitle, makeSortingOptions } from 'utils/selectors';

export const getDataPlanning = state => {
	const getSortingOptions = makeSortingOptions();
	const { page, filters, asyncDependencies } = state;

	const { schema } = page;
	const { appliedFilters } = filters[schema.name] || {};
	const {
		data,
		isFetching,
		isReady,
		errorStatus,
		calendarMode,
		selectedDay,
		selectedWeek,
		selectedMonth
	} = state[schema.name] || {};
	const planningDependencies = asyncDependencies[schema.name] || {};
	const isWeekly = calendarMode === 'weekly';

	return {
		schema,
		data,
		isFetching,
		isReady,
		errorStatus,
		sortingOptions: getSortingOptions(schema),
		title: getPageTitle(state, true),
		appliedFilters,
		planningDependencies,
		isWeekly,
		selectedDay,
		selectedWeek,
		selectedMonth
	};
};
