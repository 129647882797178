import styled, { css, keyframes } from 'styled-components';
import { rgba } from 'polished';
import { colors, timingFunctions, mixins, font } from 'theme';
import { mediaBreaks } from 'devices';
import { mobileBarHeight } from 'components/Navbar/styles';
import Link from 'components/Link';

const { tabletLg: onlyMobile } = mediaBreaks;

export const categoryMenu = {
	CategoryMenu: styled.div`
		position: relative;
	`
};

const categoryStyles = css`
	display: flex;
	align-items: center;
	font-size: ${font.medium};
	color: ${colors.black};
	width: 100%;
	height: 36px;
	padding-left: 12px;
	${mixins.transition('background-color', '200ms')};
	flex-shrink: 0;

	&:hover {
		background-color: ${rgba(colors.lightGrey, 0.5)};
	}

	a {
		flex-grow: 1;
		line-height: 36px;
	}
`;

const CategoriesContainerBase = styled.div`
	width: 100%;
	padding-top: 28px;
	position: relative;
	transition-property: transform, opacity;
	transition-duration: 0.2s;
	transition-timing-function: ${timingFunctions.standard};
	${onlyMobile`
		padding: 75px 0 25px 15px;
		height: calc(100vh - ${mobileBarHeight}px);
		overflow-y: auto;
	`}
`;

const categoryEnterAnimation = keyframes`
	from {
		transform: translateY(10px) translateX(0);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
`;

const categoryEnterAnimationRule = css`
	animation: ${categoryEnterAnimation} 0.35s ${timingFunctions.decelerate} forwards;
`;

export const categories = {
	Container: styled(CategoriesContainerBase)`
		${props =>
			props.exitToLeft &&
			css`
				transform: translateX(-100px);
				opacity: 0;
				display: none;
			`};
		${props =>
			props.noTransition &&
			css`
				opacity: 0;
				transition-duration: 0;
				${categoryEnterAnimationRule};
			`};
	`,
	Category: styled.button`
		${categoryStyles}
	`,
	CategoryLink: styled(Link)`
		${categoryStyles}
	`,
	chevronStyles: css`
		fill: ${colors.black};
		position: absolute;
		right: 8px;
	`
};

export const subcategories = {
	Subcategories: styled(CategoriesContainerBase)`
		position: absolute;
		top: 0;
		transform: translateX(100%);
		opacity: 0;
		${onlyMobile`
			width: 0;
		`}

		${props =>
			props.enter &&
			css`
				transform: translateX(0);
				opacity: 1;
				${onlyMobile`
					width: 100%;
				`}
			`}
	`
};

export const EmptySearchTextStyled = styled.div`
	font-size: 13px;
	color: ${colors.darkGrey};
	${onlyMobile`
		margin: 0 15px;
	`}
`;
