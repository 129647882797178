import { isEmpty } from 'lodash';
import { getLocationParts, getPreviewUrlParams, updateSearchParams } from 'utils/location';
import { getPageData, getPageSchema } from 'utils/api';
import { getFromStorage, setToStorage } from 'utils/storage';
import { setHasPreview, setHasRowCollapsePreview } from 'modules/browseSection/actions/fetch/data';
import { isSchemaCacheDisabled } from 'modules/page/actions';
import { actions as asyncDependenciesActions } from 'modules/asyncDependencies';
import * as types from './types';

const fetchPreviewSchemaSuccess = (schema, previewName) => ({
	type: types.FETCH_PREVIEW_SCHEMA_SUCCESS,
	name: previewName,
	schema
});

export const fetchPreviewDataSuccess = (data, previewName) => ({
	type: types.FETCH_DATA_SUCCESS,
	name: previewName,
	data
});

const fetchPreviewDataRequest = previewName => ({
	type: types.FETCH_DATA_REQUEST,
	name: previewName
});

const fetchPreviewDataFailure = (status, previewName) => ({
	type: types.FETCH_DATA_FAILURE,
	status,
	name: previewName
});

const setActiveRow = (rowId, previewName) => ({
	type: types.SET_ACTIVE_ROW,
	name: previewName,
	activeRow: rowId
});

const getSchema = async ({ path, keyBrowse, keyPreview, dispatch, previewSource = {} }) => {
	const useFetchSchemaSucces = schema => dispatch(fetchPreviewSchemaSuccess(schema, keyPreview));

	const updateHasPreview = schema => {
		if (!isEmpty(previewSource)) dispatch(setHasRowCollapsePreview(true, keyPreview)(keyBrowse));
		else dispatch(setHasPreview(true, keyPreview)(keyBrowse));

		useFetchSchemaSucces(schema);
	};

	const cachedSchema = getFromStorage(path);

	if (cachedSchema && !isSchemaCacheDisabled()) return updateHasPreview(cachedSchema);

	try {
		const schema = await getPageSchema({ method: 'preview', ...previewSource });

		setToStorage(path, schema, 30);

		updateHasPreview(schema);
	} catch (error) {
		dispatch(setHasPreview(false)(keyBrowse));
	}
};

/**
 * get all previews schema, if does not receive params find only the current browse preview
 * @param {Object} previewSource custom source
 * @returns
 */
export const fetchPreviewSchema = (previewSource = {}) => keyBrowse => async dispatch => {
	const [currentService, currentNamespace] = getLocationParts();

	const { service = currentService, namespace = currentNamespace, method } = previewSource;

	const keyPreview = `${namespace}-${method || 'main'}`;

	const schemaPath = `cacheSchema-${service}/${namespace}/${method || 'preview'}`;

	getSchema({ dispatch, keyBrowse, keyPreview, path: schemaPath, previewSource });
};

export const getCurrentPreviewProps = (state, keyBrowse) => {
	const { browses, preview: previewState } = state;

	const { currentPreview } = browses[keyBrowse].preview;

	return { ...previewState[currentPreview], name: currentPreview };
};

export const getCurrentRowCollapsePreviewProps = (state, keyBrowse) => {
	const { browses, preview: previewState } = state;

	const { currentRowCollapsePreview } = browses[keyBrowse].preview;

	return currentRowCollapsePreview
		? { ...previewState[currentRowCollapsePreview], name: currentRowCollapsePreview }
		: {};
};

export const handleSetActiveRowCollapse = ({ activeRow }) => currentBrowse => (
	dispatch,
	getState
) => {
	const state = getState();

	const { name: previewName } = getCurrentRowCollapsePreviewProps(state, currentBrowse);

	if (previewName) return dispatch(setActiveRow(activeRow, previewName));
};

const getSectionsDependencies = (sections, data, currentBrowse, type, dispatch) =>
	sections.forEach(section => {
		const { dependencies = [] } = section;

		dispatch(
			asyncDependenciesActions.executeGetAsyncDependencies(dependencies, data, currentBrowse, type)
		);
	});

/**
 *
 * @param {string} id- current record
 * @param {object} currentPreview- preview properties - source, name
 * @example dispatch(fetchPreviewData(recordId)("some-key-browse"))
 * @returns dispatch action
 */
export const fetchPreviewData = id => currentBrowse => async (dispatch, getState) => {
	const state = getState();

	const {
		schema: { source, sections },
		name: previewName
	} = getCurrentPreviewProps(state, currentBrowse);

	if (!source) return dispatch(fetchPreviewDataSuccess({}, previewName));

	dispatch(fetchPreviewDataRequest(previewName));

	try {
		const data = await getPageData({ source, endpointParameters: { id } });

		getSectionsDependencies(sections, data, currentBrowse, 'preview', dispatch);

		dispatch(handleSetActiveRowCollapse({ activeRow: '' })(currentBrowse));
		dispatch(fetchPreviewDataSuccess(data, previewName));
	} catch ({ response = {} }) {
		dispatch(fetchPreviewDataFailure({ status: response.status }, previewName));
	}
};

export const fetchRowCollapsePreviewData = id => currentBrowse => async (dispatch, getState) => {
	const state = getState();

	const {
		schema: { source, sections },
		name: previewName
	} = getCurrentRowCollapsePreviewProps(state, currentBrowse);

	dispatch(fetchPreviewDataSuccess({}, previewName));

	if (!source) return;

	dispatch(fetchPreviewDataRequest(previewName));

	try {
		const data = await getPageData({ source, endpointParameters: { id } });

		getSectionsDependencies(sections, data, currentBrowse, 'rowPreview', dispatch);

		dispatch(fetchPreviewDataSuccess(data, previewName));
	} catch ({ response = {} }) {
		dispatch(fetchPreviewDataFailure({ status: response.status }, previewName));
	}
};

/**
 * function handleSetActiveRow
 * @param {String} - activeRow id active row.
 * @param {boolean} - setFromUrl indicates if the action is called from the url to know that it should not modify
 * @example
 * 	dispatch(handleSetActiveRow(
 * 		{
 * 			activeRow:"rowId"
 * 			setFromUrl: false
 * 		}
 * 	))
 */

export const handleSetActiveRow = ({ activeRow, setFromUrl }) => currentBrowse => (
	dispatch,
	getState
) => {
	const state = getState();

	const { name: previewName } = getCurrentPreviewProps(state, currentBrowse);

	if (!setFromUrl) {
		const { section } = getPreviewUrlParams();
		// en caso de que sea ejecutado desde la url, no debe modificar los parametros
		let newParam = section ? `${activeRow}_${section}` : activeRow;

		if (!activeRow) newParam = false;

		updateSearchParams('preview', newParam);
	}

	return dispatch(setActiveRow(activeRow, previewName));
};
