/*
	-----------------------------------------
	!! ESTA FUNCIÓN ES PROVISORIA YA QUE HACE LO MISMO QUE getPickingPointsById PERO CONSULTANDO
	A LA API DE WAREHOUSES. LO IDEAL SERÍA UNIFICAR AMBAS
	-----------------------------------------
*/

import { callRequest, getQueryFiltersParameters } from 'utils/request';
import { setToStorage, getFromStorage } from 'utils/storage';

const source = {
	service: 'wms',
	namespace: 'warehouse',
	method: 'list'
};

const endpointParameters = [
	{
		name: 'id',
		target: 'filter',
		value: {
			dynamic: 'id'
		}
	}
];

/**
 * It gets warehouses from the cache if they are there, otherwise it gets them from the server and
 * caches them
 * @param {string[]}  [ids] - an array of warehouses ids
 * @returns {[{warehouse}]} An array of warehouses.
 */
const getWarehousesById = async ids => {
	try {
		if (!Array.isArray(ids) || !ids.length) return [];

		const cache = getFromStorage('warehouses') || [];
		const warehousesInCache = cache.filter(warehouse => ids.includes(warehouse.id));

		const idsWithoutCache = ids.reduce((acc, act) => {
			const warehouse = cache.find(cacheWarehouse => cacheWarehouse.id === act);
			if (!warehouse) return [...acc, { id: act }];
			return acc;
		}, []);

		if (warehousesInCache.length && !idsWithoutCache.length) return warehousesInCache;

		const filters = getQueryFiltersParameters(endpointParameters, idsWithoutCache);

		const response = await callRequest(source, filters);

		const mergeResponse = [...warehousesInCache, ...response];

		setToStorage('warehouses', mergeResponse, 5);
		return mergeResponse;
	} catch (reason) {
		// eslint-disable-next-line no-console
		console.warn('reason >>', reason);
	}
};

export default getWarehousesById;
