import arrayMove from 'array-move';

/**
 * Creates an array of numbers (positive and/or negative)
 * progressing from start up to, but not including, end.
 * @param {number} start
 * @param {number} end
 * @example
 * range(2, 10);
 * [2, 3, 4, 5, 6, 7, 8, 9]
 */
export const range = (start, end) => Array.from({ length: end - start }, (v, k) => k + start);

// last :: [a] -> a
export const last = xs => xs[xs.length - 1];

// head :: [a] -> a
export const head = xs => xs[0];

/**
 * Returns a new array with the item moved to the new position.
 * @param {array} array
 * @param {number} from
 * @param {number} to
 *
 */
export const arrayElementMove = (array, from, to) => {
	if (!Array.isArray(array) || from === to) return false;

	return arrayMove(array, from, to);
};

/**
 * Returns a new array with length from arguments.
 * @param {number} length
 * @example
 * Array.from({length: 5}, (_, i) => i);
 * [0, 1, 2, 3, 4]
 */
export const simulateArray = length => Array.from({ length }, (_, i) => i);

/**
 * Returns a new array with subarrays/chunks,
 * each with a maximum length of 60.
 * @param {array} array
 * @returns {[]}
 * @example
 * chunkArray([1, ..., 120])
 * [ [1, ..., 60] , [1, ..., 60] ]
 */
export const chunkArray = array => {
	const chunk = [];
	for (let i = 0; i < array.length; i += 60) {
		chunk.push(array.slice(i, i + 60));
	}
	return chunk;
};
