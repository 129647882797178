import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectors } from 'modules/menu';
import { useDevices } from 'utils/hooks';
import translationHOC from 'hocs/translationHOC';
import MenuSubtitle from '../MenuSubtitle';
import { EmptySearchTextStyled } from './styles';

const EmptySearchText = ({ isEmptySearch, t }) => {
	if (!isEmptySearch) return null;

	const { tabletLg: onlyMobile } = useDevices();
	return (
		<>
			{onlyMobile && <MenuSubtitle>{t('views.search.results')}</MenuSubtitle>}
			<EmptySearchTextStyled>{t('views.search.resultsNotFound')}</EmptySearchTextStyled>
		</>
	);
};

EmptySearchText.propTypes = {
	isEmptySearch: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	isEmptySearch: selectors.isEmptySearch(state)
});

export default translationHOC(connect(mapStateToProps)(EmptySearchText));
