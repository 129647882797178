/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */

import { currentEnvironment } from 'config/environment';

const loadScriptClaspo = () => {
	try {
		if (currentEnvironment !== 'prod') return null;
		const t = window;
		const e = document;
		const c = 'script';
		const n = '2A932D2A14A9422296B12BF63BEC5316';

		const s = e.createElement(c);
		(s.async = 1), (s.src = `https://scripts.claspo.io/scripts/${n}.js`);
		const r = e.scripts[0];
		r.parentNode.insertBefore(s, r);
		var f = function() {
			f.c(arguments);
		};
		f.q = [];
		f.c = function() {
			f.q.push(arguments);
		};
		t.claspo = t.claspo || f;
	} catch (reason) {
		return null;
	}
};

export default loadScriptClaspo;
