import { useEffect, useState, useRef } from 'react';
/**
 * Custom hook for execute some callback with a time interval
 * @param {number | boolean} autoRefresh
 * @param {func} callback
 * @param {boolean} [runInterval= true] - Allows to toggle the interval on/off
 * @param {boolean} [restartInterval= false] - Allows to reinit the interval
 */
const useAutorefresh = (autoRefresh, callback, runInterval = true, restartInterval = false) => {
	const [stopInterval, setStopInterval] = useState(false);

	const savedCallback = useRef();
	const savedRunInterval = useRef();

	const interval = autoRefresh && (autoRefresh < 60 || autoRefresh === true ? 60 : autoRefresh);

	// Remember the latest function.
	useEffect(
		() => {
			savedCallback.current = callback;
		},
		[callback]
	);

	useEffect(
		() => {
			savedRunInterval.current = runInterval;

			if (stopInterval) {
				savedCallback.current();
				setStopInterval(false);
			}
		},
		[runInterval]
	);

	// Set up the interval.
	useEffect(
		() => {
			if (interval && callback && !stopInterval) {
				const miliSecondsInterval = +interval * 1000;

				const executeCallback = () => {
					if (savedRunInterval.current) savedCallback.current();
					else setStopInterval(true);
				};

				const id = setInterval(executeCallback, miliSecondsInterval);

				return () => clearInterval(id);
			}
		},
		[interval, stopInterval, restartInterval]
	);
};

export default useAutorefresh;
