import { callRequest } from 'utils/request';

const source = {
	service: 'tms',
	namespace: 'route',
	method: 'simulate'
};

const simulateRouteByOptimize = async body => {
	try {
		const response = await callRequest(source, body);
		return response;
	} catch (reason) {
		// eslint-disable-next-line no-console
		console.warn('reason >>', reason);
		throw reason;
	}
};

export default simulateRouteByOptimize;
