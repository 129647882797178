import React from 'react';
import PropTypes from 'prop-types';
import { useProgressBar } from 'hooks';
import styled from './styles';

const ProgressBar = ({ value }) => {
	const { progress } = useProgressBar(value);
	return <styled.ProgressFill value={progress} max={value} />;
};

ProgressBar.propTypes = {
	value: PropTypes.number.isRequired
};

export default ProgressBar;
