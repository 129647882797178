import * as types from './types';

const initialState = {
	id: null,
	firstname: '',
	lastname: '',
	email: '',
	images: {},
	hasErrorClientData: false,
	hasErrorUserId: false,
	isReady: false,
	isFetching: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.FETCH_USER_DATA_REQUEST:
			return {
				...state,
				isFetching: true
			};
		case types.FETCH_USER_DATA_SUCCESS:
			return {
				...state,
				...action.clientData,
				hasErrorClientData: false,
				isReady: true,
				isFetching: false
			};
		case types.FETCH_USER_DATA_FAILURE:
			return {
				...state,
				hasErrorClientData: true
			};
		case types.SET_USER_ID:
			return {
				...state,
				id: action.id
			};
		case types.SET_USER_ID_FAILURE:
			return {
				...state,
				hasErrorUserId: true,
				isFetching: false
			};
		default:
			return state;
	}
}
