import { SCHEMA_ENDPOINT } from 'config/endpoints';
import { get } from 'axios';
import { setToStorage, getFromStorage } from 'utils/storage';
import { openMenuSection, closeMenuSection, goBackHistoryMenu } from 'utils/mobileMenuHistory';
import { currentEnvironment } from 'config/environment';
import { close as closeNavbar, closeClient, closeProfileDetail } from '../navbar/actions';
import * as types from './types';
import { searchCategory } from './selectors';

const CURRENT_SCHEMA = currentEnvironment === 'local' ? 'https://app.janisdev.in' : SCHEMA_ENDPOINT;

export const open = () => ({
	type: types.OPEN
});

export const openMenu = () => (dispatch, getState) => {
	const { navbar } = getState();

	if (navbar.isProfileDetailOpen) dispatch(closeProfileDetail());
	if (navbar.isOpenClient) dispatch(closeClient());
	dispatch(open());
};

export const closeMenuOnly = (closeAll = true) => ({
	type: types.CLOSE,
	closeAll
});

export const close = () => dispatch => {
	dispatch(closeMenuOnly());
	dispatch(closeNavbar());
};

export const selectCategory = category => ({
	type: types.SELECT_CATEGORY,
	category
});

export const closeCategory = () => ({
	type: types.CLOSE_CATEGORY
});

export const changeSearchTerm = searchTerm => ({
	type: types.CHANGE_SEARCH_TERM,
	searchTerm
});

export const clearSearchTerm = searchTerm => ({
	type: types.CLEAR_SEARCH_TERM,
	searchTerm
});

export const setMenuData = data => ({
	type: types.SET_MENU_SOURCE,
	data
});

export const fetchMenu = () => async dispatch => {
	// Check if menu data already exists on cache
	const cachedMenu = getFromStorage('menu');

	if (cachedMenu) return dispatch(setMenuData(cachedMenu));

	try {
		const menu = await get(`${CURRENT_SCHEMA}/menu/menu.json`, {
			headers: { 'Cache-Control': 'no-cache' }
		});

		const menuToSave = Array.isArray(menu.data) ? menu.data : [];

		setToStorage('menu', menuToSave, menuToSave.length ? 60 : 5);

		return dispatch(setMenuData(menuToSave));
	} catch (e) {
		// eslint-disable-next-line no-console
		console.warn('Something went wrong getting the menu');

		return dispatch(setMenuData([]));
	}
};

export const closeMenu = (closeAll = true, isMobile = false) => dispatch => {
	if (isMobile) {
		if (closeAll) goBackHistoryMenu();
		else {
			closeMenuSection();
		}
	}
	dispatch(close(closeAll));
};

export const addSelectedCategory = (category, onlyMobile) => dispatch => {
	if (onlyMobile) openMenuSection('category', category);
	dispatch(selectCategory(category));
};

export const closeSelectedCategory = isMobile => (dispatch, getState) => {
	const {
		menu: { source, selectedCategory }
	} = getState();

	const { path = [] } = searchCategory(source, selectedCategory);

	// eliminate Main Category - first position of array
	if (path.length) path.shift();

	const previousCategory = path.length ? path[path.length - 1] : '';

	if (!previousCategory) return isMobile ? closeMenuSection() : dispatch(closeCategory());

	dispatch(selectCategory(previousCategory));
};
