import React from 'react';
import PropTypes from 'prop-types';
import translationHOC from 'hocs/translationHOC';
import ModalComponent from 'components/Modal';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import styled from './styles';

const ActionsModal = ({
	children,
	confirmDisabled,
	confirmButtonText,
	handleConfirm,
	handleCloseModal,
	height,
	isLoading,
	isOpen,
	title,
	t,
	width
}) => (
	<ModalComponent width={width} height={height} isOpen={isOpen} handleCloseModal={handleCloseModal}>
		<styled.Wrapper>
			{isLoading && (
				<styled.SpinnerWrapper>
					<Spinner />
				</styled.SpinnerWrapper>
			)}

			<styled.TitleWrapper>
				{title ? <styled.Title>{title}</styled.Title> : <div />}
				<button type="button" onClick={handleCloseModal}>
					<styled.Icon name="cross_light" color="black" size={24} />
				</button>
			</styled.TitleWrapper>

			<styled.ContentWrapper>{children}</styled.ContentWrapper>

			<styled.ActionsWrapper>
				<styled.Button variant="outlined" onClick={handleCloseModal}>
					{t('common.action.cancel')}
				</styled.Button>

				<Button
					disabled={confirmDisabled}
					onClick={handleConfirm}
					type="button"
					variant="contained"
				>
					{t(confirmButtonText)}
				</Button>
			</styled.ActionsWrapper>
		</styled.Wrapper>
	</ModalComponent>
);

ActionsModal.propTypes = {
	/** Contenido del modal */
	children: PropTypes.node,
	/** Si el botón de confirmación debe deshabilitarse */
	confirmDisabled: PropTypes.bool,
	confirmButtonText: PropTypes.string,
	/** Acción del botón de confirmación */
	handleConfirm: PropTypes.func,
	/** Acción de cierre del Modal */
	handleCloseModal: PropTypes.func,
	/** Alto del Modal */
	height: PropTypes.number,
	/** Si se debe mostrar el loader */
	isLoading: PropTypes.bool,
	/** Si el Modal está abierto */
	isOpen: PropTypes.bool,
	/** Título del modal */
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	/** Ancho del modal */
	width: PropTypes.string
};

ActionsModal.defaultProps = {
	width: 'medium',
	confirmButtonText: 'common.action.apply'
};

export default translationHOC(ActionsModal);
