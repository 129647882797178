import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import styled from './styles';
import Cross from './Cross';

const SearchInput = ({ searchText, setSearchText }) => (
	<styled.Container hasResult={!!searchText}>
		<Icon name="search" color="darkGrey" />
		<styled.Input autoFocus value={searchText} onChange={e => setSearchText(e.target.value)} />
		<Cross setSearchText={setSearchText} searchText={searchText} />
	</styled.Container>
);

SearchInput.propTypes = {
	searchText: PropTypes.string,
	setSearchText: PropTypes.func
};

export default SearchInput;
