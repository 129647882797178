import store from 'store';
import { isValidTranslationKey } from 'utils/string';
import saveTranslationKeys from './saveTranslationKeys';

const keysProcessed = {};

const useDateTranslations = store.get('useDateTranslations');

const translationKeyPostProcessor = {
	type: 'postProcessor',
	name: 'translationKeyPostProcessor',
	process: (keyValue, keyParams) => {
		// if useDateTranslations is true create a missing and existing keys here
		if (!useDateTranslations) return keyValue;

		const [translationKey] = keyParams;

		if (keysProcessed[translationKey]) return keyValue;

		if (isValidTranslationKey(translationKey) && process.env.NODE_ENV !== 'development') {
			const [namespace, key] = translationKey.split(/\.(.+)/);

			const translationKeyData = { key, namespace, lastUsed: new Date().toISOString() };

			keysProcessed[translationKey] = true;

			saveTranslationKeys(translationKey, translationKeyData);
		}

		return keyValue;
	}
};

export default translationKeyPostProcessor;
