import React from 'react';
import AuthWrapper from 'components/AuthWrapper';
import Content from './Content';

const ForgotPassword = ({ ...props }) => (
	<AuthWrapper>
		<Content {...props} />
	</AuthWrapper>
);

export default ForgotPassword;
