import { executeGetAsyncDependencies } from 'modules/asyncDependencies/actions';
import { getQueryFiltersParameters } from 'utils/request';
import simulateRouteByOptimize from 'services/tms/simulateRoute';
import { formatStops } from 'modules/createRouteV2/actions';
import { addAlert } from 'modules/alerts/actions';
import { getLocationParts } from 'utils/location';
import { getPageData } from 'utils/api';
import { schema as internalSchema } from '../../pages/EditRoute/schema';
import * as types from './types';

export const createPage = schema => ({
	type: types.CREATE_PAGE,
	schema
});

export const setDataError = (section, status) => ({
	type: types.SET_ERROR,
	section,
	status
});

export const setStops = (section, stops) => ({
	type: types.SET_STOPS,
	section,
	stops
});

export const setSchedule = schedule => ({
	type: types.SET_SCHEDULE,
	schedule
});

export const saveData = data => ({
	type: types.FETCH_ROUTE_DATA_SUCCESS,
	data
});

export const updateFields = (section, fields) => ({
	type: types.UPDATE_SEVERAL_FIELDS,
	section,
	fields
});

export const fetchEditRouteData = () => async (dispatch, getState) => {
	const {
		editRoute: {
			schema: { name, source, endpointParameters, dependencies }
		}
	} = getState();

	if (!source) return dispatch(saveData({}));

	const id = getLocationParts()[3];
	const filters = getQueryFiltersParameters(endpointParameters);

	try {
		const data = await getPageData({ source, endpointParameters: { id }, ...filters });

		dispatch(saveData(data));
		dispatch(executeGetAsyncDependencies(dependencies, data, name, 'edit'));
	} catch ({ response = {} }) {
		dispatch(
			addAlert({
				message: response?.data?.message,
				type: 'error',
				id: `get-route_api_error_message`
			})
		);
		dispatch(setDataError('mainInfo', { status: response.status }));
	}
};

export const getOptimizedEditStops = body => async dispatch => {
	try {
		const response = await simulateRouteByOptimize(body);

		const {
			schedule,
			shippings: responseShippings,
			warehouses,
			stops: responseStops = [],
			canBeOptimized = {}
		} = response || {};

		const stopsToRender = formatStops(responseStops, warehouses, responseShippings);

		dispatch(
			updateFields('mainInfo', {
				stops: stopsToRender,
				selectedSchedule: schedule,
				canOptimize: !!canBeOptimized?.value
			})
		);

		return {
			stops: stopsToRender,
			warehouses
		};
	} catch ({ response = {} }) {
		dispatch(
			addAlert({
				message: response?.data?.message,
				type: 'error',
				id: `simulate_api_error_message`
			})
		);
	}
};
export const getStopsInfoEdit = () => async (dispatch, getState) => {
	const {
		editRoute: {
			mainInfo: {
				stops,
				shippingsToChange: { changeShippingsKey, shippings }
			}
		}
	} = getState() || {};
	const isFetchToChange = !!shippings.length;
	const payload = {
		...(!isFetchToChange && { routeId: getLocationParts()[3] }),
		...(isFetchToChange && {
			stops,
			[changeShippingsKey]: shippings
		})
	};

	try {
		const response = await simulateRouteByOptimize(payload);

		const {
			schedule,
			shippings: responseShippings,
			warehouses,
			stops: responseStops = [],
			canBeOptimized = {}
		} = response || {};

		const stopsToRender = formatStops(responseStops, warehouses, responseShippings);

		dispatch(
			updateFields('mainInfo', {
				stops: stopsToRender,
				selectedSchedule: schedule,
				canOptimize: !!canBeOptimized?.value
			})
		);

		return {
			stops: stopsToRender,
			warehouses
		};
	} catch ({ response = {} }) {
		dispatch(
			addAlert({
				message: response?.data?.message,
				type: 'error',
				id: `simulate_api_error_message`
			})
		);
		if (!isFetchToChange) dispatch(setDataError('mainInfo', { status: response.status }));
		return stops;
	}
};

export const initPage = () => (dispatch, getState) => {
	const { page } = getState();
	const { schema } = page;

	dispatch(createPage({ ...schema, ...internalSchema }));
};

export const changeSelectedRowsEditRoute = selectedRows => ({
	type: types.CHANGE_SELECTED_ROWS,
	selectedRows
});

export const setDataLoading = (section, isLoading) => ({
	type: types.SET_LOADING,
	isLoading,
	section
});

export const saveDriverData = driverData => ({
	type: types.SAVE_DRIVER_DATA,
	driverData
});

export const stopsColorByWarehouseId = payload => ({
	type: types.SAVE_STOPS_COLOR,
	payload
});

export const setRouteDataEdit = (section, routeData) => ({
	type: types.SET_ROUTE_DATA,
	section,
	routeData
});

export const changeShippings = payload => ({
	type: types.CHANGE_SHIPPINGS,
	payload
});
