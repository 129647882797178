import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getClients, changeClient, getClientsByName } from 'utils/api/user';
import { setAuthCookie } from 'utils/auth';
import { map } from 'utils/mappers';
import { actions } from 'modules/navbar';
import { actions as toastActions } from 'modules/alerts';
import Searchbar from 'components/Navbar/Menu/Searchbar';
import translationHOC from 'hocs/translationHOC';
import { Title } from 'components/Page';
import Button from 'components/Button';
import { menu } from 'components/Navbar/ProfileDetail/styles';
import { useDebounce, useDevices } from 'utils/hooks';
import { Transition } from 'react-spring/renderprops-universal';
import { menu as menuHeader } from 'components/Navbar/Menu/styles';
import { useInView } from 'react-intersection-observer';
import Spinner from 'components/Spinner';
import ClientItem from './components/ClientItem';
import { clientsModal as styled, cross } from './styles';
import ClientSkeletonItem from './components/ClientSkeletonItem';

const { DesktopContainer, MobileContainer } = menu;

const simulateClients = Array.from({ length: 18 });

const ClientsModal = ({
	janisClient: currentClient,
	isOpen,
	addAlert,
	isNavbarOpen,
	close,
	t,
	searchTerm
}) => {
	if (!isOpen) return null;
	const [state, setState] = useState({
		hasSearch: false,
		hasClients: true,
		headerMobile: false,
		isLoading: true,
		isLoadingSelect: false,
		searchClientsData: [],
		clientsData: []
	});
	const {
		hasSearch,
		hasClients,
		headerMobile,
		isLoading,
		searchClientsData,
		clientsData,
		isLoadingSelect
	} = state;

	const [inViewRef, inView] = useInView();
	const ref = useRef({
		hasMoreClients: false,
		page: 1
	});
	const { hasMoreClients, page } = ref.current;

	const searchDebounce = useDebounce(searchTerm, 350);
	const { onlyDesktop, mobile: onlyMobile } = useDevices();

	const getRequestClients = async () => {
		try {
			if (!isLoading)
				setState(prevState => ({
					...prevState,
					isLoading: true
				}));
			const { data } = await getClients(page);

			ref.current.hasMoreClients = !(data.length < 60);

			setState(prevState => ({
				...prevState,
				clientsData: [...clientsData, ...data],
				isLoading: false
			}));
		} catch ({ response = {} }) {
			const { data = {} } = response;
			setState(prevState => ({
				...prevState,
				isLoading: false
			}));
			addAlert({
				type: 'error',
				id: 'error',
				message: map('statusError', data.status)
			});
		}
	};

	const selectClient = async client => {
		setState(prevState => ({
			...prevState,
			isLoadingSelect: true
		}));

		try {
			const { data } = await changeClient(client);

			setAuthCookie(data);

			window.location.reload();
		} catch ({ response = {} }) {
			const { data = {} } = response;

			addAlert({
				type: 'error',
				id: 'error',
				message: map('statusError', data.status)
			});

			setState(prevState => ({
				...prevState,
				isLoadingSelect: false
			}));
		}
	};

	const searchClientsByName = async name => {
		if (searchDebounce) {
			setState(prevState => ({
				...prevState,
				isLoading: true
			}));
			const { data } = await getClientsByName(name);
			setState(prevState => ({
				...prevState,
				searchClientsData: data,
				isLoading: false,
				hasClients: false,
				hasSearch: true
			}));
		} else {
			setState(prevState => ({
				...prevState,
				hasClients: true,
				hasSearch: false
			}));
		}
	};

	useEffect(
		() => {
			getRequestClients(page);
		},
		[page]
	);

	useEffect(
		() => {
			searchClientsByName(searchDebounce);
		},
		[searchDebounce]
	);

	useEffect(
		() => {
			if (inView && hasMoreClients) ref.current.page += 1;
		},
		[inView]
	);

	const renderClients = dataClients => (
		<>
			{dataClients.map(client => {
				const { code, name, image, mainColor, country } = client;
				const props = {
					clientMainColor: mainColor,
					clientCode: code,
					clientName: name,
					clientImage: image,
					clientCountry: country,
					isCurrent: currentClient === code,
					selectClientMethod: selectClient
				};

				return <ClientItem key={code} {...props} />;
			})}
		</>
	);

	const content = () => (
		<styled.Clients>
			{onlyMobile ? (
				<menuHeader.MobileMenuHeader name="MobileMenu" isOpen={isOpen}>
					{headerMobile ? (
						<>
							<Searchbar searchClient />
							<menuHeader.MobileMenuHeaderButton
								icon="cross_light"
								rounded
								color="black"
								iconSize={onlyMobile && 24}
								onClick={() => setState(prevState => ({ ...prevState, headerMobile: false }))}
							/>
						</>
					) : (
						<>
							<menuHeader.MobileMenuHeaderButton
								icon="arrow_left"
								rounded
								iconSize={onlyMobile && 24}
								onClick={() => close()}
							/>
							<span>{t('views.menu.customer')}</span>
							<menuHeader.MobileMenuHeaderButton
								icon="search"
								rounded
								color="black"
								iconSize={onlyMobile && 24}
								onClick={() => setState(prevState => ({ ...prevState, headerMobile: true }))}
							/>
						</>
					)}
				</menuHeader.MobileMenuHeader>
			) : (
				<>
					<Button
						icon="cross_light"
						iconColor="black"
						rounded
						onClick={() => close()}
						styles={cross}
					/>
					<Title>{t('views.menu.client')}</Title>
					<Searchbar searchClient />
				</>
			)}

			{isLoading && (
				<styled.ClientsWrapper>
					{simulateClients.map((_, idx) => (
						<ClientSkeletonItem key={`skeleton_${idx.toString}`} />
					))}
				</styled.ClientsWrapper>
			)}

			{isLoadingSelect && (
				<styled.SpinnerWrapper>
					<Spinner />
				</styled.SpinnerWrapper>
			)}

			{hasSearch && (
				<styled.ContainerMobile>
					<styled.SubTitle>{t('views.search.results')}</styled.SubTitle>
					{!searchClientsData.length && (
						<styled.Empty>{t('views.search.clientNotFound')}</styled.Empty>
					)}
				</styled.ContainerMobile>
			)}

			<styled.ClientsWrapper>
				{hasClients && !!clientsData.length && renderClients(clientsData)}
				{hasSearch && !!searchClientsData.length && renderClients(searchClientsData)}
				<div ref={inViewRef} />
			</styled.ClientsWrapper>
		</styled.Clients>
	);

	return onlyDesktop ? (
		<DesktopContainer isOpen={isOpen} isNavbarOpen={isNavbarOpen}>
			{content()}
		</DesktopContainer>
	) : (
		<Transition
			items={isOpen}
			from={{ opacity: 0 }}
			enter={{ opacity: 1 }}
			leave={{ opacity: 0 }}
			config={{ duration: 100 }}
		>
			{show => show && (() => <MobileContainer>{content()}</MobileContainer>)}
		</Transition>
	);
};

ClientsModal.propTypes = {
	addAlert: PropTypes.func,
	janisClient: PropTypes.string,
	isOpen: PropTypes.bool,
	close: PropTypes.func,
	isNavbarOpen: PropTypes.bool.isRequired,
	searchTerm: PropTypes.string
};

const mapStateToProps = state => ({
	isNavbarOpen: state.navbar.isOpen,
	isOpen: state.navbar.isOpenClient,
	janisClient: state.page.janisClient,
	searchTerm: state.menu.searchTerm
});

const mapDispatchToProps = {
	addAlert: toastActions.addAlert,
	close: actions.closeAll
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(translationHOC(ClientsModal));
