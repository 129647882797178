import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from 'contexts/translateContext';
import styled from './styles';

class Input extends PureComponent {
	state = {
		isFocused: false,
		value: this.props.defaultValue // for uncontroled input
	};

	input = React.createRef();

	componentDidUpdate(prevProps, prevState) {
		// For maintain cursor position on update input value
		if (
			prevProps.value !== this.props.value ||
			(prevState.value !== this.state.value && this.input.current)
		) {
			if (this.input.current.type === 'text') {
				this.input.current.selectionStart = this.cursorStart;
				this.input.current.selectionEnd = this.cursorEnd;
			}
		}
	}

	handleBlur = event => {
		if (this.props.hasFloatingLabel) this.setState({ isFocused: false });
		this.setState({ value: event.target.value });
		if (this.props.setInputFocus) this.props.setInputFocus(null);
		this.props.onBlur(event);
	};

	handleChange = event => {
		const input = event.target;

		this.setState({ value: input.value });
		this.props.onChange(event);

		this.cursorStart = input.selectionStart;
		this.cursorEnd = input.selectionEnd;
	};

	handleFocus = () => {
		if (this.props.hasFloatingLabel) this.setState({ isFocused: true });

		if (this.props.setInputFocus) this.props.setInputFocus(this.props.inputDateType);
		this.props.onFocus();
	};

	handleLabelClick = () => {
		this.input.focus();
	};

	render() {
		const {
			defaultValue,
			hasFloatingLabel,
			autoComplete,
			placeholder,
			value = this.state.value,
			error,
			errorMessage,
			isFocused,
			icon,
			...props
		} = this.props;

		const autoCompleteOff = props.type === 'password' ? 'new-password' : 'off';
		const isFloating = this.state.isFocused || !!value.toString();

		return (
			<TranslateContext.Consumer>
				{({ isTranslateActive }) => (
					<styled.Container fullWidth={props.fullWidth}>
						<styled.Container fullWidth={props.fullWidth}>
							{hasFloatingLabel && (
								<styled.FloatingLabel
									className="input-floating-label"
									error={error}
									onClick={this.handleLabelClick}
									disabled={props.disabled}
									isFocused={this.state.isFocused}
									isFloating={isFloating}
									hasIcon={!!icon}
									isTranslateActive={isTranslateActive}
								>
									{props.label}
								</styled.FloatingLabel>
							)}
							{icon && <styled.InputIcon name={icon} color={error ? 'statusRed' : 'black'} />}
							<styled.Input
								{...props}
								ref={this.input}
								placeholder={hasFloatingLabel && !isFloating ? '' : placeholder}
								onBlur={this.handleBlur}
								onFocus={this.handleFocus}
								onChange={this.handleChange}
								disabled={props.disabled}
								autoComplete={autoComplete ? 'on' : autoCompleteOff}
								value={value}
								isFocused={isFocused || this.state.isFocused}
								error={error}
								hasIcon={!!icon}
								onAnimationStart={this.handleFocus}
								className={props.className}
							/>
						</styled.Container>

						{error && <styled.ErrorMessage>{errorMessage}</styled.ErrorMessage>}
					</styled.Container>
				)}
			</TranslateContext.Consumer>
		);
	}
}

Input.propTypes = {
	/** Si es true deshabilita el input */
	disabled: PropTypes.bool,
	/** Si es true habilita el autoComplete */
	autoComplete: PropTypes.bool,
	/** El valor por defecto del input, solo para usar como uncontrolled component */
	defaultValue: PropTypes.string,
	/** Si es true el input indicará un error */
	error: PropTypes.bool,
	/** El mensaje a mostrar del error */
	errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	/** Si es true aplica un width de 100% */
	fullWidth: PropTypes.bool,
	/** Nombre del ícono a mostrar al inicio del input */
	icon: PropTypes.string,
	/** Id del elemento input, usar en conjunto con label */
	id: PropTypes.string,
	/** Si es true el placeholder se transforma en label flotante */
	hasFloatingLabel: PropTypes.bool,
	/** Si es true la label flotante permite eventos */
	isTranslateActive: PropTypes.bool,
	/** Atributo name del elemento input */
	name: PropTypes.string,
	/** Callback disparado cuando se sale del foco del input */
	onBlur: PropTypes.func,
	/** Callback disparado cada vez que cambia el valor del input.
	El callback recibe el evento original del input, se puede acceder al valor a traves de él, event.target.value */
	onChange: PropTypes.func,
	/** Callback disparado cuando el value del input es cambiado,  */
	onFocus: PropTypes.func,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	/** Atributo type del elemento input */
	type: PropTypes.oneOf(['text', 'email', 'hidden', 'number', 'password', 'tel']),
	/** Valor del elemento input, requerido para un controlled component */
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Input.defaultProps = {
	defaultValue: '',
	disabled: false,
	autoComplete: false,
	error: false,
	errorMessage: '',
	fullWidth: false,
	hasFloatingLabel: true,
	isTranslateActive: false,
	onBlur: () => null,
	onChange: () => null,
	onFocus: () => null,
	type: 'text'
};

export default Input;
