import { mediaBreaks } from 'devices';
import styled, { css } from 'styled-components';
import { colors, mixins, font } from 'theme';

const { tabletLg: onlyMobile } = mediaBreaks;

export const clientsModal = {
	SpinnerWrapper: styled.div`
		background: ${colors.transparentWhite};
		position: fixed;
		top: 45px;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1;
	`,
	ClientsWrapper: styled.div`
		display: grid;
		grid-template-columns: repeat(2, 150px);
		grid-column-gap: 15px;
		grid-row-gap: 20px;
		grid-auto-rows: 64px;
		margin: 20px 0;
		max-height: 80vh;
		min-height: 79vh;
		overflow-y: auto;
		height: 100%;
		position: relative;
		width: 100%;
		${mixins.scrollbar(colors.grey)}
	`,
	ClientItem: styled.button`
		display: flex;
		align-items: center;
		cursor: pointer;
		position: relative;
		height: 64px;
		${mixins.transition('background-color', '250ms')};
		&:hover {
			background: ${colors.lightGrey};
		}
		&:active {
			background: ${colors.lightGreyPressed};
		}
		&:focus {
			border: 0.5px solid ${colors.black};
		}
		${({ isCurrent }) =>
			isCurrent &&
			css`
				border: 1px solid ${colors.blue};
				color: ${colors.blue};
			`}
	`,
	ClientBadge: styled.div`
		display: flex;
		justify-content: center;
		align-items: center;
		background: ${colors.fizzGreen};
		color: ${colors.white};
		flex-shrink: 0;
		margin: 5px;
		width: 54px;
		height: 54px;
		font-size: 16px;
	`,
	ClientImage: styled.img`
		width: 54px;
		margin: 5px;
		height: ${({ loadingImg }) => (loadingImg ? '54px' : 'auto')};
		background-color: ${({ mainColor, loadingImg }) => (loadingImg ? colors.fizzGreen : mainColor)};
	`,
	ClientName: styled.span`
		margin-left: 2px;
		text-align: left;
		font-size: ${font.base};
		text-overflow: ellipsis;
		overflow: hidden;
		font-weight: 400;
		/* stylelint-disable */
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		/* stylelint-enable */
		& > span {
			display: block;
			font-size: ${font.base};
			margin-top: 4px;
		}
	`,

	Clients: styled.div`
		position: relative;
		display: flex;
		flex-direction: column;
		padding: 20px;
		margin-top: 20px;
		${onlyMobile`
			margin-top:0;
			padding: 10px
		`}
	`,
	SubTitle: styled.h3`
		font-size: ${font.medium};
		text-transform: uppercase;
		font-weight: 700;
		color: ${colors.black};
		letter-spacing: 0.93px;
		margin-top: 19px;
	`,
	Empty: styled.span`
		font-size: ${font.base};
		font-weight: 400;
		color: ${colors.darkGrey};
		margin-top: 20px;
	`,
	ContainerMobile: styled.div`
		grid-column: 1/-1;
	`
};

export const cross = css`
	position: absolute;
	top: -2px;
	right: 22px;
	z-index: 2;
	& > svg {
		width: 24px;
		height: 24px;
	}
`;
