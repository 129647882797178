import React from 'react';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';

const Select = ({ baseProps, commonProps, ...props }) => {
	const { canCreate, options: selectOptions } = baseProps;
	const { handleCreateOption, creatableOptions } = commonProps;

	const options = [...creatableOptions, ...selectOptions];

	const Component = canCreate ? CreatableSelect : ReactSelect;

	const onCreateOption = value => handleCreateOption(props.value, value);

	return (
		<Component
			{...baseProps}
			{...commonProps}
			{...props}
			{...(canCreate ? { onCreateOption, options } : {})}
			classNamePrefix="select"
		/>
	);
};

export default Select;
