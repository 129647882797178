import ms from './ms';

const ALARM_SERVICE = 'alarm';
const ALARM_NAMESPACE = 'alarm';

export const getAlarms = async () => {
	const response = await ms.get({
		service: ALARM_SERVICE,
		namespace: ALARM_NAMESPACE,
		method: 'list'
	});

	return response.data;
};

export const dismissAlarm = (id, minutes = 10) =>
	ms.post({
		service: ALARM_SERVICE,
		namespace: ALARM_NAMESPACE,
		method: 'dismiss',
		data: {
			gracePeriod: Number(minutes)
		},
		endpointParameters: { id }
	});
