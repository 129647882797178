import * as types from './types';
import { types as navbarTypes } from '../navbar';

const initialState = {
	isOpen: false,
	selectedCategory: '',
	searchTerm: '',
	source: []
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.OPEN:
			return {
				...state,
				isOpen: true
			};
		case types.CLOSE:
			return {
				...state,
				isOpen: false,
				selectedCategory: '',
				closeAll: action.closeAll
			};
		case types.SELECT_CATEGORY:
			return {
				...state,
				selectedCategory: action.category,
				searchTerm: ''
			};
		case navbarTypes.SELECT_MODULE:
			return {
				...state,
				selectedCategory: '',
				searchTerm: ''
			};
		case types.CLOSE_CATEGORY:
			return {
				...state,
				selectedCategory: ''
			};
		case types.CHANGE_SEARCH_TERM:
			return {
				...state,
				searchTerm: action.searchTerm
			};
		case types.CLEAR_SEARCH_TERM:
			return {
				...state,
				searchTerm: ''
			};
		case types.SET_MENU_SOURCE:
			return {
				...state,
				source: action.data
			};
		default:
			return state;
	}
}
