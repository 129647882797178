import styled from 'styled-components';
import { colors, mixins, font } from 'theme';

export const logoutModule = {
	Container: styled.div`
		${mixins.flexCenter}
		padding: 8px 12px;
		cursor: pointer;
	`,
	Text: styled.span`
		margin-left: 8px;
		color: ${colors.blue};
		font-size: ${font.base};
		line-height: 15px;
		font-weight: 500;
	`
};
