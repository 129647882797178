import Chip from 'components/Chip';
import { mediaBreaks } from 'devices';
import styled from 'styled-components';

const { tabletLg: onlyMobile } = mediaBreaks;

export default {
	Chip: styled(Chip)`
		${({ isCategory }) => isCategory && 'margin-left: 12px;'}
		${onlyMobile`
			margin-left: 12px;
		`}
		height: 17px;
		width: 46px;
		padding: 9px 0;
		margin-right: 19px;
	`
};
