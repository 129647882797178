import axios from 'axios';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga4';
import qs from 'qs';
import { currentEnvironment } from 'config/environment';
import MicroServiceCallError from './microservice-call-error';
import RouterFetcher from './router-fetcher';

const instanceAxios = axios.create();
const envToLogInAnalytics = ['prod'];
if (envToLogInAnalytics.includes(currentEnvironment)) {
	instanceAxios.interceptors.request.use(config => {
		// eslint-disable-next-line no-param-reassign
		config.metadata = { startTime: new Date() };
		return config;
	});

	instanceAxios.interceptors.response.use(
		response => {
			response.config.metadata.endTime = new Date();
			response.duration = response.config.metadata.endTime - response.config.metadata.startTime;
			ReactGA.event('measurement_requests', {
				response_time: response.duration,
				endpoint: response.request.responseURL,
				client: response.config.headers['Janis-Client'],
				data_size: response.headers['content-length'] || '0',
				base_endpoint: response.request.responseURL.split('?')[0] || '',
				status_code: response?.status
			});
			return response;
		},
		error => {
			// eslint-disable-next-line no-param-reassign
			error.config.metadata.endTime = new Date();
			// eslint-disable-next-line no-param-reassign
			error.duration = error.config.metadata.endTime - error.config.metadata.startTime;
			ReactGA.event('measurement_requests', {
				response_time: error.duration,
				endpoint: error.request.responseURL,
				client: error.config.headers['Janis-Client'],
				data_size: '0',
				error_message: error?.message || '',
				base_endpoint: error?.config?.url.split('?')[0] || '',
				status_code: error?.response?.status
			});

			throw error;
		}
	);
}

const { CancelToken } = axios;

const secret = Cookies.get('JANIS_ACCESS_TOKEN');
let retriesRequest = 0;
const maxRetriesRequest = 1;

/**
 * Response of microservice.
 * @typedef {Object} MicroServiceCallResponse
 * @param {Number} StatusCode The status code of the response.
 * @param {String} StatusMessage The status message of the response.
 * @param {Object} headers The headers of the response.
 * @param {Object|String} body The body of the response (string if is '')
 */

/**
 * Response of the router.
 * @typedef {Object} RouterResponse
 * @param {String} endpoint The endpoint of microservice.
 * @param {String} httpMethod The httpMethod of endpoint.
 */

/**
 * @class MicroServiceCall
 * @classdesc Use this to make request to microservices.
 */
export default class MicroServiceCall {
	/**
	 * @param  {String} janisClient
	 */

	constructor(janisClient) {
		this.janisClient = janisClient;
		this.routerFetcher = new RouterFetcher();
		this.currentRequest = null;
	}

	/**
	 * Get the basic headers of that will be set in the request to the ms.
	 * @return {Object}
	 */
	getBasicHeaders() {
		const janisApiKeys = secret
			? { 'janis-api-key': 'Bearer', 'janis-api-secret': secret }
			: { 'janis-api-key': 'Bearer' };

		const basic = {
			'Content-Type': 'application/json',
			...janisApiKeys
		};

		if (this.janisClient) basic['Janis-Client'] = this.janisClient;

		return basic;
	}

	// Setter for janisClient
	set setJanisClient(janisClient) {
		this.janisClient = janisClient;
	}

	/**
	 * Get the endpoint data doing one request to the router.
	 * @param  {String} service The name of the microservice.
	 * @param  {String} namespace The namespace of the microservice.
	 * @param  {String} method The method of microservice.
	 * @return {Promise<RouterResponse>}
	 */
	getEndpointData(service, namespace, method) {
		return this.routerFetcher.getEndpoint(service, namespace, method);
	}

	/**
	 * Check if the service, namespace, method and httpMethod are valid and make the request to the correct ms.
	 * @param  {String} service The name of the microservice.
	 * @param  {String} namespace The namespace of the microservice.
	 * @param  {String} method The method of microservice.
	 * @param  {Object} data The data that will send
	 * @param  {Object} headers The headers of the request
	 * @param  {String} httpMethod The httpMethod executed.
	 * @param  {Object} endpointParameters The parameters of url paths ("namespace/method/{paramName}") { paramName: "test" }
	 * @param  {String} url Custom url for call request if not exist service, namespace and method
	 * @param  {Object} extendConfig extend config for axios request
	 * @param  {Boolean} cancelOverlappingRequests Defines if multiple simultaneous requests to the same URL should be blocked
	 * @return {Promise<MicroServiceCallResponse>}
	 */
	async call(
		service,
		namespace,
		method,
		data,
		headers,
		httpMethod,
		endpointParameters,
		url,
		extendConfig,
		cancelOverlappingRequests
	) {
		let requestEndpoint = url;
		let httpVerb = httpMethod;

		if (service && namespace && method) {
			const { endpoint, httpMethod: httpEndpoint } = await this.getEndpointData(
				service,
				namespace,
				method
			);

			requestEndpoint = endpoint;
			httpVerb = httpEndpoint;
		}

		return this.makeRequest({
			url: requestEndpoint,
			method: httpVerb,
			data,
			headers,
			endpointParameters,
			extendConfig,
			cancelOverlappingRequests
		});
	}

	/**
	 * Get the url of microservice with the endpoint parameters.
	 * @return {String}
	 */
	static getUrlWithEndpointParameters(endpoint, endpointParameters) {
		return Object.keys(endpointParameters || {}).reduce(
			(accum, endpointParam) =>
				accum.replace(`{${endpointParam}}`, endpointParameters[endpointParam]),
			endpoint
		);
	}

	static hasFieldQueries(url = '') {
		const queries = qs.parse(url) || {};
		return 'fields' in queries;
	}

	static removeFieldsFromUrl(url = '') {
		const [urlBase, queries] = url.split('?') || '';

		if (!urlBase) return '';

		const { fields, ...queryStrings } = qs.parse(queries);

		const parseQueries = qs.stringify(queryStrings, { encode: true, arrayFormat: 'indices' });

		return parseQueries ? `${urlBase}?${parseQueries}` : urlBase;
	}

	/**
	 * Make the request with all the necessary data.
	 * @param  {String} url The api endpoint returned by the router.
	 * @param  {String} method The httpMethod executed. default = 'GET'
	 * @param  {Object} data The data that will send
	 * @param  {Object} headers The headers of the request
	 * @param  {Object} endpointParameters The parameters of url paths ('namespace/method/{paramName}') { paramName: 'test' }
	 * @param  {Object} extendConfig extend config for axios request
	 * @param  {Boolean} cancelOverlappingRequests Defines if multiple simultaneous requests to the same URL should be blocked
	 * @return {Promise<MicroServiceCallResponse>}
	 */
	makeRequest({
		url,
		method = 'GET',
		data,
		headers,
		endpointParameters = {},
		extendConfig = {},
		cancelOverlappingRequests = false
	}) {
		return new Promise((resolve, reject) => {
			const dataOrParams = {};

			if (method.toUpperCase() === 'GET' && data) dataOrParams.params = data;

			if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(method.toUpperCase()) && data)
				dataOrParams.data = data;

			if (
				cancelOverlappingRequests &&
				this.currentRequest &&
				this.currentRequest.cancelOverlappingRequests &&
				this.currentRequest.url === url
			) {
				this.cancelLastRequest();
			}

			const config = {
				url: this.constructor.getUrlWithEndpointParameters(url, endpointParameters),
				method,
				headers: {
					...this.getBasicHeaders(),
					...headers
				},
				...dataOrParams,
				...extendConfig,
				cancelToken: new CancelToken(c => {
					this.cancelLastRequest = c;
				})
			};

			this.currentRequest = {
				cancelOverlappingRequests,
				url,
				hxr: instanceAxios(config)
			};

			this.currentRequest.hxr
				.then(response => {
					const { headers: responseHeaders, status, statusText, data: responseData } = response;
					if (status >= 400)
						return reject(
							new MicroServiceCallError(status, statusText, responseHeaders, responseData)
						);

					resolve({
						headers: responseHeaders,
						statusCode: status,
						statusMessage: statusText,
						data: responseData
					});
					retriesRequest = 0;
					this.currentRequest = null;
				})
				.catch(err => {
					const { request = {} } = err;
					const { responseURL = '' } = request;

					if (
						this.constructor.hasFieldQueries(responseURL) &&
						method.toLowerCase() === 'get' &&
						retriesRequest < maxRetriesRequest
					) {
						const urlWithoutFields = this.constructor.removeFieldsFromUrl(responseURL);
						retriesRequest = ++retriesRequest;

						return resolve(
							this.makeRequest({
								url: urlWithoutFields,
								method: 'GET',
								data: {},
								headers,
								endpointParameters: {},
								extendConfig: {},
								cancelOverlappingRequests: false
							})
						);
					}

					retriesRequest = 0;
					this.currentRequest = null;
					reject(err);
				});
		});
	}

	/**
	 * Make a get request to an microservice
	 * @param  {String} service The name of the microservice.
	 * @param  {String} namespace The namespace of the microservice.
	 * @param  {String} method The method of microservice.
	 * @param  {Object} data The data that will send
	 * @param  {Object} headers The headers of the request
	 * @param  {Object} endpointParameters The parameters of url paths ("namespace/method/{paramName}") { paramName: "test" }
	 * @param  {String} url Custom url for call request if not exist service, namespace and method
	 * @param  {Object} extendConfig extend config for axios request
	 * @param  {Boolean} cancelOverlappingRequests Defines if multiple simultaneous requests to the same URL should be blocked
	 * @return {Promise<MicroServiceCallResponse>}
	 */
	get({
		service,
		namespace,
		method,
		data = null,
		headers = null,
		endpointParameters,
		url,
		extendConfig,
		cancelOverlappingRequests
	}) {
		return this.call(
			service,
			namespace,
			method,
			data,
			{ 'x-janis-totals': false, ...headers },
			'GET',
			endpointParameters,
			url,
			extendConfig,
			cancelOverlappingRequests
		);
	}

	/**
	 * Make a get request to an microservice
	 * @param  {String} service The name of the microservice.
	 * @param  {String} namespace The namespace of the microservice.
	 * @param  {String} method The method of microservice.
	 * @param  {Object} data The data that will send
	 * @param  {Object} headers The headers of the request
	 * @param  {Object} endpointParameters The parameters of url paths ("namespace/method/{paramName}") { paramName: "test" }
	 * @param  {String} url Custom url for call request if not exist service, namespace and method
	 * @param  {Object} extendConfig extend config for axios request
	 * @param  {Boolean} cancelOverlappingRequests Defines if multiple simultaneous requests to the same URL should be blocked
	 * @return {Promise<MicroServiceCallResponse>}
	 */

	post({
		service,
		namespace,
		method,
		data,
		headers,
		endpointParameters,
		url,
		extendConfig,
		cancelOverlappingRequests
	}) {
		return this.call(
			service,
			namespace,
			method,
			data,
			headers,
			'POST',
			endpointParameters,
			url,
			extendConfig,
			cancelOverlappingRequests
		);
	}

	/**
	 * Make a get request to an microservice
	 * @param  {String} service The name of the microservice.
	 * @param  {String} namespace The namespace of the microservice.
	 * @param  {String} method The method of microservice.
	 * @param  {Object} data The data that will send
	 * @param  {Object} headers The headers of the request
	 * @param  {Object} endpointParameters The parameters of url paths ("namespace/method/{paramName}") { paramName: "test" }
	 * @param  {String} url Custom url for call request if not exist service, namespace and method
	 * @param  {Object} extendConfig extend config for axios request
	 * @param  {Boolean} cancelOverlappingRequests Defines if multiple simultaneous requests to the same URL should be blocked
	 * @return {Promise<MicroServiceCallResponse>}
	 */

	put({
		service,
		namespace,
		method,
		data,
		headers,
		endpointParameters,
		url,
		extendConfig,
		cancelOverlappingRequests
	}) {
		return this.call(
			service,
			namespace,
			method,
			data,
			headers,
			'PUT',
			endpointParameters,
			url,
			extendConfig,
			cancelOverlappingRequests
		);
	}

	/**
	 * Make a get request to an microservice
	 * @param  {String} service The name of the microservice.
	 * @param  {String} namespace The namespace of the microservice.
	 * @param  {String} method The method of microservice.
	 * @param  {Object} data The data that will send
	 * @param  {Object} headers The headers of the request
	 * @param  {Object} endpointParameters The parameters of url paths ("namespace/method/{paramName}") { paramName: "test" }
	 * @param  {String} url Custom url for call request if not exist service, namespace and method
	 * @param  {Object} extendConfig extend config for axios request
	 * @param  {Boolean} cancelOverlappingRequests Defines if multiple simultaneous requests to the same URL should be blocked
	 * @return {Promise<MicroServiceCallResponse>}
	 */

	patch({
		service,
		namespace,
		method,
		data,
		headers,
		endpointParameters,
		url,
		extendConfig,
		cancelOverlappingRequests
	}) {
		return this.call(
			service,
			namespace,
			method,
			data,
			headers,
			'PATCH',
			endpointParameters,
			url,
			extendConfig,
			cancelOverlappingRequests
		);
	}

	/**
	 * Make a get request to an microservice
	 * @param  {String} service The name of the microservice.
	 * @param  {String} namespace The namespace of the microservice.
	 * @param  {String} method The method of microservice.
	 * @param  {Object} data The data that will send
	 * @param  {Object} headers The headers of the request
	 * @param  {Object} endpointParameters The parameters of url paths ("namespace/method/{paramName}") { paramName: "test" }
	 * @param  {String} url Custom url for call request if not exist service, namespace and method
	 * @param  {Object} extendConfig extend config for axios request
	 * @param  {Boolean} cancelOverlappingRequests Defines if multiple simultaneous requests to the same URL should be blocked
	 * @return {Promise<MicroServiceCallResponse>}
	 */

	delete({
		service,
		namespace,
		method,
		data,
		headers,
		endpointParameters,
		url,
		extendConfig,
		cancelOverlappingRequests
	}) {
		return this.call(
			service,
			namespace,
			method,
			data,
			headers,
			'DELETE',
			endpointParameters,
			url,
			extendConfig,
			cancelOverlappingRequests
		);
	}
}
