import { useEffect, useState } from 'react';

const useProgressBar = value => {
	const [progress, setProgress] = useState(0);

	useEffect(
		() => {
			const timer = setInterval(() => {
				if (progress < value) setProgress(prev => prev + 1);
			}, 1000);

			return () => clearInterval(timer);
		},
		[value]
	);

	return { progress };
};

export default useProgressBar;
