import React from 'react';
import reactStringReplace from 'react-string-replace';
import { isObject } from 'utils';
import { getMappedFieldValue } from 'utils/mappers';
import { TranslateContext } from 'contexts/translateContext';
import TranslateWrapper from 'components/TranslateWrapper';
import i18n from '../i18n';
import { formatTranslationParams, formatTranslationParamsToValue } from './translate';

const t = i18n.t.bind(i18n);

const translateHelperComponentBase = (children, ...args) => {
	// children is used to translate values (key fromatted with four levels)

	const formatterFunction = children ? formatTranslationParamsToValue : formatTranslationParams;

	const formattedArgs = formatterFunction(args);

	const content = children || t(...formattedArgs);

	return (
		<TranslateContext.Consumer>
			{({ isTranslateActive }) =>
				isTranslateActive ? (
					<TranslateWrapper args={formattedArgs}> {content} </TranslateWrapper>
				) : (
					content
				)
			}
		</TranslateContext.Consumer>
	);
};

/** show the children with underline. and make translation modal with to the key
 * @param {*} value - translated value to add underline
 * @param {string} key -
 * @returns {component} -
 */
export const translateValueComponent = (value, key, ...args) =>
	translateHelperComponentBase(value, key, ...args);

// formatted the translate key into three levels
export const translateHelperComponent = (...args) => translateHelperComponentBase(false, ...args);

/**
 * Create template value which might include or not translations
 * @param {string} template
 * @param {array} fields
 * @returns string | ReactNode with translateValueComponent
 */
export const makeTemplateValue = (template, fields) => {
	const translatedFields = fields
		.map((field, i) => (isObject(field) ? { ...field, pos: i } : false))
		.filter(Boolean);

	let output = fields.reduce((acum, replacement, index) => {
		const parsedReplacement = isObject(replacement) ? `toBeReplaced-${index}` : replacement;
		return acum.replace(
			`{${index}}`,
			parsedReplacement || parsedReplacement === 0 ? parsedReplacement : ''
		);
	}, template);

	if (translatedFields.length) {
		translatedFields.forEach(translatedField => {
			const { pos, value, key } = translatedField;

			output = reactStringReplace(output, `toBeReplaced-${pos}`, () => {
				const reactKey = `${pos}--${value}--${key}`;
				return <span key={reactKey}>{translateValueComponent(value, key)}</span>;
			});
		});
	}

	return output;
};

/**
 * Make translated label value
 * @param {string} name
 * @param {string} labelValue
 * @param {boolean} translateLabels
 * @param {string} labelPrefix
 * @param {boolean} withComponent
 * @returns {object}
 */
export const makeLabelValue = (
	name,
	labelValue,
	translateLabels,
	labelPrefix,
	withComponent = true
) => {
	const mappers = [];

	const prefixMapper = { name: 'prefix', props: { value: labelPrefix } };

	if (labelPrefix) mappers.push(prefixMapper);

	if (translateLabels) mappers.push('translate');

	if (!mappers.length) return labelValue;

	const mapOptions = {
		mapper: mappers,
		value: labelValue,
		fieldName: name,
		makeTemplateValue,
		hasTranslateWrapper: withComponent
	};

	if (withComponent) mapOptions.translateValueComponent = translateValueComponent;

	return getMappedFieldValue(mapOptions);
};
