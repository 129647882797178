import styled, { css } from 'styled-components';
import { colors } from 'theme';
import { mediaBreaks } from 'devices';
import Spinner from 'components/Spinner';

const { onlyDesktop, tabletLg: onlyMobile } = mediaBreaks;

const getColumnWidth = (type, column) => {
	switch (type) {
		case 'even':
			return '49.2';
		default:
			return column === 'main' ? '64.2' : '34.2';
	}
};

export const styles = {
	StyledSpinner: styled(Spinner)`
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 3;
	`,
	Wrapper: styled.div`
		width: 100%;
		height: 100%;
		background-color: ${colors.white};

		${props =>
			props.isEditSection
				? css`
						${onlyDesktop`
							padding: 24px 20px;
							margin: ${props.hasTopComponents ? 68 : 16}px 26px 16px;
							display: flex;
						`}
						${onlyMobile`
							margin: 16px 15px;
							padding-top: 15px;
							border-radius: 3px;
						`}
				  `
				: `
					padding: 0;
					margin: 0;
				`}
	`,
	MainColumn: styled.div`
		flex-shrink: 0;
		${onlyDesktop`
			width: ${props => (props.singleColumn ? '100' : getColumnWidth(props.columnsType, 'main'))}%;
		`}
	`,
	SideColumn: styled.div`
		${onlyDesktop`
			margin-left: 1.55%;
			width: ${props => getColumnWidth(props.columnsType, 'side')}%;
		`}
		${onlyMobile`
			width: 100%;
		`}
	`,
	Group: styled.div`
		margin-bottom: 0px;
		padding: 0 12px;
		width: 100%;
	`,
	GroupHeader: styled.div`
		display: flex;
		align-items: center;
		margin-bottom: 20px;
	`,
	Icon: styled.div`
		margin-right: 8px;
	`,
	Title: styled.div`
		width: 100%;
	`,
	FieldWrapper: styled.div`
		width: 100%;
		display: flex;
		margin-bottom: 15px;
	`,
	Input: styled.div`
		width: 100%;
	`
};
