import moment from 'moment';
import { isDateISOString, parsePresets } from 'utils/dates';
import { startOfDay, endOfDay } from 'date-fns';
import { isObject } from 'utils';

export const formatInputData = date => {
	if (!date) return '';

	const includeSpacer = date.includes(':');
	const validateLength = length => date?.length === length;
	if (validateLength(1)) return `0${date}:00`;
	if (!includeSpacer && validateLength(2)) return `${date}:00`;
	if (includeSpacer && validateLength(3)) return `${date}00`;
	if (!includeSpacer && validateLength(4)) return `${date.substring(0, 2)}:${date.substring(2, 4)}`;
	if (!includeSpacer && validateLength(3))
		return `${date.substring(0, 2)}:${date.substring(2, 3)}0`;

	return date;
};

export const parseDatesValues = (
	value,
	availableValues,
	isRangePicker,
	setStart,
	setEnd,
	selectTime
) => {
	if (!value) return;
	if (typeof value === 'string' && value.length === 5 && value.includes(':')) return value;
	if (typeof value === 'string' && (availableValues?.length && availableValues.includes(value)))
		return parsePresets({ value, isRangePicker, setStart, setEnd, selectTime });
	if (typeof value === 'string' && moment(value).isValid() && !isRangePicker) return value;

	if (
		(value.from && moment(value.from).isValid()) ||
		(value.to && moment(value.to).isValid() && isRangePicker)
	)
		return value;
	if (
		value.from &&
		(availableValues?.length && availableValues.includes(value.from)) &&
		value.to &&
		(availableValues?.length && availableValues.includes(value.to)) &&
		isRangePicker
	) {
		const ObjectPresetValues = {
			from: parsePresets({ value: value.from, setStart: true }),
			to: parsePresets({ value: value.to, setEnd: true })
		};
		return ObjectPresetValues;
	}
	if (isObject(value) && !isRangePicker) {
		if (setStart) return moment(value).startOf('day');
		if (setEnd) return moment(value).endOf('day');
		return value;
	}
};

const setTimeInDate = (currentDate, prevDate) => {
	currentDate.setHours(prevDate.getHours());
	currentDate.setMinutes(prevDate.getMinutes());
	currentDate.setSeconds(prevDate.getSeconds());
};

export const setDateTime = ({
	newDate,
	isRangeStart,
	setStartOfDay,
	setEndOfDay,
	isRangePicker,
	savedDate
}) => {
	if (setStartOfDay || setEndOfDay || isRangePicker) {
		/** Sets the time at the start or end of the day according to settings */
		const getPresetTime = condition => (condition ? startOfDay(newDate) : endOfDay(newDate));

		if (isRangePicker) {
			const initialDate = savedDate || [];

			const [startDate, endDate] = initialDate;

			const setCurrentStartTime = isRangeStart && !!startDate;
			const setCurrentEndTime = !isRangeStart && !!endDate;

			if (setCurrentStartTime) setTimeInDate(newDate, startDate);
			if (setCurrentEndTime) setTimeInDate(newDate, endDate);

			if (!(setCurrentStartTime || setCurrentEndTime)) {
				newDate.setTime(getPresetTime(isRangeStart));
			}
		} else {
			if (savedDate) setTimeInDate(newDate, savedDate);

			if (setStartOfDay && !savedDate) newDate.setTime(getPresetTime(true));
			if (setEndOfDay && !savedDate) newDate.setTime(getPresetTime(false));
		}
	} else {
		const currentTime = new Date();
		setTimeInDate(newDate, savedDate || currentTime);
	}

	return newDate;
};

export const parseDate = (date, format, useTimezone) => {
	if (isDateISOString(date)) {
		return useTimezone
			? moment
					.utc(date)
					.local(true)
					.toDate()
			: new Date(date);
	}

	const dateTimeValue = moment.utc(date, format);

	const isValidDate = dateTimeValue && dateTimeValue.isValid();

	if (!isValidDate) return null;

	return useTimezone ? dateTimeValue.local(true).toDate() : dateTimeValue.toDate();
};

export const isDefaultValueDate = (defaultValue, selectedDate) => {
	if (!defaultValue || !selectedDate) return;
	if (defaultValue?.from && defaultValue?.to)
		return (
			moment(defaultValue.from).isSame(selectedDate[0]) &&
			moment(defaultValue.to).isSame(selectedDate[1])
		);
	return moment(defaultValue).isSame(selectedDate);
};
