import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions } from 'modules/menu';
import { Transition } from 'react-spring/renderprops';
import { menu as styled } from './styles';
import Cross from './Cross';
import Titles from './Titles';
import Searchbar from './Searchbar';
import CategoryMenu from './CategoryMenu';
import Back from './Searchbar/Back';

class Menu extends PureComponent {
	state = {
		isSearchBarOpen: false
	};

	render() {
		const {
			closeMenu,
			enterToUp,
			isNavbarOpen,
			isOpen,
			selectedCategory,
			onlyDesktop,
			onlyMobile
		} = this.props;

		const { isSearchBarOpen } = this.state;

		return (
			<styled.Container isOpen={isOpen} isNavbarOpen={isNavbarOpen}>
				{isOpen && (
					<Fragment>
						{onlyDesktop && <Cross />}
						<styled.AnimatedContainer enterToUp={enterToUp}>
							{onlyDesktop ? (
								<>
									<Titles />
									<Searchbar />
								</>
							) : (
								<Transition
									items={isSearchBarOpen}
									from={{ position: 'absolute', opacity: 0 }}
									enter={{ opacity: 1 }}
									leave={{ opacity: 0 }}
									config={{ duration: 100 }}
								>
									{toggle =>
										toggle
											? props => (
													<styled.MobileMenuHeader name="MobileMenuHeader" style={props}>
														<Searchbar />
														<styled.MobileMenuHeaderButton
															icon="cross_light"
															iconSize={!onlyDesktop && 24}
															rounded
															color="black"
															onClick={() => this.setState({ isSearchBarOpen: false })}
														/>
													</styled.MobileMenuHeader>
											  )
											: props => (
													<styled.MobileMenuHeader name="MobileMenuHeader" style={props}>
														{!selectedCategory ? (
															<styled.MobileMenuHeaderButton
																icon="arrow_left"
																rounded
																onClick={() => closeMenu(false, onlyMobile)}
															/>
														) : (
															<Back />
														)}
														<Titles />
														<styled.MobileMenuHeaderButton
															icon="search"
															rounded
															color="black"
															onClick={() => this.setState({ isSearchBarOpen: true })}
														/>
													</styled.MobileMenuHeader>
											  )
									}
								</Transition>
							)}
							<CategoryMenu />
						</styled.AnimatedContainer>
					</Fragment>
				)}
			</styled.Container>
		);
	}
}

Menu.propTypes = {
	closeMenu: PropTypes.func,
	enterToUp: PropTypes.bool.isRequired,
	isNavbarOpen: PropTypes.bool.isRequired,
	isOpen: PropTypes.bool.isRequired,
	selectedCategory: PropTypes.string,
	onlyDesktop: PropTypes.bool,
	onlyMobile: PropTypes.bool
};

const mapStateToProps = state => ({
	enterToUp: state.navbar.isChangingModule,
	isOpen: state.menu.isOpen,
	isNavbarOpen: state.navbar.isOpen,
	selectedCategory: state.menu.selectedCategory
});

const mapDispatchToProps = {
	closeMenu: actions.closeMenuOnly
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Menu);
