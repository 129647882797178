import styled from 'styled-components';

export default {
	ClientItem: styled.div`
		display: flex;
		align-items: center;
		height: 64px;
	`,
	ClientImage: styled.div`
		max-width: 54px;
		max-height: 54px;
		width: 100%;
		height: 100%;
		margin: 5px;
	`,
	ClientName: styled.div`
		display: flex;
		margin-left: 4px;
	`
};
