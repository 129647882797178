import { useState, useEffect } from 'react';

/**
 * Returns if the page is visible or not
 * @param {boolean} checkVisibility
 */
const useVisibility = checkVisibility => {
	const [visibility, setVisibility] = useState(true);

	useEffect(
		() => {
			if (checkVisibility) {
				const handleVisibility = () => {
					if (document.visibilityState === 'visible') setVisibility(true);
					if (document.visibilityState === 'hidden') setVisibility(false);
				};

				window.addEventListener('visibilitychange', handleVisibility);

				return () => window.removeEventListener('visibilitychange', handleVisibility);
			}
		},
		[checkVisibility]
	);

	return visibility;
};

export default useVisibility;
