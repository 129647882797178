import React from 'react';
import Spinner from 'components/Spinner';
import StyledPageWrapper from './styles';

const Loading = () => (
	<StyledPageWrapper>
		<Spinner />
	</StyledPageWrapper>
);

export default Loading;
