import styled from 'styled-components';
import { mediaBreaks } from 'devices';
import { font } from 'theme';

const { onlyDesktop, tabletLg: onlyMobile } = mediaBreaks;

export const pageError = {
	Container: styled.div`
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	`,
	Text: styled.p`
		color: #000000;
		font-family: ${font.family};
		font-weight: 700;
		letter-spacing: 0.05em;
		text-align: center;
		margin-top: 15vh;
		margin-bottom: 7vh;
		${onlyDesktop`
		font-size: 29px;	
	`}
		${onlyMobile`
		font-size: 20px;		
	`}
	`,
	Image: styled.img`
		display: block;
		max-width: 45vw;
		max-height: 40vh;
		min-height: 126px;
		min-width: 247px;
	`
};
