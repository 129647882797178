import React, { useState, useContext } from 'react';
import translationHOC from 'hocs/translationHOC';
import Switch from 'components/Switch';
import Button from 'components/Button';
import store from 'store';
import PropTypes from 'prop-types';
import { TranslateContext } from 'contexts/translateContext';
import { useDevices } from 'utils/hooks';
import ShowSchemaModal from './DevToolsComponents/ShowSchemaModal';
import ShowEditDataModal from './DevToolsComponents/ShowEditDataModal';
import { menu as styled } from './styles';
import { FieldLabel } from '../../FormSection/schema-components/styles';

const DevTools = ({ page, t }) => {
	const [noLogs, setNoLogs] = useState(store.get('noLogs'));
	const [cacheDisabled, setCacheDisabled] = useState(store.get('schemaCacheDisabled'));
	const [useDateTranslations, setUseDateTranslations] = useState(store.get('useDateTranslations'));
	const { isTranslateActive, setIsTranslateActive } = useContext(TranslateContext);
	const { onlyDesktop } = useDevices();

	const handlerCache = value => {
		setCacheDisabled(value);
		store.set('schemaCacheDisabled', value);
	};

	const handlerLogs = value => {
		setNoLogs(value);
		store.set('noLogs', value);
	};

	const handlerTranslate = value => {
		setIsTranslateActive(value);
		store.set('translationStatus', value);
	};

	const handlerUseDateTranslations = value => {
		setUseDateTranslations(value);
		store.set('useDateTranslations', value);
	};

	const handlerRemoveCache = () => {
		store.clearAll();
		handlerLogs(noLogs);
		handlerCache(cacheDisabled);
	};

	return (
		<styled.DevToolsWrapper>
			<styled.DevToolsField>
				<FieldLabel> {t('common.action.disabledCache')}</FieldLabel>
				<Switch value={cacheDisabled} onChange={handlerCache} />
			</styled.DevToolsField>
			<styled.DevToolsField>
				<FieldLabel> {t('common.action.clearCache')}</FieldLabel>
				<Button rounded icon="trash" onClick={handlerRemoveCache} />
			</styled.DevToolsField>
			<styled.DevToolsField>
				<FieldLabel> {t('common.action.noLogs')}</FieldLabel>
				<Switch value={noLogs} onChange={handlerLogs} />
			</styled.DevToolsField>
			<styled.DevToolsField>
				<FieldLabel>{t('common.action.updateTranslationsUseDate')}</FieldLabel>
				<Switch value={useDateTranslations} onChange={handlerUseDateTranslations} />
			</styled.DevToolsField>
			<styled.DevToolsField>
				<FieldLabel>{t('common.action.showSchema')}</FieldLabel>
				<ShowSchemaModal page={page} t={t} />
			</styled.DevToolsField>
			<styled.DevToolsField>
				<FieldLabel>{t('common.action.showEditData')}</FieldLabel>
				<ShowEditDataModal page={page} t={t} />
			</styled.DevToolsField>
			{onlyDesktop && (
				<styled.DevToolsField>
					<FieldLabel>{t('common.action.translatePage')}</FieldLabel>
					<Switch value={isTranslateActive} onChange={handlerTranslate} />
				</styled.DevToolsField>
			)}
		</styled.DevToolsWrapper>
	);
};

DevTools.propTypes = {
	page: PropTypes.shape({})
};

export default translationHOC(DevTools);
