import styled from 'styled-components';
import { colors } from 'theme';
import Input from 'components/Input';

const changeColor = props => {
	const { isFocused, error } = props;

	switch (true) {
		case error:
			return colors.statusRed;
		case isFocused:
			return colors.blue;
		default:
			return colors.grey;
	}
};

export default {
	FieldWrapper: styled.div`
		display: flex;
		align-items: center;
		height: 58px;
		margin-bottom: 14px;
	`,
	InputWrapper: styled.div`
		width: 100%;
	`,
	Input: styled(Input)`
		color: ${colors.black};
		border-bottom-color: ${props => changeColor(props)};
	`
};
