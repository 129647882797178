import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import translationHOC from 'hocs/translationHOC';
import Title from 'components/AuthResources/Title';
import styled from 'components/AuthResources/styles';
import LoginForm from './components/LoginForm';
import ClientForm from './components/ClientForm';
import TitleContent from './components/TitleContent';

export const ContentContext = React.createContext({
	clientCode: '',
	currentForm: 'login'
});

class Content extends PureComponent {
	constructor(props) {
		super(props);

		const clientCode = this.getClientCodeFromLocationOrStorage();

		this.state = {
			currentForm: 'login',
			clientCode
		};
	}

	componentDidMount() {
		const { translateHelperString } = this.props;

		const title = `${translateHelperString('common.page.title.login')} | JANIS`;

		//  Set document title
		if (document.title !== title) document.title = title;
	}

	/**
	 * Get clientCode from location or sessionStorage if exists
	 */
	getClientCodeFromLocationOrStorage = () => {
		const { host } = window.location;

		const clientStorage = sessionStorage.getItem('client') || '';

		const hostParts = host.split('.');

		// If exists in location
		if (hostParts && hostParts.length === 4) return hostParts[0];

		// If exists in sessionStorage
		return clientStorage;
	};

	/**
	 * Fn for set client setted in clientForm
	 * @param {object} formData
	 */
	setClient = formData => {
		sessionStorage.setItem('client', formData.clientCode);

		this.setState({ clientCode: formData.clientCode }, () => {
			this.setForm('login');
		});
	};

	/**
	 * Fn for clearing the client and returning to the initial LoginForm
	 */
	clearClient = () => {
		sessionStorage.removeItem('client');

		this.setState({ clientCode: undefined }, () => {
			this.setForm('login');
		});
	};

	/**
	 * Fn for set current form for render
	 * @param {string} currentForm
	 */
	setForm = currentForm => {
		this.setState({ currentForm });
	};

	render() {
		const { currentForm, clientCode } = this.state;

		return (
			<ContentContext.Provider value={{ clientCode, currentForm }}>
				<styled.Container>
					<Title>
						<TitleContent setForm={this.setForm} />
					</Title>

					<LoginForm setForm={this.setForm} />

					<ClientForm onSubmit={this.setClient} clearClient={this.clearClient} />
				</styled.Container>
			</ContentContext.Provider>
		);
	}
}

Content.propTypes = {
	translateHelperString: PropTypes.func
};

export default translationHOC(Content);
