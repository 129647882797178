import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import translationHOC from 'hocs/translationHOC';
import SectionLoader from 'components/SectionLoader';
import { asyncValidate } from './validation';
import getConditions from './conditions';
import { content as styled } from './styles';

const FormContent = lazy(() => import('./FormContent'));

const Form = ({
	handleSubmit,
	initialValues,
	fieldsGroup,
	form,
	formSection,
	conditionals,
	groupConditionals,
	schema,
	isEditSection
}) => (
	<styled.ContainerForm onSubmit={handleSubmit}>
		<Suspense
			fallback={
				<SectionLoader schemaData={schema} isEditSection={isEditSection} hasTopComponents={false} />
			}
		>
			<FormContent
				conditionals={conditionals}
				groupConditionals={groupConditionals}
				fieldsGroup={fieldsGroup}
				form={form}
				formSection={formSection}
				initialValues={initialValues}
				columnsType={schema.columnsType}
			/>
		</Suspense>
	</styled.ContainerForm>
);

Form.propTypes = {
	schema: PropTypes.shape({}),
	conditionals: PropTypes.shape({}),
	groupConditionals: PropTypes.shape({}),
	handleSubmit: PropTypes.func,
	initialValues: PropTypes.shape({}),
	fieldsGroup: PropTypes.arrayOf(PropTypes.shape({})),
	form: PropTypes.string,
	formSection: PropTypes.string,
	isEditSection: PropTypes.bool
};

const FormWithConditions = connect((state, props) => getConditions(state, props))(Form);

/**
 * Pass a id for return a new Form connected
 */
export default form => {
	// ReduxForm initialize
	const FormConnected = reduxForm({
		form,
		asyncValidate,
		shouldAsyncValidate: ({ trigger }) => {
			switch (trigger) {
				case 'blur':
				case 'change':
					return true;
				case 'submit':
					return false;
				default:
					return false;
			}
		}
	})(FormWithConditions);

	// Pass i18n props
	return translationHOC(FormConnected);
};
