import React from 'react';
import Icon from 'components/Icon';
import { searchInput as styled } from './styles';
import Cross from './Cross';
import Input from './Input';

const SearchInput = props => (
	<styled.Container>
		<Icon name="search" color="darkGrey" />
		<Input autoFocus {...props} />
		<Cross />
	</styled.Container>
);

export default SearchInput;
