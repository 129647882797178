import { debounce } from 'utils';
import { makeDateTimePickerRelativeValues } from 'utils/dates';
import { isEmptyValue } from './actions-utils';
import {
	CHANGE_FILTER,
	CHANGE_FILTERS,
	CLEAR_FILTER,
	CLEAR_ALL_FILTERS,
	APPLY_FILTERS,
	APPLY_FILTERS_TOGGLE,
	SET_DEFAULTVALUES
} from '../types';
import { fetchData } from './fetch';
import { changeSort } from './sort';

export const changeFilter = (name, value) => currentBrowse => ({
	type: CHANGE_FILTER,
	name,
	value,
	meta: { name: currentBrowse }
});

export const changeFilters = values => currentBrowse => ({
	type: CHANGE_FILTERS,
	values,
	meta: { name: currentBrowse }
});

const clearFilter = name => currentBrowse => ({
	type: CLEAR_FILTER,
	name,
	meta: { name: currentBrowse }
});

export const clearAllFiltersAction = () => currentBrowse => ({
	type: CLEAR_ALL_FILTERS,
	meta: { name: currentBrowse }
});

export const handleApplyFiltersToggle = () => currentFilters => ({
	type: APPLY_FILTERS_TOGGLE,
	meta: { name: currentFilters }
});

const fetchDataDebounced = debounce((dispatch, currentBrowse) => {
	dispatch(handleApplyFiltersToggle()(currentBrowse));
	dispatch(fetchData()(currentBrowse));
}, 500);

export const setApplyFiltersFlag = value => currentBrowse => ({
	type: APPLY_FILTERS,
	meta: { name: currentBrowse },
	value
});
export const setInitialDefaultValues = values => currentBrowse => ({
	type: SET_DEFAULTVALUES,
	values,
	meta: { name: currentBrowse }
});

export const setDefaultValues = values => currentBrowse => dispatch => {
	dispatch(setInitialDefaultValues(values)(currentBrowse));
};

export const clearSingleFilter = name => currentBrowse => dispatch => {
	dispatch(clearFilter(name)(currentBrowse));
	dispatch(handleApplyFiltersToggle()(currentBrowse));
	dispatch(fetchData()(currentBrowse));
	dispatch(setApplyFiltersFlag(true)(currentBrowse));
};

export const clearAllFilters = currentBrowse => dispatch => {
	dispatch(clearAllFiltersAction()(currentBrowse));
	dispatch(handleApplyFiltersToggle()(currentBrowse));
	dispatch(fetchData()(currentBrowse));
	dispatch(setApplyFiltersFlag(true)(currentBrowse));
};

export const changeFilterHelper = (
	name,
	currentValue,
	applyFilterValue = false
) => currentBrowse => dispatch => {
	if (isEmptyValue(currentValue)) dispatch(clearFilter(name)(currentBrowse));
	else dispatch(changeFilter(name, currentValue)(currentBrowse));

	dispatch(setApplyFiltersFlag(applyFilterValue)(currentBrowse));
};

/**
 * Action to apply filters simultaneously
 * @param {object} values
 */
export const changeAndApplyFilters = values => currentBrowse => dispatch => {
	dispatch(changeFilters(values)(currentBrowse));
	dispatch(handleApplyFiltersToggle()(currentBrowse));
	dispatch(fetchData()(currentBrowse));
};

/**
 * Action to apply filters one by one
 * @param {string} name
 * @param {string|number|array} currentValue
 */
export const changeAndApplyFilter = (name, currentValue) => currentBrowse => dispatch => {
	dispatch(changeFilterHelper(name, currentValue)(currentBrowse));
	dispatch(handleApplyFiltersToggle()(currentBrowse));
	dispatch(fetchData()(currentBrowse));
};

/**
 * Action to apply filters one by one debounced
 * @param {string|number|array} currentValue
 */
export const changeAndApplyFilterDebounced = (name, currentValue) => currentBrowse => dispatch => {
	dispatch(changeFilterHelper(name, currentValue)(currentBrowse));
	fetchDataDebounced(dispatch, currentBrowse);
};

/**
 * Check current filters in URL for dispatch actions for change filters in store
 * @param {object} parsedParams
 */
export const checkUrlFilter = parsedParams => currentBrowse => (dispatch, getState) => {
	const { browses } = getState();

	const browse = browses[currentBrowse];

	const filtersComponents = browse.filters || [];
	const sortableFields = (browse.schema && browse.schema.sortableFields) || [];

	Object.keys(parsedParams).forEach(keyParam => {
		const valueParam = parsedParams[keyParam];

		if (keyParam === 'filters') {
			const filters = valueParam;
			const filterKeys = Object.keys(filters);

			filterKeys.forEach(key => {
				const currentFilterComponent = filtersComponents.find(filter => filter.name === key);
				const filterValue = makeDateTimePickerRelativeValues(currentFilterComponent, filters);

				dispatch(changeFilter(key, filterValue || filters[key])(currentBrowse));
			});

			dispatch(handleApplyFiltersToggle()(currentBrowse));
		}
	});

	const { sortBy, sortDirection } = parsedParams;

	const intialSortBy = sortableFields
		.filter(option => sortBy && sortBy.includes(option.name))
		.map((sort, index) => ({
			...sort,
			initialSortDirection: Array.isArray(sortDirection)
				? sortDirection[index]
				: sortDirection || sort.initialSortDirection
		}));

	if (intialSortBy.length) dispatch(changeSort(intialSortBy)(currentBrowse));
};
