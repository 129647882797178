import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { triggerSubmit } from 'modules/formSection/actions';
import { translateHelperComponent as t } from 'utils/translateComponent';
import { translateHelperString } from 'utils/translate';
import FormSectionModule from 'components/FormSectionModule';
import BaseModal from 'components/Modal';
import Button from 'components/Button';
import makeSchema from './base-schema';
import styled from './styles';

const Modal = ({ isOpen, toggleModal, item, submit }) => {
	const schema = makeSchema(item);

	const handleCloseModal = e => {
		e.preventDefault();
		e.stopPropagation();
		toggleModal(false);
	};

	return (
		<BaseModal
			width="large"
			isOpen={isOpen}
			handleCloseModal={handleCloseModal}
			defaultTitle={t('common.title.translate')}
		>
			{schema ? (
				<styled.FormSectionWrapper>
					<FormSectionModule
						name={schema.name}
						schema={schema.fields}
						endpoint={schema.target}
						endpointParameters={schema.targetEndpointParameters}
						hideUserCreated
						hideUserModified
					/>
				</styled.FormSectionWrapper>
			) : (
				<styled.Text>{t('views.error.noResultsFound')}</styled.Text>
			)}
			<styled.BottomContent>
				<styled.Button variant="outlined" onClick={handleCloseModal}>
					{translateHelperString('common.action.cancel')}
				</styled.Button>
				{schema && (
					<Button type="button" variant="contained" onClick={() => submit(schema.name)}>
						{translateHelperString('common.action.apply')}
					</Button>
				)}
			</styled.BottomContent>
		</BaseModal>
	);
};

Modal.propTypes = {
	isOpen: PropTypes.bool,
	toggleModal: PropTypes.func,
	item: PropTypes.arrayOf(PropTypes.shape({})),
	submit: PropTypes.func
};

Modal.defaultProps = {
	isOpen: false,
	item: []
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			submit: formSection => triggerSubmit('applyForm')(formSection)
		},
		dispatch
	);

export default connect(
	null,
	mapDispatchToProps
)(Modal);
