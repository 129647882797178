import styled from 'styled-components';
import Button from 'components/Button';

export default {
	FormSectionWrapper: styled.div`
		/* stylelint-disable declaration-no-important */
		form > div > div {
			padding: 0px !important;
			margin-top: 25px;
		}
		/* stylelint-enable */
	`,
	Text: styled.span`
		margin-top: 25px;
	`,
	BottomContent: styled.div`
		display: flex;
		justify-content: flex-end;
	`,
	Button: styled(Button)`
		margin-right: 8px;
	`
};
