import styled from 'styled-components';
import { font, findColorInTheme } from 'theme';
import Icon from 'components/Icon';
import { mediaBreaks } from 'devices';

const { onlyDesktop, tabletLg, tablet, mobile, xsMobile } = mediaBreaks;
const disabledOpacity = '0.5';

const getFontWeight = fontWeight => {
	switch (fontWeight) {
		case 'light':
			return '300';
		case 'normal':
			return '400';
		case 'medium':
			return '500';
		case 'bold':
			return '700';
		default:
			return '400';
	}
};

export const StyledWrapper = styled.span`
	display: flex;
	align-items: center;

	${tabletLg`
		width: 100%;
		max-width: 85vw;
	`}
	
	${tablet`
		width: 100%;
		max-width: 85vw;
	`}

	${mobile`
		width: 100%;
		max-width: 67vw;
	`}

	${xsMobile`
		width: 100%;
		max-width: 57vw;
	`}
	
	${onlyDesktop`
		width: 100%;
		max-width: 15vw;
	`}
`;

export const StyledText = styled.span`
	font-weight: ${props => getFontWeight(props.fontWeight)};
	color: ${props => findColorInTheme(props.color)};
	opacity: ${props => props.disabled && disabledOpacity};
	font-size: ${props => (props.fontSize ? font[props.fontSize] : font.base)};
	text-overflow: ellipsis;
	overflow: hidden;
	width: 100%;
	white-space: ${props => props.withWhiteSpace && 'nowrap'};
	overflow-wrap: break-word;
	line-height: 18px;
`;

export const StyledIcon = styled(Icon)`
	margin-right: 8px;
	align-self: center;
	flex-shrink: 0;
`;
