import axios from 'axios';

axios.interceptors.request.use(
	config => {
		const currentConfig = { ...config };

		if (/\/api\/login/.test(config.url) && process.env.NODE_ENV !== 'development')
			currentConfig.withCredentials = true;

		return currentConfig;
	},
	error => Promise.reject(error)
);

export * from './alarms';
export * from './user';
export * from './page';
