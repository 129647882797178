import { get } from 'lodash';
import * as types from './types';

const initialState = {
	appliedFilters: {},
	defaultValues: {},
	filtersAreApplied: true,
	applyFiltersToggle: true,
	rows: [],
	error: false,
	errorData: {},
	filters: [],
	isFetching: true,
	isReady: false,
	sortBy: [],
	hasMassiveActions: false,
	massiveActionsAreFetching: false,
	massiveActionsAreReady: false,
	showMassiveActionsCheckboxes: false,
	selectedRows: [],
	allRowsSelected: false,
	preview: {
		hasPreview: false,
		withinPreview: false,
		currentPreview: '',
		currentRowCollapsePreview: ''
	},
	page: 1,
	pageSize: 60,
	total: null,
	isEmptyBrowse: false,
	useUrlUpdates: false,
	asyncWrapperStatus: {}
};

const normalizeNewFilters = fields =>
	fields.map(field => {
		const { componentAttributes = {} } = field;
		return {
			...field,
			remote: (componentAttributes.options || {}).scope === 'remote',
			attributes: field.attributes || {}
		};
	});

const normalizeFilters = fields =>
	fields
		.filter(field => field.filter)
		.map(field => ({
			name: field.name,
			label: field.label,
			mapper: field.mapper,
			...field.filter,
			isFetching: field.remote,
			attributes: field.filter.attributes || {},
			componentAttributes: field.filter.componentAttributes || {}
		}));

const getInitialSort = (fields = [], sortableFields = []) => {
	const sortableFieldItems = sortableFields.filter(({ isDefaultSort }) => isDefaultSort);
	const fieldItems = fields.filter(({ attributes }) => attributes && attributes.isDefaultSort);

	const fieldItemsAttributes = fieldItems.map(({ name, attributes }) => ({
		name,
		isDefaultSort: attributes.isDefaultSort,
		initialSortDirection: attributes.initialSortDirection
	}));

	if (sortableFieldItems.length) return { sortBy: sortableFieldItems };

	if (fieldItemsAttributes.length) return { sortBy: fieldItemsAttributes };
};

const mergeState = (state, action, properties) => ({
	...state,
	[action.meta.name]: {
		...state[action.meta.name],
		...properties
	}
});

const mergePreviewState = (state, action, properties) => {
	const { name } = action.meta;

	const previewSate = get(state, `${name}.preview`);

	const preview = { ...previewSate, ...properties };

	return mergeState(state, action, { preview });
};

export default function reducer(state = {}, action) {
	switch (action.type) {
		case types.FETCH_DATA_REQUEST:
			return mergeState(state, action, {
				isFetching: true
			});
		case types.FETCH_SUCCESS_WITHOUT_TOTAL:
			return mergeState(state, action, {
				isFetching: false,
				rows: action.rows,
				total: action.total,
				page: action.page,
				isReady: true,
				error: false,
				errorData: {},
				allRowsSelected: false,
				selectedRows: []
			});
		case types.FETCH_DEPENDENCIES_SUCCESS:
			return mergeState(state, action, {
				isFetching: false,
				rows: action.rows,
				page: action.page,
				isReady: true,
				error: false,
				errorData: {},
				allRowsSelected: false,
				selectedRows: []
			});
		case types.FETCH_TOTAL_SUCCESS:
			return mergeState(state, action, {
				total: action.total,
				isFetching: false,
				error: false
			});

		case types.FETCH_DATA_FAILURE:
			return mergeState(state, action, {
				isFetching: false,
				isReady: true,
				error: true,
				errorData: action.status
			});

		case types.CLEAR_DATA:
			return initialState;

		case types.CHANGE_FILTER:
			return mergeState(state, action, {
				appliedFilters: {
					...state[action.meta.name].appliedFilters,
					[action.name]: action.value
				}
			});

		case types.CHANGE_FILTERS:
			return mergeState(state, action, {
				appliedFilters: action.values
			});

		case types.CLEAR_FILTER: {
			const appliedFilters = { ...state[action.meta.name].appliedFilters };
			delete appliedFilters[action.name];

			return mergeState(state, action, {
				appliedFilters
			});
		}

		case types.APPLY_FILTERS_TOGGLE:
			return mergeState(state, action, {
				applyFiltersToggle: !state[action.meta.name].applyFiltersToggle
			});

		case types.CLEAR_ALL_FILTERS:
			return mergeState(state, action, {
				appliedFilters: { ...state[action.meta.name]?.defaultValues }
			});

		case types.APPLY_FILTERS:
			return mergeState(state, action, {
				filtersAreApplied: action.value
			});

		case types.CHANGE_SORT:
			return mergeState(state, action, {
				sortBy: action.sortBy
			});

		case types.SET_EMPTY_BROWSE:
			return mergeState(state, action, {
				isEmptyBrowse: action.status
			});

		case types.FETCH_MASSIVE_ACTIONS_REQUEST:
			return mergeState(state, action, {
				massiveActionsAreFetching: true,
				massiveActionsAreReady: false
			});

		case types.FETCH_MASSIVE_ACTIONS_SUCCESS:
			return mergeState(state, action, {
				hasMassiveActions: action.hasMassiveActions,
				massiveActionsAreFetching: false,
				massiveActionsAreReady: true
			});

		case types.FETCH_MASSIVE_ACTIONS_FAILURE:
			return mergeState(state, action, {
				massiveActionsAreFetching: false,
				massiveActionsAreReady: true
			});

		case types.SHOW_MASSIVE_ACTIONS_CHECKBOXES:
			return mergeState(state, action, {
				showMassiveActionsCheckboxes: !state[action.meta.name].showMassiveActionsCheckboxes
			});

		case types.CHANGE_SELECTED_ROWS:
			return mergeState(state, action, {
				selectedRows: action.selectedRows,
				allRowsSelected: action.allRowsSelected
			});

		case types.SET_HAS_PREVIEW_SCHEMA:
			return mergePreviewState(state, action, {
				hasPreview: action.hasPreview,
				currentPreview: action.meta.preview
			});

		case types.SET_HAS_ROW_COLLAPSE_PREVIEW_SCHEMA:
			return mergePreviewState(state, action, {
				hasPreview: action.hasPreview,
				currentRowCollapsePreview: action.meta.preview
			});

		case types.SET_BROWSE_INITIAL_STATES:
			return mergeState(state, action, {
				pageSize: action.schema.pageSize || initialState.pageSize,
				filters: [
					...normalizeFilters(action.schema.fields),
					...normalizeNewFilters(action.schema.filters || [])
				],
				...getInitialSort(action.schema.fields, action.schema.sortableFields)
			});

		case types.START_ASYNC_LOADING:
			return mergeState(state, action, {
				asyncWrapperStatus: {
					...state[action.meta.name].asyncWrapperStatus,
					[action.name]: {
						isLoading: true,
						hasError: false
					}
				}
			});

		case types.STOP_ASYNC_LOADING:
			return mergeState(state, action, {
				asyncWrapperStatus: {
					...state[action.meta.name].asyncWrapperStatus,
					[action.name]: {
						isLoading: false,
						hasError: false
					}
				}
			});

		case types.SET_ASYNC_ERROR:
			return mergeState(state, action, {
				asyncWrapperStatus: {
					...state[action.meta.name].asyncWrapperStatus,
					[action.name]: {
						isLoading: false,
						hasError: true
					}
				}
			});

		case types.INIT_BROWSE: {
			const browse = {
				...state,
				[action.browse]: {
					...initialState,
					schema: action.schema,
					useUrlUpdates: action.useUrlUpdates,
					preview: {
						...initialState.preview,
						withinPreview: action.withinPreview
					}
				}
			};

			return browse;
		}

		case types.CLEAR_BROWSE:
			return Object.keys(state).reduce((accum, key) => {
				const accumulator = { ...accum };
				if (action.browse !== key) accumulator[key] = state[key];
				return accumulator;
			}, {});

		case types.SET_DEFAULTVALUES:
			return mergeState(state, action, {
				defaultValues: {
					...state[action.meta.name].defaultValues,
					...action.values
				}
			});
		default:
			return state;
	}
}
