import * as types from './types';

export const initialError = { hasError: false, errorData: {} };

const initialState = {
	schema: {},
	mainInfo: {
		data: {},
		selectedRows: [],
		isLoading: true,
		allRowsSelected: false,
		isCreateRouteStep: false,
		driverData: {},
		errorStatus: initialError,
		userGoBack: false,
		stopsColorByWarehouseId: {},
		stops: [],
		selectedSchedule: {},
		routeData: {},
		canOptimize: false
	}
};

/**
 *
 * @param {object} currentProperty fields to change into state with the value
 * @param {string} section section state name (warehouseInfo || mainInfo || schema)
 * @param {object} state current state
 * @returns {object} state
 */
const mergeState = (state, action, properties) => ({
	...state,
	[action.section]: {
		...state[action.section],
		...properties
	}
});

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case types.CREATE_PAGE:
			return { ...state, schema: action.schema };

		case types.FETCH_MAIN_DATA_SUCCESS:
			return mergeState(
				state,
				{ section: 'mainInfo' },
				{
					data:
						action.data.hasFilter || !action.data.applyFiltersToggle
							? action.data.responseData
							: state.mainInfo.data,
					errorStatus: initialError
				}
			);

		case types.CLEAN_LIST_DATA:
			return mergeState(
				state,
				{ section: 'mainInfo' },
				{ data: initialState.data, isLoading: false }
			);

		case types.SET_LOADING:
			return mergeState(state, action, {
				errorStatus: initialError,
				isLoading: action.isLoading
			});

		case types.SET_ERROR:
			return mergeState(state, action, {
				isLoading: false,
				errorStatus: { hasError: true, errorData: action.status }
			});

		case types.SET_STOPS:
			return mergeState(state, action, {
				stops: action.stops
			});

		case types.SET_ROUTE_DATA:
			return mergeState(state, action, {
				routeData: action.routeData
			});

		case types.SET_SCHEDULE:
			return mergeState(state, { section: 'mainInfo' }, { selectedSchedule: action.schedule });

		case types.UPDATE_FIELD: {
			const { section, field, value } = action;

			return mergeState(
				state,
				{ section },
				{
					[field]: value
				}
			);
		}

		case types.UPDATE_SEVERAL_FIELDS: {
			const { section, fields } = action;

			return mergeState(state, { section }, fields);
		}

		case types.CHANGE_SELECTED_ROWS:
			return mergeState(
				state,
				{ section: 'mainInfo' },
				{ selectedRows: action.selectedRows, allRowsSelected: action.allRowsSelected }
			);

		case types.CHANGE_STEP:
			return mergeState(
				state,
				{ section: 'mainInfo' },
				{ isCreateRouteStep: action.isCreateRouteStep }
			);

		case types.SEND_DRIVER_DATA:
			return mergeState(state, { section: 'mainInfo' }, { driverData: action.driverData });

		case types.GO_BACK:
			return mergeState(state, { section: 'mainInfo' }, { userGoBack: action.payload });

		case types.CLEAN_STATE:
			return mergeState(
				state,
				{ section: 'mainInfo' },
				{
					data: {},
					selectedRows: [],
					isLoading: false,
					allRowsSelected: false,
					isCreateRouteStep: false,
					driverData: {},
					errorStatus: initialError,
					userGoBack: false
				}
			);
		case types.STOPS_COLOR:
			return mergeState(
				state,
				{ section: 'mainInfo' },
				{ stopsColorByWarehouseId: action.payload }
			);

		default:
			return state;
	}
}
