import styled, { css } from 'styled-components';
import Button from 'components/Button';
import Chip from 'components/Chip';
import Link from 'components/Link';
import { colors, mixins, font } from 'theme';
import { mediaBreaks } from 'devices';
import { openedNavbarWidth, closedNavbarWidth } from '../styles';

export const menuWidth = 360;
export const initialLeftWithOpenedNavbar = -menuWidth + openedNavbarWidth;
export const initialLeftWithClosedNavbar = -menuWidth + closedNavbarWidth;

const { onlyDesktop, tabletLg: onlyMobile } = mediaBreaks;

export const menu = {
	DevToolsWrapper: styled.div`
		display: flex;
		flex-direction: column;
		align-items: start;
		margin: 16px auto;
	`,
	DevToolsField: styled.div`
		display: flex;
		justify-content: space-between;
		width: 205px;
	`,
	DevToolsLink: styled(Link)`
		display: flex;
		margin-bottom: 8px;
	`,
	DesktopContainer: styled.div`
		width: ${menuWidth}px;
		height: 100vh;
		display: flex;
		flex-direction: column;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9;
		opacity: 0;
		background-color: ${colors.white};
		border: 1px solid #${colors.lightGrey};
		box-shadow: 0 2px 5px 0 rgba(39, 57, 71, 0.15);
		${mixins.transition('transform, opacity')};
		transition-duration: ${props => (props.isNavbarOpen ? '.2s' : '.15s')};
		overflow: hidden;
		transform: translateX(
			${props => (props.isNavbarOpen ? initialLeftWithOpenedNavbar : initialLeftWithClosedNavbar)}px
		);

		${props =>
			props.isOpen &&
			css`
				transform: translateX(
					${({ isNavbarOpen }) => (isNavbarOpen ? openedNavbarWidth : closedNavbarWidth)}px
				);
				opacity: 1;
				transition-property: transform;
				transition-duration: 0.2s;
			`}
		${props =>
			props.isDev &&
			css`
				overflow-y: auto;
				${mixins.scrollbar(colors.greyPressed)}
			`}
	`,
	MobileContainer: styled.div`
		display: flex;
		flex-direction: column;
		height: calc(100% - 45px);
		background-color: ${colors.white};
		position: fixed;
		z-index: 9;
		left: 0;
		right: 0;
		overflow-y: auto;
	`,
	Detail: styled.div`
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: auto;
		${onlyDesktop`
			padding: 16px;
		`}
	`,
	Close: styled(Button)`
		align-self: flex-end;
	`,
	Title: styled.div`
		${mixins.flexCenter}
		width: 100%;
		height: 55px;
		margin-bottom: 22px;
		color: ${colors.black};
		font-size: ${font.medium};
		border-bottom: 1px solid ${colors.lightGrey};
	`,
	Chip: styled(Chip)`
		margin: 29px 0 24px;
		${onlyMobile`
			margin: 18px 0 10px;
		`}
	`,
	Profile: styled.span`
		color: ${colors.white};
		font-size: 15px;
		font-weight: 900;
	`,
	Text: styled.span`
		color: ${colors.black};
		font-size: ${font.large};
		line-height: 24px;
		${({ bold }) => bold && 'font-weight: bold'};
	`,
	Email: styled.span`
		margin: 4px 0 24px;
		color: ${colors.darkGrey};
		font-size: ${font.base};
		line-height: 24px;
		${onlyMobile`
			margin: 0 0 10px;
		`}
	`,
	Container: styled.div`
		height: 100px;
		${mixins.flexCenter}
		border-top: 1px solid ${colors.lightGrey};
		${onlyMobile`
			height: 73px;
		`}
	`,
	ActiveIcon: styled.div`
		${mixins.flexCenter}
		width: 40px;
		height: 40px;
		margin-right: 12px;
		background-color: ${colors.lightGrey};
		border-radius: 50%;
	`,
	ActiveText: styled.span`
		color: ${colors.darkGrey};
		font-size: ${font.base};
	`,
	Link: styled(Link)`
		margin-bottom: 34px;
	`
};
